import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

export const TaskRedirect = () => {
  const params = useParams<{ date: string; seriesId: string }>();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (params.date && params.seriesId) {
      searchParams.set('seriesId', params.seriesId);
      searchParams.set('date', params.date);
      searchParams.set('open', 'task');
      navigate(`/dashboard/assignments?${searchParams.toString()}`);
    }
  }, [navigate, params.date, params.seriesId, searchParams]);

  return null;
};
