import { InspectionInstance } from '@dakota/platform-client';
import { clsx } from 'clsx';
import { Spinner } from 'components/Spinner';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC, MouseEvent, useState } from 'react';
import { EditSeriesChoice } from 'types';

import ChoiceMenu from './ChoiceMenu';

type Props = {
  fetchInspectionSeries: (choice: EditSeriesChoice) => void;
  inspection: InspectionInstance;
  isLoadingSeries: boolean;
};

const buttonClasses = clsx(
  'flex items-center gap-1 px-2 py-1 text-green-base text-sm',
  'border border-green-base rounded h-full',
  'hover:bg-green-base hover:text-white',
  'disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed',
  'disabled:border-gray-400',
);

export const Buttons: FC<Props> = ({
  fetchInspectionSeries,
  inspection,
  isLoadingSeries,
}) => {
  const t = useInternationalization('inspections.schedule.button.series');

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const isSingleInstance = inspection.seriesRecurrence.isSingleInstance;
  const buttonLabel = isSingleInstance ? t('convert') : t('edit');

  const onEditSeries = (event: MouseEvent<HTMLButtonElement>) => {
    if (!inspection.canSplitFrom) {
      fetchInspectionSeries(EditSeriesChoice.All);
    } else {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }
  };

  return (
    <>
      <div className='flex gap-2 !justify-end'>
        {!inspection.seriesRecurrence.isCanceled && (
          <button
            className={clsx(buttonClasses, 'grid place-items-center relative')}
            disabled={isLoadingSeries}
            onClick={onEditSeries}
          >
            <span className={clsx({ 'opacity-0': isLoadingSeries })}>
              {buttonLabel}
            </span>

            {isLoadingSeries && <Spinner className='absolute' />}
          </button>
        )}
      </div>
      {!isSingleInstance && (
        <ChoiceMenu
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
          }}
          onSelect={fetchInspectionSeries}
        />
      )}
    </>
  );
};
