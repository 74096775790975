import { clsx } from 'clsx';
import { MultilineInput } from 'components/MultilineInput';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { useAppConfiguration } from 'hooks/useAppConfiguration';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export const Description: FC = () => {
  const dispatch = useAppDispatch();
  const description = useSelector(taskEditSlice.selectors.description);
  const isSingleInstance = useSelector(
    taskEditSlice.selectors.isSingleInstance,
  );
  const t = useInternationalization('tasks.edit');

  const { getConfig } = useAppConfiguration();

  return isSingleInstance ? (
    <MultilineInput
      aria-label='Description'
      id='description'
      maxLength={getConfig('TaskDescriptionMaxLength')}
      maxRows={6}
      onChange={(value) => {
        dispatch(
          taskEditSlice.actions.setTaskField({ field: 'description', value }),
        );
      }}
      placeholder={t('placeholder.description')}
      value={description}
    />
  ) : (
    <div
      className={clsx(
        '!block text-sm font-normal text-balance whitespace-pre-line',
        'max-h-40 overflow-y-auto',
        description ? 'text-gray-700' : 'text-gray-400 italic',
      )}
    >
      {description || t('noDescription')}
    </div>
  );
};
