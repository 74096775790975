import TabNavigation from 'components/TabNavigation';
import { useInternationalization } from 'hooks/useInternationalization';
import { NavLink, Outlet } from 'react-router-dom';

const Dashboard = () => {
  const t = useInternationalization('layout.navigation.tabs');

  return (
    <div className='h-full' data-testid='dashboard'>
      <TabNavigation>
        <NavLink id='tabnav-dashboard-charts' to='charts'>
          {t('charts')}
        </NavLink>
        <NavLink id='tabnav-my-assignments' to='assignments'>
          {t('my.assignments')}
        </NavLink>
      </TabNavigation>
      <Outlet />
    </div>
  );
};
export default Dashboard;
