import dakotaLogo from 'assets/DakotaLogo_wht.png';
import inspector from 'assets/inspector.png';
import { clsx } from 'clsx';

export const OnError = () => {
  return (
    <div className='flex flex-col h-screen bg-[#4A7D3B]'>
      <div className='p-8'>
        <img
          alt='white dakota logo'
          className='object-contain h-6 sm:h-10'
          src={dakotaLogo}
        />
      </div>
      <div
        className={clsx(
          'flex items-end justify-center flex-grow pb-16',
          'gap-4 text-center bg-bottom bg-no-repeat bg-contain bg-(image:--errorBackdrop)',
        )}
      >
        <div className='grid grid-cols-3 gap-4 w-full max-w-4xl p-4'>
          <div
            className={clsx(
              'col-span-2 flex flex-col items-center',
              'justify-center gap-6 sm:gap-12',
            )}
          >
            <p className='text-white text-4xl max-sm:text-xl'>
              Oh no! This page failed its compliance check. Let&apos;s navigate
              you back to the Home Page.
            </p>
            <button
              className={clsx(
                'bg-yellow-base/90 text-gray-800 max-sm:text-sm',
                'p-3 rounded-md hover:bg-yellow-base hover:text-black',
              )}
              onClick={() => {
                window.location.assign('/');
              }}
            >
              Return to Home Page
            </button>
          </div>
          <div className='col-span-1'>
            <img alt='Inspector' src={inspector} />
          </div>
        </div>
      </div>
    </div>
  );
};
