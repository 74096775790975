import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { Skeleton } from '@mui/material';
import { clsx } from 'clsx';
import Tooltip from 'components/Tooltip';
import { reportsSlice } from 'features/reports/reportsSlice';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import ChartContainer from './ChartContainer';
import { ChartData } from './types';

export const FailedResponsesChart: FC = () => {
  const t = useInternationalization('charts');

  const failedItemTypesReport = useSelector(
    reportsSlice.selectors.failedItemTypesReport,
  );
  const isLoadingFailedItemTypesReport = useSelector(
    reportsSlice.selectors.isLoadingFailedItemTypesReport,
  );

  const failedResponses: ChartData[] =
    failedItemTypesReport?.failingItemTypes.map((itemType) => ({
      name: itemType.name,
      value: itemType.count,
    })) ?? [];

  const displayData = failedResponses.toSorted((a, b) => b.value - a.value);

  const maxValue = displayData.reduce(
    (acc, item) => Math.max(acc, item.value),
    0,
  );

  if (isLoadingFailedItemTypesReport) {
    return (
      <div className='flex flex-col gap-4 items-center'>
        <Skeleton height={44} variant='rounded' width={240} />
        <Skeleton height={44} variant='rounded' width={240} />
        <Skeleton height={44} variant='rounded' width={240} />
        <Skeleton height={44} variant='rounded' width={240} />
        <Skeleton height={44} variant='rounded' width={240} />
      </div>
    );
  }

  const content =
    failedResponses.length === 0 ? (
      <div className='h-full flex flex-col justify-center items-center'>
        <CheckCircleIcon className='w-24 h-24 text-green-base' />
        <div className='text-lg font-light text-gray-700 text-center min-h-16'>
          You have no failed responses!
        </div>
      </div>
    ) : (
      <div className='grid grid-cols-1 gap-6 pb-4'>
        {displayData.map((item, index) => (
          <div
            className='w-11/12 flex gap-2 items-center group'
            key={`${item.name}-${index}`}
          >
            <div className='flex-none w-2/5 text-gray-500 text-xs text-end'>
              {item.name}
            </div>
            <div className='flex-1 min-w-12 flex items-center'>
              <Tooltip
                arrow
                placement='top'
                title={
                  <div>
                    <strong>{item.value}</strong> failed response
                    {item.value === 1 ? '' : 's'} in the{' '}
                    <strong>{item.name}</strong> item type.
                  </div>
                }
              >
                <div
                  className={clsx(
                    'rounded-md bg-red-base group-hover:bg-(image:--barChartHover)',
                    'flex items-center justify-center',
                    'text-white text-sm h-10 min-w-8',
                  )}
                  style={{
                    width: `${(item.value / maxValue) * 100}%`,
                  }}
                >
                  <span className='bg-red-base px-2'>{item.value}</span>
                </div>
              </Tooltip>
            </div>
          </div>
        ))}
      </div>
    );

  return (
    <ChartContainer
      content={content}
      title={t('inspections.failed.responses')}
    />
  );
};
