import { LocalDate } from '@js-joda/core';
import { DatePicker } from 'components/DatePicker';
import { inspectionSeriesEditSlice } from 'features/inspections/inspectionSeriesEditSlice';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export const StartDate: FC = () => {
  const dispatch = useAppDispatch();

  const date = useSelector(inspectionSeriesEditSlice.selectors.startDate);

  return (
    <>
      <label htmlFor='series-datepicker'>Start Date</label>
      <div className='place-self-end'>
        <DatePicker
          asSingle
          id='series-datepicker'
          onChange={(newDate) =>
            dispatch(
              inspectionSeriesEditSlice.actions.setInspectionField({
                field: 'startDate',
                value: newDate.toString(),
              }),
            )
          }
          value={LocalDate.parse(date)}
        />
      </div>
    </>
  );
};
