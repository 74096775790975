import {
  isInspectionNotification,
  isTaskNotification,
  Notification,
} from '@dakota/platform-client';
import { BellSlashIcon } from '@heroicons/react/24/outline';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { clsx } from 'clsx';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC } from 'react';

import { InspectionNotificationDisplay } from './InspectionNotification';
import { TaskNotificationDisplay } from './TaskNotification';

type Props = {
  notifications: Notification[];
  onClose: () => void;
};

export const NotificationsPanel: FC<Props> = ({
  notifications,
  onClose,
}: Props) => {
  const t = useInternationalization('notifications');

  return (
    <div
      className={clsx(
        'max-sm:w-80 sm:w-96 flex flex-col gap-2',
        '*:px-4 *:py-2 text-gray-700 text-sm',
      )}
    >
      <div className='flex justify-between items-center text-lg font-semibold'>
        {t('title')}
        <IconButton
          aria-label='Close notifications'
          onClick={onClose}
          size='small'
        >
          <CloseIcon />
        </IconButton>
      </div>
      {notifications.length === 0 ? (
        <div className='flex flex-col items-center text-gray-300'>
          <BellSlashIcon className='w-12 h-12' />
          No notifications yet
        </div>
      ) : (
        <div className=''>
          {t('itemsNeedingAttention', { numberOfItems: notifications.length })}
        </div>
      )}
      {notifications.map((notification) => {
        if (isTaskNotification(notification)) {
          return (
            <TaskNotificationDisplay
              closePanel={onClose}
              key={notification.ticketId}
              notification={notification}
            />
          );
        }
        if (isInspectionNotification(notification)) {
          return (
            <InspectionNotificationDisplay
              closePanel={onClose}
              key={notification.ticketId}
              notification={notification}
            />
          );
        }
      })}
    </div>
  );
};
