import { TrashIcon } from '@heroicons/react/24/outline';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { clsx } from 'clsx';
import {
  BuilderQuestion,
  templatesSlice,
} from 'features/templates/templatesSlice';
import useToast from 'hooks/useToast';
import { FC, useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useAppDispatch } from 'store/store';

type Props = {
  index: number;
  question: BuilderQuestion;
};

const SectionQuestion: FC<Props> = ({ index, question }) => {
  const dispatch = useAppDispatch();

  const { setInfoMessage } = useToast();

  const [rendered, setRendered] = useState(false);
  const [removing, setRemoving] = useState(false);

  useEffect(() => {
    setRendered(true);
  }, []);

  const removeQuestionFromTemplate = () => {
    setRemoving(true);
    setTimeout(() => {
      dispatch(templatesSlice.actions.removeQuestionFromTemplate(question.id));
      let text = question.text.slice(0, 30);
      if (question.text.length > 30) {
        text += '...';
      }
      setInfoMessage(`Question "${text}" removed from template`);
    }, 300);
  };

  return (
    <Draggable draggableId={question.id} index={index} key={question.id}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={clsx(
            snapshot.isDragging ? 'shadow-lg' : '',
            snapshot.dropAnimation ? 'bg-green-lightest' : '',
          )}
          data-testid='section-question'
          ref={provided.innerRef}
        >
          <div
            className={clsx(
              'flex justify-between items-baseline cursor-grab',
              'text-gray-800 active:cursor-grabbing',
            )}
          >
            <div
              className={`flex items-center gap-1 pr-1 transition-all ${
                rendered && !removing
                  ? 'translate-x-0 opacity-100'
                  : '-translate-x-full opacity-0'
              } duration-300`}
            >
              <DragIndicatorIcon sx={{ color: 'var(--color-gray-400)' }} />
              {question.text}
            </div>
            <button
              className='text-gray-400 hover:text-gray-800 active:text-black'
              data-testid='remove-section-question'
              onClick={removeQuestionFromTemplate}
            >
              <TrashIcon className='w-5 h-5' />
            </button>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default SectionQuestion;
