import { Priority } from '@dakota/platform-client';
import {
  Bars2Icon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/16/solid';
import Tooltip from 'components/Tooltip';
import { useEnumLabel } from 'hooks/useEnumLabel';

type PriorityIconProps = {
  priority: Priority;
};

export const PriorityIcon = ({ priority }: PriorityIconProps) => {
  const { getPriorityLabel } = useEnumLabel();

  const getPriorityIcon = () => {
    if (priority === Priority.Low) {
      return (
        <ChevronDownIcon
          aria-label='Low priority'
          className='w-5 h-5 text-blue-base'
        />
      );
    }
    if (priority === Priority.Medium) {
      return (
        <Bars2Icon
          aria-label='Medium priority'
          className='w-5 h-5 text-yellow-base'
        />
      );
    }
    return (
      <ChevronUpIcon
        aria-label='High priority'
        className='w-5 h-5 text-red-base'
      />
    );
  };

  return (
    <Tooltip arrow placement='bottom' title={getPriorityLabel(priority)}>
      <div className='flex items-center font-bold'>{getPriorityIcon()}</div>
    </Tooltip>
  );
};
