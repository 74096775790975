import { ComprehensiveStatusReport } from '@dakota/platform-client';
import { reportsSlice } from 'features/reports/reportsSlice';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import {
  GoogleDataTableColumn,
  GoogleDataTableColumnRoleType,
} from 'react-google-charts';
import { useSelector } from 'react-redux';
import { AssignmentType } from 'types';

import ChartContainer from './ChartContainer';
import DashboardPieChart from './DashboardPieChart';

type Props = { selectedType: AssignmentType };

export const StatusChart: FC<Props> = ({ selectedType }) => {
  const t = useInternationalization('charts');

  const comprehensiveStatusReport = useSelector(
    reportsSlice.selectors.comprehensiveStatusReport,
  );
  const statusReport = comprehensiveStatusReport;
  const isLoadingComprehensiveStatusReport = useSelector(
    reportsSlice.selectors.isLoadingComprehensiveStatusReport,
  );

  interface Report {
    canceled: number;
    completed: number;
    inProgress: number;
    overdue: number;
    scheduled: number;
  }
  enum ReportStatus {
    Canceled = 'canceled',
    Completed = 'completed',
    InProgress = 'inProgress',
    Overdue = 'overdue',
    Scheduled = 'scheduled',
  }

  enum EntityTotalTypes {
    Inspections = 'inspections',
    Tasks = 'tasks',
  }

  const getSpecificTooltip = (status: ReportStatus, value: number) => {
    return renderToStaticMarkup(
      <div className='p-2 w-max'>
        <div className='font-semibold'>
          {t(`label.${selectedType}.${status}`)}: {value}
        </div>
      </div>,
    );
  };

  const byStatus = (report: ComprehensiveStatusReport) => {
    /**
     * Data for the 'Breakdown by Status' chart *MUST* be passed
     * in the following order:
     *    Completed, In Progress, Overdue, Scheduled, Canceled.
     */

    const currentReport = reportDataByType(report);
    const { canceled, completed, inProgress, overdue, scheduled } =
      currentReport;

    const customTooltip = (status: ReportStatus) => {
      const total = currentReport[status];

      const inspections = getStatusReport(EntityTotalTypes.Inspections, report)[
        status
      ];
      const tasks = getStatusReport(EntityTotalTypes.Tasks, report)[status];

      if (selectedType === AssignmentType.All) {
        return renderToStaticMarkup(
          <div className='p-2 w-max'>
            <div className='font-semibold'>
              {t(`label.total.${status}`)}: {total}
            </div>
            <div>
              {t('label.inspections')}: {inspections}
            </div>
            <div>
              {t('label.tasks')}: {tasks}
            </div>
          </div>,
        );
      } else if (selectedType === AssignmentType.Inspection) {
        return getSpecificTooltip(status, inspections);
      } else {
        return getSpecificTooltip(status, tasks);
      }
    };

    return [
      [
        'Status',
        'Value',
        {
          p: { html: true },
          role: GoogleDataTableColumnRoleType.tooltip,
          type: 'string',
        },
      ],
      ['Completed', completed, customTooltip(ReportStatus.Completed)],
      ['In Progress', inProgress, customTooltip(ReportStatus.InProgress)],
      ['Overdue', overdue, customTooltip(ReportStatus.Overdue)],
      ['Scheduled', scheduled, customTooltip(ReportStatus.Scheduled)],
      ['Canceled', canceled, customTooltip(ReportStatus.Canceled)],
    ] satisfies (GoogleDataTableColumn | number)[][];
  };

  const getStatusReport = (
    type: EntityTotalTypes,
    report: ComprehensiveStatusReport,
  ) => ({
    canceled: report.canceled[type],
    completed: report.completed[type],
    inProgress: report.inProgress[type],
    overdue: report.overdue[type],
    scheduled: report.scheduled[type],
  });

  const reportDataByType = (report: ComprehensiveStatusReport): Report => {
    const inspectionsStatusReport = getStatusReport(
      EntityTotalTypes.Inspections,
      report,
    );
    const tasksStatusReport = getStatusReport(EntityTotalTypes.Tasks, report);

    const allStatusReport = {
      canceled: inspectionsStatusReport.canceled + tasksStatusReport.canceled,
      completed:
        inspectionsStatusReport.completed + tasksStatusReport.completed,
      inProgress:
        inspectionsStatusReport.inProgress + tasksStatusReport.inProgress,
      overdue: inspectionsStatusReport.overdue + tasksStatusReport.overdue,
      scheduled:
        inspectionsStatusReport.scheduled + tasksStatusReport.scheduled,
    };

    switch (selectedType) {
      case AssignmentType.All:
        return allStatusReport;
      case AssignmentType.Inspection:
        return inspectionsStatusReport;
      case AssignmentType.Task:
        return tasksStatusReport;
    }
  };

  return (
    <ChartContainer
      content={
        <DashboardPieChart
          data={statusReport ? byStatus(statusReport) : []}
          loading={isLoadingComprehensiveStatusReport}
          type='status'
        />
      }
      title={t('breakdown.by.status')}
    />
  );
};
