import { Status } from '@dakota/platform-client';

export function getStatusText(status: Status) {
  switch (status) {
    case Status.Canceled:
      return 'Canceled';
    case Status.Completed:
      return 'Completed';
    case Status.InProgress:
      return 'In Progress';
    case Status.Scheduled:
      return 'Scheduled';
  }
}
