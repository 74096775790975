import {
  Facility,
  Status,
  Summary,
  UserSummary,
} from '@dakota/platform-client';
import { LocalDate } from '@js-joda/core';
import Autocomplete from 'components/Autocomplete';
import Chips from 'components/Chip/Chips';
import { ClearAllButton } from 'components/ClearAll';
import { DatePicker } from 'components/DatePicker';
import InspectionsTable from 'components/Inspections/InspectionsTable';
import { PageHeader } from 'components/PageHeader';
import SearchInput from 'components/SearchInput';
import { facilitiesSlice } from 'features/facilities/facilitiesSlice';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { useEnumLabel } from 'hooks/useEnumLabel';
import { useFilteredInspections } from 'hooks/useFilteredInspections';
import { useInspectionSidePanelLoader } from 'hooks/useInspectionSidePanelLoader';
import { useInternationalization } from 'hooks/useInternationalization';
import { DataStatus, usePageLoadTracking } from 'hooks/usePageLoadTracking';
import { useUsers } from 'hooks/useUsers';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

const CompletedInspections: React.FC = () => {
  const { activeUsersAndUnassigned } = useUsers();
  const { getStatusLabel, Label } = useEnumLabel();
  const { stopTracking } = usePageLoadTracking();

  const selectableFacilities = useSelector(
    facilitiesSlice.selectors.activeFacilities,
  );

  const loadingInspections = useSelector(
    inspectionSlice.selectors.isLoadingInspections,
  );

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedAssignees, setSelectedAssignees] = useState<UserSummary[]>([]);
  const [selectedFacilities, setSelectedFacilities] = useState<Facility[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<Status>(
    Status.Completed,
  );

  const statuses = useMemo(() => [Status.Completed, Status.Canceled], []);

  const {
    dateRange,
    filteredInspections,
    hasFilters,
    hookDataLoaded,
    resetDateRange,
    setDateRange,
  } = useFilteredInspections({
    filterByEndDate: true,
    initialDateRange: {
      begin: LocalDate.now().minusDays(30),
      end: LocalDate.now(),
    },
    initialSelectedStatus: Status.Completed,
    searchQuery,
    selectedAssignees,
    selectedFacilities,
    selectedStatus,
    statuses,
  });

  useEffect(() => {
    if (hookDataLoaded) {
      stopTracking(DataStatus.Fetched);
    }
  }, [hookDataLoaded, stopTracking]);

  const removeFacility = (facility: Summary) => {
    setSelectedFacilities((prev) => prev.filter((f) => f.id !== facility.id));
  };

  const removeAssignee = (assignee: UserSummary) => {
    setSelectedAssignees((prev) => prev.filter((f) => f.id !== assignee.id));
  };

  const resetFilters = () => {
    setSearchQuery('');
    setSelectedAssignees([]);
    setSelectedFacilities([]);
    setSelectedStatus(Status.Completed);
    resetDateRange();
  };
  const t = useInternationalization('inspections');

  const { inspectionViewerComponent } = useInspectionSidePanelLoader();

  return (
    <div className='p-4 sm:p-8'>
      <PageHeader scaffold={['Completed', 'Inspections']} title='Completed' />
      <div className='sm:flex sm:justify-between sm:items-center sm:gap-2'>
        <div className='filters-container'>
          <SearchInput
            data-testid='search-input'
            onSearch={setSearchQuery}
            value={searchQuery}
          />
          <DatePicker
            asSingle={false}
            id='completed-inspections-date'
            onChange={setDateRange}
            placeholder='date range'
            popoverDirection='down'
            showShortcuts
            value={dateRange}
          />
          <Autocomplete
            className='w-full sm:w-40'
            getOptionKey={(option) => option.id}
            getOptionLabel={(option) => option.name}
            id='completed-inspections-facilities-selector'
            label={Label.Facilities}
            multiple
            onChange={setSelectedFacilities}
            options={selectableFacilities}
            value={selectedFacilities}
          />
          <Autocomplete
            className='w-full sm:w-40'
            getOptionKey={(option) => option.id}
            getOptionLabel={(option) => option.name}
            id='completed-inspections-assignee-selector'
            label={Label.Assignee}
            multiple
            onChange={setSelectedAssignees}
            options={activeUsersAndUnassigned}
            value={selectedAssignees}
          />
          <Autocomplete
            className='w-full sm:w-36'
            getOptionLabel={getStatusLabel}
            id='completed-inspections-status-selector'
            label={Label.ProgressStatus}
            onChange={setSelectedStatus}
            options={statuses}
            value={selectedStatus}
          />
          {hasFilters && <ClearAllButton onClick={resetFilters} />}
        </div>
      </div>
      <Chips
        elements={selectedFacilities}
        getKey={(f) => f.id}
        getLabel={(f) => f.name}
        onRemove={removeFacility}
        title={t('chips.label.facilities')}
      />
      <Chips
        elements={selectedAssignees}
        getKey={(f) => f.id}
        getLabel={(f) => f.name}
        onRemove={removeAssignee}
        title={t('chips.label.assignees')}
      />
      <InspectionsTable
        data={filteredInspections}
        loading={loadingInspections}
        searchQuery={searchQuery}
        showCompletedDate
      />
      {inspectionViewerComponent}
    </div>
  );
};

export default CompletedInspections;
