import { InspectionSeries } from '@dakota/platform-client';
import { configSlice } from 'features/config/configSlice';
import {
  splitInspectionSeries,
  updateInspectionSeries,
} from 'features/inspections/inspectionActions';
import { inspectionSeriesEditSlice } from 'features/inspections/inspectionSeriesEditSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/store';

import useToast from './useToast';

export const useInspectionSeriesEdit = (inspection: InspectionSeries) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const { setErrorMessage, setSuccessMessage } = useToast();

  const editInspectionSeriesRequest = useSelector(
    inspectionSeriesEditSlice.selectors.editInspectionSeriesRequest,
  );

  const splitInspectionSeriesRequest = useSelector(
    inspectionSeriesEditSlice.selectors.splitInspectionSeriesRequest,
  );

  const [isSaving, setIsSaving] = useState(false);

  const saveAll = useCallback(() => {
    setIsSaving(true);

    dispatch(
      updateInspectionSeries({
        baseUrl,
        body: editInspectionSeriesRequest,
        id: inspection.id,
        token,
      }),
    )
      .unwrap()
      .then(() => {
        setSuccessMessage('Inspection Series updated successfully');
        navigate('/inspections');
      })
      .catch(() => {
        setErrorMessage('Failed to update inspection series');
      })
      .finally(() => {
        setIsSaving(false);
      });
  }, [
    dispatch,
    editInspectionSeriesRequest,
    inspection.id,
    baseUrl,
    token,
    navigate,
    setErrorMessage,
    setSuccessMessage,
  ]);

  const splitSeries = useCallback(() => {
    setIsSaving(true);

    dispatch(
      splitInspectionSeries({
        baseUrl,
        body: splitInspectionSeriesRequest,
        id: inspection.id,
        token,
      }),
    )
      .unwrap()
      .then(() => {
        setSuccessMessage('Inspection Series updated successfully');
        navigate('/inspections');
      })
      .catch(() => {
        setErrorMessage('Failed to update inspection series');
      })
      .finally(() => {
        setIsSaving(false);
      });
  }, [
    dispatch,
    splitInspectionSeriesRequest,
    inspection.id,
    baseUrl,
    token,
    navigate,
    setErrorMessage,
    setSuccessMessage,
  ]);

  return {
    isSaving,
    splitSeries,
    updateSeries: saveAll,
  };
};
