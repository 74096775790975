import { TaskInstanceDetails, TaskSeries } from '@dakota/platform-client';
import AcknowledgmentDialog from 'components/Dialog/AcknowledgmentDialog';
import { configSlice } from 'features/config/configSlice';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { getTaskSeries } from 'features/tasks/tasksActions';
import { tokenSlice } from 'features/token/tokenSlice';
import { EditSeries } from 'Pages/Tasks/EditSeries';
import { ChoiceDialog } from 'Pages/Tasks/EditSeries/ChoiceDialog';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { EditSeriesChoice } from 'types';

import useToast from './useToast';

type Props = {
  isSavingTask: boolean;
  saveTask: () => Promise<unknown>;
  task: TaskInstanceDetails;
};

export const useEditSeries = ({ isSavingTask, saveTask, task }: Props) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const { setErrorMessage } = useToast();

  const hasUnsavedChanges = useSelector(
    taskEditSlice.selectors.hasUnsavedChanges,
  );

  const [taskSeries, setTaskSeries] = useState<TaskSeries | undefined>(
    undefined,
  );
  const [isLoadingSeries, setIsLoadingSeries] = useState(false);
  const [showAcknowledgmentDialog, setShowAcknowledgmentDialog] =
    useState(false);
  const [showEditSeriesPrompt, setShowEditSeriesPrompt] = useState(false);
  const [editOption, setEditOption] = useState<EditSeriesChoice>(
    EditSeriesChoice.CurrentAndFollowing,
  );

  const openEditSeriesDialog = () => {
    setIsLoadingSeries(true);

    dispatch(getTaskSeries({ baseUrl, id: task.seriesId, token }))
      .unwrap()
      .then((payload) => {
        setTaskSeries(payload);
      })
      .catch(() => {
        setErrorMessage('Error loading task series');
      })
      .finally(() => {
        setIsLoadingSeries(false);
      });
  };

  const proceedToEditSeries = () => {
    // canSplitFrom is true unless:
    // The task is the first instance in the series
    // The task is the only instance in the series
    // The task is canceled
    const canSplit = task.canSplitFrom;

    if (canSplit) {
      setShowEditSeriesPrompt(true);
    } else {
      setEditOption(EditSeriesChoice.All);
      openEditSeriesDialog();
    }
  };

  const editSeries = () => {
    if (hasUnsavedChanges) {
      setShowAcknowledgmentDialog(true);
    } else {
      proceedToEditSeries();
    }
  };

  const onChoiceMade = (option: EditSeriesChoice) => {
    setEditOption(option);
    setShowEditSeriesPrompt(false);
    openEditSeriesDialog();
  };

  const EditSeriesComponent = () => (
    <>
      {taskSeries && (
        <EditSeries
          editOption={editOption}
          instanceScheduledDate={task.timeline.dueDate}
          onClose={() => {
            setTaskSeries(undefined);
          }}
          task={taskSeries}
        />
      )}
      {showAcknowledgmentDialog && (
        <AcknowledgmentDialog
          acknowledgmentDisabled={isSavingTask}
          acknowledgmentText='You have unsaved changes. Do you want to save them before editing the series?'
          additionalButtonText='Return to form'
          buttonText='Yes'
          cancelButtonText='No'
          confirmationButtonSide='right'
          onAcknowledgment={() =>
            void saveTask().then(() => {
              setShowAcknowledgmentDialog(false);
              proceedToEditSeries();
            })
          }
          onAdditionalAction={() => {
            setShowAcknowledgmentDialog(false);
          }}
          onCloseDialog={() => {
            setShowAcknowledgmentDialog(false);
            proceedToEditSeries();
          }}
          title='Save Changes?'
        />
      )}
      {showEditSeriesPrompt && (
        <ChoiceDialog
          onClose={() => {
            setShowEditSeriesPrompt(false);
          }}
          onContinue={onChoiceMade}
          open
        />
      )}
    </>
  );

  return { editSeries, EditSeriesComponent, isLoadingSeries };
};
