import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC } from 'react';
import { useSelector } from 'react-redux';

export const Zone: FC = () => {
  const zone = useSelector(taskEditSlice.selectors.zone);
  const t = useInternationalization('tasks.edit');

  if (!zone) {
    return null;
  }

  return (
    <div>
      <label className='flex-1' htmlFor='task-zone'>
        {t('label.zone')}
      </label>
      <div className='font-bold' id='task-zone'>
        {zone.name}
      </div>
    </div>
  );
};
