import { isApiException, isProblemDetails } from '@dakota/client-common';
import { Question, QuestionsClient } from '@dakota/platform-client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClientData, getClient } from 'features/clientProvider';

export const getAllQuestions = createAsyncThunk(
  'questions/getQuestions',
  async (params: ClientData) => {
    const client = getClient(QuestionsClient, params);
    const response = await client.listQuestions();
    return response.result;
  },
);

/**
 * @return the question that was added
 */
export const addQuestion = createAsyncThunk(
  'questions/addQuestion',
  async (params: { question: Question } & ClientData, { rejectWithValue }) => {
    const client = getClient(QuestionsClient, params);
    try {
      const response = await client.createQuestion(params.question);
      return response.result;
    } catch (e: unknown) {
      // We specifically handle the duplicate question message detail here
      // because it indicates that a question with the same text already exists
      // for the selected item type.
      if (isApiException(e) && isProblemDetails(e.result)) {
        const errorDetail = e.result.detail;
        // TODO: use an actual error code, numeric or string, instead of the
        // actual user-facing message for this check. It is very brittle.
        if (
          errorDetail?.startsWith(
            'A Question already exists for this Item Type',
          )
        ) {
          return rejectWithValue(errorDetail);
        }
      }
      throw e; // rethrow the error if not a duplicate question error
    }
  },
);

export const editQuestion = createAsyncThunk(
  'questions/editQuestion',
  async (params: { question: Question } & ClientData) => {
    const client = getClient(QuestionsClient, params);
    const response = await client.updateQuestion(
      params.question.id,
      params.question,
    );
    return {
      ...response.result,
      itemType: params.question.itemType,
    } as Question;
  },
);

/**
 * @return the id of the question that was deactivated
 */
export const deactivateQuestion = createAsyncThunk(
  'questions/deactivateQuestion',
  async (params: { id: string } & ClientData, { fulfillWithValue }) => {
    const client = getClient(QuestionsClient, params);
    await client.deactivateQuestion(params.id);
    return fulfillWithValue(params.id);
  },
);

/**
 * @return the id of the question that was activated
 */
export const activateQuestion = createAsyncThunk(
  'questions/activateQuestion',
  async (params: { id: string } & ClientData, { fulfillWithValue }) => {
    const client = getClient(QuestionsClient, params);
    await client.reactivateQuestion(params.id);
    return fulfillWithValue(params.id);
  },
);

/**
 * @return an array of `QuestionBatchResult` objects
 */
export const addQuestions = createAsyncThunk(
  'questions/addQuestions',
  async (params: { questions: Question[] } & ClientData) => {
    const client = getClient(QuestionsClient, params);
    const response = await client.createQuestions(params.questions);
    return response.result;
  },
);
