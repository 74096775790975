import { Facility, Status } from '@dakota/platform-client';
import Autocomplete from 'components/Autocomplete';
import Chips from 'components/Chip/Chips';
import { ClearAllButton } from 'components/ClearAll';
import { CreateItemButton } from 'components/CreateItemButton';
import { DatePicker } from 'components/DatePicker';
import { PageHeader } from 'components/PageHeader';
import SearchInput from 'components/SearchInput';
import { facilitiesSlice } from 'features/facilities/facilitiesSlice';
import { userSlice } from 'features/user/userSlice';
import { useEnumLabel } from 'hooks/useEnumLabel';
import { useFilteredAssignments } from 'hooks/useFilteredAssignments';
import { useInspectionSidePanelLoader } from 'hooks/useInspectionSidePanelLoader';
import { useInternationalization } from 'hooks/useInternationalization';
import { DataStatus, usePageLoadTracking } from 'hooks/usePageLoadTracking';
import { useTaskLoader } from 'hooks/useTaskLoader';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AssignmentType, SearchStatus } from 'types';
import { Permission } from 'utils/permissions';

import AssignmentsTable from './AssignmentsTable';

const Assignments: FC = () => {
  const t = useInternationalization();
  const { getAssignmentTypeLabel, getStatusLabel, Label } = useEnumLabel();

  const { taskViewerComponent } = useTaskLoader(true);
  const { inspectionViewerComponent } = useInspectionSidePanelLoader(true);

  const currentUserId = useSelector(userSlice.selectors.currentUser).id;

  const selectableFacilities = useSelector(
    facilitiesSlice.selectors.activeFacilities,
  );

  const [selectedFacilities, setSelectedFacilities] = useState<Facility[]>([]);

  const [selectedStatus, setSelectedStatus] = useState<SearchStatus[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const initialSelectedType = AssignmentType.All;
  const [selectedType, setSelectedType] = useState(initialSelectedType);

  const { stopTracking } = usePageLoadTracking();

  const statuses = useMemo(
    () => [Status.InProgress, 'Overdue', Status.Scheduled] as SearchStatus[],
    [],
  );

  const types = [
    AssignmentType.Inspection,
    AssignmentType.Task,
    AssignmentType.All,
  ];

  const {
    dateRange,
    filteredAssignments,
    hasFilters,
    hookDataLoaded,
    isLoading,
    resetDateRange,
    setDateRange,
  } = useFilteredAssignments({
    assigneeId: currentUserId,
    initialSelectedType,
    searchQuery,
    selectedFacilities,
    selectedStatus,
    selectedType,
    statuses,
  });

  useEffect(() => {
    if (hookDataLoaded) {
      stopTracking(DataStatus.Fetched);
    }
  }, [hookDataLoaded, stopTracking]);

  const removeFacility = (facility: Facility) => {
    setSelectedFacilities((prev) => prev.filter((f) => f.id !== facility.id));
  };

  const resetFilters = () => {
    setSelectedFacilities([]);
    setSelectedStatus([]);
    resetDateRange();
    setSearchQuery('');
    setSelectedType(initialSelectedType);
  };

  return (
    <div className='p-4 sm:p-8'>
      <PageHeader
        scaffold={['My Assignments', 'Dashboard']}
        title='Assignments'
      >
        <CreateItemButton
          buttonText={t('charts.schedule.inspection')}
          link='/manage/inspection-templates'
          permission={Permission.CreateInspection}
        />
        <CreateItemButton
          buttonText={t('charts.create.task')}
          link='/tasks/scheduled?action=create'
          permission={Permission.CreateTask}
        />
      </PageHeader>
      <div className='sm:flex sm:justify-between sm:items-center sm:gap-2'>
        <div className='filters-container'>
          <SearchInput
            data-testid='search-input'
            id='search-input'
            onSearch={setSearchQuery}
            value={searchQuery}
          />
          <DatePicker
            asSingle={false}
            id='assignments-date-picker'
            onChange={setDateRange}
            scrollOnFocus={false}
            shortcutsType='next'
            showShortcuts
            value={dateRange}
          />
          <Autocomplete
            className='max-sm:w-full w-44'
            getOptionKey={(facility) => facility.id}
            getOptionLabel={(facility) => facility.name}
            id='assignments-facility-selector'
            label={Label.Facilities}
            multiple
            onChange={setSelectedFacilities}
            options={selectableFacilities}
            value={selectedFacilities}
          />
          <Autocomplete
            className='max-sm:w-full w-44'
            getOptionLabel={getStatusLabel}
            id='assignments-status-selector'
            label={Label.ProgressStatus}
            multiple
            onChange={setSelectedStatus}
            options={statuses}
            value={selectedStatus}
          />
          <Autocomplete
            className='max-sm:w-full w-36'
            getOptionLabel={getAssignmentTypeLabel}
            id='assignments-type-selector'
            label={Label.AssignmentType}
            onChange={setSelectedType}
            options={types}
            value={selectedType}
          />
          {hasFilters && <ClearAllButton onClick={resetFilters} />}
        </div>
      </div>
      <Chips
        elements={selectedFacilities}
        getKey={(f) => f.id}
        getLabel={(f) => f.name}
        onRemove={removeFacility}
        title='Facilities'
      />
      <AssignmentsTable
        data={filteredAssignments}
        loading={isLoading}
        searchQuery={searchQuery}
      />
      {taskViewerComponent}
      {inspectionViewerComponent}
    </div>
  );
};

export default Assignments;
