import { ByWeekday, Frequency, RRule } from 'rrule';

import { toOrdinal } from './print';
import { dayToString, monthToString } from './rrule';

type ByWeekdayType = ByWeekday | ByWeekday[] | null | undefined;
type NumericParamType = null | number | number[] | undefined;

const byweekdayToStr = (byweekday: ByWeekdayType) => {
  if (
    Array.isArray(byweekday) &&
    byweekday.length > 0 &&
    byweekday.every((day) => typeof day === 'number')
  ) {
    if (byweekday.length === 1) {
      return dayToString(byweekday[0]);
    } else {
      const days = byweekday.map(dayToString);
      return `${days.slice(0, -1).join(', ')} and ${days[days.length - 1]}`;
    }
  }
};

const monthlyRuleToString = (
  interval: number | undefined,
  bymonthday: NumericParamType,
  bysetpos: NumericParamType,
  byweekday: ByWeekday | ByWeekday[] | null | undefined,
) => {
  const frequencyStr = interval === 1 ? 'Monthly' : `Every ${interval} months`;
  if (
    Array.isArray(bymonthday) &&
    bymonthday.length > 0 &&
    bymonthday.every((day) => typeof day === 'number')
  ) {
    return `${frequencyStr} on the ${toOrdinal(bymonthday[0])}`;
  }
  if (Array.isArray(bysetpos)) {
    return `${frequencyStr} on the ${toOrdinal(bysetpos[0])} ${byweekdayToStr(
      byweekday,
    )}`;
  }
};

const yearlyRuleToString = (
  interval: number | undefined,
  bymonth: NumericParamType,
  bymonthday: NumericParamType,
  bysetpos: NumericParamType,
  byweekday: ByWeekday | ByWeekday[] | null | undefined,
) => {
  const frequencyStr = interval === 1 ? 'Annually' : `Every ${interval} years`;
  if (Array.isArray(bymonth)) {
    if (Array.isArray(bymonthday) && bymonthday.length > 0) {
      return `${frequencyStr} on ${monthToString(bymonth[0])} ${toOrdinal(
        bymonthday[0],
      )}`;
    } else if (
      Array.isArray(bysetpos) &&
      Array.isArray(bymonth) &&
      Array.isArray(byweekday)
    ) {
      return `${frequencyStr} on the ${toOrdinal(bysetpos[0])} ${byweekdayToStr(
        byweekday,
      )} of ${monthToString(bymonth[0])}`;
    }
  }
};

/**
 * Convert an RRule string to a human-readable string.
 * It works only for the recurrence cases we support.
 */
export const toHumanReadable = (rule: string) => {
  if (!rule) {
    return 'Does not repeat';
  }

  const rrule = new RRule(RRule.parseString(rule));
  const { bymonth, bymonthday, bysetpos, byweekday, count, freq, interval } =
    rrule.options;

  if (count === 1) {
    return 'Occurs one time';
  }

  if (freq === Frequency.DAILY) {
    if (interval === 1) {
      return 'Daily';
    }
    return `Every ${interval} days`;
  }

  if (freq === Frequency.WEEKLY) {
    const frequencyStr = interval === 1 ? 'Weekly' : `Every ${interval} weeks`;
    const daysStr = byweekdayToStr(byweekday);
    return `${frequencyStr} on ${daysStr}`;
  }

  if (freq === Frequency.MONTHLY) {
    return monthlyRuleToString(interval, bymonthday, bysetpos, byweekday);
  }

  if (freq === Frequency.YEARLY) {
    return yearlyRuleToString(
      interval,
      bymonth,
      bymonthday,
      bysetpos,
      byweekday,
    );
  }
};
