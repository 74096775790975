import { TaskInstanceDetails } from '@dakota/platform-client';
import { PlusIcon } from '@heroicons/react/24/solid';
import { FilePreview, UploadState } from 'components/Attachment/FilePreview';
import Button from 'components/Button';
import { WarningMessage } from 'components/WarningMessage';
import dotProp from 'dot-prop-immutable';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { useAppConfiguration } from 'hooks/useAppConfiguration';
import { useTaskEdit } from 'hooks/useTaskEdit';
import useToast from 'hooks/useToast';
import { FC, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

type AddAttachmentsProps = {
  /**
   * When present, we are in "edit task" mode and the component will be used to
   * add more attachments to the task, as long as it's allowed by the
   * attachment limit of 10 files per task.
   *
   * When not present, we are in "create task" mode and the uploads get saved
   * elsewhere.
   */
  task?: TaskInstanceDetails;
};

export const AddAttachments: FC<AddAttachmentsProps> = ({ task }) => {
  const dispatch = useAppDispatch();
  const { getConfig } = useAppConfiguration();
  const newAttachments = useSelector(taskEditSlice.selectors.newAttachments);

  const currentCount = task?.media.length ?? 0;

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [states, setStates] = useState<UploadState[]>([]);

  const limit = 10;

  const [isAddingFiles, setIsAddingFiles] = useState(false);
  const { addAttachmentsToInstance } = useTaskEdit();

  const { setSuccessMessage } = useToast();

  const hasErrorFiles = useSelector(taskEditSlice.selectors.hasErrorFiles);

  const handleFileEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = e.target.files;
    const filesToAdd = [] as File[];

    if (newFiles) {
      for (const file of Array.from(newFiles)) {
        if (!newAttachments.some((f) => f.mediaFile.fileName === file.name)) {
          filesToAdd.push(file);
        }
      }
    }

    const newMediaParam = filesToAdd.map((file) => ({
      description: '',
      mediaFile: {
        data: file,
        fileName: file.name,
      },
    }));

    dispatch(
      taskEditSlice.actions.setTaskField({
        field: 'newAttachments',
        value: [...newAttachments, ...newMediaParam],
      }),
    );
    setStates((prev) => [
      ...prev,
      ...filesToAdd.map(() => 'pending' as UploadState),
    ]);
  };

  const removeFile = (name: string) => {
    const index = newAttachments.findIndex(
      (file) => file.mediaFile.fileName === name,
    );
    if (index !== -1) {
      dispatch(taskEditSlice.actions.removeAttachment(name));

      setStates((prev) => prev.toSpliced(index, 1));
    }
  };

  const markAsUploading = (name: string) => {
    const index = newAttachments.findIndex(
      (file) => file.mediaFile.fileName === name,
    );
    if (index !== -1) {
      setStates((prev) =>
        prev.map((state, i) => (i === index ? 'uploading' : state)),
      );
    }
  };

  const addFilesToTask = () => {
    if (task) {
      setIsAddingFiles(true);

      void addAttachmentsToInstance(
        task.seriesId,
        task.timeline.dueDate,
        markAsUploading,
        removeFile,
      )
        .then((failedFiles) => {
          setSuccessMessage(
            `${
              newAttachments.length - failedFiles.length
            } attachments added to task`,
          );
          dispatch(taskEditSlice.actions.keepFailedFiles(failedFiles));
          setStates(failedFiles.map(() => 'error'));
        })
        .finally(() => {
          setIsAddingFiles(false);
        });
    }
  };

  const hasLargeFiles = newAttachments.some(
    (file) =>
      (file.mediaFile.data as File).size > getConfig('AttachmentMaxFileSize'),
  );

  return (
    <div className='h-full flex flex-col justify-between text-gray-700'>
      <div className='flex-1 overflow-y-auto'>
        <div aria-label='Attachments' className='flex flex-col gap-3'>
          {currentCount + newAttachments.length > limit && (
            <WarningMessage>
              You can upload up to {limit} attachments to a task. Please remove
              some files before saving.
            </WarningMessage>
          )}
          {currentCount == limit && (
            <WarningMessage scrollIntoView={!task} variant='light'>
              This task has the maximum number of attachments allowed ({limit}).
            </WarningMessage>
          )}
          {currentCount < limit &&
            currentCount + newAttachments.length == limit && (
              <WarningMessage variant='light'>
                You can upload up to {limit} attachments to a task
              </WarningMessage>
            )}
          {hasLargeFiles && (
            <WarningMessage variant='warning'>
              File size too large; limit 20 MB per attachment.
            </WarningMessage>
          )}
          {currentCount + newAttachments.length < limit && (
            <div className='space-y-2'>
              <input
                className='hidden'
                data-testid='file-upload-input'
                disabled={hasErrorFiles}
                multiple
                onChange={handleFileEvent}
                ref={fileInputRef}
                type='file'
              />
              <button
                className='flex gap-2 items-center text-green-base'
                onClick={() => fileInputRef.current?.click()}
              >
                <PlusIcon className='w-5' /> Add Attachments
              </button>
              <div className='text-xs text-gray-400'>
                Image and PDF files up to 20MB
              </div>
            </div>
          )}
          <div className='flex flex-col gap-3'>
            {newAttachments.map((file, index) => (
              <div key={file.mediaFile.fileName}>
                <FilePreview
                  description={file.description ?? ''}
                  file={file.mediaFile.data as File}
                  index={currentCount + index}
                  key={file.mediaFile.fileName}
                  onRemove={() => {
                    if (!hasErrorFiles) {
                      removeFile(file.mediaFile.fileName);
                    }
                  }}
                  setDescription={(desc) =>
                    dispatch(
                      taskEditSlice.actions.setTaskField({
                        field: 'newAttachments',
                        value: dotProp.set(
                          newAttachments,
                          `${index}.description`,
                          desc,
                        ),
                      }),
                    )
                  }
                  state={states[index]}
                  warning={
                    (file.mediaFile.data as File).size >
                    getConfig('AttachmentMaxFileSize')
                  }
                />
              </div>
            ))}
          </div>
          {task && newAttachments.length > 0 && (
            <Button
              className='place-self-start'
              disabled={
                task.media.length + newAttachments.length > limit ||
                hasLargeFiles
              }
              loading={isAddingFiles}
              onClick={addFilesToTask}
            >
              Add to task
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
