import {
  Tooltip as MuiTooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';

/**
 * Wrapper around the MUI tooltip, with one addition: when `disabled` is `true`,
 * the tooltip will not be rendered, and the children will be rendered as-is.
 */
const Tooltip = styled(
  ({
    className,
    disabled,
    ...props
  }: { disabled?: boolean } & TooltipProps) => {
    if (disabled) {
      return props.children;
    }
    return <MuiTooltip {...props} classes={{ popper: className }} />;
  },
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontFamily: 'var(--font-sans)',
    fontSize: 'var(--text-xs)',
    fontWeight: 'var(--font-weight-light)',
  },
}));

export default Tooltip;
