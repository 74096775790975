import { DayOfWeek, LocalDate } from '@js-joda/core';
import isEqual from 'lodash/isEqual';
import { datetime, Frequency, RRule, Weekday } from 'rrule';

import {
  RecurrenceEndCondition,
  RecurrenceFrequency,
  RecurrenceOption,
  RecurrenceRepetition,
} from './types';

/**
 * Convert the weekday of a LocalDate to an RRule Weekday.
 */
export const toRRuleWeekday = (date: LocalDate): Weekday => {
  switch (date.dayOfWeek()) {
    case DayOfWeek.FRIDAY:
      return RRule.FR;
    case DayOfWeek.SATURDAY:
      return RRule.SA;
    case DayOfWeek.SUNDAY:
      return RRule.SU;
    case DayOfWeek.THURSDAY:
      return RRule.TH;
    case DayOfWeek.TUESDAY:
      return RRule.TU;
    case DayOfWeek.WEDNESDAY:
      return RRule.WE;
    case DayOfWeek.MONDAY:
    default:
      return RRule.MO;
  }
};

/**
 * Convert a LocalDate to a Date object
 * that can be used by the RRule library.
 */
export const toRRuleDatetime = (date: LocalDate): Date =>
  datetime(date.year(), date.monthValue(), date.dayOfMonth());

/**
 * Convert a Date object from the RRule library
 * to its corresponding LocalDate representation.
 *
 * This will ignore the time part of the Date object,
 * as explained in the RRule documentation.
 *
 * @see https://www.npmjs.com/package/rrule#important-use-utc-dates
 */
export const toLocalDate = (rruleDate: Date): LocalDate =>
  LocalDate.of(
    rruleDate.getUTCFullYear(),
    rruleDate.getUTCMonth() + 1,
    rruleDate.getUTCDate(),
  );

/**
 * Convert a frequency type to a human-readable string
 * taking into account the cardinality of the given value.
 */
export const frequencyTypeToString = (
  type: RecurrenceFrequency,
  value: number,
): string => {
  switch (type) {
    case Frequency.DAILY:
      return value === 1 ? 'Day' : 'Days';
    case Frequency.MONTHLY:
      return value === 1 ? 'Month' : 'Months';
    case Frequency.WEEKLY:
      return value === 1 ? 'Week' : 'Weeks';
    case Frequency.YEARLY:
      return value === 1 ? 'Year' : 'Years';
  }
};

/**
 * Convert a weekday to a human-readable string.
 */
export const dayToString = (day: number | Weekday): string => {
  const weekday = day instanceof Weekday ? day.weekday : day;
  switch (weekday) {
    case RRule.FR.weekday:
      return 'Friday';
    case RRule.MO.weekday:
      return 'Monday';
    case RRule.SA.weekday:
      return 'Saturday';
    case RRule.TH.weekday:
      return 'Thursday';
    case RRule.TU.weekday:
      return 'Tuesday';
    case RRule.WE.weekday:
      return 'Wednesday';
    case RRule.SU.weekday:
    default:
      return 'Sunday';
  }
};

export const monthToString = (month: number): string => {
  switch (month) {
    case 1:
      return 'January';
    case 10:
      return 'October';
    case 11:
      return 'November';
    case 2:
      return 'February';
    case 3:
      return 'March';
    case 4:
      return 'April';
    case 5:
      return 'May';
    case 6:
      return 'June';
    case 7:
      return 'July';
    case 8:
      return 'August';
    case 9:
      return 'September';
    case 12:
    default:
      return 'December';
  }
};

/**
 * Calculate the internal `RecurrenceOption` from an RRule. If the rule is not
 * present, default to `NOT_REPEATING`.
 */
export const getDefaultOption = (date: LocalDate, ruleStr?: string) => {
  if (!ruleStr) {
    return RecurrenceOption.NOT_REPEATING;
  }

  const rule = RRule.fromString(ruleStr);

  if (
    rule.options.freq === Frequency.DAILY &&
    rule.options.interval === 1 &&
    rule.options.count === 1 &&
    rule.options.until === null
  ) {
    return RecurrenceOption.NOT_REPEATING;
  }

  if (
    rule.options.freq === Frequency.DAILY &&
    rule.options.interval === 1 &&
    rule.options.count === null &&
    rule.options.until === null
  ) {
    return RecurrenceOption.DAILY;
  }

  if (
    rule.options.freq === Frequency.WEEKLY &&
    rule.options.interval === 1 &&
    rule.options.byweekday.length === 1 &&
    rule.options.byweekday[0] === toRRuleWeekday(date).weekday &&
    rule.options.count === null &&
    rule.options.until === null
  ) {
    return RecurrenceOption.WEEKLY_ON_WEEKDAY;
  }

  if (
    rule.options.freq === Frequency.MONTHLY &&
    rule.options.interval === 1 &&
    rule.options.bymonthday.length === 1 &&
    rule.options.bymonthday[0] === date.dayOfMonth() &&
    rule.options.count === null &&
    rule.options.until === null
  ) {
    return RecurrenceOption.MONTHLY_ON_DAY;
  }

  if (
    rule.options.freq === Frequency.YEARLY &&
    rule.options.interval === 1 &&
    rule.options.bymonthday.length === 1 &&
    rule.options.bymonth.length === 1 &&
    rule.options.bymonthday[0] === date.dayOfMonth() &&
    rule.options.bymonth[0] === date.monthValue() &&
    rule.options.count === null &&
    rule.options.until === null
  ) {
    return RecurrenceOption.YEARLY_ON_DAY_MONTH;
  }

  return RecurrenceOption.CUSTOM;
};

/**
 * Return the default repetition in internal state form (i.e. when creating a
 * new recurrence) for a given RRule. If the rule is not present or not
 * supported, default to daily repetition every one day.
 */
export const getDefaultRepetition = (
  ruleStr?: string,
): RecurrenceRepetition => {
  if (!ruleStr) {
    return { freq: Frequency.DAILY, interval: 1 };
  }

  const rule = RRule.fromString(ruleStr);

  const freq = rule.options.freq;
  const interval = rule.options.interval;

  if (freq === Frequency.DAILY) {
    return { freq: Frequency.DAILY, interval };
  }

  if (freq === Frequency.WEEKLY) {
    /**
     * The only case where we pass the array, as we do support multiple days in
     * the weekly recurrence.
     */
    return {
      byweekday: rule.options.byweekday.map(
        (dayNumber) => new Weekday(dayNumber),
      ),
      freq: Frequency.WEEKLY,
      interval,
    };
  }

  if (freq === Frequency.MONTHLY && rule.options.bymonthday.length > 0) {
    /**
     * Intentionally ignoring multiple `bymonthday` values, as we only support
     * one day of the month in the recurrence rule.
     */
    return {
      bymonthday: rule.options.bymonthday[0],
      freq: Frequency.MONTHLY,
      interval,
    };
  }

  if (
    freq === Frequency.MONTHLY &&
    rule.options.byweekday.length > 0 &&
    rule.options.bysetpos.length > 0
  ) {
    /**
     * Intentionally ignoring multiple `byweekday` and `bysetpos` values, as we
     * only support one day of the month in the recurrence rule.
     */
    return {
      bysetpos: rule.options.bysetpos[0],
      byweekday: new Weekday(rule.options.byweekday[0]),
      freq: Frequency.MONTHLY,
      interval,
    };
  }

  if (
    freq === Frequency.YEARLY &&
    rule.options.bymonthday.length > 0 &&
    rule.options.bymonth.length > 0
  ) {
    /**
     * Intentionally ignoring multiple `bymonthday` and `bymonth` values, as we
     * only support one day of the year in the recurrence rule.
     */
    return {
      bymonth: rule.options.bymonth[0],
      bymonthday: rule.options.bymonthday[0],
      freq: Frequency.YEARLY,
      interval,
    };
  }

  if (
    freq === Frequency.YEARLY &&
    rule.options.byweekday.length > 0 &&
    rule.options.bymonth.length > 0 &&
    rule.options.bysetpos.length > 0
  ) {
    /**
     * Intentionally ignoring multiple `byweekday`, `bymonth`, and `bysetpos`
     * values, as we only support one day of the year in the recurrence rule.
     */
    return {
      bymonth: rule.options.bymonth[0],
      bysetpos: rule.options.bysetpos[0],
      byweekday: new Weekday(rule.options.byweekday[0]),
      freq: Frequency.YEARLY,
      interval,
    };
  }

  // Any other cases are rules we don't support
  throw new Error('Unsupported recurrence rule');
};

/**
 * Return the default end condition in internal state form (i.e. when creating a
 * new recurrence) for a given RRule. If the rule is not present or not
 * supported, default to a count of 1, since the default is "does not repeat".
 */
export const getDefaultEndCondition = (
  ruleStr?: string,
): RecurrenceEndCondition => {
  if (!ruleStr) {
    return { count: 1, until: null };
  }

  const rule = RRule.fromString(ruleStr);

  if (rule.options.until === null) {
    return rule.options.count === null
      ? { count: null, until: null }
      : { count: rule.options.count, until: null };
  }

  return {
    count: null,
    until: rule.options.until,
  };
};

/**
 * Compare two strings representing RRules for equality.
 *
 * It ignores the order of the properties in the string and it takes into
 * account default values, so for example the following rules are equal:
 * `FREQ=MONTHLY;BYMONTHDAY=31` and `BYMONTHDAY=31;INTERVAL=1;FREQ=MONTHLY`.
 */
export const isEqualAsRRule = (lhs: string, rhs: string): boolean => {
  return isEqual(
    new RRule(RRule.parseString(lhs)).options,
    new RRule(RRule.parseString(rhs)).options,
  );
};
