type OrgInfo = {
  id: string;
  name: string;
};

type UserInfo = {
  guidesEnabled?: boolean;
  userId: string;
};

export const IdentityClaims = {
  Country: 'https://app.dakotasoft.com/country',
  CountryCode: 'https://app.dakotasoft.com/country_code',
  OrgInfo: 'https://app.dakotasoft.com/org_info',
  TimeZone: 'https://app.dakotasoft.com/timezone',
  UserInfo: 'https://app.dakotasoft.com/user_info',
} as const;

// extend from @auth0/auth0-react/user for full property list
// but most properties are unused or won't be populated
export interface DakotaUser {
  [IdentityClaims.Country]: string;
  [IdentityClaims.CountryCode]?: string;
  [IdentityClaims.OrgInfo]: OrgInfo;
  [IdentityClaims.TimeZone]?: string;
  [IdentityClaims.UserInfo]: { [key: string]: unknown } & UserInfo;
  email: string;
  family_name: string; // last name, this is how it's returned from in the identity token from Auth0
  given_name: string; // first name, this is how it's returned from in the identity token from Auth0
  locale?: string;
  name?: string;
  picture?: string;
  sub?: string;
  zoneinfo?: string;
}
