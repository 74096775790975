import {
  ClipboardDocumentCheckIcon,
  ClipboardDocumentListIcon,
  GlobeAmericasIcon,
  HomeIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import PermissionGuard from 'auth/PermissionGuard';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC } from 'react';
import { Permission } from 'utils/permissions';

import NavLink from './NavLink';

type Props = {
  isSidebarOpen: boolean;
};

const Navigation: FC<Props> = ({ isSidebarOpen }) => {
  const { isMobile } = useBreakpoints();

  const t = useInternationalization('layout.navigation.menu');

  const id = (name: string) =>
    `main-navigation-${name}${isMobile ? '-mobile' : ''}`;

  return (
    <div
      className={`flex flex-col justify-center gap-2.5`}
      data-testid={`navigation${isMobile ? '-mobile' : ''}`}
      id={`navigation${isMobile ? '-mobile' : ''}`}
    >
      <NavLink
        icon={HomeIcon}
        id={id('dashboard')}
        isSidebarOpen={isSidebarOpen}
        title={t('dashboard')}
        to='/dashboard'
      />
      <NavLink
        icon={MagnifyingGlassIcon}
        id={id('inspections')}
        isSidebarOpen={isSidebarOpen}
        title={t('inspections')}
        to='/inspections'
      />
      <PermissionGuard permissions={[Permission.ReadTasks]}>
        <NavLink
          icon={ClipboardDocumentCheckIcon}
          id={id('tasks')}
          isSidebarOpen={isSidebarOpen}
          title={t('tasks')}
          to='/tasks'
        />
      </PermissionGuard>
      <PermissionGuard
        permissions={[
          Permission.Admin,
          Permission.CreateItemMetadata,
          Permission.CreateInspection,
        ]}
      >
        <NavLink
          icon={ClipboardDocumentListIcon}
          id={id('manage-content')}
          isSidebarOpen={isSidebarOpen}
          title={t('manage.content')}
          to='/manage'
        />
      </PermissionGuard>
      <PermissionGuard
        permissions={[
          Permission.Admin,
          Permission.CreateFacility,
          Permission.ManageUsers,
        ]}
      >
        <NavLink
          icon={GlobeAmericasIcon}
          id={id('organization')}
          isSidebarOpen={isSidebarOpen}
          title={t('my.organization')}
          to='/organization'
        />
      </PermissionGuard>
    </div>
  );
};
export default Navigation;
