import { Status } from '@dakota/platform-client';

export type ActiveStatus = 'Active' | 'All' | 'Inactive';

export const activeStatuses: ReadonlyArray<ActiveStatus> = [
  'All',
  'Active',
  'Inactive',
];

export type AreaType = 'All' | 'Facility' | 'Global';

export const areaTypes: ReadonlyArray<AreaType> = ['All', 'Global', 'Facility'];

export enum AssignmentType {
  All = 'all',
  Inspection = 'inspections',
  Task = 'tasks',
}

export enum DakotaErrorCode {
  BlockedUser,
  DuplicateQuestion,
  LoginFailed,
}

export enum EditSeriesChoice {
  All = 'all',
  CurrentAndFollowing = 'currentAndFollowing',
}

/**
 * We need this type because the user experience is to search for overdue
 * as well as the four possible values of Status.
 */
export type SearchStatus = 'Overdue' | Status;
