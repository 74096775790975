import { InspectionInstance, InspectionSeries } from '@dakota/platform-client';
import AcknowledgmentDialog from 'components/Dialog/AcknowledgmentDialog';
import { configSlice } from 'features/config/configSlice';
import { getInspectionSeries } from 'features/inspections/inspectionActions';
import { tokenSlice } from 'features/token/tokenSlice';
import { EditSeries } from 'Pages/Inspections/EditSeries';
import { FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { EditSeriesChoice } from 'types';

import { useInternationalization } from './useInternationalization';
import useToast from './useToast';

type Props = {
  hasUnsavedChanges?: boolean;
  inspection: InspectionInstance;
};

export const useInspectionSeriesLoader = ({
  hasUnsavedChanges,
  inspection,
}: Props) => {
  const dispatch = useAppDispatch();
  const t = useInternationalization('inspections');
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const { setErrorMessage } = useToast();

  const [inspectionSeries, setInspectionSeries] = useState<
    InspectionSeries | undefined
  >(undefined);
  const [isLoadingSeries, setIsLoadingSeries] = useState(false);
  const [editOptionState, setEditOptionState] = useState<EditSeriesChoice>(
    EditSeriesChoice.CurrentAndFollowing,
  );
  const [pendingEditOption, setPendingEditOption] = useState<
    EditSeriesChoice | undefined
  >();
  const [showAcknowledgmentDialog, setShowAcknowledgmentDialog] =
    useState(false);
  const canRenderEditSeries =
    inspectionSeries && !showAcknowledgmentDialog && !pendingEditOption;

  const fetchSeriesData = useCallback(
    (editOption: EditSeriesChoice) => {
      setIsLoadingSeries(true);

      dispatch(getInspectionSeries({ baseUrl, id: inspection.seriesId, token }))
        .unwrap()
        .then((data) => {
          setInspectionSeries(data);
          setEditOptionState(editOption);
        })
        .catch(() => {
          setErrorMessage(t('series.fetch.failure'));
        })
        .finally(() => {
          setIsLoadingSeries(false);
        });
    },
    [baseUrl, dispatch, inspection.seriesId, setErrorMessage, t, token],
  );

  const fetchInspectionSeries = useCallback(
    (editOption: EditSeriesChoice) => {
      if (hasUnsavedChanges) {
        setPendingEditOption(editOption);
        setShowAcknowledgmentDialog(true);
      } else {
        fetchSeriesData(editOption);
      }
    },
    [fetchSeriesData, hasUnsavedChanges],
  );

  const closeDialog = useCallback(() => {
    setShowAcknowledgmentDialog(false);
    if (pendingEditOption) {
      const editOption = pendingEditOption;
      setPendingEditOption(undefined);
      fetchSeriesData(editOption);
    }
  }, [fetchSeriesData, pendingEditOption]);

  const EditSeriesComponent: FC<{ onClose: () => void }> = ({ onClose }) => (
    <>
      {canRenderEditSeries && (
        <EditSeries
          editOption={editOptionState}
          inspection={inspectionSeries}
          instanceDate={inspection.timeline.dueDate}
          onClose={onClose}
        />
      )}
      {showAcknowledgmentDialog && (
        <AcknowledgmentDialog
          acknowledgmentText={t('edit.discard.message')}
          buttonText={t('schedule.acknowledgement.button')}
          cancelButtonText={t('edit.discard.confirm')}
          confirmationButtonSide='right'
          onAcknowledgment={() => {
            setShowAcknowledgmentDialog(false);
            setPendingEditOption(undefined);
          }}
          onCloseDialog={closeDialog}
          title={t('schedule.acknowledgement.title')}
        />
      )}
    </>
  );

  return {
    EditSeriesComponent,
    fetchInspectionSeries,
    hasUnsavedChanges,
    inspectionSeries,
    isLoadingSeries,
  };
};
