import { InspectionInstance, Status } from '@dakota/platform-client';
import { useCheckPermission } from 'hooks/useCheckPermission';
import { FC } from 'react';
import { Permission } from 'utils/permissions';

import { EditInspection } from './EditInspection';
import { ViewInspection } from './ViewInspection';

type Props = {
  inspection: InspectionInstance;
  onClose: () => void;
};

/**
 * Take an inspection series ID and a date and load the inspection side panel.
 *
 * This component checks for the update inspection permissions first. If the
 * user does not have said permission, we simply trigger a sticky message with
 * the appropriate text. If the user has the permission, we display the
 * inspection side panel.
 */
export const SidePanelLoader: FC<Props> = (props) => {
  const hasUpdatePermission = useCheckPermission(Permission.UpdateInspection);
  const isScheduledInspection = props.inspection.status === Status.Scheduled;

  return hasUpdatePermission && isScheduledInspection ? (
    <EditInspection {...props} />
  ) : (
    <ViewInspection {...props} />
  );
};
