import Tooltip from 'components/Tooltip';
import { reportsSlice } from 'features/reports/reportsSlice';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AssignmentType } from 'types';

import SummaryCard from './SummaryCard';

type Props = { selectedType: AssignmentType };

export const OverdueCard: FC<Props> = ({ selectedType }) => {
  const t = useInternationalization('charts');

  const comprehensiveStatusReport = useSelector(
    reportsSlice.selectors.comprehensiveStatusReport,
  );
  const isLoadingComprehensiveStatusReport = useSelector(
    reportsSlice.selectors.isLoadingComprehensiveStatusReport,
  );

  const comprehensiveStatusReportOverdue =
    isLoadingComprehensiveStatusReport ||
    comprehensiveStatusReport?.overdue === undefined
      ? undefined
      : comprehensiveStatusReport.overdue;

  const totalTypeOverdue = useMemo(() => {
    if (!comprehensiveStatusReportOverdue) {
      return 0;
    }
    const { inspections = 0, tasks = 0 } = comprehensiveStatusReportOverdue;
    switch (selectedType) {
      case AssignmentType.All:
        return inspections + tasks;
      case AssignmentType.Inspection:
        return inspections;
      case AssignmentType.Task:
        return tasks;
    }
  }, [comprehensiveStatusReportOverdue, selectedType]);

  const overdueTooltipText = useMemo(() => {
    const inspectionsOverdue =
      comprehensiveStatusReportOverdue?.inspections ?? 0;
    const tasksOverdue = comprehensiveStatusReportOverdue?.tasks ?? 0;
    const total = inspectionsOverdue + tasksOverdue;

    switch (selectedType) {
      case AssignmentType.All:
        return (
          <div
            aria-label='Overdue Inspections and Tasks'
            className='grid grid-cols-3 even:*:text-right odd:*:col-span-2'
          >
            <div className='font-medium'>Total Overdue</div>
            <div className='font-medium'>{total}</div>
            <div>Inspections</div>
            <div>{inspectionsOverdue}</div>
            <div>Tasks</div>
            <div>{tasksOverdue}</div>
          </div>
        );
      case AssignmentType.Inspection:
        return (
          <div aria-label='Overdue Inspections'>
            Inspections: {inspectionsOverdue}
          </div>
        );
      case AssignmentType.Task:
        return <div aria-label='Overdue Tasks'>Tasks: {tasksOverdue}</div>;
    }
  }, [
    comprehensiveStatusReportOverdue?.inspections,
    comprehensiveStatusReportOverdue?.tasks,
    selectedType,
  ]);

  return (
    <Tooltip arrow title={overdueTooltipText}>
      <div>
        <SummaryCard
          bgBreakpoints={[1, 5]}
          loading={isLoadingComprehensiveStatusReport}
          title={t('overdue')}
          value={totalTypeOverdue}
        />
      </div>
    </Tooltip>
  );
};
