import { LocalDate } from '@js-joda/core';
import { Recurrence as InspectionRecurrence } from 'components/recurrence';
import { inspectionEditSlice } from 'features/inspections/inspectionEditSlice';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export const Recurrence: FC = () => {
  const dispatch = useAppDispatch();

  const date = useSelector(inspectionEditSlice.selectors.date);

  const initialDate = useMemo(() => LocalDate.parse(date), [date]);

  const t = useInternationalization('inspections.schedule.label');

  return (
    <>
      <label className='flex items-center' htmlFor='recurrence-option-selector'>
        {t('recurrence')}
      </label>
      <div>
        <InspectionRecurrence
          className='w-full'
          initialDate={initialDate}
          onChange={(rule) =>
            dispatch(
              inspectionEditSlice.actions.setInspectionField({
                field: 'recurrence',
                value: rule,
              }),
            )
          }
        />
      </div>
    </>
  );
};
