import Autocomplete from 'components/Autocomplete';
import { inspectionEditSlice } from 'features/inspections/inspectionEditSlice';
import { useInternationalization } from 'hooks/useInternationalization';
import { useSelectedFacilityUser } from 'hooks/useSelectedFacilityUser';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export const Assignee: FC = () => {
  const dispatch = useAppDispatch();

  const facility = useSelector(inspectionEditSlice.selectors.facility);

  const { selectableUsers } = useSelectedFacilityUser(facility?.id);

  const findAssigneeById = (id: null | string) =>
    selectableUsers.find((user) => user.id === id) ?? null;

  const t = useInternationalization('inspections.schedule.label');

  return (
    <>
      <label
        className='flex items-center'
        htmlFor='inspection-assignee-selector'
      >
        {t('assignee')}
      </label>
      <Autocomplete
        disabled={!facility}
        fullWidth
        getOptionKey={(u) => u.id}
        getOptionLabel={(u) => u.name}
        id='inspection-assignee-selector'
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(option) =>
          dispatch(
            inspectionEditSlice.actions.setInspectionField({
              field: 'assigneeId',
              value: option.id,
            }),
          )
        }
        options={selectableUsers}
        value={findAssigneeById(
          useSelector(inspectionEditSlice.selectors.assigneeId),
        )}
      />
    </>
  );
};
