import { InspectionInstance } from '@dakota/platform-client';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { SidePanelLoader } from 'Pages/Inspections/SidePanelLoader';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

/**
 * @param requireOpenParam when true, the inspection will only be opened
 * if the URL contains `open=inspection` as a search param.
 */
export const useInspectionSidePanelLoader = (requireOpenParam = false) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const inspections = useSelector(inspectionSlice.selectors.inspections);

  const [inspection, setInspection] = useState<
    InspectionInstance | undefined
  >();

  useEffect(() => {
    const dueDate = searchParams.get('date');
    const seriesId = searchParams.get('seriesId');
    const open = searchParams.get('open');
    if (dueDate && seriesId && (!requireOpenParam || open === 'inspection')) {
      setInspection(
        inspections.find(
          (i) => i.seriesId === seriesId && i.timeline.dueDate === dueDate,
        ),
      );
    } else {
      setInspection(undefined);
    }
  }, [inspections, requireOpenParam, searchParams]);

  /**
   * When an inspection is open, this component displays it in the side panel,
   * with a loading indicator while the inspection object is being fetched. The
   * inspection is then displayed in editable mode if the user has permissions
   * to edit it, and in read-only mode otherwise.
   */
  const inspectionViewerComponent = useMemo(
    () =>
      inspection ? (
        <SidePanelLoader
          inspection={inspection}
          onClose={() => {
            navigate(location.pathname);
          }}
        />
      ) : null,
    [inspection, navigate],
  );

  const openSidePanel = useCallback(
    (i: InspectionInstance) => {
      setSearchParams({ date: i.timeline.dueDate, seriesId: i.seriesId });
    },
    [setSearchParams],
  );

  const openPerformInspection = useCallback(
    (i: InspectionInstance) => {
      navigate(`/inspections/${i.seriesId}/${i.timeline.dueDate}`);
    },
    [navigate],
  );

  return {
    inspectionViewerComponent,
    openPerformInspection,
    openSidePanel,
  };
};
