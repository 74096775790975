import { Group } from '@dakota/platform-client';
import { PlusIcon } from '@heroicons/react/24/outline';
import { GridColDef } from '@mui/x-data-grid';
import PermissionGuard from 'auth/PermissionGuard';
import Autocomplete from 'components/Autocomplete';
import Button from 'components/Button';
import { ClearAllButton } from 'components/ClearAll';
import DakotaLibraryLogo from 'components/DakotaLibraryLogo';
import { PageHeader } from 'components/PageHeader';
import SearchInput from 'components/SearchInput';
import { MuiGridWrapper } from 'components/Table/GridWrapper';
import { itemGroupsSlice } from 'features/items/itemGroupsSlice';
import Fuse from 'fuse.js';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useCheckPermission } from 'hooks/useCheckPermission';
import { useEnumLabel } from 'hooks/useEnumLabel';
import { useInternationalization } from 'hooks/useInternationalization';
import { DataStatus, usePageLoadTracking } from 'hooks/usePageLoadTracking';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ActiveStatus, activeStatuses } from 'types';
import { alphabeticalCompare } from 'utils/functional';
import { getHighlighter } from 'utils/highlighter';
import { Permission } from 'utils/permissions';

import AddEditItemGroup from './addItemGroup';
import ItemGroupsMenu from './itemGroupsMenu';

const ItemGroups: React.FC = () => {
  const { isMobile } = useBreakpoints();
  const t = useInternationalization('manage.table.header');
  const t2 = useInternationalization();
  const { getActiveStatusLabel, Label } = useEnumLabel();

  const { stopTracking } = usePageLoadTracking();

  const itemGroups = useSelector(itemGroupsSlice.selectors.itemGroups);

  useEffect(() => {
    stopTracking(DataStatus.PreviouslyLoaded);
  }, [stopTracking]);

  const [searchQuery, setSearchQuery] = useState('');
  const [isAddItemGroupPanelOpen, setIsAddItemGroupPanelOpen] = useState(false);

  // We *only* display the filtered item groups
  const [filteredItemGroups, setFilteredItemGroups] = useState<Group[]>([]);

  const [selectedStatus, setSelectedStatus] = useState<ActiveStatus>('Active');

  useEffect(() => {
    let newItemGroups = itemGroups;

    if (selectedStatus === 'Active') {
      newItemGroups = newItemGroups.filter((q) => !q.inactive);
    } else if (selectedStatus === 'Inactive') {
      newItemGroups = newItemGroups.filter((q) => q.inactive);
    }

    if (searchQuery) {
      const fuse = new Fuse(newItemGroups, {
        findAllMatches: true,
        ignoreLocation: true,
        keys: ['name'],
        shouldSort: true,
        threshold: 0.2,
        useExtendedSearch: true,
      });
      newItemGroups = fuse.search(searchQuery).map((r) => r.item);
    }

    setFilteredItemGroups(
      newItemGroups.toSorted(alphabeticalCompare((i) => i.name)),
    );
  }, [itemGroups, searchQuery, selectedStatus]);

  const resetFilters = () => {
    setSelectedStatus('Active');
    setSearchQuery('');
  };

  const canSeeContextualMenu = useCheckPermission(
    Permission.DeleteItemMetadata,
  );

  const highlight = getHighlighter(searchQuery);

  const columns: GridColDef<Group>[] = [
    {
      field: 'item-group',
      flex: 1,
      headerName: t('itemGroup.name'),
      renderCell: (params) => {
        const group = params.row;
        return (
          <p
            aria-label='Item Group name'
            className={group.inactive ? 'text-gray-400' : ''}
          >
            {highlight(group.name)}
            {group.isPlatformDefined && <DakotaLibraryLogo />}
          </p>
        );
      },
    },
    {
      align: 'center',
      field: 'number-of-item-types',
      headerAlign: 'center',
      headerName: t('itemGroup.numberOfItemTypes'),
      renderCell: (params) => {
        const group = params.row;
        const total = group.itemTypes.length;
        const inactive = group.itemTypes.filter(
          (itemType) => itemType.inactive,
        ).length;
        return (
          <>
            {total - inactive}
            {inactive > 0 && (
              <span className='text-sm italic text-gray-400 ml-2'>
                (+{inactive} inactive)
              </span>
            )}
          </>
        );
      },
      width: 110,
    },
  ];

  if (!isMobile) {
    columns.push({
      display: 'flex',
      field: 'menu',
      headerName: '',
      renderCell: (params) => {
        const group = params.row;
        return canSeeContextualMenu &&
          !group.isPlatformDefined &&
          !group.isDefault ? (
          <ItemGroupsMenu itemGroup={group} />
        ) : (
          <></>
        );
      },
      resizable: false,
      width: 50,
    });
  }

  return (
    <div className='p-4 sm:p-8'>
      <PageHeader
        scaffold={[t2('scaffold.itemGroups'), t2('scaffold.manageContent')]}
        title={t2('page.title.itemGroups')}
      >
        <PermissionGuard permissions={Permission.CreateItemMetadata}>
          <Button
            className='bg-green-base px-4 py-2'
            data-testid='add-item-group-button'
            icon={<PlusIcon />}
            id='add-item-group-button'
            onClick={() => {
              setIsAddItemGroupPanelOpen(true);
            }}
          >
            {t2('manage.cta.addItemGroup')}
          </Button>
        </PermissionGuard>
      </PageHeader>
      <div className='filters-container'>
        <SearchInput
          data-testid='search-input'
          id='item-groups-search-input'
          onSearch={setSearchQuery}
          value={searchQuery}
        />
        <PermissionGuard permissions={Permission.Admin}>
          <Autocomplete
            className='w-full sm:w-28'
            getOptionLabel={getActiveStatusLabel}
            id='item-groups-status-selector'
            label={Label.ActiveStatus}
            onChange={setSelectedStatus}
            options={activeStatuses}
            value={selectedStatus}
          />
        </PermissionGuard>
        {(selectedStatus !== 'Active' || searchQuery.length > 0) && (
          <ClearAllButton onClick={resetFilters} />
        )}
      </div>
      <MuiGridWrapper
        columns={columns}
        density={isMobile ? 'compact' : 'comfortable'}
        rows={filteredItemGroups}
      />
      {isAddItemGroupPanelOpen && (
        <AddEditItemGroup
          onClose={() => {
            setIsAddItemGroupPanelOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default ItemGroups;
