import { Prompt } from '@dakota/platform-client';
import Attachment from 'components/Attachment';
import { FC } from 'react';

type AttachmentsProps = {
  prompt: Prompt;
  showInCarousel: (index: number) => void;
};

export const Attachments: FC<AttachmentsProps> = ({
  prompt,
  showInCarousel,
}) => {
  return prompt.media.length > 0 ? (
    <div
      aria-label='Attachments container'
      className='grid max-sm:grid-cols-2 sm:grid-cols-5 gap-2 pt-2'
    >
      {prompt.media.map((attachment, attachmentIndex) => (
        // eslint-disable-next-line @typescript-eslint/no-deprecated
        <Attachment
          attachment={attachment}
          key={attachment.id}
          showInCarousel={() => {
            showInCarousel(attachmentIndex);
          }}
        />
      ))}
    </div>
  ) : null;
};
