import {
  AddMediaParams,
  CreateTaskSeriesRequest,
  Priority,
  Status,
  Summary,
  TaskInstanceDetails,
  UpdateNonRecurringTaskInstanceRequest,
  UpdateTaskInstanceRequest,
} from '@dakota/platform-client';
import { LocalDate } from '@js-joda/core';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer';
import { unassignedUser } from 'utils/user';

import {
  updateNonRecurringTaskInstance,
  updateTaskInstance,
} from './tasksActions';

const editTaskInstanceRequest = createSelector(
  [
    (state: TaskEditState) =>
      state.assigneeId === unassignedUser.id
        ? undefined
        : state.assigneeId || undefined,
    (state: TaskEditState) => state.priority,
    (state: TaskEditState) => state.date,
    (state: TaskEditState) => state.status,
    (state: TaskEditState) => state.originalDueDate,
    (state: TaskEditState) => state.seriesId,
  ],
  (assigneeId, priority, newDueDate, status, dueDate, seriesId) =>
    ({
      body: { assigneeId, newDueDate, priority, status },
      dueDate,
      seriesId,
    }) as {
      body: UpdateTaskInstanceRequest;
      dueDate: string;
      seriesId: string;
    },
);
const resetOriginal = (state: TaskEditState) => {
  state.originalState = {
    assigneeId: state.assigneeId,
    date: state.date,
    description: state.description,
    facility: state.facility,
    newAttachments: state.newAttachments,
    note: state.note,
    priority: state.priority,
    recurrence: state.recurrence,
    status: state.status,
    title: state.title,
    zone: state.zone,
  };
};

/**
 * State specific to the creation and editing of a single task.
 */
type TaskEditState = {
  assigneeId: string;
  date: string;
  description: string;
  facility?: Summary;
  hasErrorFiles: boolean;
  hasUnsavedChanges: boolean;
  isSingleInstance: boolean;
  newAttachments: AddMediaParams[];
  note: string;
  /**
   * When editing a task instance, its original scheduled date.
   */
  originalDueDate: string;
  /**
   * When editing a task instance, its original state used to compare changes.
   */
  originalState?: Pick<
    TaskEditState,
    | 'assigneeId'
    | 'date'
    | 'description'
    | 'facility'
    | 'newAttachments'
    | 'note'
    | 'priority'
    | 'recurrence'
    | 'status'
    | 'title'
    | 'zone'
  >;
  priority: Priority;
  recurrence: string;
  reportedBy: string;
  /**
   * When editing a task instance, the ID of the series to which it belongs.
   */
  seriesId: string;
  status: Status;
  ticketId: string;
  title: string;
  zone?: Summary;
};

const initialState: TaskEditState = {
  assigneeId: '',
  date: LocalDate.now().toString(),
  description: '',
  facility: undefined,
  hasErrorFiles: false,
  hasUnsavedChanges: false,
  isSingleInstance: false,
  newAttachments: [],
  note: '',
  originalDueDate: '',
  originalState: undefined,
  priority: Priority.Medium,
  recurrence: 'FREQ=DAILY;INTERVAL=1;COUNT=1',
  reportedBy: '',
  seriesId: '',
  status: Status.Scheduled,
  ticketId: '',
  title: '',
  zone: undefined,
};

export const taskEditSlice = createSlice({
  extraReducers: (builder) => {
    /**
     * We need to update the original state when the task is updated
     * to ensure that the unsaved changes detection works correctly
     * since the edit task instance stays open when clicking edit series.
     */
    builder.addCase(updateTaskInstance.fulfilled, resetOriginal);
    builder.addCase(updateNonRecurringTaskInstance.fulfilled, resetOriginal);
  },
  initialState,
  name: 'taskEdit',
  reducers: {
    clear: (state) => Object.assign(state, initialState),
    clearNewAttachments: (state) => {
      state.newAttachments = [];
      state.hasErrorFiles = false;
    },
    /**
     * When files fail to upload, we want to clear the files that succeeded and
     * keep only the failed ones for retrying and letting the user know.
     */
    keepFailedFiles: (
      state: WritableDraft<TaskEditState>,
      action: PayloadAction<string[]>,
    ) => {
      const failedFiles = action.payload;
      state.newAttachments = state.newAttachments.filter((file) =>
        failedFiles.includes(file.mediaFile.fileName),
      );
    },
    load: (
      state: WritableDraft<TaskEditState>,
      action: PayloadAction<TaskInstanceDetails>,
    ) => {
      const task = action.payload;
      state.assigneeId = task.assigneeId ?? '';
      state.date = task.timeline.dueDate;
      state.description = task.description ?? '';
      state.facility = task.facility;
      state.hasErrorFiles = false;
      state.hasUnsavedChanges = false;
      state.isSingleInstance = task.seriesRecurrence.isSingleInstance;
      state.note = '';
      state.originalDueDate = task.timeline.dueDate;
      state.priority = task.priority;
      state.recurrence = task.seriesRecurrence.rule;
      state.reportedBy = task.reportedBy;
      state.seriesId = task.seriesId;
      state.status = task.status;
      state.title = task.title;
      state.zone = task.zone;
      state.ticketId = task.ticketId ?? '';
      /**
       * Store the original state of the task to compare changes later. This
       * will be used to determine if the task being edited has unsaved changes.
       */
      state.originalState = {
        assigneeId: state.assigneeId,
        date: state.date,
        description: state.description,
        facility: state.facility,
        newAttachments: state.newAttachments,
        note: state.note,
        priority: state.priority,
        recurrence: state.recurrence,
        status: state.status,
        title: state.title,
        zone: state.zone,
      };
    },
    removeAttachment: (
      state: WritableDraft<TaskEditState>,
      action: PayloadAction<string>,
    ) => {
      state.newAttachments = state.newAttachments.filter(
        (file) => file.mediaFile.fileName !== action.payload,
      );
    },
    setTaskField: <K extends keyof TaskEditState>(
      state: WritableDraft<TaskEditState>,
      action: PayloadAction<{ field: K; value: TaskEditState[K] }>,
    ) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
  },
  selectors: {
    assigneeId: (state) => state.assigneeId,
    canSave: (state) =>
      !!state.title &&
      !!state.facility &&
      state.newAttachments.length <= 10 &&
      state.newAttachments.every(
        (file) => (file.mediaFile.data as File).size <= 20 * 1024 * 1024,
      ),
    /**
     * Build a `CreateTaskSeriesRequest` object from the current state. It does
     * not perform validation, it is assumed that validation is done before
     * calling this selector.
     */
    createTaskRequest: createSelector(
      [
        (state: TaskEditState) =>
          state.assigneeId === unassignedUser.id
            ? undefined
            : state.assigneeId || undefined,
        (state: TaskEditState) => state.description || undefined,
        (state: TaskEditState) => state.facility?.id,
        (state: TaskEditState) => state.priority,
        (state: TaskEditState) => state.recurrence,
        (state: TaskEditState) => state.date,
        (state: TaskEditState) => state.title,
        (state: TaskEditState) => state.zone?.id,
        (state: TaskEditState) => state.note || undefined,
      ],
      (
        assigneeId,
        description,
        facilityId,
        priority,
        recurrenceRule,
        startDate,
        title,
        zoneId,
        comment,
      ) =>
        ({
          assigneeId,
          comment,
          description,
          facilityId,
          priority,
          recurrenceRule,
          startDate,
          title,
          zoneId,
        }) as CreateTaskSeriesRequest,
    ),
    date: (state) => state.date,
    description: (state) => state.description,
    editTaskInstanceRequest,
    facility: (state) => state.facility,
    hasErrorFiles: (state) => state.hasErrorFiles,
    hasUnsavedChanges: (state: TaskEditState) => {
      /**
       * Check if the current state differs from the original or initial state.
       * Returns true if there are unsaved changes, otherwise false.
       */
      return !isStateEqual(state, state.originalState ?? initialState);
    },
    isSingleInstance: (state) => state.isSingleInstance,
    newAttachments: (state) => state.newAttachments,
    note: (state) => state.note,
    priority: (state) => state.priority,
    recurrence: (state) => state.recurrence,
    reportedBy: (state) => state.reportedBy,
    seriesId: (state) => state.seriesId,
    status: (state) => state.status,
    ticketId: (state) => state.ticketId,
    title: (state) => state.title,
    updateNonRecurringTaskInstanceRequest: createSelector(
      [
        editTaskInstanceRequest,
        (state: TaskEditState) => state.description || undefined,
        (state: TaskEditState) => state.title,
      ],
      (request, description, title) => ({
        ...request,
        body: {
          ...request.body,
          description,
          title,
        } as UpdateNonRecurringTaskInstanceRequest,
      }),
    ),
    zone: (state) => state.zone,
  },
});

/**
 * Helper function to determine if the current state is equal to the original
 * state of the task. This is used to detect unsaved changes during both the
 * creation and editing of a task.
 */
const isStateEqual = (
  currentState: Partial<TaskEditState>,
  originalState: Partial<TaskEditState>,
): boolean => {
  return (
    currentState.assigneeId === originalState.assigneeId &&
    currentState.date === originalState.date &&
    currentState.description === originalState.description &&
    currentState.facility?.id === originalState.facility?.id &&
    currentState.note === originalState.note &&
    currentState.priority === originalState.priority &&
    currentState.recurrence === originalState.recurrence &&
    currentState.status === originalState.status &&
    currentState.title === originalState.title &&
    currentState.zone?.id === originalState.zone?.id
  );
};
