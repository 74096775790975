import TabNavigation from 'components/TabNavigation';
import { useInternationalization } from 'hooks/useInternationalization';
import { NavLink, Outlet } from 'react-router-dom';

const Inspections = () => {
  const t = useInternationalization('layout.navigation.tabs');

  return (
    <div className='h-full bg-white' data-testid='inspections'>
      <TabNavigation>
        <NavLink id='tabnav-scheduled-inspections' to='scheduled'>
          {t('scheduled.inspections')}
        </NavLink>
        <NavLink id='tabnav-completed-inspections' to='completed'>
          {t('completed.inspections')}
        </NavLink>
      </TabNavigation>
      <Outlet />
    </div>
  );
};
export default Inspections;
