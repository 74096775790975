import { InspectionSeries, Summary } from '@dakota/platform-client';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import AcknowledgmentDialog from 'components/Dialog/AcknowledgmentDialog';
import { isEqualAsRRule } from 'components/recurrence/rrule';
import { SidePanel_v2 as SidePanel } from 'components/SidePanel_v2';
import { WarningMessage } from 'components/WarningMessage';
import { SelectZones } from 'components/Zones/SelectZones';
import { inspectionSeriesEditSlice } from 'features/inspections/inspectionSeriesEditSlice';
import { useInspectionSeriesEdit } from 'hooks/useInspectionSeriesEdit';
import { useInternationalization } from 'hooks/useInternationalization';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { EditSeriesChoice } from 'types';

import { Assignee } from './fields/Assignee';
import { Facility } from './fields/Facility';
import { Name } from './fields/Name';
import { InspectionPriority } from './fields/Priority';
import { Recurrence } from './fields/Recurrence';
import { StartDate } from './fields/StartDate';

type EditInspectionSeriesProps = {
  editOption: EditSeriesChoice;
  inspection: InspectionSeries;
  instanceDate: string;
  onClose: () => void;
};

export const EditSeries = ({
  editOption,
  inspection,
  instanceDate,
  onClose,
}: EditInspectionSeriesProps) => {
  const dispatch = useAppDispatch();
  const zones = useSelector(inspectionSeriesEditSlice.selectors.zones);
  const hasChanges = useSelector(
    inspectionSeriesEditSlice.selectors.hasUnsavedChanges,
  );
  const { splitSeries, updateSeries } = useInspectionSeriesEdit(inspection);
  const recurrence = useSelector(
    inspectionSeriesEditSlice.selectors.recurrenceRule,
  );
  const t = useInternationalization('inspections.edit');

  const isSingleInstance = inspection.isSingleInstance;
  const recurrenceSingleAndUnchanged =
    isSingleInstance && isEqualAsRRule(recurrence, inspection.recurrenceRule);

  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    const startDate =
      editOption === EditSeriesChoice.CurrentAndFollowing
        ? instanceDate
        : inspection.startDate;

    dispatch(
      inspectionSeriesEditSlice.actions.load({
        inspection,
        instanceDate,
        startDate,
      }),
    );
  }, [editOption, inspection, instanceDate, dispatch]);

  const moveZone = useCallback(
    (fromIndex: number, toIndex: number) => {
      dispatch(
        inspectionSeriesEditSlice.actions.moveZone({ fromIndex, toIndex }),
      );
    },
    [dispatch],
  );

  const deleteZone = useCallback(
    (zone: Summary) => {
      dispatch(inspectionSeriesEditSlice.actions.deleteZone(zone));
    },
    [dispatch],
  );

  const updateZones = useCallback(
    (updatedZones: Summary[]) => {
      dispatch(inspectionSeriesEditSlice.actions.updateZones(updatedZones));
    },
    [dispatch],
  );

  const attemptClose = () => {
    if (hasChanges) {
      setShowConfirmation(true);
    } else {
      onClose();
    }
  };

  const confirmClose = () => {
    setShowConfirmation(false);
    onClose();
  };

  return (
    <SidePanel
      onClose={attemptClose}
      primary={{
        action: () => {
          if (editOption === EditSeriesChoice.All) {
            updateSeries();
          } else {
            splitSeries();
          }
        },
        disabled: !hasChanges || recurrenceSingleAndUnchanged,
        text: t('button.save'),
      }}
      secondary={{
        action: attemptClose,
        text: t('button.close'),
      }}
      title={t(isSingleInstance ? 'series.convert.title' : 'series.title')}
      titleIcon={<CalendarIcon />}
    >
      <Name />
      {recurrenceSingleAndUnchanged && (
        <WarningMessage variant='light'>
          {t('series.recurrence.warning')}.
        </WarningMessage>
      )}
      <div
        className={clsx(
          'mt-6 text-sm font-medium gap-3 items-center',
          'grid grid-cols-[minmax(auto,40%)_calc(60%-0.5rem)]',
        )}
      >
        {isSingleInstance && (
          <>
            <StartDate />
            <Recurrence />
          </>
        )}
        <Facility />
        <SelectZones
          deleteZone={deleteZone}
          facility={inspection.facility}
          moveZone={moveZone}
          updateZones={updateZones}
          zones={zones}
        />
        <Assignee />
        <InspectionPriority />
        {!isSingleInstance && (
          <>
            <StartDate />
            <Recurrence />
          </>
        )}
      </div>
      {showConfirmation && (
        <AcknowledgmentDialog
          acknowledgmentText={t('series.discard.message')}
          buttonText={t('series.discard.confirm')}
          cancelButtonText={t('series.discard.return')}
          onAcknowledgment={confirmClose}
          onCloseDialog={() => {
            setShowConfirmation(false);
          }}
          title={t('series.discard.header')}
        />
      )}
    </SidePanel>
  );
};
