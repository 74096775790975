import { InspectionInstance, TaskInstance } from '@dakota/platform-client';
import {
  ClipboardDocumentCheckIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import { GridColDef } from '@mui/x-data-grid';
import { clsx } from 'clsx';
import { AttachmentCounter } from 'components/AttachmentCounter';
import { AssigneeAvatar } from 'components/Avatar';
import { InspectionLauncher } from 'components/Inspections/Launcher';
import { ZoneCell } from 'components/Inspections/ZoneCell';
import { OverdueIndicator } from 'components/OverdueIndicator';
import { PriorityIcon } from 'components/PriorityIcon';
import { toHumanReadable } from 'components/recurrence/humanReadable';
import { MuiGridWrapper } from 'components/Table/GridWrapper';
import { MobileTable } from 'components/Table/mobileTable';
import Tooltip from 'components/Tooltip';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useDateFormat } from 'hooks/useDateFormat';
import { useEnumLabel } from 'hooks/useEnumLabel';
import { useInspectionSidePanelLoader } from 'hooks/useInspectionSidePanelLoader';
import { useInternationalization } from 'hooks/useInternationalization';
import { useTaskLoader } from 'hooks/useTaskLoader';
import { useUsers } from 'hooks/useUsers';
import { StatusDropdown } from 'Pages/Tasks/StatusDropdown';
import { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { getHighlighter } from 'utils/highlighter';

type AssignmentRow = InspectionInstance | TaskInstance;

interface AssignmentsTableProps {
  data: AssignmentRow[];
  loading?: boolean;
  /**
   * If provided, it will highlight the search query in a few columns.
   * @default `undefined`
   */
  searchQuery?: string;
}

const AssignmentsTable: FC<AssignmentsTableProps> = ({
  data,
  loading,
  searchQuery,
}) => {
  const { isMobile } = useBreakpoints();
  const t = useInternationalization('assignments');

  const highlight = getHighlighter(searchQuery ?? '');

  const { getUserSummary } = useUsers();
  const { formatBackendDate } = useDateFormat();
  const { getPriorityLabel, getStatusLabel } = useEnumLabel();

  const getParams = useCallback(
    (date: string, seriesId: string) => `date=${date}&seriesId=${seriesId}`,
    [],
  );
  const { openTask } = useTaskLoader(true);
  const { openSidePanel: openInspection } = useInspectionSidePanelLoader(true);

  // Type guard functions to identify whether a row is an Inspection or
  // TaskInstance. This helps safely distinguish between these two types in
  // `AssignmentRow`.
  const isInspection = (row: AssignmentRow): row is InspectionInstance => {
    return row instanceof InspectionInstance;
  };

  const isTaskInstance = (row: AssignmentRow): row is TaskInstance => {
    return row instanceof TaskInstance;
  };

  const openAssignment = (row: AssignmentRow) => {
    if (isInspection(row)) {
      openInspection(row);
    } else if (isTaskInstance(row)) {
      openTask(row.timeline.dueDate, row.seriesId);
    }
  };

  const typeColumn: GridColDef<AssignmentRow> = {
    align: 'center',
    display: 'flex',
    field: 'type',
    headerAlign: 'center',
    headerName: '',
    renderCell: ({ row }) =>
      isInspection(row) ? (
        <Tooltip arrow placement='bottom' title={t('table.type.inspection')}>
          <div aria-label={t('table.type.inspection')}>
            <ClipboardDocumentCheckIcon className='w-5 h-5 max-sm:hidden' />
          </div>
        </Tooltip>
      ) : (
        <Tooltip arrow placement='bottom' title={t('table.type.task')}>
          <div aria-label={t('table.type.task')}>
            <PencilSquareIcon className='w-5 h-5 max-sm:hidden' />
          </div>
        </Tooltip>
      ),
    resizable: false,
    width: 50,
  };

  const titleColumn: GridColDef<AssignmentRow> = {
    cellClassName: 'flex-col !justify-center !items-start gap-1',
    display: 'flex',
    field: 'title',
    flex: 1,
    headerName: t('table.header.title'),
    minWidth: 300,
    renderCell: ({ row }) => {
      const link = `/dashboard/assignments?open=${
        isInspection(row) ? 'inspection' : 'task'
      }&${getParams(row.timeline.dueDate, row.seriesId)}`;

      const title = isInspection(row) ? row.form.name : row.title;

      return (
        <>
          <Link
            className={clsx('text-green-base hover:text-green-darker w-full', {
              truncate: !isMobile,
            })}
            to={link}
          >
            {highlight(title)}
          </Link>
          {row.overdue && <OverdueIndicator />}
        </>
      );
    },
  };

  const recurrenceColumn: GridColDef<AssignmentRow> = {
    field: 'recurrence',
    headerName: t('table.header.recurrence'),
    maxWidth: 250,
    minWidth: 140,
    renderCell: (params) => toHumanReadable(params.row.seriesRecurrence.rule),
  };

  const assigneeColumn: GridColDef<AssignmentRow> = {
    align: 'center',
    display: 'flex',
    field: 'assignee',
    headerAlign: 'center',
    headerName: t('table.header.assignee'),
    minWidth: 100,
    renderCell: ({ row }) => {
      if (isMobile) {
        // Suppressing the warning because the fragment ensures a consistent
        // return type (JSX.Element) without introducing unnecessary wrapping
        // elements like <div> or <span>.
        return <>{getUserSummary(row.assigneeId)?.name}</>;
      } else {
        return <AssigneeAvatar user={getUserSummary(row.assigneeId)} />;
      }
    },
  };

  const dueDateColumn: GridColDef<AssignmentRow> = {
    field: 'dueDate',
    headerName: t('table.header.dueDate'),
    renderCell: ({ row }) => formatBackendDate(row.timeline.dueDate),
    resizable: false,
    width: 100,
  };

  const facilityColumn: GridColDef<AssignmentRow> = {
    display: 'flex',
    field: 'facility',
    flex: 1,
    headerName: t('table.header.facility'),
    maxWidth: 300,
    minWidth: 150,
    renderCell: ({ row }) => {
      if (isInspection(row)) {
        return (
          <div className='py-2 space-y-2'>
            <div>{highlight(row.facility.name)}</div>
            <ZoneCell name={row.form.name} zones={row.zones} />
          </div>
        );
      } else if (isTaskInstance(row) && row.zone) {
        return (
          <div className='py-2 space-y-2'>
            <div>{highlight(row.facility.name)}</div>
            <div aria-label='Zone name'>{highlight(row.zone.name)}</div>
          </div>
        );
      } else if (isTaskInstance(row) && !row.zone) {
        return (
          <div className='py-2 space-y-2'>
            <div>{highlight(row.facility.name)}</div>
            <ZoneCell name={row.facility.name} zones={[]} />
          </div>
        );
      }
    },
  };

  const metadataColumn: GridColDef<AssignmentRow> = {
    align: 'left',
    display: 'flex',
    field: 'metadata',
    flex: 1,
    headerAlign: 'center',
    headerName: '',
    minWidth: 340,
    renderCell: ({ row }) => {
      const { comments, media } = row.attachments;

      return (
        <div className='flex items-center gap-2'>
          <PriorityIcon priority={row.priority} />
          {isInspection(row) ? (
            <span className='min-w-36 text-left pl-2'>
              {getStatusLabel(row.status)}
            </span>
          ) : (
            <StatusDropdown task={row} />
          )}
          <AttachmentCounter
            comments={comments}
            media={media}
            onClick={() => {
              openAssignment(row);
            }}
          />
          {isInspection(row) && <InspectionLauncher inspection={row} />}
        </div>
      );
    },
  };

  const attachmentsColumn: GridColDef<AssignmentRow> = {
    field: 'attachments',
    renderCell: ({ row }) => {
      const { comments, media } = row.attachments;
      return (
        <AttachmentCounter
          comments={comments}
          media={media}
          onClick={() => {
            openAssignment(row);
          }}
        />
      );
    },
  };

  const columns = [
    typeColumn,
    titleColumn,
    recurrenceColumn,
    assigneeColumn,
    dueDateColumn,
    facilityColumn,
    metadataColumn,
  ];

  const typeColumnMobile: GridColDef<AssignmentRow> = {
    field: 'type',
    headerName: t('table.header.type'),
    renderCell: (params) =>
      isInspection(params.row)
        ? t('table.type.inspection')
        : t('table.type.task'),
  };

  const priorityColumnMobile: GridColDef<AssignmentRow> = {
    field: 'priority',
    headerName: t('table.header.priority'),
    renderCell: (params) => getPriorityLabel(params.row.priority),
  };

  const statusColumnMobile: GridColDef<AssignmentRow> = {
    field: 'status',
    headerName: t('table.header.status'),
    renderCell: (params) =>
      isInspection(params.row) ? (
        getStatusLabel(params.row.status)
      ) : (
        <StatusDropdown task={params.row} />
      ),
  };

  const actionsColumnMobile: GridColDef<AssignmentRow> = {
    field: 'actions',
    headerName: 'Actions',
    renderCell: ({ row }) =>
      isInspection(row) && <InspectionLauncher inspection={row} />,
  };

  const mobileColumns = [
    typeColumnMobile,
    titleColumn,
    recurrenceColumn,
    dueDateColumn,
    facilityColumn,
    priorityColumnMobile,
    assigneeColumn,
    statusColumnMobile,
    attachmentsColumn,
    actionsColumnMobile,
  ];

  return isMobile ? (
    <MobileTable columns={mobileColumns} loading={loading} rows={data} />
  ) : (
    <MuiGridWrapper
      columns={columns}
      loading={loading}
      rowHeight={70}
      rows={data}
    />
  );
};

export default AssignmentsTable;
