import { Priority } from '@dakota/platform-client';
import Autocomplete from 'components/Autocomplete';
import { inspectionSeriesEditSlice } from 'features/inspections/inspectionSeriesEditSlice';
import { useEnumLabel } from 'hooks/useEnumLabel';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export const InspectionPriority: FC = () => {
  const dispatch = useAppDispatch();
  const { getPriorityLabel } = useEnumLabel();

  const priority = useSelector(inspectionSeriesEditSlice.selectors.priority);

  return (
    <>
      <label htmlFor='series-priority-selector'>Priority</label>
      <Autocomplete
        fullWidth
        getOptionLabel={getPriorityLabel}
        id='series-priority-selector'
        onChange={(value: Priority) =>
          dispatch(
            inspectionSeriesEditSlice.actions.setInspectionField({
              field: 'priority',
              value,
            }),
          )
        }
        options={[Priority.Low, Priority.Medium, Priority.High]}
        value={priority}
      />
    </>
  );
};
