import { ClipboardIcon } from '@heroicons/react/24/outline';
import { DateTimeFormatter, LocalDate } from '@js-joda/core';
import { clsx } from 'clsx';
import AcknowledgmentDialog from 'components/Dialog/AcknowledgmentDialog';
import { SidePanel_v2 as SidePanel } from 'components/SidePanel_v2';
import { WarningMessage } from 'components/WarningMessage';
import { notificationsSlice } from 'features/notifications/notificationsSlice';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { useInternationalization } from 'hooks/useInternationalization';
import { useTaskEdit } from 'hooks/useTaskEdit';
import useToast from 'hooks/useToast';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { datetime, RRule } from 'rrule';
import { useAppDispatch } from 'store/store';

import { Assignee } from './fields/Assignee';
import { Attachments } from './fields/Attachments';
import { Description } from './fields/Description';
import { DueDate } from './fields/DueDate';
import { Facility } from './fields/Facility';
import { Note } from './fields/Note';
import { TaskPriority } from './fields/Priority';
import { Recurrence } from './fields/Recurrence';
import { Title } from './fields/Title';
import { Zone } from './fields/Zone';

type CreateTaskProps = {
  onClose: (taskCreated?: boolean) => void;
};

export const CreateTask: FC<CreateTaskProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { addAttachmentsToInstance, createNewTask } = useTaskEdit();
  const hasUnsavedChanges = useSelector(
    taskEditSlice.selectors.hasUnsavedChanges,
  );

  const { setErrorMessage, setSuccessMessage } = useToast();

  const t = useInternationalization('tasks.create');

  const canSave = useSelector(taskEditSlice.selectors.canSave);
  const date = useSelector(taskEditSlice.selectors.date);
  const recurrence = useSelector(taskEditSlice.selectors.recurrence);

  const { firstInstanceDate, isRecurring } = useMemo(() => {
    const localDate = LocalDate.parse(date);
    const ruleObj = new RRule({
      ...RRule.parseString(recurrence),
      dtstart: datetime(
        localDate.year(),
        localDate.monthValue(),
        localDate.dayOfMonth(),
      ),
    });

    // Obtain the first instance only to correctly display the due date.
    // It is VERY important we stop the iterator after the first instance,
    // otherwise a call to `ruleObj.all()` can be very slow.
    // See https://github.com/jkbrzt/rrule?tab=readme-ov-file#rruleprototypealliterator
    const firstInstance = ruleObj.all((_, i) => i < 1)[0];

    return {
      firstInstanceDate: LocalDate.of(
        firstInstance.getUTCFullYear(),
        firstInstance.getUTCMonth() + 1,
        firstInstance.getUTCDate(),
      ).format(DateTimeFormatter.ofPattern('MM/dd/yyyy')),
      isRecurring: ruleObj.options.count !== 1,
    };
  }, [date, recurrence]);

  const [saving, setSaving] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    // Clear the edit state when we open the create dialog
    dispatch(taskEditSlice.actions.clear());
  }, [dispatch]);

  const clearAndClose = (taskCreated?: boolean) => {
    dispatch(taskEditSlice.actions.clear());
    onClose(taskCreated);
  };

  const checkChangesAndClose = () => {
    if (hasUnsavedChanges) {
      setShowConfirmation(true);
    } else {
      clearAndClose();
    }
  };

  const goToCreatedTask = (seriesId: string, startDate: string) => {
    dispatch(taskEditSlice.actions.clear());
    setSearchParams({ ...searchParams, date: startDate, seriesId });
  };

  const createTaskWithAttachments = () => {
    setSaving(true);

    createNewTask()
      .then(async (taskSeries) => ({
        failedFiles: await addAttachmentsToInstance(
          taskSeries.id,
          taskSeries.startDate,
        ),
        taskSeries,
      }))
      .then((result) => {
        if (result.failedFiles.length === 0) {
          setSuccessMessage(t('success'));
        } else {
          const message = (
            <>
              {t('failure.attachment')}
              <ul className='list-inside list-disc'>
                {result.failedFiles.map((file) => (
                  <li key={file}>{file}</li>
                ))}
              </ul>
            </>
          );

          const actions = [
            {
              onClick: () => {
                goToCreatedTask(
                  result.taskSeries.id,
                  result.taskSeries.startDate,
                );
              },
              text: t('openTask'),
            },
          ];

          dispatch(
            notificationsSlice.actions.addStickyMessage({
              actions,
              content: message,
            }),
          );
        }
        clearAndClose(true);
      })
      // @TODO: Update error messages to handle all possible error responses as
      // well as use keys for translated strings
      .catch((e: unknown) => {
        if (e === 400) {
          setErrorMessage(t('failure.recurrence'));
        } else {
          setErrorMessage(t('failure'));
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <SidePanel
      onClose={checkChangesAndClose}
      primary={{
        action: createTaskWithAttachments,
        disabled: !canSave,
        loading: saving,
        text: saving ? t('button.saving') : t('button.save'),
      }}
      secondary={{
        action: checkChangesAndClose,
        disabled: saving,
        text: t('button.close'),
      }}
      title={t('panel.title')}
      titleIcon={<ClipboardIcon aria-label='Clipboard Icon' />}
    >
      <div
        className={clsx(
          'text-sm font-medium gap-3 items-center',
          'grid grid-cols-[minmax(auto,40%)_calc(60%-0.5rem)]',
        )}
      >
        <Title />
        <Facility />
        <Zone />
        <Assignee />
        <TaskPriority />
        <DueDate />
        <Recurrence />
        <Description />
      </div>
      {isRecurring && (
        <div className='p-4'>
          <WarningMessage variant='light'>
            {t('recurrence.warning', { firstInstanceDate })}
          </WarningMessage>
        </div>
      )}
      <div className='flex flex-col gap-4'>
        <Note />
        <Attachments />
      </div>
      {showConfirmation && (
        <AcknowledgmentDialog
          acknowledgmentText={t('acknowledgement.message')}
          buttonText={t('acknowledgement.button')}
          cancelButtonText={t('acknowledgement.cancel')}
          confirmationButtonSide='right'
          onAcknowledgment={() => {
            setShowConfirmation(false);
          }}
          onCloseDialog={() => {
            setShowConfirmation(false);
            onClose();
          }}
          title={t('acknowledgement.title')}
        />
      )}
    </SidePanel>
  );
};
