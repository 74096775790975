import { InspectionInstanceDetails, Prompt } from '@dakota/platform-client';
import { faPaperclip } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SidePanel_v2 as SidePanel } from 'components/SidePanel_v2';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { useAppConfiguration } from 'hooks/useAppConfiguration';
import useInspectionAttachmentUpload from 'hooks/useInspectionAttachmentUpload';
import { useInternationalization } from 'hooks/useInternationalization';
import useToast from 'hooks/useToast';
import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { QuestionSummary } from '../QuestionSummary';
import { AddAttachments } from './AddAttachments';

type Props = {
  onClose: () => void;
  prompt: Prompt;
  promptIndex: number;
  sectionIndex: number;
  showInCarousel: (index: number) => void;
};

export const AttachmentsSidePanel: FC<Props> = ({
  onClose,
  prompt,
  promptIndex,
  sectionIndex,
  showInCarousel,
}) => {
  const t = useInternationalization('inspections.prompts.attachments');
  const { getConfig } = useAppConfiguration();
  const { setSuccessMessage } = useToast();

  const inspection = useSelector(
    inspectionSlice.selectors.inspectionDetails,
  ) as InspectionInstanceDetails;

  const uploadProps = useInspectionAttachmentUpload({
    dueDate: inspection.timeline.dueDate,
    promptId: prompt.id,
    promptIndex,
    sectionIndex,
    seriesId: inspection.seriesId,
  });

  const saveAttachments = useCallback(() => {
    void uploadProps.saveAttachments().then(({ success }) => {
      if (success) {
        setSuccessMessage(t('savedToast'));
        onClose();
      }
    });
  }, [onClose, setSuccessMessage, t, uploadProps]);

  return (
    <SidePanel
      hasPadding={false}
      onClose={onClose}
      primary={{
        action: saveAttachments,
        disabled:
          uploadProps.uploads.length === 0 ||
          uploadProps.uploads.length >
            getConfig('MaximumAttachmentsPerQuestion') ||
          uploadProps.uploads.some(
            (upload) => upload.file.size > getConfig('AttachmentMaxFileSize'),
          ),
        loading: uploadProps.isSaving,
        text: t('save'),
      }}
      title={t('title')}
      titleIcon={<FontAwesomeIcon icon={faPaperclip} />}
    >
      <QuestionSummary question={prompt.question} />
      <AddAttachments
        prompt={prompt}
        showInCarousel={showInCarousel}
        {...uploadProps}
      />
    </SidePanel>
  );
};
