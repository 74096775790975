import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { filesSlice } from 'features/files/filesSlice';
import { flagsSlice } from 'features/flags/flagsSlice';
import { inspectionEditSlice } from 'features/inspections/inspectionEditSlice';
import { inspectionSeriesEditSlice } from 'features/inspections/inspectionSeriesEditSlice';
import { reportsSlice } from 'features/reports/reportsSlice';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { tasksDetailsSlice } from 'features/tasks/tasksDetailsSlice';
import { taskSeriesEditSlice } from 'features/tasks/taskSeriesEditSlice';
import { tasksSlice } from 'features/tasks/tasksSlice';
import { timeZoneSlice } from 'features/timeZones/timeZoneSlice';
import { zonesSlice } from 'features/zones/zonesSlice';
import { enableMapSet } from 'immer';
import { useDispatch } from 'react-redux';

import { answersSlice } from '../features/answers/answersSlice';
import { configSlice } from '../features/config/configSlice';
import { facilitiesSlice } from '../features/facilities/facilitiesSlice';
import { formsSlice } from '../features/forms/formsSlice';
import { inspectionSlice } from '../features/inspections/inspectionSlice';
import { itemGroupsSlice } from '../features/items/itemGroupsSlice';
import { itemTypesSlice } from '../features/items/itemTypesSlice';
import { notificationsSlice } from '../features/notifications/notificationsSlice';
import { questionsSlice } from '../features/questions/questionsSlice';
import { rolesSlice } from '../features/roles/rolesSlice';
import { templatesSlice } from '../features/templates/templatesSlice';
import { toastSlice } from '../features/toast/toastSlice';
import { tokenSlice } from '../features/token/tokenSlice';
import { userSlice } from '../features/user/userSlice';

// Enable the use of Map and Set in reducers
enableMapSet();

export const rootReducer = combineReducers({
  answers: answersSlice.reducer,
  conductInspection: inspectionSlice.reducer,
  config: configSlice.reducer,
  facilities: facilitiesSlice.reducer,
  files: filesSlice.reducer,
  flags: flagsSlice.reducer,
  forms: formsSlice.reducer,
  inspectionEdit: inspectionEditSlice.reducer,
  inspectionSeriesEdit: inspectionSeriesEditSlice.reducer,
  itemGroups: itemGroupsSlice.reducer,
  itemTypes: itemTypesSlice.reducer,
  notifications: notificationsSlice.reducer,
  questions: questionsSlice.reducer,
  reports: reportsSlice.reducer,
  roles: rolesSlice.reducer,
  taskEdit: taskEditSlice.reducer,
  tasks: tasksSlice.reducer,
  tasksDetails: tasksDetailsSlice.reducer,
  taskSeriesEdit: taskSeriesEditSlice.reducer,
  templates: templatesSlice.reducer,
  timeZones: timeZoneSlice.reducer,
  toast: toastSlice.reducer,
  token: tokenSlice.reducer,
  user: userSlice.reducer,
  zones: zonesSlice.reducer,
});

export function setupStore(preloadedState?: RootState) {
  return configureStore({
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: { warnAfter: 256 },
        serializableCheck: false,
      }),
    preloadedState,
    reducer: rootReducer,
  });
}

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;

export const useAppDispatch = () => useDispatch<(typeof store)['dispatch']>();
