import { Summary } from '@dakota/platform-client';
import { clsx } from 'clsx';
import Autocomplete from 'components/Autocomplete';
import Toggle from 'components/Toggle';
import Tooltip from 'components/Tooltip';
import { inspectionEditSlice } from 'features/inspections/inspectionEditSlice';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useInternationalization } from 'hooks/useInternationalization';
import { useZones } from 'hooks/useZones';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from 'store/store';

import { OrderZones } from './OrderZones';

type SelectZonesProps = {
  deleteZone?: (zone: Summary) => void;
  facility: Summary | undefined;
  moveZone?: (fromIndex: number, toIndex: number) => void;
  updateZones?: (zones: Summary[]) => void;
  zones: Summary[];
};

export const SelectZones: FC<SelectZonesProps> = ({
  deleteZone,
  facility,
  moveZone,
  updateZones,
  zones,
}) => {
  const t = useInternationalization('components.zones');
  const { isMobile } = useBreakpoints();
  const dispatch = useAppDispatch();

  const onMoveZone = useCallback(
    (fromIndex: number, toIndex: number) => {
      if (moveZone) {
        moveZone(fromIndex, toIndex);
      } else {
        dispatch(inspectionEditSlice.actions.moveZone({ fromIndex, toIndex }));
      }
    },
    [moveZone, dispatch],
  );

  const onDeleteZone = useCallback(
    (zone: Summary) => {
      if (deleteZone) {
        deleteZone(zone);
      } else {
        dispatch(inspectionEditSlice.actions.deleteZone(zone));
      }
    },
    [deleteZone, dispatch],
  );

  const onUpdateZones = useCallback(
    (updatedZones: Summary[]) => {
      if (updateZones) {
        updateZones(updatedZones);
      } else {
        dispatch(inspectionEditSlice.actions.updateZones(updatedZones));
      }
    },
    [updateZones, dispatch],
  );

  const [selectableZones, setSelectableZones] = useState<Summary[]>([]);

  const { getActiveFacilityZonesSummaries } = useZones();

  useEffect(() => {
    if (facility?.id) {
      const facilityZones = getActiveFacilityZonesSummaries(facility.id);
      setSelectableZones(facilityZones);
    } else {
      setSelectableZones([]);
    }
  }, [facility?.id, getActiveFacilityZonesSummaries]);

  const [isSelecting, setIsSelecting] = useState(zones.length > 0);

  return (
    <>
      {selectableZones.length > 0 && (
        <Tooltip arrow placement='left' title={t('tooltip')}>
          <label className='col-span-2 flex items-center gap-3 sm:place-self-end'>
            <Toggle isOn={isSelecting} onToggle={setIsSelecting} />
            <span className='max-sm:-order-1'>{t('label')}</span>
          </label>
        </Tooltip>
      )}
      {isSelecting && (
        <>
          {!isMobile && <div className='h-0' />}
          <div
            className={clsx('flex flex-col gap-3', isMobile && 'col-span-2')}
          >
            <Autocomplete
              className='w-full'
              getOptionKey={(zone) => zone.id}
              getOptionLabel={(z) => z.name}
              id='zones-selector'
              isOptionEqualToValue={(option, value) => option.id === value.id}
              multiple
              onChange={onUpdateZones}
              options={selectableZones}
              value={zones}
            />
            <OrderZones
              onDeleteZone={onDeleteZone}
              onMoveZone={onMoveZone}
              zones={zones}
            />
          </div>
        </>
      )}
    </>
  );
};
