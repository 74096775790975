import { inspectionSeriesEditSlice } from 'features/inspections/inspectionSeriesEditSlice';
import { FC } from 'react';
import { useSelector } from 'react-redux';

export const Name: FC = () => {
  const form = useSelector(inspectionSeriesEditSlice.selectors.form);

  return (
    <h3 className='text-base font-normal text-gray-900 pb-6'>{form?.name}</h3>
  );
};
