import Autocomplete from 'components/Autocomplete';
import { FC, useEffect, useState } from 'react';
import { Weekday } from 'rrule';

import { toOrdinal } from './print';
import { dayToString, monthToString } from './rrule';
import { YearlyRecurrenceDetails } from './types';

type AnnualSelectionProps = {
  initialBySetPos: number;
  initialDayOfMonth: number;
  initialMonthOfYear: number;
  initialType: 'bymonthday' | 'byweekday';
  initialWeekday: Weekday;
  /**
   * Callback that is called when the selection changes.
   */
  onChange: (details: YearlyRecurrenceDetails) => void;
};

export const YearlySelection: FC<AnnualSelectionProps> = ({
  initialBySetPos,
  initialDayOfMonth,
  initialMonthOfYear,
  initialType,
  initialWeekday,
  onChange,
}) => {
  // bymonthday: "Annually on July 23rd"
  // byweekday: "Annually on the 2nd Saturday of April"
  const options = ['bymonthday', 'byweekday'] as const;
  const [option, setOption] = useState<(typeof options)[number]>(initialType);
  const [bymonth, setBymonth] = useState(initialMonthOfYear);
  const [bymonthday, setBymonthday] = useState(initialDayOfMonth);
  const [bysetpos, setBysetpos] = useState(initialBySetPos);
  const [byweekday, setByweekday] = useState(initialWeekday);

  useEffect(() => {
    setOption(initialType);
  }, [initialType]);
  useEffect(() => {
    setBymonth(initialMonthOfYear);
  }, [initialMonthOfYear]);
  useEffect(() => {
    setBymonthday(initialDayOfMonth);
  }, [initialDayOfMonth]);
  useEffect(() => {
    setBysetpos(initialBySetPos);
  }, [initialBySetPos]);
  useEffect(() => {
    setByweekday(initialWeekday);
  }, [initialWeekday]);

  const selectOption = (newOption: (typeof options)[number]) => {
    setOption(newOption);
    if (newOption === 'byweekday') {
      onChange({ bymonth, bysetpos, byweekday });
    } else {
      onChange({ bymonth: initialMonthOfYear, bymonthday: initialDayOfMonth });
    }
  };

  const getOptionLabel = (opt: (typeof options)[number]) => {
    switch (opt) {
      case 'bymonthday':
        return `on ${monthToString(bymonth)} ${toOrdinal(bymonthday)}`;
      case 'byweekday':
        return `on the ${toOrdinal(bysetpos)} ${dayToString(
          byweekday,
        )} of ${monthToString(bymonth)}`;
    }
  };

  return (
    <div className='flex justify-end'>
      <Autocomplete
        className='w-80'
        getOptionLabel={getOptionLabel}
        id='recurrence-yearly-option'
        onChange={selectOption}
        options={options}
        value={option}
      />
    </div>
  );
};
