import {
  Facility,
  Status,
  Summary,
  TaskInstance,
  UserSummary,
} from '@dakota/platform-client';
import { DateTimeFormatter, LocalDate } from '@js-joda/core';
import Autocomplete from 'components/Autocomplete';
import Chips from 'components/Chip/Chips';
import { ClearAllButton } from 'components/ClearAll';
import { DatePicker, DatePickerRange } from 'components/DatePicker';
import { PageHeader } from 'components/PageHeader';
import SearchInput from 'components/SearchInput';
import { facilitiesSlice } from 'features/facilities/facilitiesSlice';
import { notificationsSlice } from 'features/notifications/notificationsSlice';
import { tasksSlice } from 'features/tasks/tasksSlice';
import { useEnumLabel } from 'hooks/useEnumLabel';
import { useFilteredTasks } from 'hooks/useFilteredTasks';
import { useInternationalization } from 'hooks/useInternationalization';
import { DataStatus, usePageLoadTracking } from 'hooks/usePageLoadTracking';
import { useTaskLoader } from 'hooks/useTaskLoader';
import { useUsers } from 'hooks/useUsers';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { validateDateRange } from 'utils/validateDateRange';

import TasksTable from '../TasksTable';

export const CompletedTasks: FC = () => {
  const dispatch = useAppDispatch();

  const activeFacilities = useSelector(
    facilitiesSlice.selectors.activeFacilities,
  );

  const loadingTasks = useSelector(tasksSlice.selectors.isLoadingTasks);
  const { openTask, taskViewerComponent } = useTaskLoader();

  const { activeUsersAndUnassigned } = useUsers();
  const { stopTracking } = usePageLoadTracking();
  const { getStatusLabel, Label } = useEnumLabel();

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedAssignees, setSelectedAssignees] = useState<UserSummary[]>([]);
  const [selectedFacilities, setSelectedFacilities] = useState<Facility[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<Status>(
    Status.Completed,
  );

  const statuses = useMemo(() => [Status.Completed, Status.Canceled], []);

  const t = useInternationalization('tasks');
  const {
    dateRange,
    filteredTasks,
    hasFilters,
    hookDataLoaded,
    resetDateRange,
    setDateRange,
  } = useFilteredTasks({
    filterByEndDate: true,
    initialDateRange: {
      begin: LocalDate.now().minusDays(30),
      end: LocalDate.now(),
    },
    initialSelectedStatus: Status.Completed,
    searchQuery,
    selectedAssignees,
    selectedFacilities,
    selectedStatus,
    statuses,
  });

  useEffect(() => {
    if (hookDataLoaded) {
      stopTracking(DataStatus.Fetched);
    }
  }, [hookDataLoaded, stopTracking]);
  const removeFacility = (facility: Summary) => {
    setSelectedFacilities((prev) => prev.filter((f) => f.id !== facility.id));
  };

  const removeAssignee = (assignee: UserSummary) => {
    setSelectedAssignees((prev) => prev.filter((f) => f.id !== assignee.id));
  };

  const resetFilters = () => {
    setSearchQuery('');
    setSelectedAssignees([]);
    setSelectedFacilities([]);
    setSelectedStatus(Status.Completed);
    resetDateRange();
  };

  const openTaskInstance = (task: TaskInstance) => {
    openTask(task.timeline.dueDate, task.seriesId);
  };

  const validateRange = (selectedDateRange: DatePickerRange) => {
    const beginDate = selectedDateRange.begin;
    const endDate = validateDateRange(selectedDateRange);
    if (endDate) {
      const formattedMaxDate = endDate.format(
        DateTimeFormatter.ofPattern('MM/dd/yyyy'),
      );
      dispatch(
        notificationsSlice.actions.addStickyMessage({
          actions: [],
          content: `Tasks can only display up to one year in advance, you may select a date range up to ${formattedMaxDate}.`,
        }),
      );
      setDateRange({ begin: beginDate, end: endDate });
    } else {
      setDateRange(selectedDateRange);
    }
  };

  return (
    <div className='p-4 sm:p-8'>
      <PageHeader title='Completed Tasks' />
      <div className='sm:flex sm:justify-between sm:items-center sm:gap-2'>
        <div className='filters-container'>
          <SearchInput
            aria-label='Search tasks'
            id='completed-tasks-search-input'
            onSearch={setSearchQuery}
            value={searchQuery}
          />
          <DatePicker
            asSingle={false}
            id='completed-tasks-date-picker'
            onChange={validateRange}
            scrollOnFocus={false}
            shortcutsType='last'
            showShortcuts
            value={dateRange}
          />
          <Autocomplete
            className='max-sm:w-full w-48'
            getOptionKey={(facility) => facility.id}
            getOptionLabel={(facility) => facility.name}
            id='completed-tasks-facility-selector'
            label={Label.Facilities}
            multiple
            onChange={setSelectedFacilities}
            options={activeFacilities}
            value={selectedFacilities}
          />
          <Autocomplete
            className='max-sm:w-full w-44'
            getOptionKey={(user) => user.id}
            getOptionLabel={(user) => user.name}
            id='completed-tasks-assignee-selector'
            label={Label.Assignee}
            multiple
            onChange={setSelectedAssignees}
            options={activeUsersAndUnassigned}
            value={selectedAssignees}
          />
          <Autocomplete
            className='max-sm:w-full w-40'
            getOptionLabel={getStatusLabel}
            id='completed-tasks-status-selector'
            label={Label.ProgressStatus}
            onChange={setSelectedStatus}
            options={statuses}
            value={selectedStatus}
          />
          {hasFilters && <ClearAllButton onClick={resetFilters} />}
        </div>
      </div>
      <Chips
        elements={selectedFacilities}
        getKey={(f) => f.id}
        getLabel={(f) => f.name}
        onRemove={removeFacility}
        title={t('chips.label.facilities')}
      />
      <Chips
        elements={selectedAssignees}
        getKey={(f) => f.id}
        getLabel={(f) => f.name}
        onRemove={removeAssignee}
        title={t('chips.label.assignees')}
      />
      <TasksTable
        data={filteredTasks}
        loading={loadingTasks}
        openTaskInstance={openTaskInstance}
        showCompletedDate
      />
      {taskViewerComponent}
    </div>
  );
};
