import { tokenSlice } from 'features/token/tokenSlice';
import { jwtDecode } from 'jwt-decode';
import { useSelector } from 'react-redux';

type AccessToken = {
  permissions: readonly string[];
};

export const useCheckPermission = (
  requestedPermissions: Array<string> | string,
) => {
  const token = useSelector(tokenSlice.selectors.token);

  // Fail gracefully (returning false) if the token
  // is not available yet, or is not valid.
  let decodedToken: AccessToken | undefined;
  try {
    decodedToken = jwtDecode<AccessToken>(token);
  } catch (_) {
    return false;
  }

  const { permissions: userPermissions } = decodedToken;
  if (userPermissions.length === 0) {
    return false;
  }
  const permissionsArray = Array.isArray(requestedPermissions)
    ? requestedPermissions
    : [requestedPermissions];
  return permissionsArray.some((v) => userPermissions.includes(v));
};
