import {
  InstanceSearchParams,
  Status,
  Summary,
  TaskInstance,
  UserSummary,
} from '@dakota/platform-client';
import { LocalDate } from '@js-joda/core';
import { DatePickerRange } from 'components/DatePicker';
import { ClientData } from 'features/clientProvider';
import { configSlice } from 'features/config/configSlice';
import { listTasks } from 'features/tasks/tasksActions';
import { tasksSlice } from 'features/tasks/tasksSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import Fuse from 'fuse.js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { SearchStatus } from 'types';
import { compareTasks } from 'utils/compare';
import { unassignedUser } from 'utils/user';

import { useDateRange } from './useDateRange';

type UseFilteredTasksParams = {
  /**
   * If true, use the `endDate` of a Tasks's timeline when filtering by
   * date, otherwise, filter by the `dueDate`.
   *
   * Note: this should be true when calling the hook in completed views, and
   * false in scheduled views.
   */
  filterByEndDate: boolean;
  /**
   * Initial date range for the date picker.
   * @default range from today to 7 days from now
   */
  initialDateRange?: DatePickerRange;
  /**
   * If present, filter by text search
   * @default `undefined`
   */
  searchQuery?: string;
  /**
   * If present, filter by assignee
   * @default `undefined`
   */
  selectedAssignees?: UserSummary[];
  /**
   * If present, filter by facility
   * @default `undefined`
   */
  selectedFacilities?: Summary[];
  /**
   * Statuses to show in the table. This object *must* be memoized in the
   * caller.
   * @default `[]`
   */
  statuses: SearchStatus[];
} & (
  | {
      /**
       * When we use an array for selected statuses, we always default to "all
       * statuses", so we don't need an initial value.
       */
      initialSelectedStatus?: never;
      /**
       * If present, filter by the statuses in the array. If not, use all statuses.
       * @default `undefined`
       */
      selectedStatus?: SearchStatus[];
    }
  | {
      initialSelectedStatus: SearchStatus;
      /**
       * If present, filter by this status.
       * @default `undefined`
       */
      selectedStatus?: SearchStatus;
    }
);

export const useFilteredTasks = ({
  filterByEndDate,
  initialDateRange = {
    begin: LocalDate.now(),
    end: LocalDate.now().plusDays(7),
  },
  initialSelectedStatus,
  searchQuery = '',
  selectedAssignees = undefined,
  selectedFacilities = undefined,
  selectedStatus = undefined,
  statuses = [],
}: UseFilteredTasksParams) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const allTasks = useSelector(tasksSlice.selectors.tasks);
  const sortedTasks = useMemo(
    () => allTasks.toSorted(compareTasks),
    [allTasks],
  );
  const [filteredTasks, setFilteredTasks] = useState<TaskInstance[]>([]);

  const { dateRange, resetDateRange, setDateRange } =
    useDateRange(initialDateRange);

  /** Used to force re-fetching the data when the refresh function is called. */
  const [forceTimestamp, setForceTimestamp] = useState(0);

  const [hookDataLoaded, setHookDataLoaded] = useState(false);

  const hasFilters =
    !!searchQuery ||
    !dateRange.begin.equals(initialDateRange.begin) ||
    !dateRange.end.equals(initialDateRange.end) ||
    !!selectedAssignees?.length ||
    !!selectedFacilities?.length ||
    (Array.isArray(selectedStatus) && !!selectedStatus.length) ||
    (!Array.isArray(selectedStatus) &&
      selectedStatus !== initialSelectedStatus);

  /**
   * If there's an assignee ID, we only show tasks assigned to that user.
   *
   * If `unassignedUser` is selected, we include tasks that are not
   * assigned to any user.
   *
   * Otherwise, we only filter if there are selected assignees. If there are
   * none, all tasks pass this filter.
   */
  const isAssignedToUser = useCallback(
    (task: TaskInstance, assignees?: UserSummary[]) =>
      !assignees?.length ||
      assignees.some(
        (assignee) =>
          (task.assigneeId === undefined &&
            assignee.id === unassignedUser.id) ||
          task.assigneeId === assignee.id,
      ),
    [],
  );

  /** Fetch fresh data from the backend, even if the filters haven't changed. */
  const refresh = useCallback(() => {
    setForceTimestamp(Date.now());
  }, []);

  // Check if task status matches the selected status
  const matchesStatus = (
    task: TaskInstance,
    status?: SearchStatus | SearchStatus[],
  ) => {
    if (!status) {
      return true;
    }

    if (Array.isArray(status)) {
      return (
        !status.length ||
        status.includes(task.status) ||
        (status.includes('Overdue') && task.overdue)
      );
    }

    return (
      status.includes(task.status) || (status === 'Overdue' && task.overdue)
    );
  };

  const isAtFacility = (task: TaskInstance, facilities?: Summary[]) =>
    !facilities?.length || facilities.some((f) => f.id === task.facility.id);

  const isInDateRange = useCallback(
    (task: TaskInstance) => {
      const taskDate = LocalDate.parse(
        filterByEndDate && task.timeline.endDate
          ? task.timeline.endDate
          : task.timeline.dueDate,
      );
      return (
        !taskDate.isBefore(dateRange.begin) && !taskDate.isAfter(dateRange.end)
      );
    },
    [dateRange.begin, dateRange.end, filterByEndDate],
  );

  const filterTasks = useCallback(
    (tasks: TaskInstance[], skipDateCheck = false) =>
      tasks.filter((task) => {
        // Check if task status matches the selected status
        const matchesFilterStatuses = statuses.includes(task.status);

        // If 'includeOverdue' is true, include all overdue tasks
        if (
          Array.isArray(selectedStatus) &&
          selectedStatus.includes('Overdue') &&
          statuses.includes('Overdue') &&
          task.overdue
        ) {
          return matchesFilterStatuses;
        }

        return (
          matchesFilterStatuses &&
          isAssignedToUser(task, selectedAssignees) &&
          matchesStatus(task, selectedStatus) &&
          (skipDateCheck || isInDateRange(task)) &&
          isAtFacility(task, selectedFacilities)
        );
      }),
    [
      isAssignedToUser,
      isInDateRange,
      selectedAssignees,
      selectedFacilities,
      selectedStatus,
      statuses,
    ],
  );

  useEffect(() => {
    const baseParams = {
      baseUrl,
      includeAllOverdue: statuses.includes('Overdue'),
      statuses: statuses.filter((s) => s !== 'Overdue'),
      token,
    } satisfies ClientData & InstanceSearchParams;

    const byDueDate = {
      dueDate_begin: dateRange.begin.toString(),
      dueDate_end: dateRange.end.toString(),
    } satisfies InstanceSearchParams;

    const byEndDate = {
      endDate_begin: dateRange.begin.toString(),
      endDate_end: dateRange.end.toString(),
    } satisfies InstanceSearchParams;

    void dispatch(
      filterByEndDate
        ? listTasks({ ...baseParams, ...byEndDate })
        : listTasks({ ...baseParams, ...byDueDate }),
    )
      .unwrap()
      .then(() => {
        setHookDataLoaded(true);
      });
  }, [
    token,
    dispatch,
    baseUrl,
    forceTimestamp,
    dateRange.begin,
    dateRange.end,
    statuses,
    filterByEndDate,
  ]);

  useEffect(() => {
    // Combine filtered tasks with overdue and in progress tasks
    let newFilteredTasks = filterTasks(sortedTasks);

    // Ignore overdue and in progress by adding all of them
    const overdueAndInProgressTasks = filterTasks(
      sortedTasks.filter((t) => t.overdue || t.status === Status.InProgress),
      true,
    );

    // Remove the duplicates
    newFilteredTasks = [
      ...overdueAndInProgressTasks,
      ...newFilteredTasks.filter(
        (t) => t.status !== Status.InProgress && !t.overdue,
      ),
    ];

    if (searchQuery) {
      const fuse = new Fuse(newFilteredTasks, {
        findAllMatches: true,
        ignoreLocation: true,
        keys: [
          { name: 'title', weight: 1 },
          { name: 'facility.name', weight: 1 },
          { name: 'assigneeName', weight: 0.8 },
          { name: 'status', weight: 0.8 },
        ],
        shouldSort: true,
        threshold: 0.2,
        useExtendedSearch: true,
      });
      newFilteredTasks = fuse.search(searchQuery).map((result) => result.item);
    }

    setFilteredTasks(newFilteredTasks);
  }, [filterTasks, searchQuery, sortedTasks]);

  return {
    dateRange,
    filteredTasks,
    hasFilters,
    hookDataLoaded,
    refresh,
    resetDateRange,
    setDateRange,
  };
};
