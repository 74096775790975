import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC } from 'react';
import { useSelector } from 'react-redux';

export const Facility: FC = () => {
  const facility = useSelector(taskEditSlice.selectors.facility);
  const t = useInternationalization('tasks.edit');

  return (
    <div>
      <label className='flex-1' htmlFor='task-facility'>
        {t('label.facility')}
      </label>
      <div className='font-bold' id='task-facility'>
        {facility?.name}
      </div>
    </div>
  );
};
