import { Question } from '@dakota/platform-client';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Dialog, IconButton } from '@mui/material';
import { clsx } from 'clsx';
import Button from 'components/Button';
import Confirmation from 'components/SimpleConfirmation';
import { configSlice } from 'features/config/configSlice';
import { addQuestions } from 'features/questions/questionsActions';
import { questionsSlice } from 'features/questions/questionsSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import useToast from 'hooks/useToast';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

import { CategorizeQuestions } from './categorizeQuestions';
import { Results } from './results';
import { Steps } from './steps';
import { TypeQuestions } from './typeQuestions';
import { useAddMultipleContext } from './useContext';

type AddMultipleQuestionsDialogProps = {
  onClose: () => void;
};

export const AddMultipleQuestionsDialog: FC<
  AddMultipleQuestionsDialogProps
> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const token = useSelector(tokenSlice.selectors.token);
  const baseUrl = useSelector(configSlice.selectors.backend);

  const {
    defaultAnswerType,
    defaultItemType,
    lines,
    questionExists,
    questionIsDuplicate,
    questions,
    setLines,
    setQuestionExists,
    setQuestionIsDuplicate,
    setQuestions,
    setQuestionSelected,
    setResults,
    setStep,
    setText,
    step,
  } = useAddMultipleContext();

  const { setErrorMessage } = useToast();

  const [confirmDefaults, setConfirmDefaults] = useState(false);

  const isAddingQuestions = useSelector(
    questionsSlice.selectors.isAddingQuestions,
  );

  const doSave = () => {
    dispatch(
      addQuestions({
        baseUrl,
        questions: questions.map((q) => Question.fromJS(q)),
        token,
      }),
    )
      .unwrap()
      .then((res) => {
        setResults(res);
        setStep(3);
      })
      .catch(() => {
        setErrorMessage('Failed to add questions');
      });
  };

  const checkAndSave = () => {
    // If all questions have default item type and response type,
    // show a confirmation dialog instead of saving directly
    const allDefault = questions.every(
      (q) =>
        q.itemType.id === defaultItemType.id &&
        q.answerType.id === defaultAnswerType.id,
    );
    if (allDefault) {
      setConfirmDefaults(true);
    } else {
      doSave();
    }
  };

  const processText = (val: string) => {
    setText(val);

    const newLines = val
      .split('\n')
      .map((q) => q.trim())
      .filter((q) => q !== '');

    setLines(newLines);
    setQuestions(
      newLines.map((line) => ({
        answerType: defaultAnswerType,
        citation: '',
        itemType: defaultItemType,
        text: line,
      })),
    );
    setQuestionExists(newLines.map(() => false));
    setQuestionIsDuplicate(newLines.map(() => false));
    setQuestionSelected(newLines.map(() => false));
  };

  const dialogOnClose = () => {
    dispatch(questionsSlice.actions.clearAddedQuestions());
    onClose();
  };

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen
      maxWidth={false}
      onClose={dialogOnClose}
      open
    >
      <div className='h-full flex flex-col'>
        <div
          className={clsx(
            'flex-none h-16 bg-green-base text-white',
            'flex justify-between items-center gap-2 p-4',
          )}
        >
          <div className='text-xl'>Add Multiple Questions</div>
          <IconButton aria-label='Close dialog' onClick={dialogOnClose}>
            <XMarkIcon className='text-white h-6' />
          </IconButton>
        </div>
        <Steps step={step} />
        <div
          className={clsx(
            'flex-1 bg-gray-100 py-4 px-12 text-gray-700',
            'flex flex-col gap-2 overflow-y-auto',
          )}
        >
          <div className='h-full flex flex-col gap-2'>
            <span className='text-2xl'>Questions</span>
            {step === 1 && <TypeQuestions onChange={processText} />}
            {step === 2 && questions.length > 0 && <CategorizeQuestions />}
            {step === 3 && <Results onClose={onClose} />}
          </div>
        </div>
        {step < 3 && (
          <div
            className={clsx(
              'flex-none h-16 flex flex-row-reverse gap-2 *:text-xs',
              'border-t border-gray-300 px-12 py-4',
            )}
          >
            <Button
              disabled={
                (step === 1 && lines.length === 0) ||
                (step === 2 &&
                  (questions.length === 0 ||
                    questionExists.some(Boolean) ||
                    questionIsDuplicate.some(Boolean)))
              }
              loading={step === 2 && isAddingQuestions}
              onClick={() => {
                if (step === 1) {
                  setStep(2);
                } else {
                  checkAndSave();
                }
              }}
            >
              {step === 1 && 'Next'}
              {step === 2 &&
                `Add ${questions.length} Question${
                  questions.length === 1 ? '' : 's'
                }`}
            </Button>
            <Button hasShadow={false} onClick={dialogOnClose} secondary>
              Cancel
            </Button>
          </div>
        )}
      </div>
      {confirmDefaults && (
        <Confirmation
          cancelText={'Go back and change'}
          confirmText={'Save with defaults'}
          onCancel={() => {
            setConfirmDefaults(false);
          }}
          onConfirm={() => {
            setConfirmDefaults(false);
            doSave();
          }}
        >
          You have not changed any of the question item types or response types
          from the default options. These properties cannot be edited once the
          questions are saved. Do you want to review and make changes before you
          save?
        </Confirmation>
      )}
    </Dialog>
  );
};
