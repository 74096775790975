import { ChatBubbleLeftIcon, PaperClipIcon } from '@heroicons/react/24/outline';
import { DropdownMenu } from 'components/DropdownMenu';
import { FC } from 'react';

type PromptMenuProps = {
  openAttachmentPanel: () => void;
  openNotePanel: () => void;
};

export const PromptMenu: FC<PromptMenuProps> = ({
  openAttachmentPanel,
  openNotePanel,
}) => {
  const buttonClasses =
    'flex items-center gap-2 cursor-pointer disabled:text-gray-400';

  return (
    <DropdownMenu buttonAriaLabel='Open options menu'>
      <div className='p-4 flex flex-col gap-3 text-gray-700'>
        <button className={buttonClasses} onClick={openNotePanel}>
          <ChatBubbleLeftIcon className='w-5' />
          Add a note
        </button>
        <button className={buttonClasses} onClick={openAttachmentPanel}>
          <PaperClipIcon className='w-5' />
          Add attachments
        </button>
      </div>
    </DropdownMenu>
  );
};
