/**
 * Downloads an attachment from a URL with a given file name.
 *
 * @throws Error if the download fails, usually when the URL has expired.
 */
export async function downloadAttachment(url: string, name: string) {
  return fetch(url)
    .then((response) => {
      if (response.status >= 400) {
        throw new Error('Error downloading file');
      }
      return response.blob();
    })
    .then((blob) => {
      saveBlobToDisk(blob, name);
    });
}

/**
 *
 * @param blob data to save to disk, in the form of a Blob
 * @param fileName name of the file to save
 */
export function saveBlobToDisk(blob: Blob, fileName: string) {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.rel = 'noopener';
  link.setAttribute('download', fileName);

  document.body.appendChild(link);

  setTimeout(() => {
    window.URL.revokeObjectURL(link.href);
  }, 1000);
  setTimeout(() => {
    link.click();
    link.remove();
  }, 0);
}

export const blobFromBase64 = (base64: string) => {
  return new Blob([
    new Uint8Array(
      atob(base64)
        .split('')
        .map((char) => char.charCodeAt(0)),
    ),
  ]);
};
