import {
  FormInfo,
  InspectionSeries,
  Priority,
  SplitInspectionSeriesRequest,
  Summary,
  UpdateInspectionSeriesRequest,
} from '@dakota/platform-client';
import { LocalDate } from '@js-joda/core';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEqualAsRRule } from 'components/recurrence/rrule';
import { WritableDraft } from 'immer';
import { unassignedUser } from 'utils/user';

export type InspectionSeriesEditState = {
  assigneeId: string;
  facility?: Summary;
  form?: FormInfo;
  instanceDate: string;
  originalState?: OriginalState;
  priority: Priority;
  recurrenceRule: string;
  startDate: string;
  zones: Summary[];
};

type OriginalState = Omit<
  InspectionSeriesEditState,
  'facility' | 'form' | 'instanceDate' | 'originalState'
>;

const initialState: InspectionSeriesEditState = {
  assigneeId: '',
  facility: undefined,
  form: undefined,
  instanceDate: '',
  originalState: undefined,
  priority: Priority.Medium,
  recurrenceRule: 'FREQ=DAILY;INTERVAL=1;COUNT=1',
  startDate: LocalDate.now().toString(),
  zones: [],
};

export const inspectionSeriesEditSlice = createSlice({
  initialState,
  name: 'inspectionSeriesEdit',
  reducers: {
    clear: (state) => Object.assign(state, initialState),
    deleteZone: (state, action: PayloadAction<Summary>) => {
      state.zones = state.zones.filter((zone) => zone.id !== action.payload.id);
    },
    load: (
      state: WritableDraft<InspectionSeriesEditState>,
      action: PayloadAction<{
        inspection: InspectionSeries;
        instanceDate: string;
        startDate: string;
      }>,
    ) => {
      const { inspection, instanceDate, startDate } = action.payload;
      state.assigneeId = inspection.assigneeId ?? '';
      state.facility = inspection.facility;
      state.form = inspection.form;
      state.instanceDate = instanceDate;
      state.priority = inspection.priority;
      state.recurrenceRule = inspection.recurrenceRule;
      state.startDate = startDate;
      state.zones = inspection.zones;

      state.originalState = {
        assigneeId: state.assigneeId,
        priority: state.priority,
        recurrenceRule: state.recurrenceRule,
        startDate: state.startDate,
        zones: state.zones,
      };
    },
    moveZone: (
      state,
      action: PayloadAction<{ fromIndex: number; toIndex: number }>,
    ) => {
      const { fromIndex, toIndex } = action.payload;
      const [removed] = state.zones.splice(fromIndex, 1);
      state.zones.splice(toIndex, 0, removed);
    },
    setInspectionField: <k extends keyof InspectionSeriesEditState>(
      state: WritableDraft<InspectionSeriesEditState>,
      action: PayloadAction<{ field: k; value: InspectionSeriesEditState[k] }>,
    ) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    updateZones: (state, action: PayloadAction<Summary[]>) => {
      // Add the zones from the payload list that are not on the state list to
      // the end of the state list, and remove the ones that are on the state
      // list but not on the payload list.
      const newZones = action.payload.filter(
        (zone) => !state.zones.some((z) => z.id === zone.id),
      );
      const zonesToRemove = state.zones.filter(
        (zone) => !action.payload.some((z) => z.id === zone.id),
      );
      state.zones = state.zones
        .filter((zone) => !zonesToRemove.some((z) => z.id === zone.id))
        .concat(newZones);
    },
  },
  selectors: {
    assigneeId: (state) => state.assigneeId,
    editInspectionSeriesRequest: createSelector(
      [
        (state: InspectionSeriesEditState) =>
          state.assigneeId === unassignedUser.id
            ? undefined
            : state.assigneeId || undefined,
        (state: InspectionSeriesEditState) => state.priority,
        (state: InspectionSeriesEditState) => state.recurrenceRule,
        (state: InspectionSeriesEditState) => state.startDate,
        (state: InspectionSeriesEditState) => state.zones,
      ],
      (assigneeId, priority, recurrenceRule, startDate, zones) =>
        ({
          assigneeId,
          priority,
          recurrenceRule,
          startDate,
          zones: zones.map((zone) => zone.id),
        }) as UpdateInspectionSeriesRequest,
    ),
    facility: (state) => state.facility,
    form: (state) => state.form,
    hasUnsavedChanges: (state) => {
      return !isStateEqual(state, state.originalState ?? initialState);
    },
    instanceDate: (state) => state.instanceDate,
    priority: (state) => state.priority,
    recurrenceRule: (state) => state.recurrenceRule,
    splitInspectionSeriesRequest: createSelector(
      [
        (state: InspectionSeriesEditState) =>
          state.assigneeId === unassignedUser.id
            ? undefined
            : state.assigneeId || undefined,
        (state: InspectionSeriesEditState) => state.priority,
        (state: InspectionSeriesEditState) => state.recurrenceRule,
        (state: InspectionSeriesEditState) => state.startDate,
        (state: InspectionSeriesEditState) => state.instanceDate,
        (state: InspectionSeriesEditState) => state.zones,
      ],
      (assigneeId, priority, recurrenceRule, startDate, asOfDate, zones) =>
        ({
          asOfDate,
          assigneeId,
          priority,
          recurrenceRule,
          startDate,
          zones: zones.map((zone) => zone.id),
        }) as SplitInspectionSeriesRequest,
    ),
    startDate: (state) => state.startDate,
    zones: (state) => state.zones,
  },
});

export const isStateEqual = (
  currentState: InspectionSeriesEditState,
  originalState: OriginalState,
): boolean =>
  currentState.assigneeId === originalState.assigneeId &&
  currentState.startDate === originalState.startDate &&
  currentState.priority === originalState.priority &&
  isEqualAsRRule(currentState.recurrenceRule, originalState.recurrenceRule) &&
  currentState.zones.length === originalState.zones.length &&
  currentState.zones.every(
    (zone, index) => zone.id === originalState.zones[index].id,
  );
