import {
  isApiException,
  isValidationProblemDetails,
} from '@dakota/client-common';
import {
  AddMediaParams,
  CreateInspectionSeriesRequest,
  FilesClient,
  InspectionsClient,
  InstanceSearchParams,
  PromptComment,
  PromptMedia,
  PromptResponse,
  SecuredFileParams,
  SplitInspectionSeriesRequest,
  UpdateInspectionInstanceRequest,
  UpdateInspectionSeriesRequest,
} from '@dakota/platform-client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClientData, getClient } from 'features/clientProvider';

export const listInspectionInstances = createAsyncThunk(
  'inspections/listInspectionInstances',
  async (params: ClientData & InstanceSearchParams) => {
    const client = getClient(InspectionsClient, params);

    const response = await client.listInspectionInstances(params);
    return response.result;
  },
);

export const getInspectionDetails = createAsyncThunk(
  'inspections/getInspectionDetails',
  async (
    params: ({ dueDate: string; seriesId: string } | { id: string }) &
      ClientData,
  ) => {
    const client = getClient(InspectionsClient, params);
    const response =
      'id' in params
        ? // eslint-disable-next-line @typescript-eslint/no-deprecated
          await client.getInspectionDetailsV0(params.id)
        : await client.getInspectionInstanceDetails(
            params.seriesId,
            params.dueDate,
          );
    return response.result;
  },
);

export const createInspection = createAsyncThunk(
  'inspections/createInspection',
  async (
    params: { inspection: CreateInspectionSeriesRequest } & ClientData,
    { rejectWithValue },
  ) => {
    const client = getClient(InspectionsClient, params);

    try {
      const response = await client.createInspectionSeries(params.inspection);
      return response.result;
    } catch (e: unknown) {
      if (isApiException(e) && isValidationProblemDetails(e.result)) {
        return rejectWithValue(e.status);
      }
      throw e;
    }
  },
);

export const startInspection = createAsyncThunk(
  'inspections/startInspection',
  async (params: { dueDate: string; seriesId: string } & ClientData) => {
    const client = getClient(InspectionsClient, params);
    await client.startInspectionInstance(params.seriesId, params.dueDate);

    // Starting the inspection doesn't fill the inspection state object
    // with sections/zones, so we need to re-fetch it.
    const response = await client.getInspectionInstanceDetails(
      params.seriesId,
      params.dueDate,
    );
    return response.result;
  },
);

export const cancelInspection = createAsyncThunk(
  'inspections/cancelInspection',
  async (params: { dueDate: string; seriesId: string } & ClientData) => {
    const client = getClient(InspectionsClient, params);
    await client.cancelInspectionInstance(params.seriesId, params.dueDate);
  },
);

export const getInspectionSeries = createAsyncThunk(
  'inspections/getInspectionSeries',
  async (params: { id: string } & ClientData) => {
    const client = getClient(InspectionsClient, params);
    const response = await client.getInspectionSeries(params.id);
    return response.result;
  },
);

export const updateInspectionInstance = createAsyncThunk(
  'inspections/updateInspectionInstance',
  async (
    params: {
      body: UpdateInspectionInstanceRequest;
      dueDate: string;
      seriesId: string;
    } & ClientData,
  ) => {
    const client = getClient(InspectionsClient, params);
    const response = await client.updateInspectionInstance(
      params.seriesId,
      params.dueDate,
      params.body,
    );
    return response.result;
  },
);

export const saveResponses = createAsyncThunk(
  'inspections/saveResponses',
  async (
    params: {
      dueDate: string;
      responses: PromptResponse[];
      seriesId: string;
    } & ClientData,
  ) => {
    const client = getClient(InspectionsClient, params);
    await client.respondToInspectionInstance(
      params.seriesId,
      params.dueDate,
      params.responses,
    );
  },
);

export const completeInspection = createAsyncThunk(
  'inspections/completeInspection',
  async (params: { dueDate: string; seriesId: string } & ClientData) => {
    const client = getClient(InspectionsClient, params);
    await client.completeInspectionInstance(params.seriesId, params.dueDate);

    // Completing the inspection doesn't return the inspection object
    // so we need to re-fetch it to display completion date.
    const response = await client.getInspectionInstanceDetails(
      params.seriesId,
      params.dueDate,
    );
    return response.result;
  },
);

export const addNote = createAsyncThunk(
  'inspections/addNote',
  async (
    params: {
      dueDate: string;
      promptId: string;
      promptIndex: number;
      sectionIndex: number;
      seriesId: string;
      text: string;
    } & ClientData,
  ) => {
    const client = getClient(InspectionsClient, params);
    const response = await client.addCommentToPrompt(
      params.seriesId,
      params.dueDate,
      params.promptId,
      { text: params.text } as PromptComment,
    );
    return response.result;
  },
);

export const updateNote = createAsyncThunk(
  'inspections/updateNote',
  async (
    params: {
      commentId: string;
      dueDate: string;
      noteIndex: number;
      promptId: string;
      promptIndex: number;
      sectionIndex: number;
      seriesId: string;
      text: string;
    } & ClientData,
  ) => {
    const client = getClient(InspectionsClient, params);
    const response = await client.updateCommentForPrompt(
      params.seriesId,
      params.dueDate,
      params.promptId,
      params.commentId,
      { text: params.text } as PromptComment,
    );
    return response.result;
  },
);

export const deleteNote = createAsyncThunk(
  'inspections/deleteNote',
  async (
    params: {
      commentId: string;
      dueDate: string;
      noteIndex: number;
      promptId: string;
      promptIndex: number;
      sectionIndex: number;
      seriesId: string;
    } & ClientData,
  ) => {
    const client = getClient(InspectionsClient, params);
    await client.deleteCommentForPrompt(
      params.seriesId,
      params.dueDate,
      params.promptId,
      params.commentId,
    );
  },
);

export const addAttachment = createAsyncThunk(
  'inspections/addAttachment',
  async (
    params: {
      dueDate: string;
      options: AddMediaParams;
      promptId: string;
      promptIndex: number;
      sectionIndex: number;
      seriesId: string;
    } & ClientData,
    { rejectWithValue },
  ) => {
    const client = getClient(InspectionsClient, params);
    try {
      const response = await client.addMediaToPrompt(
        params.seriesId,
        params.dueDate,
        params.promptId,
        params.options,
      );
      return response.result;
    } catch (e: unknown) {
      if (isApiException(e) && isValidationProblemDetails(e.result)) {
        // Confirmed with the Backend, it is 'uber' rare that we would see more
        // than one error in this array
        return rejectWithValue(e.result.errors.mediaFile[0]);
      }
      throw e;
    }
  },
);

export const updateAttachment = createAsyncThunk(
  'inspections/updateAttachment',
  async (
    params: {
      attachmentId: string;
      attachmentIndex: number;
      description: string;
      dueDate: string;
      promptId: string;
      promptIndex: number;
      sectionIndex: number;
      seriesId: string;
    } & ClientData,
  ) => {
    const client = getClient(InspectionsClient, params);
    const body = {
      description: params.description || undefined,
    } as PromptMedia;
    const response = await client.updateMediaForPrompt(
      params.seriesId,
      params.dueDate,
      params.promptId,
      params.attachmentId,
      body,
    );
    return response.result;
  },
);

export const deleteAttachment = createAsyncThunk(
  'inspections/deleteAttachment',
  async (
    params: {
      attachmentId: string;
      attachmentIndex: number;
      dueDate: string;
      promptId: string;
      promptIndex: number;
      sectionIndex: number;
      seriesId: string;
    } & ClientData,
  ) => {
    const client = getClient(InspectionsClient, params);
    await client.deleteMediaForPrompt(
      params.seriesId,
      params.dueDate,
      params.promptId,
      params.attachmentId,
    );
  },
);

/**
 * TODO: consider moving to a separate file, it is not exclusive to inspections.
 */
export const getFile = createAsyncThunk(
  'inspections/getFile',
  async (params: { attachmentId: string } & ClientData & SecuredFileParams) => {
    const client = getClient(FilesClient, params);

    const response = await client.getSecuredFile(params);
    return response.result;
  },
);

export const updateInspectionSeries = createAsyncThunk(
  'inspections/updateInspectionSeries',
  async (
    params: {
      body: UpdateInspectionSeriesRequest;
      id: string;
    } & ClientData,
  ) => {
    const client = getClient(InspectionsClient, params);

    const response = await client.updateInspectionSeries(
      params.id,
      params.body,
    );
    return response.result;
  },
);
export const splitInspectionSeries = createAsyncThunk(
  'inspections/splitInspectionSeries',
  async (
    params: {
      body: SplitInspectionSeriesRequest;
      id: string;
    } & ClientData,
  ) => {
    const client = getClient(InspectionsClient, params);

    const response = await client.splitInspectionSeries(params.id, params.body);
    return response.result;
  },
);
