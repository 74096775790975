import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC } from 'react';

export const OverdueIndicator: FC = () => {
  const t = useInternationalization('tasks');

  return (
    <div
      aria-label={t('overdue.status.indicator')}
      className='flex items-center gap-1 text-red-base'
    >
      <ExclamationCircleIcon aria-hidden='true' className='w-5 h-5' />
      <span className='text-sm font-semibold'>{t('overdue')}</span>
    </div>
  );
};
