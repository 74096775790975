import { InspectionInstance, Status } from '@dakota/platform-client';
import { GridColDef } from '@mui/x-data-grid';
import { clsx } from 'clsx';
import { AttachmentCounter } from 'components/AttachmentCounter';
import { AssigneeAvatar } from 'components/Avatar';
import { OverdueIndicator } from 'components/OverdueIndicator';
import { PriorityIcon } from 'components/PriorityIcon';
import { toHumanReadable } from 'components/recurrence/humanReadable';
import { MuiGridWrapper } from 'components/Table/GridWrapper';
import { MobileTable } from 'components/Table/mobileTable';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useCheckPermission } from 'hooks/useCheckPermission';
import { useDateFormat } from 'hooks/useDateFormat';
import { useEnumLabel } from 'hooks/useEnumLabel';
import { useInspectionSidePanelLoader } from 'hooks/useInspectionSidePanelLoader';
import { useInternationalization } from 'hooks/useInternationalization';
import { useUsers } from 'hooks/useUsers';
import { FC } from 'react';
import { getHighlighter } from 'utils/highlighter';
import { Permission } from 'utils/permissions';

import { InspectionLauncher } from './Launcher';
import { ResultsBar } from './ResultsBar';
import { ZoneCell } from './ZoneCell';

interface InspectionsTableProps {
  data: InspectionInstance[];
  loading?: boolean;
  /**
   * If provided, it will highlight the search query in a few columns.
   * @default `undefined`
   */
  searchQuery?: string;
  /**
   * If `true`, the Due Date column will be replaced with the Completed Date
   * column The Completed Date column works similarly to Due Date, but the
   * former renders inspection.timeline.endDate whereas the latter renders
   * inspection.timeline.dueDate
   * @default `false`
   */
  showCompletedDate?: boolean;
}

const InspectionsTable: FC<InspectionsTableProps> = ({
  data,
  loading,
  searchQuery = undefined,
  showCompletedDate = false,
}) => {
  const { isMobile } = useBreakpoints();
  const t = useInternationalization('inspections.table');
  const { getPriorityLabel, getStatusLabel } = useEnumLabel();

  const { openPerformInspection, openSidePanel } =
    useInspectionSidePanelLoader();

  const highlight = getHighlighter(searchQuery ?? '');

  const { getUserSummary } = useUsers();

  const { formatBackendDate } = useDateFormat();

  /**
   * The side panel is for viewing or editing inspection details (not for
   * performing the inspection), and can only be opened if the user has
   * edit:inspections permission.
   */
  const canOpenSidePanel = useCheckPermission(Permission.UpdateInspection);

  const titleColumn: GridColDef<InspectionInstance> = {
    cellClassName: 'flex-col !justify-center !items-start gap-1',
    display: 'flex',
    field: 'form.name',
    flex: 1,
    headerName: t('header.title'),
    minWidth: 300,
    renderCell: (params) => {
      const inspection = params.row;
      return canOpenSidePanel ? (
        <>
          <button
            className={clsx(
              'text-start text-green-base hover:text-green-darker',
              'w-full truncate',
            )}
            onClick={() => {
              openSidePanel(inspection);
            }}
          >
            {highlight(inspection.form.name)}
          </button>
          {inspection.overdue && <OverdueIndicator />}
        </>
      ) : (
        <>
          {highlight(inspection.form.name)}
          {inspection.overdue && <OverdueIndicator />}
        </>
      );
    },
  };

  const recurrenceColumn: GridColDef<InspectionInstance> = {
    field: 'recurrence',
    headerName: t('header.recurrence'),
    maxWidth: 250,
    minWidth: 140,
    renderCell: (params) => toHumanReadable(params.row.seriesRecurrence.rule),
  };

  const assigneeColumn: GridColDef<InspectionInstance> = {
    align: 'center',
    display: 'flex',
    field: 'assignee',
    headerAlign: 'center',
    headerName: t('header.assignee'),
    renderCell: (params) =>
      isMobile ? (
        getUserSummary(params.row.assigneeId)?.name
      ) : (
        <AssigneeAvatar user={getUserSummary(params.row.assigneeId)} />
      ),
    resizable: false,
    width: 100,
  };

  const dateColumn: GridColDef<InspectionInstance> = showCompletedDate
    ? {
        field: 'completedDate',
        headerName: t('header.completedDate'),
        minWidth: 130,
        renderCell: (params) => (
          <span aria-label='Completed date'>
            {formatBackendDate(params.row.timeline.endDate as string)}
          </span>
        ),
      }
    : {
        field: 'dueDate',
        headerName: t('header.dueDate'),
        renderCell: (params) => formatBackendDate(params.row.timeline.dueDate),
      };

  const facilityColumn: GridColDef<InspectionInstance> = {
    display: 'flex',
    field: 'facility',
    flex: 1,
    headerName: t('header.facility'),
    minWidth: 120,
    renderCell: (params) => {
      return (
        <div className='py-2 space-y-2'>
          <div>{highlight(params.row.facility.name)}</div>
          <ZoneCell name={params.row.form.name} zones={params.row.zones} />
        </div>
      );
    },
  };

  const statusColumn: GridColDef<InspectionInstance> = {
    align: 'center',
    field: 'status',
    headerAlign: 'center',
    headerName: t('header.status'),
    renderCell: (params) => getStatusLabel(params.row.status),
    resizable: false,
  };

  const resultsColumn: GridColDef<InspectionInstance> = {
    align: 'center',
    display: 'flex',
    field: 'results',
    headerAlign: 'center',
    headerName: t('header.results'),
    renderCell: (params) => (
      <div className='w-full flex items-center *:w-full'>
        <ResultsBar
          overdue={params.row.overdue}
          prompts={params.row.prompts}
          status={params.row.status}
        />
      </div>
    ),
    width: 100,
  };

  const metadataColumn: GridColDef<InspectionInstance> = {
    align: 'left',
    display: 'flex',
    field: 'metadata',
    flex: 1,
    headerAlign: 'center',
    headerName: '',
    minWidth: 220,
    renderCell: (params) => {
      const inspection = params.row;
      const { comments, media } = inspection.attachments;

      return (
        <div className='flex items-center gap-2'>
          <PriorityIcon priority={inspection.priority} />
          <AttachmentCounter
            comments={comments}
            media={media}
            onClick={() => {
              if (inspection.status === Status.Completed) {
                openPerformInspection(inspection);
              }
            }}
          />
          <InspectionLauncher inspection={inspection} />
        </div>
      );
    },
    resizable: false,
  };

  const columns = [
    titleColumn,
    recurrenceColumn,
    assigneeColumn,
    dateColumn,
    facilityColumn,
    statusColumn,
    resultsColumn,
    metadataColumn,
  ];

  const priorityColumnMobile: GridColDef<InspectionInstance> = {
    field: 'priority',
    headerName: 'Priority',
    renderCell: (params) => getPriorityLabel(params.row.priority),
  };

  const statusColumnMobile: GridColDef<InspectionInstance> = {
    field: 'status',
    headerName: 'Status',
    renderCell: (params) => getStatusLabel(params.row.status),
  };

  const zoneColumnMobile: GridColDef<InspectionInstance> = {
    field: 'zone',
    headerName: 'Zones',
    renderCell: (params) =>
      params.row.zones.length === 0 ? (
        <div className='text-gray-400 italic'>No Zones</div>
      ) : (
        <ul>
          {params.row.zones.map((zone) => (
            <li key={zone.id}>{zone.name}</li>
          ))}
        </ul>
      ),
  };

  const attachmentsColumnMobile: GridColDef<InspectionInstance> = {
    field: 'attachments',
    headerName: 'Attachments',
    renderCell: (params) => {
      const inspection = params.row;
      const onClick = () =>
        canOpenSidePanel ? (
          <button
            className='text-green-base hover:text-green-darker'
            onClick={() => {
              openSidePanel(inspection);
            }}
          >
            {highlight(inspection.form.name)}
          </button>
        ) : (
          highlight(inspection.form.name)
        );

      return (
        <AttachmentCounter
          comments={inspection.attachments.comments}
          media={inspection.attachments.media}
          onClick={onClick}
        />
      );
    },
  };

  const actionsColumnMobile: GridColDef<InspectionInstance> = {
    field: 'actions',
    headerName: 'Actions',
    renderCell: (params) => <InspectionLauncher inspection={params.row} />,
  };

  const mobileColumns = [
    titleColumn,
    dateColumn,
    recurrenceColumn,
    facilityColumn,
    zoneColumnMobile,
    priorityColumnMobile,
    assigneeColumn,
    statusColumnMobile,
    resultsColumn,
    attachmentsColumnMobile,
    actionsColumnMobile,
  ];

  return isMobile ? (
    <MobileTable columns={mobileColumns} loading={!!loading} rows={data} />
  ) : (
    <MuiGridWrapper
      columns={columns}
      loading={!!loading}
      rowHeight={70}
      rows={data}
    />
  );
};

export default InspectionsTable;
