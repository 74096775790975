import { InspectionInstanceDetails, Status } from '@dakota/platform-client';
import { notificationsSlice } from 'features/notifications/notificationsSlice';
import { userSlice } from 'features/user/userSlice';
import { useCheckPermission } from 'hooks/useCheckPermission';
import { useInspectionLoad } from 'hooks/useInspectionLoad';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/store';
import { Permission } from 'utils/permissions';

import { ConductInspection } from './ConductInspection';
import { InspectionLoadingAnimation } from './InspectionLoadingAnimation';

type Props = {
  inspection: InspectionInstanceDetails;
  onClose: () => void;
};

export const Dispatch: FC<Props> = ({ inspection, onClose }) => {
  const t = useInternationalization('inspections.loader');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { start } = useInspectionLoad();
  const [starting, setStarting] = useState(false);

  const currentUser = useSelector(userSlice.selectors.currentUser);

  const isAssignee = currentUser.id === inspection.assigneeId;
  const hasPerformPermission = useCheckPermission(Permission.PerformInspection);
  const hasUpdatePermission = useCheckPermission(Permission.UpdateInspection);

  useEffect(() => {
    if (
      isAssignee &&
      hasPerformPermission &&
      inspection.status === Status.Scheduled
    ) {
      setStarting(true);
      void start(inspection.seriesId, inspection.timeline.dueDate).finally(
        () => {
          setStarting(false);
        },
      );
    }
  }, [hasPerformPermission, inspection, isAssignee, start]);

  useEffect(() => {
    const scheduledPage = `/inspections/scheduled?date=${inspection.timeline.dueDate}&seriesId=${inspection.seriesId}`;
    const completedPage = `/inspections/completed?date=${inspection.timeline.dueDate}&seriesId=${inspection.seriesId}`;

    const addStickyMessage = (content: string) =>
      dispatch(
        notificationsSlice.actions.addStickyMessage({
          content,
          severity: 'info',
        }),
      );

    if (
      inspection.status === Status.Scheduled ||
      inspection.status === Status.InProgress
    ) {
      if (!isAssignee || !hasPerformPermission) {
        if (hasUpdatePermission) {
          navigate(scheduledPage);
        } else {
          addStickyMessage(t('noPermission'));
          navigate('/');
        }
      }
    } else if (inspection.status === Status.Canceled) {
      if (hasUpdatePermission) {
        navigate(completedPage);
      } else {
        addStickyMessage(t('canceled'));
        navigate('/inspections/completed');
      }
    }
  }, [
    inspection,
    isAssignee,
    hasPerformPermission,
    hasUpdatePermission,
    navigate,
    t,
    dispatch,
  ]);

  if (starting) {
    return <InspectionLoadingAnimation text={t('starting')} />;
  }

  if (
    inspection.status === Status.Completed ||
    (isAssignee &&
      hasPerformPermission &&
      inspection.status === Status.InProgress)
  ) {
    return (
      <div className='h-full bg-white'>
        <ConductInspection inspectionDetails={inspection} onClose={onClose} />
      </div>
    );
  }
};
