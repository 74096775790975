import {
  InspectionInstanceDetails,
  Prompt as InspectionPrompt,
  PromptQuestionDetails,
} from '@dakota/platform-client';
import { ChatBubbleLeftIcon } from '@heroicons/react/24/outline';
import AcknowledgmentDialog from 'components/Dialog/AcknowledgmentDialog';
import { NewNote } from 'components/Note/NewNote';
import { SidePanel_v2 as SidePanel } from 'components/SidePanel_v2';
import { configSlice } from 'features/config/configSlice';
import { flagsSlice } from 'features/flags/flagsSlice';
import { addNote } from 'features/inspections/inspectionActions';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { useInternationalization } from 'hooks/useInternationalization';
import useToast from 'hooks/useToast';
import { FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { FeatureFlag } from 'utils/featureFlags';

import { Notes } from './Notes';
import { QuestionSummary } from './QuestionSummary';

type AddNoteProps = {
  onClose: () => void;
  prompt: InspectionPrompt;
  promptId: string;
  promptIndex: number;
  question: PromptQuestionDetails;
  sectionIndex: number;
};

export const AddNote: FC<AddNoteProps> = ({
  onClose,
  prompt,
  promptId,
  promptIndex,
  question,
  sectionIndex,
}) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const isFlagEnabled = useSelector(flagsSlice.selectors.isFlagEnabled);
  const useTaskLinking = isFlagEnabled(FeatureFlag.TaskLinking);

  const inspection = useSelector(
    inspectionSlice.selectors.inspectionDetails,
  ) as InspectionInstanceDetails;

  const t = useInternationalization('inspections.conduct');

  const [newNoteText, setNewNoteText] = useState('');

  const [isSaving, setIsSaving] = useState(false);

  const { setErrorMessage, setSuccessMessage } = useToast();

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const createNewNote = useCallback(
    (text: string) =>
      dispatch(
        addNote({
          baseUrl,
          dueDate: inspection.timeline.dueDate,
          promptId,
          promptIndex,
          sectionIndex,
          seriesId: inspection.seriesId,
          text,
          token,
        }),
      )
        .unwrap()
        .then((result) => {
          onClose();
          return result;
        }),
    [
      baseUrl,
      dispatch,
      inspection,
      onClose,
      promptId,
      promptIndex,
      sectionIndex,
      token,
    ],
  );

  const save = useCallback(() => {
    setIsSaving(true);
    createNewNote(newNoteText)
      .then(() => {
        setNewNoteText('');
        setSuccessMessage(t('note.success'));
      })
      .catch(() => {
        setErrorMessage(t('note.failure'));
      })
      .finally(() => {
        setIsSaving(false);
      });
  }, [createNewNote, newNoteText, setErrorMessage, setSuccessMessage, t]);

  const hasUnsavedChanges = newNoteText.length > 0;

  const checkChangesAndClose = useCallback(() => {
    if (hasUnsavedChanges) {
      setShowConfirmationDialog(true);
    } else {
      onClose();
    }
  }, [hasUnsavedChanges, onClose]);

  return (
    <SidePanel
      hasPadding={false}
      onClose={useTaskLinking ? checkChangesAndClose : onClose}
      primary={{
        action: save,
        disabled: !hasUnsavedChanges,
        loading: isSaving,
        text: t('button.save'),
      }}
      title={t('addNote')}
      titleIcon={<ChatBubbleLeftIcon />}
    >
      {showConfirmationDialog && (
        <AcknowledgmentDialog
          acknowledgmentText={t('discard.message')}
          buttonText={t('discard.confirm')}
          cancelButtonText={t('discard.return')}
          confirmationButtonSide='right'
          onAcknowledgment={onClose}
          onCloseDialog={() => {
            setShowConfirmationDialog(false);
          }}
          title={t('discard.title')}
        />
      )}
      <QuestionSummary question={question} />
      <div className='px-6'>
        {useTaskLinking && prompt.comments.length > 0 && (
          <Notes showTitle={false} {...{ prompt, promptIndex, sectionIndex }} />
        )}
        <div className='mt-6'>
          {useTaskLinking ? (
            <NewNote onTextChange={setNewNoteText} />
          ) : (
            <NewNote onCancel={onClose} onSave={createNewNote} />
          )}
        </div>
      </div>
    </SidePanel>
  );
};
