import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drawer, IconButton } from '@mui/material';
import { clsx } from 'clsx';
import { FC, PropsWithChildren, ReactElement } from 'react';

import Button from './Button';

type ButtonProps = {
  action: () => void;
  disabled?: boolean;
  loading?: boolean;
  text: string;
};

type Props = {
  /** An optional destructive action, displayed appropriately. */
  destructive?: ButtonProps;
  /**
   * If true, adds padding to the content area.
   * @default true
   */
  hasPadding?: boolean;
  /**
   * Called from the X button. Unsaved changes and confirmation
   * dialogs must be dealt with from here.
   */
  onClose: () => void;
  /** The main action to be performed */
  primary?: ButtonProps;
  /**
   * An optional secondary action. If not provided, it defaults to
   * a button with 'Close' text that calls `onClose`.
   * @default { action: onClose, text: 'Close' }
   */
  secondary?: ButtonProps;
  /** The text to display in the header. */
  title: string;
  /** Optional icon to display next to the title in the header. */
  titleIcon?: ReactElement;
};

export const SidePanel_v2: FC<PropsWithChildren<Props>> = ({
  children,
  destructive,
  hasPadding = true,
  onClose,
  primary,
  secondary = { action: onClose, text: 'Close' },
  title,
  titleIcon,
}) => (
  <Drawer
    anchor='right'
    // Do nothing. We only want to close from the close buttons
    onClose={(e: Event) => {
      e.stopPropagation();
    }}
    open
    // Do not animate the drawer
    SlideProps={{ appear: false }}
  >
    <div className='h-screen w-screen sm:w-[550px] flex flex-col'>
      <div
        className={clsx(
          'flex-0 min-h-14 sm:min-h-16 px-4 sm:px-6 flex items-center justify-between',
          'border-b border-gray-200 text-lg font-medium text-green-base',
        )}
      >
        <div className='flex items-center gap-2'>
          {titleIcon && <div className='size-6'>{titleIcon}</div>}
          <h2>{title}</h2>
        </div>
        <IconButton aria-label='Close panel' onClick={onClose}>
          <FontAwesomeIcon icon={faXmark} />
        </IconButton>
      </div>
      <div
        className={clsx('flex-1 overflow-y-auto', {
          'p-3 sm:p-6': hasPadding,
        })}
      >
        {children}
      </div>
      <div
        className={clsx(
          'flex-0 p-3 sm:px-6 sm:py-4 border-t border-gray-200',
          'flex items-center justify-between',
        )}
      >
        <div>
          {destructive && (
            <Button
              alert
              disabled={destructive.disabled}
              loading={destructive.loading}
              onClick={destructive.action}
              outlined
            >
              {destructive.text}
            </Button>
          )}
        </div>
        <div className='flex items-center gap-2'>
          <Button
            disabled={secondary.disabled}
            loading={secondary.loading}
            onClick={secondary.action}
            secondary
          >
            {secondary.text}
          </Button>
          {primary && (
            <Button
              disabled={primary.disabled}
              loading={primary.loading}
              onClick={primary.action}
            >
              {primary.text}
            </Button>
          )}
        </div>
      </div>
    </div>
  </Drawer>
);
