import { InspectionInstanceDetails } from '@dakota/platform-client';
import { faPrint } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/Button_v2';
import { answersSlice } from 'features/answers/answersSlice';
import useToast from 'hooks/useToast';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

type PrintButtonProps = {
  inspectionDetails: InspectionInstanceDetails;
  saveCurrentResponses: () => Promise<void>;
};

export const PrintButton: FC<PrintButtonProps> = ({
  inspectionDetails,
  saveCurrentResponses,
}) => {
  const navigate = useNavigate();

  const { setErrorMessage } = useToast();

  const answerTypes = useSelector(answersSlice.selectors.answerTypes);

  const handleOnClick = () => {
    saveCurrentResponses()
      .then(() => {
        navigate('/print-inspection', {
          state: {
            answerTypes,
            inspection: inspectionDetails,
          },
        });
      })
      .catch(() => {
        setErrorMessage('Failed to save responses');
      });
  };

  return (
    <Button onClick={handleOnClick} variant='outlined'>
      <FontAwesomeIcon icon={faPrint} /> Print
    </Button>
  );
};
