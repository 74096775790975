import { Thumbnail } from '@dakota/platform-client';
import { clsx } from 'clsx';
import { Spinner } from 'components/Spinner';
import { FC, useEffect, useState } from 'react';
import { blobFromBase64 } from 'utils/file';

type Props = {
  description: string;
  filename: string;
  fullSizeUrl: string;
  onError: () => void;
  thumbnail: Thumbnail;
  toggleNavigation: () => void;
};

export const ImageWithThumbnail: FC<Props> = ({
  description,
  filename,
  fullSizeUrl,
  onError,
  thumbnail,
  toggleNavigation,
}) => {
  const [src, setSrc] = useState('');
  const [isFullSizeLoaded, setIsFullSizeLoaded] = useState(false);

  useEffect(() => {
    setSrc(URL.createObjectURL(blobFromBase64(thumbnail.data)));
  }, [thumbnail.data]);

  useEffect(() => {
    fetch(fullSizeUrl)
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob))
      .then((url) => {
        setSrc(url);
        setIsFullSizeLoaded(true);
      })
      .catch(onError);
  }, [fullSizeUrl, onError]);

  useEffect(() => {
    if (src) {
      return () => {
        URL.revokeObjectURL(src);
      };
    }
  }, [src]);

  return (
    <div className='h-full relative py-[10px]'>
      <button className='h-full cursor-default' onClick={toggleNavigation}>
        <img
          alt={description || filename}
          className={clsx(
            isFullSizeLoaded ? 'object-scale-down' : 'object-contain',
            'transition-all duration-500 h-full',
          )}
          onError={onError}
          src={src}
          style={{
            filter: isFullSizeLoaded ? 'none' : 'blur(5px) opacity(50%)',
          }}
        />
      </button>
      {!isFullSizeLoaded && (
        <div
          className={clsx(
            'absolute-center max-sm:top-[calc(50%-0.5rem)]',
            'flex flex-col items-center gap-1 p-8 bg-black/50 rounded-md',
            'text-gray-300 font-base overflow-hidden whitespace-nowrap',
          )}
        >
          <Spinner />
          Loading full image...
        </div>
      )}
    </div>
  );
};
