import {
  Facility,
  Status,
  Summary,
  TaskInstance,
  UserSummary,
} from '@dakota/platform-client';
import { PlusIcon } from '@heroicons/react/24/outline';
import { DateTimeFormatter } from '@js-joda/core';
import Autocomplete from 'components/Autocomplete';
import Button from 'components/Button';
import Chips from 'components/Chip/Chips';
import { ClearAllButton } from 'components/ClearAll';
import { DatePicker, DatePickerRange } from 'components/DatePicker';
import { PageHeader } from 'components/PageHeader';
import SearchInput from 'components/SearchInput';
import { facilitiesSlice } from 'features/facilities/facilitiesSlice';
import { notificationsSlice } from 'features/notifications/notificationsSlice';
import { tasksSlice } from 'features/tasks/tasksSlice';
import { useCheckPermission } from 'hooks/useCheckPermission';
import { useEnumLabel } from 'hooks/useEnumLabel';
import { useFilteredTasks } from 'hooks/useFilteredTasks';
import { useInternationalization } from 'hooks/useInternationalization';
import { DataStatus, usePageLoadTracking } from 'hooks/usePageLoadTracking';
import { useTaskLoader } from 'hooks/useTaskLoader';
import { useUsers } from 'hooks/useUsers';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store/store';
import { SearchStatus } from 'types';
import { Permission } from 'utils/permissions';
import { validateDateRange } from 'utils/validateDateRange';

import { CreateTask } from '../CreateTask';
import TasksTable from '../TasksTable';

export const ScheduledTasks: FC = () => {
  const dispatch = useAppDispatch();

  const activeFacilities = useSelector(
    facilitiesSlice.selectors.activeFacilities,
  );

  const canCreateTask = useCheckPermission(Permission.CreateTask);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { openTask, taskViewerComponent } = useTaskLoader();

  const { activeUsersAndUnassigned } = useUsers();
  const t = useInternationalization();
  const { getStatusLabel, Label } = useEnumLabel();

  const [isCreatePanelOpen, setIsCreatePanelOpen] = useState(false);

  const { stopTracking } = usePageLoadTracking();

  const loadingTasks = useSelector(tasksSlice.selectors.isLoadingTasks);

  const [selectedFacilities, setSelectedFacilities] = useState<Facility[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<SearchStatus[]>([]);
  const [selectedAssignees, setSelectedAssignees] = useState<UserSummary[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (searchParams.get('action') === 'create') {
      setIsCreatePanelOpen(true);
    }
  }, [searchParams]);

  const statuses = useMemo(
    () => [Status.InProgress, Status.Scheduled, 'Overdue'] as SearchStatus[],
    [],
  );

  const {
    dateRange,
    filteredTasks,
    hasFilters,
    hookDataLoaded,
    refresh,
    resetDateRange,
    setDateRange,
  } = useFilteredTasks({
    filterByEndDate: false,
    searchQuery,
    selectedAssignees,
    selectedFacilities,
    selectedStatus,
    statuses,
  });

  useEffect(() => {
    if (hookDataLoaded) {
      stopTracking(DataStatus.Fetched);
    }
  }, [hookDataLoaded, stopTracking]);
  const removeFacility = (facility: Summary) => {
    setSelectedFacilities((prev) => prev.filter((f) => f.id !== facility.id));
  };

  const removeAssignee = (assignee: UserSummary) => {
    setSelectedAssignees((prev) => prev.filter((f) => f.id !== assignee.id));
  };
  const resetFilters = () => {
    setSelectedFacilities([]);
    setSelectedStatus([]);
    setSelectedAssignees([]);
    setSearchQuery('');
    resetDateRange();
  };

  const openTaskInstance = (task: TaskInstance) => {
    openTask(task.timeline.dueDate, task.seriesId);
  };

  const validateRange = (selectedDateRange: DatePickerRange) => {
    const beginDate = selectedDateRange.begin;
    const endDate = validateDateRange(selectedDateRange);
    if (endDate) {
      const formattedMaxDate = endDate.format(
        DateTimeFormatter.ofPattern('MM/dd/yyyy'),
      );
      dispatch(
        notificationsSlice.actions.addStickyMessage({
          actions: [],
          content: `Tasks can only display up to one year in advance, you may select a date range up to ${formattedMaxDate}.`,
        }),
      );
      setDateRange({ begin: beginDate, end: endDate });
    } else {
      setDateRange(selectedDateRange);
    }
  };

  return (
    <div className='p-4 sm:p-8'>
      <PageHeader title='Scheduled Tasks'>
        {canCreateTask && (
          <Button
            className='bg-green-base px-4 py-2'
            icon={<PlusIcon />}
            onClick={() => {
              setIsCreatePanelOpen(true);
            }}
          >
            {t('charts.create.task')}
          </Button>
        )}
      </PageHeader>
      <div className='sm:flex sm:justify-between sm:items-center sm:gap-2'>
        <div className='filters-container'>
          <SearchInput
            aria-label='Search tasks'
            id='scheduled-tasks-search-input'
            onSearch={setSearchQuery}
            value={searchQuery}
          />
          <DatePicker
            asSingle={false}
            id='scheduled-tasks-date-picker'
            onChange={validateRange}
            scrollOnFocus={false}
            shortcutsType='next'
            showShortcuts
            value={dateRange}
          />
          <Autocomplete
            className='max-sm:w-full w-48'
            getOptionKey={(facility) => facility.id}
            getOptionLabel={(facility) => facility.name}
            id='scheduled-tasks-facility-selector'
            label={Label.Facilities}
            multiple
            onChange={setSelectedFacilities}
            options={activeFacilities}
            value={selectedFacilities}
          />
          <Autocomplete
            className='max-sm:w-full w-44'
            getOptionKey={(user) => user.id}
            getOptionLabel={(user) => user.name}
            id='scheduled-tasks-assignee-selector'
            label={Label.Assignee}
            multiple
            onChange={setSelectedAssignees}
            options={activeUsersAndUnassigned}
            value={selectedAssignees}
          />
          <Autocomplete
            className='max-sm:w-full w-40'
            getOptionLabel={getStatusLabel}
            id='scheduled-tasks-status-selector'
            label={Label.ProgressStatus}
            multiple
            onChange={setSelectedStatus}
            options={statuses}
            value={selectedStatus}
          />
          {hasFilters && <ClearAllButton onClick={resetFilters} />}
        </div>
      </div>
      <Chips
        elements={selectedFacilities}
        getKey={(f) => f.id}
        getLabel={(f) => f.name}
        onRemove={removeFacility}
        title={t('tasks.chips.label.facilities')}
      />
      <Chips
        elements={selectedAssignees}
        getKey={(f) => f.id}
        getLabel={(f) => f.name}
        onRemove={removeAssignee}
        title={t('tasks.chips.label.assignees')}
      />
      <TasksTable
        data={filteredTasks}
        loading={loadingTasks}
        openTaskInstance={openTaskInstance}
      />
      {isCreatePanelOpen && (
        <CreateTask
          onClose={() => {
            // In the event the sidepanel was opened because the user navigated
            // from the dashboard page to here, close the panel
            searchParams.delete('action');
            navigate({ search: searchParams.toString() }, { replace: true });

            refresh();
            setIsCreatePanelOpen(false);
          }}
        />
      )}
      {taskViewerComponent}
    </div>
  );
};
