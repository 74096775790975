import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC } from 'react';
import { useSelector } from 'react-redux';

export const TicketId: FC = () => {
  const ticketId = useSelector(taskEditSlice.selectors.ticketId);
  const t = useInternationalization('tasks.edit');

  return ticketId ? (
    <div className='text-sm text-gray-800'>{t('id', { ticketId })}</div>
  ) : (
    <></>
  );
};
