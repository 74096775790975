import { Notification } from '@dakota/platform-client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactNode } from 'react';

import { getNotifications } from './notificationsActions';

export type NotificationsState = {
  /**
   * The full list of current notifications for the current user.
   */
  notifications: Notification[];
  /**
   * A list of sticky messages that are displayed at the top of the screen
   * and only disappear once the user dismisses them.
   */
  stickyMessages: StickyMessageType[];
  /**
   * The total number of sticky messages that have been displayed so far.
   */
  totalStickyMessages: number;
};

export type StickyMessageAction = {
  onClick: () => void;
  text: string;
};

/**
 * A request to add a sticky message to the UI. It includes everything but the
 * ID, given that it is generated automatically.
 */
export type StickyMessageRequest = Omit<StickyMessageType, 'id'>;

export type StickyMessageType = {
  /**
   * Actions that the user can take in response to the message.
   * These actions are displayed as buttons with the provided text.
   *
   * An action will *always* dismiss the message when clicked.
   *
   * If no actions are provided, the message will still be dismissible via the
   * X button on the right side of the message.
   *
   * @default []
   */
  actions?: StickyMessageAction[];
  /**
   * The message to display.
   */
  content: ReactNode;
  /**
   * If `true`, an 'X' button is shown and the user can dismiss the message by
   * clicking on it. If `false`, then the message MUST have at least one action,
   * and the actions will be shown instead of the 'X' button.
   *
   * Note that the message can still have actions *and* be dismissible.
   *
   * @default true
   */
  dismissible?: boolean;
  /**
   * A unique identifier for the message. This is used to remove the message
   * without affecting other sticky messages that might be present at the same
   * time.
   */
  id: string;
  /**
   * Determines the color of the message.
   *
   * - 'info': Light blue background
   * - 'warning': Light yellow background
   *
   * @default warning
   */
  severity?: 'info' | 'warning';
};

const initialState: NotificationsState = {
  notifications: [],
  stickyMessages: [],
  totalStickyMessages: 0,
};

export const notificationsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload;
    });
  },
  initialState,
  name: 'notifications',
  reducers: {
    addStickyMessage: (state, action: PayloadAction<StickyMessageRequest>) => {
      state.totalStickyMessages += 1;
      state.stickyMessages.push({
        ...action.payload,
        id: `sticky-message-${state.totalStickyMessages.toString()}`,
      });
    },
    removeStickyMessage: (state, action) => {
      state.stickyMessages = state.stickyMessages.filter(
        (m) => m.id !== action.payload,
      );
    },
  },
  selectors: {
    notifications: (state) => state.notifications,
    stickyMessages: (state) => state.stickyMessages,
  },
});
