import { Form, FormSummary, FormType } from '@dakota/platform-client';
import { createSlice } from '@reduxjs/toolkit';

import {
  activateForm,
  createFacilityForm,
  createGlobalForm,
  deactivateForm,
  getAllFormSummaries,
  getForm,
  updateFacilityForm,
  updateGlobalForm,
} from './formsActions';

export type FormsState = {
  /**
   * A single form with all its data (not a summary).
   */
  form: Form | undefined;
  /**
   * The complete list of forms for the tenant.
   */
  forms: FormSummary[];
  /**
   * True when *a single* form is being fetched.
   */
  isLoadingForm: boolean;
  /**
   * True when forms are being fetched.
   */
  isLoadingForms: boolean;
};

const initialState: FormsState = {
  form: undefined,
  forms: [],
  isLoadingForm: false,
  isLoadingForms: false,
};

export const formsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(createFacilityForm.fulfilled, (state, action) => {
      const form = action.payload;
      state.forms.push({
        description: form.description,
        facility: form.facility,
        id: form.id,
        inactive: form.inactive,
        name: form.name,
        type: FormType.Facility,
      } as FormSummary);
    });
    builder.addCase(createGlobalForm.fulfilled, (state, action) => {
      const form = action.payload;
      state.forms.push({
        description: form.description,
        id: form.id,
        inactive: form.inactive,
        name: form.name,
        type: FormType.Global,
      } as FormSummary);
    });
    builder.addCase(updateFacilityForm.fulfilled, (state, action) => {
      const form = action.payload;
      state.forms = state.forms.map((f) => {
        if (f.id === form.id) {
          return {
            description: form.description,
            facility: form.facility,
            id: form.id,
            inactive: form.inactive,
            name: form.name,
            type: FormType.Facility,
          } as FormSummary;
        }
        return f;
      });
    });
    builder.addCase(updateGlobalForm.fulfilled, (state, action) => {
      const form = action.payload;
      state.forms = state.forms.map((f) => {
        if (f.id === form.id) {
          return {
            description: form.description,
            id: form.id,
            inactive: form.inactive,
            name: form.name,
            type: FormType.Global,
          } as FormSummary;
        }
        return f;
      });
    });
    builder.addCase(getForm.fulfilled, (state, action) => {
      state.isLoadingForm = false;
      state.form = action.payload;
    });
    builder.addCase(getForm.pending, (state) => {
      state.isLoadingForm = true;
    });
    builder.addCase(getForm.rejected, (state) => {
      state.isLoadingForm = false;
    });
    builder.addCase(getAllFormSummaries.fulfilled, (state, action) => {
      state.isLoadingForms = false;
      state.forms = action.payload;
    });
    builder.addCase(getAllFormSummaries.pending, (state) => {
      state.isLoadingForms = true;
    });
    builder.addCase(getAllFormSummaries.rejected, (state) => {
      state.isLoadingForms = false;
    });
    builder.addCase(activateForm.fulfilled, (state, action) => {
      state.forms = state.forms.map((form) => {
        if (form.id === action.meta.arg.id) {
          return { ...form, inactive: false };
        }
        return form;
      });
    });
    builder.addCase(deactivateForm.fulfilled, (state, action) => {
      state.forms = state.forms.map((form) => {
        if (form.id === action.meta.arg.id) {
          return { ...form, inactive: true };
        }
        return form;
      });
    });
  },
  initialState,
  name: 'forms',
  reducers: {},
  selectors: {
    form: (state: FormsState) => state.form,
    forms: (state: FormsState) => state.forms,
    isLoadingForm: (state: FormsState) => state.isLoadingForm,
    isLoadingForms: (state: FormsState) => state.isLoadingForms,
  },
});
