import { FC } from 'react';
import { PulseLoader } from 'react-spinners';

const Loading: FC = () => {
  return (
    <div
      className={'h-screen w-screen flex justify-center items-center'}
      data-testid='loading'
    >
      <PulseLoader color='var(--color-green-base)' />
    </div>
  );
};

export default Loading;
