import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC } from 'react';
import { useSelector } from 'react-redux';

export const ReportedBy: FC = () => {
  const reportedBy = useSelector(taskEditSlice.selectors.reportedBy);
  const t = useInternationalization('tasks.edit');

  return (
    <div>
      <label className='flex-1 text-sm ' htmlFor='task-reported-by'>
        {t('label.reportedBy')}
      </label>
      <div className='font-bold' id='task-reported-by'>
        {reportedBy}
      </div>
    </div>
  );
};
