import { useCallback } from 'react';
import { DakotaErrorCode } from 'types';

import { useInternationalization } from './useInternationalization';

export const useErrorStrings = () => {
  const t = useInternationalization();

  const getErrorMessage = useCallback(
    (error: DakotaErrorCode) => {
      switch (error) {
        case DakotaErrorCode.BlockedUser:
          return t('errors.blocked.user');
        case DakotaErrorCode.DuplicateQuestion:
          return t('errors.duplicate.question');
        case DakotaErrorCode.LoginFailed:
          return t('errors.login.failed');
      }
    },
    [t],
  );

  return { getErrorMessage };
};
