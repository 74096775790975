import { Facility } from '@dakota/platform-client';
import { PlusIcon } from '@heroicons/react/24/outline';
import { Skeleton } from '@mui/material';
import { clsx } from 'clsx';
import Button from 'components/Button';
import SidePanel from 'components/SidePanel';
import { WarningMessage } from 'components/WarningMessage';
import useToast from 'hooks/useToast';
import { useZone } from 'hooks/useZone';
import { useZones } from 'hooks/useZones';
import { FC, useState } from 'react';

import ZoneDetails from './zone';

type EditZonesProps = {
  facility: Facility;
  onClose: () => void;
};

export const EditZones: FC<EditZonesProps> = ({
  facility,
  onClose,
}: EditZonesProps) => {
  const [saving, setSaving] = useState(false);

  const { setErrorMessage } = useToast();

  const { facilitiesLoadingZones } = useZones();

  const {
    addedZones,
    addZone,
    canCreateZones,
    canEditZones,
    createZones,
    facilityZones,
    removeZone,
    updateExistingZone,
    updateExistingZones,
    updateNewZone,
  } = useZone(facility.id);

  const save = () => {
    setSaving(true);

    const promises = [];
    if (canCreateZones) {
      promises.push(createZones());
    }
    if (canEditZones) {
      promises.push(updateExistingZones());
    }

    Promise.all(promises)
      .catch(() => {
        setErrorMessage('Failed to save zones');
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <SidePanel
      isOpen
      onClose={onClose}
      PanelTitle={
        <div className='text-lg font-medium text-gray-700 text-pretty'>
          Edit Zones for <em>{facility.name}</em>
        </div>
      }
    >
      <div
        className={clsx(
          'h-full flex flex-col justify-between',
          'text-gray-700 *:p-4',
        )}
      >
        <div className='flex-1 overflow-auto flex flex-col gap-4'>
          {facility.zones.length > 0 && (
            <WarningMessage variant='light'>
              <em>{facility.name}</em> may have inspections associated with it.
              Edits made to existing facility zones can impact those
              inspections.
            </WarningMessage>
          )}
          <button
            className='flex items-center text-green-base cursor-pointer'
            data-testid='add-zone'
            onClick={addZone}
          >
            <PlusIcon className='w-5 h-5 mr-1' />
            Add Zone
          </button>
          {addedZones.length > 0 && <div className='text-lg'>Zones to add</div>}
          {addedZones.map((zone, index) => (
            <ZoneDetails
              facilityId={facility.id}
              isAdding
              key={zone.id}
              onChange={(data) => {
                updateNewZone(index, data);
              }}
              onRemove={() => {
                removeZone(index);
              }}
              zone={zone}
            />
          ))}
          <div className='text-lg'>Current zones</div>
          {facilitiesLoadingZones.includes(facility.id) && (
            <div className='flex flex-col gap-4'>
              {[...Array(facility.zones.length).keys()].map((k) => (
                <Skeleton height={50} key={k} variant='rounded' />
              ))}
            </div>
          )}
          {facilityZones.map((zone) => (
            <ZoneDetails
              facilityId={facility.id}
              key={zone.id}
              onChange={(data) => {
                updateExistingZone(zone.id, data);
              }}
              zone={zone}
            />
          ))}
          {facility.zones.length === 0 && (
            <div className='italic text-gray-400'>No zones</div>
          )}
        </div>
        <div
          className={clsx(
            'flex-none h-18 border-t border-gray-200',
            'flex gap-2 items-center',
          )}
        >
          <Button
            disabled={!canCreateZones && !canEditZones}
            loading={saving}
            onClick={save}
          >
            {saving ? 'Saving...' : 'Save'}
          </Button>
          <Button onClick={onClose} secondary>
            Cancel
          </Button>
        </div>
      </div>
    </SidePanel>
  );
};
