import { Priority, Status } from '@dakota/platform-client';
import { useCallback } from 'react';
import { ActiveStatus, AreaType, AssignmentType, SearchStatus } from 'types';

import { useInternationalization } from './useInternationalization';

export const useEnumLabel = () => {
  const t = useInternationalization();

  const getActiveStatusLabel = useCallback(
    (status: ActiveStatus) => {
      switch (status) {
        case 'Active':
          return t('filters.dropdowns.activeStatus.active');
        case 'All':
          return t('filters.dropdowns.activeStatus.all');
        case 'Inactive':
          return t('filters.dropdowns.activeStatus.inactive');
      }
    },
    [t],
  );

  const getAreaLabel = useCallback(
    (area: AreaType) => {
      switch (area) {
        case 'All':
          return t('filters.dropdowns.area.all');
        case 'Facility':
          return t('filters.dropdowns.area.facility');
        case 'Global':
          return t('filters.dropdowns.area.global');
      }
    },
    [t],
  );

  const getAssignmentTypeLabel = useCallback(
    (type: AssignmentType) => {
      switch (type) {
        case AssignmentType.All:
          return t('filters.dropdowns.assignmentType.all');
        case AssignmentType.Inspection:
          return t('filters.dropdowns.assignmentType.inspections');
        case AssignmentType.Task:
          return t('filters.dropdowns.assignmentType.tasks');
      }
    },
    [t],
  );

  const getStatusLabel = useCallback(
    (status: SearchStatus | Status) => {
      switch (status) {
        case 'Overdue':
          return t('filters.dropdowns.progressStatus.overdue');
        case Status.Canceled:
          return t('filters.dropdowns.progressStatus.canceled');
        case Status.Completed:
          return t('filters.dropdowns.progressStatus.completed');
        case Status.InProgress:
          return t('filters.dropdowns.progressStatus.inProgress');
        case Status.Scheduled:
          return t('filters.dropdowns.progressStatus.scheduled');
      }
    },
    [t],
  );

  const getPriorityLabel = useCallback(
    (priority: Priority) => {
      switch (priority) {
        case Priority.High:
          return t('filters.dropdowns.priority.high');
        case Priority.Low:
          return t('filters.dropdowns.priority.low');
        case Priority.Medium:
          return t('filters.dropdowns.priority.medium');
      }
    },
    [t],
  );

  const Label = {
    ActiveStatus: t('filters.label.activeStatus'),
    AddressState: t('filters.label.address.state'),
    Area: t('filters.label.area'),
    Assignee: t('filters.label.assignee'),
    AssignmentType: t('filters.label.assignmentType'),
    Facilities: t('filters.label.facilities'),
    ItemGroup: t('filters.label.itemGroup'),
    ItemType: t('filters.label.itemType'),
    ProgressStatus: t('filters.label.progressStatus'),
    Roles: t('filters.label.roles'),
    Search: t('filters.label.search'),
    SearchQuestions: t('filters.label.searchQuestions'),
    TaskStatus: t('filters.label.taskStatus'),
  } as const;

  return {
    getActiveStatusLabel,
    getAreaLabel,
    getAssignmentTypeLabel,
    getPriorityLabel,
    getStatusLabel,
    Label,
  };
};
