import { Priority, TaskSeries } from '@dakota/platform-client';
import { ClockIcon } from '@heroicons/react/24/outline';
import { LocalDate } from '@js-joda/core';
import { clsx } from 'clsx';
import Autocomplete from 'components/Autocomplete';
import { DatePicker } from 'components/DatePicker';
import EditableText from 'components/EditableText';
import { MultilineInput } from 'components/MultilineInput';
import { Recurrence } from 'components/recurrence';
import { toHumanReadable } from 'components/recurrence/humanReadable';
import { isEqualAsRRule } from 'components/recurrence/rrule';
import {
  taskSeriesEditSlice,
  TaskSeriesEditState,
} from 'features/tasks/taskSeriesEditSlice';
import { useAppConfiguration } from 'hooks/useAppConfiguration';
import { useEnumLabel } from 'hooks/useEnumLabel';
import { useSelectedFacilityUser } from 'hooks/useSelectedFacilityUser';
import { useUsers } from 'hooks/useUsers';
import { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { EditSeriesChoice } from 'types';

type SeriesDetailsProps = {
  /**
   * The option to edit all events or this event and following events.
   */
  editOption: EditSeriesChoice;
  /**
   * The task series object.
   */
  task: TaskSeries;
};

export const SeriesDetails: FC<SeriesDetailsProps> = ({ editOption, task }) => {
  const dispatch = useAppDispatch();

  const { getConfig } = useAppConfiguration();
  const { getPriorityLabel } = useEnumLabel();

  const title = useSelector(taskSeriesEditSlice.selectors.title);
  const description = useSelector(taskSeriesEditSlice.selectors.description);
  const assigneeId = useSelector(taskSeriesEditSlice.selectors.assigneeId);
  const priority = useSelector(taskSeriesEditSlice.selectors.priority);
  const recurrenceRule = useSelector(
    taskSeriesEditSlice.selectors.recurrenceRule,
  );
  const instanceScheduledDate = useSelector(
    taskSeriesEditSlice.selectors.instanceDate,
  );
  const startDate = useSelector(taskSeriesEditSlice.selectors.startDate);

  const { getActiveUserSummary } = useUsers();
  const { selectableUsers } = useSelectedFacilityUser(task.facility.id);

  const priorities = [Priority.High, Priority.Medium, Priority.Low];

  const isFieldDisabled =
    task.isSingleInstance &&
    isEqualAsRRule(recurrenceRule, task.recurrenceRule);

  const updateTaskField = useCallback(
    <K extends keyof TaskSeriesEditState>(
      fieldName: K,
      value: TaskSeriesEditState[K],
    ) => {
      dispatch(
        taskSeriesEditSlice.actions.setTaskField({
          field: fieldName,
          value,
        }),
      );
    },
    [dispatch],
  );
  useEffect(() => {
    const initialStartDate =
      editOption === EditSeriesChoice.CurrentAndFollowing
        ? instanceScheduledDate
        : task.startDate;

    updateTaskField('startDate', initialStartDate);
  }, [editOption, instanceScheduledDate, task.startDate, updateTaskField]);

  return (
    <>
      <div className='p-4 border-x border-gray-100'>
        <EditableText
          containerClasses='w-full'
          id='task-title'
          initialEditableValue={task.title}
          maxLength={getConfig('TaskTitleMaxLength')}
          onEdit={(value) => {
            updateTaskField('title', value);
          }}
          required
          saveOnEnter
          showIcon={false}
          styleAfterSave
          text={title}
          textClasses='text-xl font-semibold text-gray-700'
        />
      </div>
      <div
        className={clsx(
          'p-4 flex items-center gap-2',
          'border-b-0 border border-gray-100',
        )}
      >
        <ClockIcon className='h-6 w-6' />
        <div aria-label='Recurrence' className='text-sm'>
          {toHumanReadable(recurrenceRule)}
        </div>
      </div>
      <div className='p-4 border-t-0 border border-gray-100'>
        <MultilineInput
          aria-label='Task description'
          id='task-description'
          maxLength={getConfig('TaskDescriptionMaxLength')}
          onChange={(value) => {
            updateTaskField('description', value);
          }}
          placeholder='Enter a description'
          value={description}
        />
      </div>
      <div className='p-4 border-gray-100'>
        <div className='text-base font-semibold'>Task Details</div>
        <p className='text-sm text-gray-600'>
          Details for every recurrence of this task.
        </p>
        <div className='flex flex-col gap-4 mt-4'>
          <div className='flex text-sm'>
            <div className='flex-1'>Facility</div>
            <div className='font-bold'>{task.facility.name}</div>
          </div>
          {task.zone?.name && (
            <div className='flex text-sm'>
              <div className='flex-1'>Zone</div>
              <div className='font-bold'>{task.zone.name}</div>
            </div>
          )}
          <div className='flex'>
            <div className='flex-1 text-sm'>Start Date</div>
            <DatePicker
              asSingle
              disabled={isFieldDisabled}
              id='series-datepicker'
              onChange={(date) => {
                updateTaskField('startDate', date.toString());
              }}
              value={LocalDate.parse(startDate)}
            />
          </div>
          <div className='flex'>
            <div className='flex-1 text-sm text-gray-700'>Assignee</div>
            <Autocomplete
              className='max-sm:w-48 w-72'
              disabled={isFieldDisabled}
              getOptionKey={(user) => user.id}
              getOptionLabel={(user) => user.name}
              id='assignee-selector'
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(user) => {
                updateTaskField('assigneeId', user.id);
              }}
              options={selectableUsers}
              value={getActiveUserSummary(assigneeId) ?? null}
            />
          </div>
          <div className='flex'>
            <div className='flex-1 text-sm text-gray-700'>Priority</div>
            <Autocomplete
              className='max-sm:w-48 w-72'
              disabled={isFieldDisabled}
              getOptionLabel={getPriorityLabel}
              id='priority-selector'
              loading={false}
              onChange={(value: Priority) => {
                updateTaskField('priority', value);
              }}
              options={priorities}
              value={priority}
            />
          </div>
          <div className='flex'>
            <div className='flex-1 text-sm text-gray-700'>Recurrence</div>
            <Recurrence
              initialDate={LocalDate.parse(startDate)}
              initialRRule={recurrenceRule}
              onChange={(value) => {
                updateTaskField('recurrenceRule', value);
              }}
            />
          </div>
          <div className='text-base font-semibold'>Source</div>
          <div className='flex text-sm'>
            <div className='flex-1 text-gray-700'>Reported By</div>
            <div className='font-bold'>{task.reportedBy}</div>
          </div>
        </div>
      </div>
    </>
  );
};
