import { Facility } from '@dakota/platform-client';
import { clsx } from 'clsx';
import Tooltip from 'components/Tooltip';
import { FC } from 'react';

type ZonesLengthProps = {
  facility: Facility;
  setSelectedFacilityForZones: (facility: Facility) => void;
};

export const ZonesLength: FC<ZonesLengthProps> = ({
  facility,
  setSelectedFacilityForZones,
}) => {
  const activeZones = facility.zones.filter((zone) => !zone.inactive);
  const inactiveZones = facility.zones.filter((zone) => zone.inactive);

  const activeCount = activeZones.length;
  const inactiveCount = inactiveZones.length;

  if (activeCount === 0 && inactiveCount === 0) {
    return (
      <Tooltip
        arrow
        enterTouchDelay={0}
        title={`${facility.name} has no zones`}
      >
        <div
          className={clsx(
            'inline-block text-xs rounded-md p-2',
            'bg-gray-100 text-gray-500 hover:cursor-help',
          )}
        >
          No zones
        </div>
      </Tooltip>
    );
  }

  return (
    <button
      className={clsx(
        'text-green-dark bg-green-lighter rounded-md',
        'text-xs p-2 hover:bg-green-light',
      )}
      onClick={() => {
        setSelectedFacilityForZones(facility);
      }}
    >
      {activeCount === 1 ? activeZones[0].name : `${activeCount} zones`}
      {inactiveCount > 0 && (
        <span className='text-xs italic text-gray-600 ml-2'>
          (+{inactiveCount} inactive)
        </span>
      )}
    </button>
  );
};
