import {
  InspectionInstance,
  Status,
  Summary,
  UserSummary,
} from '@dakota/platform-client';
import { LocalDate } from '@js-joda/core';
import { DatePickerRange } from 'components/DatePicker';
import { configSlice } from 'features/config/configSlice';
import { listInspectionInstances } from 'features/inspections/inspectionActions';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import Fuse from 'fuse.js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { SearchStatus } from 'types';
import { compareInspections } from 'utils/compare';
import { unassignedUser } from 'utils/user';

import { useDateRange } from './useDateRange';

type UseFilteredInspectionsParams = {
  /**
   * If present, only inspections assigned to this user will be included,
   * and the assignee filter will be disabled.
   * @default `undefined`
   */
  assigneeId?: string;
  /**
   * If true, use the `endDate` of an Inspection's timeline when filtering by
   * date, otherwise, filter by the `dueDate`.
   *
   * Note: this should be true when calling the hook in completed views, and
   * false in scheduled views.
   */
  filterByEndDate: boolean;
  /**
   * Initial date range for the date picker.
   * @default range from today to 7 days from now
   */
  initialDateRange?: DatePickerRange;
  /**
   * If present, filter by text search
   * @default `undefined`
   */
  searchQuery?: string;
  /**
   * If present, filter by assignee
   * @default `undefined`
   */
  selectedAssignees?: UserSummary[];
  /**
   * If present, filter by facility
   * @default `undefined`
   */
  selectedFacilities?: Summary[];
  /**
   * Statuses to show in the table. This object *must* be memoized in the
   * caller.
   */
  statuses: SearchStatus[];
} & (
  | {
      /**
       * When we use an array for selected statuses, we always default to "all
       * statuses", so we don't need an initial value.
       */
      initialSelectedStatus?: never;
      /**
       * If present, filter by the statuses in the array. If not, use all statuses.
       * @default `undefined`
       */
      selectedStatus?: SearchStatus[];
    }
  | {
      initialSelectedStatus: SearchStatus;
      /**
       * If present, filter by status
       * @default `undefined`
       */
      selectedStatus?: SearchStatus;
    }
);

export const useFilteredInspections = ({
  assigneeId = undefined,
  filterByEndDate,
  initialDateRange = {
    begin: LocalDate.now(),
    end: LocalDate.now().plusDays(7),
  },
  initialSelectedStatus,
  searchQuery = '',
  selectedAssignees = undefined,
  selectedFacilities = undefined,
  selectedStatus = undefined,
  statuses = [],
}: UseFilteredInspectionsParams) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const [filteredInspections, setFilteredInspections] = useState<
    InspectionInstance[]
  >([]);

  const allInspections = useSelector(inspectionSlice.selectors.inspections);
  const sortedInspections = useMemo(
    () => allInspections.toSorted(compareInspections),
    [allInspections],
  );

  const { dateRange, resetDateRange, setDateRange } =
    useDateRange(initialDateRange);

  const [hookDataLoaded, setHookDataLoaded] = useState(false);

  const hasFilters =
    !!searchQuery ||
    !dateRange.begin.equals(initialDateRange.begin) ||
    !dateRange.end.equals(initialDateRange.end) ||
    !!selectedAssignees?.length ||
    !!selectedFacilities?.length ||
    (Array.isArray(selectedStatus) && !!selectedStatus.length) ||
    (!Array.isArray(selectedStatus) &&
      selectedStatus !== initialSelectedStatus);

  /**
   * If there's an assignee ID, we only show inspections assigned to that user.
   *
   * If `unassignedUser` is selected, we include inspections that are not
   * assigned to any user.
   *
   * Otherwise, we only filter if there are selected assignees. If there are
   * none, all inspections pass this filter.
   */
  const isAssignedToUser = useCallback(
    (inspection: InspectionInstance, assignees?: UserSummary[]) => {
      if (assigneeId !== undefined) {
        return inspection.assigneeId === assigneeId;
      }
      return (
        !assignees?.length ||
        assignees.some(
          (assignee) =>
            (inspection.assigneeId === undefined &&
              assignee.id === unassignedUser.id) ||
            inspection.assigneeId === assignee.id,
        )
      );
    },
    [assigneeId],
  );

  // Only checks for matching status if there are any selected; otherwise,
  // all pass this filter.
  const matchesStatus = (
    inspection: InspectionInstance,
    status?: SearchStatus | SearchStatus[],
  ) => {
    if (!status) {
      return true;
    }
    if (Array.isArray(status)) {
      return (
        !status.length ||
        status.includes(inspection.status) ||
        (status.includes('Overdue') && inspection.overdue)
      );
    }
    return (
      inspection.status === status ||
      (status === 'Overdue' && inspection.overdue)
    );
  };

  // Only checks for matching facility if there are any selected; otherwise,
  // all pass this filter.
  const isAtFacility = (
    inspection: InspectionInstance,
    facilities?: Summary[],
  ) =>
    !facilities?.length ||
    facilities.some((facility) => facility.id === inspection.facility.id);

  // Determines if the inspection's scheduled date falls within the selected
  // date range
  const isInDateRange = useCallback(
    (inspection: InspectionInstance) => {
      const inspectionDate = LocalDate.parse(
        filterByEndDate && inspection.timeline.endDate
          ? inspection.timeline.endDate
          : inspection.timeline.dueDate,
      );
      return (
        !inspectionDate.isBefore(dateRange.begin) &&
        !inspectionDate.isAfter(dateRange.end)
      );
    },
    [dateRange.begin, dateRange.end, filterByEndDate],
  );

  // Filters inspections based on all criteria (assignee, status, facility,
  // date range)
  const filterInspections = useCallback(
    (inspections: InspectionInstance[], skipDateCheck = false) =>
      inspections.filter((inspection) => {
        // Check if inspection status matches the selected status
        const matchesFilterStatuses = statuses.includes(inspection.status);

        // If 'overdue' status is selected, include all overdue inspections
        if (
          Array.isArray(selectedStatus) &&
          selectedStatus.includes('Overdue') &&
          statuses.includes('Overdue') &&
          inspection.overdue
        ) {
          return matchesFilterStatuses;
        }

        return (
          matchesFilterStatuses &&
          isAssignedToUser(inspection, selectedAssignees) &&
          matchesStatus(inspection, selectedStatus) &&
          (skipDateCheck || isInDateRange(inspection)) &&
          isAtFacility(inspection, selectedFacilities)
        );
      }),
    [
      isAssignedToUser,
      isInDateRange,
      selectedAssignees,
      selectedFacilities,
      selectedStatus,
      statuses,
    ],
  );

  useEffect(() => {
    void dispatch(
      listInspectionInstances({
        baseUrl,
        dueDate_begin: filterByEndDate ? undefined : dateRange.begin.toString(),
        dueDate_end: filterByEndDate ? undefined : dateRange.end.toString(),
        endDate_begin: filterByEndDate ? dateRange.begin.toString() : undefined,
        endDate_end: filterByEndDate ? dateRange.end.toString() : undefined,
        includeAllOverdue: statuses.includes('Overdue'),
        statuses: statuses.filter((status) => status !== 'Overdue'),
        token,
      }),
    ).then(() => {
      setHookDataLoaded(true);
    });
  }, [
    token,
    dispatch,
    baseUrl,
    statuses,
    dateRange.end,
    dateRange.begin,
    filterByEndDate,
  ]);

  useEffect(() => {
    // Combine filtered inspections with overdue and in progress inspections
    let newFilteredInspections = filterInspections(sortedInspections);

    // Ignore overdue and in progress by adding all of them
    const overdueAndInProgressInspections = filterInspections(
      sortedInspections.filter(
        (i) => i.overdue || i.status === Status.InProgress,
      ),
      true,
    );

    // Remove duplicates
    newFilteredInspections = [
      ...overdueAndInProgressInspections,
      ...newFilteredInspections.filter(
        (i) => i.status !== Status.InProgress && !i.overdue,
      ),
    ];

    // Apply search query if present
    if (searchQuery) {
      const fuse = new Fuse(newFilteredInspections, {
        findAllMatches: true,
        ignoreLocation: true,
        keys: [
          { name: 'form.name', weight: 1 },
          { name: 'facility.name', weight: 1 },
          { name: 'userName', weight: 0.8 },
          { name: 'status', weight: 0.8 },
        ],
        shouldSort: true,
        threshold: 0.2,
        useExtendedSearch: true,
      });
      newFilteredInspections = fuse
        .search(searchQuery)
        .map((result) => result.item);
    }

    setFilteredInspections(newFilteredInspections);
  }, [filterInspections, searchQuery, sortedInspections]);

  return {
    dateRange,
    filteredInspections,
    hasFilters,
    hookDataLoaded,
    resetDateRange,
    setDateRange,
  };
};
