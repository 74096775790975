import {
  CreateInspectionSeriesRequest,
  FormInfo,
  FormSummary,
  InspectionInstance,
  Priority,
  Status,
  Summary,
  UpdateInspectionInstanceRequest,
} from '@dakota/platform-client';
import { LocalDate } from '@js-joda/core';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer';
import { unassignedUser } from 'utils/user';

const selectedZoneIds = createSelector(
  (state: InspectionEditState) => state.zones,
  (zones) => zones.map((zone) => zone.id),
);

export type InspectionEditState = {
  assigneeId: string;
  date: string;
  facility?: Summary;
  form?: FormInfo;
  originalState?: Pick<
    InspectionEditState,
    | 'assigneeId'
    | 'date'
    | 'facility'
    | 'form'
    | 'priority'
    | 'recurrence'
    | 'zones'
  >;
  priority: Priority;
  recurrence: string;
  seriesId: string;
  status: Status;
  zones: Summary[];
};

const initialState: InspectionEditState = {
  assigneeId: '',
  date: LocalDate.now().toString(),
  facility: undefined,
  form: undefined,
  originalState: undefined,
  priority: Priority.Medium,
  recurrence: 'FREQ=DAILY;INTERVAL=1;COUNT=1',
  seriesId: '',
  status: Status.Scheduled,
  zones: [],
};

export const inspectionEditSlice = createSlice({
  initialState,
  name: 'inspectionEdit',
  reducers: {
    clear: (state) => Object.assign(state, initialState),
    deleteZone: (state, action: PayloadAction<Summary>) => {
      state.zones = state.zones.filter((zone) => zone.id !== action.payload.id);
    },
    initializeInspection: (
      state,
      action: PayloadAction<{
        assigneeId?: string;
        facility?: Summary;
        form: FormSummary;
      }>,
    ) => {
      inspectionEditSlice.caseReducers.clear(state);
      state.form = action.payload.form;
      if (action.payload.assigneeId) {
        state.assigneeId = action.payload.assigneeId;
      }
      if (action.payload.facility) {
        state.facility = action.payload.facility;
      }

      state.originalState = {
        assigneeId: state.assigneeId,
        date: state.date,
        facility: state.facility,
        form: state.form,
        priority: state.priority,
        recurrence: state.recurrence,
        zones: state.zones,
      };
    },
    load: (state, action: PayloadAction<InspectionInstance>) => {
      const inspection = action.payload;
      state.assigneeId = inspection.assigneeId ?? '';
      state.date = inspection.timeline.dueDate;
      state.facility = inspection.facility;
      state.form = inspection.form;
      state.priority = inspection.priority;
      state.recurrence = inspection.seriesRecurrence.rule;
      state.zones = inspection.zones;
      state.seriesId = inspection.seriesId;

      state.originalState = {
        assigneeId: state.assigneeId,
        date: state.date,
        facility: state.facility,
        form: state.form,
        priority: state.priority,
        recurrence: state.recurrence,
        zones: state.zones,
      };
    },
    moveZone: (
      state,
      action: PayloadAction<{ fromIndex: number; toIndex: number }>,
    ) => {
      const { fromIndex, toIndex } = action.payload;
      const [removed] = state.zones.splice(fromIndex, 1);
      state.zones.splice(toIndex, 0, removed);
    },
    setInspectionField: <K extends keyof InspectionEditState>(
      state: WritableDraft<InspectionEditState>,
      action: PayloadAction<{ field: K; value: InspectionEditState[K] }>,
    ) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    updateZones: (state, action: PayloadAction<Summary[]>) => {
      // Add the zones from the payload list that are not on the state list to
      // the end of the state list, and remove the ones that are on the state
      // list but not on the payload list.
      const newZones = action.payload.filter(
        (zone) => !state.zones.some((z) => z.id === zone.id),
      );
      const zonesToRemove = state.zones.filter(
        (zone) => !action.payload.some((z) => z.id === zone.id),
      );
      state.zones = state.zones
        .filter((zone) => !zonesToRemove.some((z) => z.id === zone.id))
        .concat(newZones);
    },
  },
  selectors: {
    assigneeId: (state) => state.assigneeId,
    createInspectionRequest: createSelector(
      [
        (state: InspectionEditState) =>
          state.assigneeId === unassignedUser.id
            ? undefined
            : state.assigneeId || undefined,
        (state: InspectionEditState) => state.date,
        (state: InspectionEditState) => state.facility?.id,
        (state: InspectionEditState) => state.form?.id,
        (state: InspectionEditState) => state.priority,
        (state: InspectionEditState) => state.recurrence,
        (state: InspectionEditState) => state.zones,
      ],
      (
        assigneeId,
        startDate,
        facilityId,
        formId,
        priority,
        recurrenceRule,
        zones,
      ) =>
        ({
          assigneeId,
          facilityId,
          formId,
          priority,
          recurrenceRule,
          startDate,
          zones: zones.map((zone) => zone.id),
        }) as CreateInspectionSeriesRequest,
    ),
    date: (state) => state.date,
    editInspectionInstanceRequest: createSelector(
      [
        (state: InspectionEditState) =>
          state.assigneeId === unassignedUser.id
            ? undefined
            : state.assigneeId || undefined,
        (state: InspectionEditState) => state.priority,
        (state: InspectionEditState) => state.date,
        (state: InspectionEditState) => state.originalState?.date,
        (state: InspectionEditState) => state.seriesId,
        selectedZoneIds,
      ],
      (assigneeId, priority, newDueDate, dueDate, seriesId, zones) =>
        ({
          body: { assigneeId, newDueDate, priority, zones },
          dueDate,
          seriesId,
        }) as {
          body: UpdateInspectionInstanceRequest;
          dueDate: string;
          seriesId: string;
        },
    ),
    facility: (state) => state.facility,
    form: (state) => state.form,
    hasUnsavedChanges: (state: InspectionEditState) => {
      /**
       * Check if the current state differs from the original or initial state.
       * Returns true if there are unsaved changes, otherwise false.
       */
      return !isStateEqual(state, state.originalState ?? initialState);
    },
    isFacilityForm: (state) => !!state.originalState?.facility,
    priority: (state) => state.priority,
    recurrence: (state) => state.recurrence,
    zones: (state) => state.zones,
  },
});

export const isStateEqual = (
  state: Partial<InspectionEditState>,
  originalState: Partial<InspectionEditState>,
): boolean => {
  return (
    state.assigneeId === originalState.assigneeId &&
    state.date === originalState.date &&
    state.facility?.id === originalState.facility?.id &&
    state.form?.id === originalState.form?.id &&
    state.priority === originalState.priority &&
    state.recurrence === originalState.recurrence &&
    state.zones?.length === originalState.zones?.length &&
    (state.zones ?? []).every(
      (zone, index) => zone.id === (originalState.zones ?? [])[index]?.id,
    )
  );
};
