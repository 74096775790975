const avatarColors = [
  '#60a5fa', // blue-base
  '#155e75', // TW cyan-700
  '#497d28', // green-base
  '#eb5757', // red-base
  '#0369a1', // TW sky-700
  '#6d28d9', // TW violet-700
  '#fbbf24', // yellow-base
] as const;

const colorHash = (userId: string) =>
  // Convert the last character from hex to decimal and get the modulo 7 to
  // determine the color. Since we have 7 colors for avatars, this will ensure
  // consistent color for a user, across refreshes and even if the user changes
  // their name.
  Number(`0x${userId.charAt(userId.length - 1)}`) % 7;

/** Get the user avatar color in RGB form. */
export const getRgbColorById = (assigneeId: string) => {
  return avatarColors[colorHash(assigneeId)];
};
