import { Priority } from '@dakota/platform-client';
import Autocomplete from 'components/Autocomplete';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { useEnumLabel } from 'hooks/useEnumLabel';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export const TaskPriority: FC = () => {
  const dispatch = useAppDispatch();
  const t = useInternationalization('tasks.create.label');
  const { getPriorityLabel } = useEnumLabel();

  const hasErrorFiles = useSelector(taskEditSlice.selectors.hasErrorFiles);

  const priority = useSelector(taskEditSlice.selectors.priority);

  return (
    <>
      <label htmlFor='task-priority-selector'>{t('priority')}</label>
      <Autocomplete
        disabled={hasErrorFiles}
        fullWidth
        getOptionLabel={getPriorityLabel}
        id='task-priority-selector'
        onChange={(value: Priority) =>
          dispatch(
            taskEditSlice.actions.setTaskField({
              field: 'priority',
              value,
            }),
          )
        }
        options={[Priority.Low, Priority.Medium, Priority.High]}
        value={priority}
      />
    </>
  );
};
