import Autocomplete from 'components/Autocomplete';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { useInternationalization } from 'hooks/useInternationalization';
import { useSelectedFacilityUser } from 'hooks/useSelectedFacilityUser';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export const Assignee: FC = () => {
  const dispatch = useAppDispatch();
  const t = useInternationalization('tasks.edit');

  const assigneeId = useSelector(taskEditSlice.selectors.assigneeId);
  const facility = useSelector(taskEditSlice.selectors.facility);

  const { selectableUsers } = useSelectedFacilityUser(facility?.id);

  const findAssigneeById = (id: null | string) =>
    selectableUsers.find((user) => user.id === id) ?? null;

  return (
    <div className='flex'>
      <label className='flex-1' htmlFor='task-assignee'>
        {t('label.assignee')}
      </label>
      <Autocomplete
        className='w-64'
        getOptionKey={(u) => u.id}
        getOptionLabel={(u) => u.name}
        id='task-assignee'
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(option) =>
          dispatch(
            taskEditSlice.actions.setTaskField({
              field: 'assigneeId',
              value: option.id,
            }),
          )
        }
        options={selectableUsers}
        placeholder={t('placeholder.assignee')}
        value={findAssigneeById(assigneeId)}
      />
    </div>
  );
};
