import { Thumbnail } from '@dakota/platform-client';
import {
  ArrowDownOnSquareIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Dialog } from '@mui/material';
import { clsx } from 'clsx';
import Button from 'components/Button';
import { MultilineInput } from 'components/MultilineInput';
import Confirmation from 'components/SimpleConfirmation';
import { Spinner } from 'components/Spinner';
import Tooltip from 'components/Tooltip';
import { configSlice } from 'features/config/configSlice';
import { getFile } from 'features/files/filesActions';
import { filesSlice } from 'features/files/filesSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { useAppConfiguration } from 'hooks/useAppConfiguration';
import useToast from 'hooks/useToast';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { useEventListener } from 'usehooks-ts';
import { downloadAttachment } from 'utils/file';

import { NavButton } from './NavButton';
import { Preview } from './Preview';

export interface IMedia {
  description: string;
  filePath: string;
  fileSize: number;
  fileType: string;
  id: string;
  originalName: string;
  thumbnail?: Thumbnail;
}

type Props = {
  /**
   * Check if the user has permission to delete the media based on its id.
   */
  canDelete: (id: string) => boolean;
  /**
   * Check if the user has permission to edit the description of the media
   * based on its id.
   */
  canEditDescription: (id: string) => boolean;
  /**
   * The index of the initial file in the collection to display.
   * It can fall outside of the boundaries of the collection, in which case the
   * selected file will default to the first file if the given value is
   * negative, or the last file if the given value is greater or equal than the
   * size of the collection.
   */
  initialFileIndex: number;
  /**
   * The collection of files to display. It *must* be non-empty.
   */
  media: IMedia[];
  onClose: () => void;
  /**
   * Delete the media with the given id.
   */
  onDelete: (id: string) => Promise<unknown>;
  /**
   * Edit the description of the media with the given id.
   */
  onEditDescription: (id: string, description: string) => Promise<unknown>;
};

export const Carousel: FC<Props> = ({
  canDelete,
  canEditDescription,
  initialFileIndex,
  media,
  onClose,
  onDelete,
  onEditDescription,
}) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const { getConfig } = useAppConfiguration();

  const [fileIndex, setFileIndex] = useState(initialFileIndex);

  // Make sure the file index is within the bounds of the media array.
  // Since the array is required to have at least one element, this will always
  // hit an actual file in the collection.
  const file = media[Math.max(0, Math.min(fileIndex, media.length - 1))];
  const isEditable = canEditDescription(file.id);
  const isDeletable = canDelete(file.id);

  const getFileById = useSelector(filesSlice.selectors.getFileById);
  /** The actual secured file we can use for displaying and downloading. */
  const cachedFile = getFileById(file.id);

  const { setErrorMessage } = useToast();

  const [isDeleting, setIsDeleting] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [editDescription, setEditDescription] = useState(file.description);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [isSavingDescription, setIsSavingDescription] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isNavigationVisible, setIsNavigationVisible] = useState(true);

  useEffect(() => {
    setEditDescription(file.description);
  }, [file.description]);

  const goToPrev = () => {
    setFileIndex((current) => (current - 1 + media.length) % media.length);
  };

  const goToNext = () => {
    setFileIndex((current) => (current + 1) % media.length);
  };

  useEventListener('keydown', (event) => {
    // Do not navigate if the user is editing the description
    if (event.target instanceof HTMLTextAreaElement) {
      return;
    }

    if (event.key === 'ArrowRight') {
      goToNext();
    } else if (event.key === 'ArrowLeft') {
      goToPrev();
    }
  });

  useEffect(() => {
    // Fetch when we don't have the file or the cached file is expired.
    if (!cachedFile || new Date(cachedFile.expiration) < new Date()) {
      void dispatch(
        getFile({
          attachmentId: file.id,
          baseUrl,
          file: file.filePath,
          token,
          ttl: 1200, // 20 minutes
        }),
      );
    }
  }, [baseUrl, cachedFile, dispatch, file, token]);

  const deleteAttachment = () => {
    setIsDeleting(true);

    void onDelete(file.id)
      .then(goToPrev)
      .finally(() => {
        setIsDeleting(false);
        setConfirmDelete(false);
      });
  };

  const download = () => {
    setIsDownloading(true);

    downloadAttachment(cachedFile?.fileUrl as string, file.originalName)
      .catch(() => {
        setErrorMessage('Failed to download media');
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  const saveDescription = () => {
    setIsSavingDescription(true);

    void onEditDescription(file.id, editDescription)
      .catch(() => {
        setErrorMessage('Failed to update description');
      })
      .finally(() => {
        setIsSavingDescription(false);
        setIsEditingDescription(false);
      });
  };

  return (
    <Dialog fullScreen onClose={onClose} open>
      <div
        aria-label='Attachments Carousel'
        className={clsx(
          'w-full h-full bg-black text-gray-100 sm:p-9',
          'flex flex-col justify-between',
        )}
      >
        <div className='min-h-16 flex gap-4 justify-between items-center p-4 max-sm:p-2'>
          <div className='flex-1 flex flex-col min-w-0'>
            <div
              aria-label='File name'
              className={clsx(
                'sm:truncate text-2xl',
                'max-sm:text-lg max-sm:overflow-x-auto max-sm:text-nowrap',
              )}
            >
              {file.originalName}
            </div>
            {isEditingDescription && (
              <div className='flex flex-col gap-2'>
                <MultilineInput
                  aria-label='Edit description'
                  dark
                  id='description'
                  maxLength={getConfig('AttachmentDescriptionMaxLength')}
                  maxRows={5}
                  onChange={setEditDescription}
                  placeholder='Enter a description...'
                  value={editDescription}
                />
                <div className='flex justify-end gap-2'>
                  <Button
                    aria-label='Cancel editing description'
                    className='text-sm !px-2 !py-1'
                    disabled={isSavingDescription}
                    onClick={() => {
                      setEditDescription(file.description);
                      setIsEditingDescription(false);
                    }}
                    secondary
                  >
                    Cancel
                  </Button>
                  <Button
                    aria-label='Save description'
                    className='text-sm !px-2 !py-1'
                    disabled={editDescription === file.description}
                    loading={isSavingDescription}
                    onClick={saveDescription}
                  >
                    Save
                  </Button>
                </div>
              </div>
            )}
            {!isEditingDescription && (
              <button
                aria-label='Description'
                className={clsx(
                  'whitespace-pre-wrap text-start outline-1 outline-gray-400',
                  'max-h-32 max-sm:max-h-80 overflow-y-auto rounded-sm',
                  {
                    'hover:outline hover:outline-offset-2': isEditable,
                    'text-gray-500 italic': !editDescription,
                  },
                )}
                onClick={() => {
                  if (isEditable) {
                    setIsEditingDescription(true);
                  }
                }}
              >
                {editDescription || 'No description'}
              </button>
            )}
          </div>
          <div className='flex gap-2'>
            <Tooltip arrow disabled={!cachedFile} title='Download attachment'>
              <button
                aria-label='Download attachment'
                disabled={!cachedFile || isDownloading}
                onClick={download}
              >
                {!cachedFile || isDownloading ? (
                  <Spinner />
                ) : (
                  <ArrowDownOnSquareIcon className='size-5' />
                )}
              </button>
            </Tooltip>
            {isDeletable && (
              <Tooltip arrow title='Delete attachment'>
                <button
                  aria-label='Delete attachment'
                  onClick={() => {
                    setConfirmDelete(true);
                  }}
                >
                  <TrashIcon className='size-5 text-red-base' />
                </button>
              </Tooltip>
            )}
            <button aria-label='Close Attachments Carousel' onClick={onClose}>
              <XMarkIcon className='size-5' />
            </button>
          </div>
        </div>
        <div
          className={clsx(
            'flex-1 overflow-hidden',
            'flex items-center sm:gap-2',
            media.length === 1 ? 'justify-center' : 'justify-between',
          )}
        >
          {media.length > 1 && (
            <NavButton
              direction='left'
              onClick={goToPrev}
              visible={isNavigationVisible}
            />
          )}
          <Preview
            file={file}
            isDownloading={isDownloading}
            isNavigationVisible={isNavigationVisible}
            key={cachedFile?.expiration}
            onDownload={download}
            toggleNavigation={() => {
              setIsNavigationVisible((prev) => !prev);
            }}
          />
          {media.length > 1 && (
            <NavButton
              direction='right'
              onClick={goToNext}
              visible={isNavigationVisible}
            />
          )}
        </div>
      </div>
      {confirmDelete && (
        <Confirmation
          cancelText='Cancel'
          confirmLabel='Confirm delete attachment'
          confirmText='Delete Media'
          loading={isDeleting}
          onCancel={() => {
            setConfirmDelete(false);
          }}
          onConfirm={deleteAttachment}
        >
          Are you sure you want to delete this media?
          <br />
          This action cannot be reverted.
        </Confirmation>
      )}
    </Dialog>
  );
};
