import { Drawer } from '@mui/material';
import { useInternationalization } from 'hooks/useInternationalization';

import logo from '../../assets/Dakota-Logo.png';
import Navigation from './Navigation';

type Props = {
  isOpen: boolean;
  toggleSidebar: () => void;
};

const SidebarMobile = ({ isOpen, toggleSidebar }: Props) => {
  const t = useInternationalization('layout.header');

  return (
    <Drawer
      onClose={() => {
        toggleSidebar();
      }}
      open={isOpen}
    >
      <div className='py-5 px-2 flex flex-col gap-5' id='mobile-sidebar'>
        <img
          alt={t('alt.dakota.logo')}
          className='w-32 place-self-center mx-auto'
          src={logo}
        />
        <Navigation isSidebarOpen />
      </div>
    </Drawer>
  );
};
export default SidebarMobile;
