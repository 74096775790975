import { FormatXMLElementFn } from 'intl-messageformat';
import { ReactNode, useCallback, useMemo } from 'react';
import { PrimitiveType, useIntl } from 'react-intl';

export const useInternationalization = (prefix?: string) => {
  const intl = useIntl();

  const withPrefix = useCallback(
    (key: string) => `${prefix?.concat('.') ?? ''}${key}`,
    [prefix],
  );

  return useMemo(() => {
    const t = (key: string, values?: Record<string, PrimitiveType>) => {
      return intl.formatMessage({ id: withPrefix(key) }, values);
    };

    // Add `formatMessage` to the `t` function with the prefix for this instance
    t.formatMessage = (
      key: string,
      values?:
        | Record<
            string,
            FormatXMLElementFn<string, ReactNode> | PrimitiveType | ReactNode
          >
        | Record<string, FormatXMLElementFn<string, string> | PrimitiveType>,
    ) => intl.formatMessage({ id: withPrefix(key) }, values);

    // Format bold text in messages
    t.boldText = (text: ReactNode) => <strong>{text}</strong>;

    // Format monospaced text in messages
    t.monoText = (text: ReactNode) => <span className='text-mono'>{text}</span>;

    return t;
  }, [intl, withPrefix]);
};
