import { DashboardClient } from '@dakota/platform-client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClientData, getClient } from 'features/clientProvider';

export const getNotifications = createAsyncThunk(
  'notifications/getNotifications',
  async (params: ClientData) => {
    const client = getClient(DashboardClient, params);
    const response = await client.getMyNotifications();
    return response.result;
  },
);
