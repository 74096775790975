import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC, ReactNode } from 'react';

type Props = {
  ctaAction: () => void;
  ctaText: string;
  icon: ReactNode;
  loading?: boolean;
  message: ReactNode;
  ticketId?: string;
  title: string;
};

export const NotificationCard: FC<Props> = ({
  ctaAction,
  ctaText,
  icon,
  loading,
  message,
  ticketId,
  title,
}) => {
  const t = useInternationalization('notifications');

  return (
    <button
      aria-label={t('button.label')}
      className={clsx(
        'flex-0 group flex gap-2 min-h-20 rounded-md bg-gray-100 mx-4',
        'outline-gray-300 hover:outline text-start text-sm',
        { 'opacity-50 cursor-not-allowed': loading },
      )}
      onClick={ctaAction}
    >
      <div className='place-self-center size-6'>{icon}</div>
      <div className='flex-1 flex flex-col overflow-x-hidden'>
        {ticketId && (
          <div className='text-xs text-gray-500 uppercase'>{ticketId}</div>
        )}
        <div className='font-medium truncate'>{title}</div>
        <div className='mt-4 group-hover:underline'>{message}</div>
        <div className='place-self-end mt-2 inline-flex items-center gap-1 text-blue-dark'>
          {ctaText}
          <ArrowRightIcon className='size-4 motion-safe:group-hover:scale-125 transition-all' />
        </div>
      </div>
    </button>
  );
};
