import { InspectionInstance, Status } from '@dakota/platform-client';
import Button from 'components/Button';
import AcknowledgmentDialog from 'components/Dialog/AcknowledgmentDialog';
import { configSlice } from 'features/config/configSlice';
import {
  getInspectionDetails,
  startInspection,
} from 'features/inspections/inspectionActions';
import { tokenSlice } from 'features/token/tokenSlice';
import { userSlice } from 'features/user/userSlice';
import { useCheckPermission } from 'hooks/useCheckPermission';
import { useInternationalization } from 'hooks/useInternationalization';
import useToast from 'hooks/useToast';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/store';
import { Permission } from 'utils/permissions';

type Props = {
  inspection: Pick<
    InspectionInstance,
    'assigneeId' | 'form' | 'seriesId' | 'status' | 'timeline'
  >;
};

/**
 * "Start" button for scheduled inspections.
 * "Resume" button for in-progress inspections.
 * Nothing for the rest.
 */
export const InspectionLauncher: FC<Props> = ({ inspection }) => {
  const t = useInternationalization('inspections.table');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const currentUser = useSelector(userSlice.selectors.currentUser);
  const canPerformInspection = useCheckPermission(Permission.PerformInspection);
  const canReadInspection = useCheckPermission(Permission.ReadInspection);

  const { setErrorMessage } = useToast();

  const [startingInspection, setStartingInspection] = useState(false);
  const [loadingInspection, setLoadingInspection] = useState(false);
  const [showUnassignedWarning, setShowUnassignedWarning] = useState(false);

  const inspectionUrl = `/inspections/${inspection.seriesId}/${inspection.timeline.dueDate}?source=${location.pathname}`;

  const warningMessage = t.formatMessage('unassigned.warning', {
    boldText: t.boldText,
    formName: inspection.form.name,
  });
  const start = () => {
    setStartingInspection(true);

    dispatch(
      startInspection({
        baseUrl,
        dueDate: inspection.timeline.dueDate,
        seriesId: inspection.seriesId,
        token,
      }),
    )
      .unwrap()
      .then(() => {
        navigate(inspectionUrl);
      })
      .catch(() => {
        setErrorMessage(t('action.start.failure'));
      })
      .finally(() => {
        setStartingInspection(false);
      });
  };

  const startHandler = () => {
    if (inspection.assigneeId === undefined) {
      setShowUnassignedWarning(true);
    } else {
      start();
    }
  };

  const load = () => {
    setLoadingInspection(true);

    dispatch(
      getInspectionDetails({
        baseUrl,
        dueDate: inspection.timeline.dueDate,
        seriesId: inspection.seriesId,
        token,
      }),
    )
      .then(() => {
        navigate(inspectionUrl);
      })
      .catch(() => {
        setErrorMessage(t('action.load.failure'));
      })
      .finally(() => {
        setLoadingInspection(false);
      });
  };

  if (inspection.status === Status.Scheduled) {
    return (
      <>
        <Button
          className='text-xs ms-1'
          disabled={
            !canPerformInspection ||
            (inspection.assigneeId !== currentUser.id &&
              inspection.assigneeId !== undefined)
          }
          hasShadow={false}
          loading={startingInspection}
          onClick={startHandler}
          outlined
        >
          {t('action.start')}
        </Button>
        {showUnassignedWarning && (
          <AcknowledgmentDialog
            acknowledgmentText={warningMessage}
            buttonText={t('action.start')}
            cancelButtonText={t('action.close')}
            confirmationButtonSide='right'
            onAcknowledgment={start}
            onCloseDialog={() => {
              setShowUnassignedWarning(false);
            }}
            title={t('unassigned.warning.header')}
          />
        )}
      </>
    );
  }

  if (inspection.status === Status.InProgress) {
    return (
      <Button
        className='text-xs ms-1'
        disabled={
          !canPerformInspection || inspection.assigneeId !== currentUser.id
        }
        hasShadow={false}
        loading={loadingInspection}
        onClick={load}
        outlined
      >
        {t('action.resume')}
      </Button>
    );
  }

  if (inspection.status === Status.Completed && canReadInspection) {
    return (
      <Button
        className='text-xs ms-1'
        hasShadow={false}
        loading={loadingInspection}
        onClick={load}
        outlined
      >
        {t('action.view')}
      </Button>
    );
  }
};
