import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { IconButton, Slide } from '@mui/material';
import { clsx } from 'clsx';
import {
  notificationsSlice,
  StickyMessageType,
} from 'features/notifications/notificationsSlice';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC, useState } from 'react';
import { useAppDispatch } from 'store/store';

type Props = {
  message: StickyMessageType;
};

export const StickyMessage: FC<Props> = ({ message }) => {
  const {
    actions = [],
    content,
    dismissible = true,
    id,
    severity = 'warning',
  } = message;

  const t = useInternationalization('components.stickyMessage');
  const dispatch = useAppDispatch();

  const [isShown, setIsShown] = useState(true);

  const buttonClasses = clsx(
    'text-sm flex-none flex items-center gap-1 border rounded-md py-1 px-2',
    'border-gray-400 hover:border-gray-500',
    'text-gray-800 bg-white',
  );

  const doActionAndDismiss = (action: () => void) => () => {
    action();
    setIsShown(false);
  };

  return (
    <Slide
      in={isShown}
      onExited={() =>
        dispatch(notificationsSlice.actions.removeStickyMessage(id))
      }
    >
      <div
        aria-label={t('label')}
        className={clsx(
          { 'bg-yellow-base/30 text-gray-700': severity === 'warning' },
          { 'bg-blue-base/30 text-gray-800': severity === 'info' },
          'p-4 text-sm flex justify-between gap-4',
          actions.length > 0 ? 'items-start' : 'items-center',
          'border-b border-white',
        )}
      >
        <div className='flex-none place-self-center w-5'>
          {severity === 'warning' && <ExclamationTriangleIcon />}
          {severity === 'info' && <InformationCircleIcon />}
        </div>
        <div className='flex-1 flex flex-col gap-2'>
          <div>{content}</div>
          {actions.length > 0 && (
            <div className='flex items-center gap-4'>
              {actions.map((action) => (
                <button
                  className={buttonClasses}
                  key={action.text}
                  onClick={doActionAndDismiss(action.onClick)}
                >
                  {action.text}
                </button>
              ))}
            </div>
          )}
        </div>
        {(dismissible || actions.length === 0) && (
          <IconButton
            aria-label={t('dismiss')}
            onClick={() => {
              setIsShown(false);
            }}
          >
            <XMarkIcon className='size-5' />
          </IconButton>
        )}
      </div>
    </Slide>
  );
};
