import Autocomplete from 'components/Autocomplete';
import { FC, useEffect, useState } from 'react';
import { Weekday } from 'rrule';

import { toOrdinal } from './print';
import { dayToString } from './rrule';
import { MonthlyRecurrenceDetails } from './types';

type MonthlySelectionProps = {
  initialBySetPos: number;
  initialDayOfMonth: number;
  initialType: 'bymonthday' | 'byweekday';
  initialWeekday: Weekday;
  /**
   * Callback that is called when the selection changes.
   */
  onChange: (details: MonthlyRecurrenceDetails) => void;
};

export const MonthlySelection: FC<MonthlySelectionProps> = ({
  initialBySetPos,
  initialDayOfMonth,
  initialType,
  initialWeekday,
  onChange,
}) => {
  // bymonthday: "Monthly on the 7th", byweekday: "Monthly on the 3rd Tuesday"
  const options = ['bymonthday', 'byweekday'] as const;
  const [option, setOption] = useState<(typeof options)[number]>(initialType);
  const [bysetpos, setBysetpos] = useState(initialBySetPos);
  const [byweekday, setByweekday] = useState(initialWeekday);

  useEffect(() => {
    setOption(initialType);
  }, [initialType]);
  useEffect(() => {
    setBysetpos(initialBySetPos);
  }, [initialBySetPos]);
  useEffect(() => {
    setByweekday(initialWeekday);
  }, [initialWeekday]);

  const selectOption = (newOption: (typeof options)[number]) => {
    setOption(newOption);
    if (newOption === 'byweekday') {
      onChange({ bysetpos, byweekday });
    } else {
      onChange({ bymonthday: initialDayOfMonth });
    }
  };

  const getOptionLabel = (opt: (typeof options)[number]) => {
    switch (opt) {
      case 'bymonthday':
        return `on the ${toOrdinal(initialDayOfMonth)}`;
      case 'byweekday':
        return `on the ${toOrdinal(bysetpos)} ${dayToString(byweekday)}`;
    }
  };

  return (
    <div className='flex justify-end'>
      <Autocomplete
        className='w-72'
        getOptionLabel={getOptionLabel}
        id='recurrence-monthly-option'
        onChange={selectOption}
        options={options}
        value={option}
      />
    </div>
  );
};
