import {
  faArrowsMaximize,
  faArrowsMinimize,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/Button_v2';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export const ExpandCollapse: FC = () => {
  const dispatch = useAppDispatch();

  return (
    <div className='flex gap-3'>
      <Button
        disabled={useSelector(inspectionSlice.selectors.areAllSectionsExpanded)}
        onClick={() => dispatch(inspectionSlice.actions.expandAll())}
        variant='outlined'
      >
        <FontAwesomeIcon icon={faArrowsMaximize} /> Expand
      </Button>
      <Button
        disabled={useSelector(
          inspectionSlice.selectors.areAllSectionsCollapsed,
        )}
        onClick={() => dispatch(inspectionSlice.actions.collapseAll())}
        variant='outlined'
      >
        <FontAwesomeIcon icon={faArrowsMinimize} /> Collapse
      </Button>
    </div>
  );
};
