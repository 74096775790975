import { ApiException } from '@dakota/client-common';
import {
  CreateUserRequest,
  Summary,
  User,
  UserDetails,
  UserProfile,
  UsersClient,
} from '@dakota/platform-client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClientData, getClient } from 'features/clientProvider';
import { toUserSummary } from 'utils/user';

export const getAllUsers = createAsyncThunk(
  'users/getAllUsers',
  async (params: ClientData) => {
    const client = getClient(UsersClient, params);
    const response = await client.listUsers();
    return response.result;
  },
);

export const getAllUserSummaries = createAsyncThunk(
  'users/getAllUserSumaries',
  async (params: ClientData) => {
    const client = getClient(UsersClient, params);
    const response = await client.listUserSummary();
    return response.result;
  },
);

export const listFacilityUsers = createAsyncThunk(
  'users/listFacilityUsers',
  async (
    params: {
      active: boolean;
      directFacilityOnly: boolean;
      facilityId: string;
      orgWide: boolean;
    } & ClientData,
  ) => {
    const client = getClient(UsersClient, params);
    const response = await client.listUsers({
      active: params.active,
      directFacilityOnly: params.directFacilityOnly,
      facilities: [params.facilityId],
    });

    return {
      facilityId: params.facilityId,
      users: response.result.map(toUserSummary),
    };
  },
);

export const listActiveUsersForActiveFacilities = createAsyncThunk(
  'users/listActiveUsersForActiveFacilities',
  async (params: ClientData) => {
    const client = getClient(UsersClient, params);
    const response = await client.listActiveUsersForActiveFacilities();
    return response.result;
  },
);

export const getCurrentUser = createAsyncThunk(
  'users/getCurrentUser',
  async (params: ClientData) => {
    const client = getClient(UsersClient, params);
    const response = await client.getMe();
    return response.result;
  },
);

export const addUser = createAsyncThunk(
  'users/addUser',
  async (params: { user: CreateUserRequest } & ClientData) => {
    const client = getClient(UsersClient, params);
    const createUser = await client.createUser(params.user);
    // Security fields are only returned on the list users endpoint
    // list users includes user param so we can get a single user with security
    // fields
    const response = await client.listUsers({ user: createUser.result.id });
    return response.result[0];
  },
);

/**
 * Update phone number for the current user.
 */
export const updatePhoneNumber = createAsyncThunk(
  'users/updatePhoneNumber',
  async (params: { phone: string } & ClientData) => {
    const client = getClient(UsersClient, params);
    const currentUser = (await client.getMe()).result;
    const response = await client.updateMe(
      User.fromJS({
        ...currentUser,
        phone: params.phone,
      }),
    );
    return UserProfile.fromJS({ ...currentUser, phone: response.result.phone });
  },
);

export const updateUserWithAllFields = createAsyncThunk(
  'users/updateUserWithAllFields',
  async (
    params: {
      addedFacilities: Summary[];
      addedRoles: Summary[];
      removedFacilities: Summary[];
      removedRoles: Summary[];
      user: User;
    } & ClientData,
    { rejectWithValue },
  ) => {
    const client = getClient(UsersClient, params);

    const addRoles = async () => {
      if (params.addedRoles.length > 0) {
        await client.addUserToRoles(params.user.id, params.addedRoles);
      }
    };
    const removeRoles = async () => {
      if (params.removedRoles.length > 0) {
        await client.removeUserFromRoles(params.user.id, params.removedRoles);
      }
    };
    const addFacilities = async () => {
      if (params.addedFacilities.length > 0) {
        await client.grantUserAccessToFacilities(
          params.user.id,
          params.addedFacilities,
        );
      }
    };
    const removedFacilities = async () => {
      if (params.removedFacilities.length > 0) {
        await client.revokeAccessToFacilitiesFromUser(
          params.user.id,
          params.removedFacilities,
        );
      }
    };

    try {
      // add roles has to be performed first since the user has to have at
      // least a role
      await addRoles();
      await Promise.all([
        client.updateUser(params.user.id, params.user),
        removeRoles(),
        addFacilities(),
        removedFacilities(),
      ]);
    } catch (_) {
      return rejectWithValue(params.user);
    }

    const getUser = await client.listUsers({ user: params.user.id });
    return getUser.result[0];
  },
);

export const activateUser = createAsyncThunk(
  'users/activateUser',
  async (params: { user: UserDetails } & ClientData, { fulfillWithValue }) => {
    const client = getClient(UsersClient, params);
    await client.reactivateUser(params.user.id);
    return fulfillWithValue(params.user);
  },
);

/**
 * @returns the user object that was deactivated,
 * with unchanged inactive status (to be handled in the reducer)
 */
export const deactivateUser = createAsyncThunk(
  'users/deactivateUser',
  async (params: { user: UserDetails } & ClientData, { fulfillWithValue }) => {
    const client = getClient(UsersClient, params);
    await client.deleteOrDeactivateUser(params.user.id, false);
    return fulfillWithValue(params.user);
  },
);

/**
 * @returns the user object that was deleted
 */
export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async (params: { user: UserDetails } & ClientData, { fulfillWithValue }) => {
    const client = getClient(UsersClient, params);
    await client.deleteOrDeactivateUser(params.user.id, true);
    return fulfillWithValue(params.user);
  },
);

export const resendInvitation = createAsyncThunk(
  'users/resendInvitation',
  async (params: { id: string } & ClientData) => {
    const client = getClient(UsersClient, params);
    await client.resendInvite(params.id);
  },
);

export const resetPassword = createAsyncThunk(
  'users/resetPassword',
  async (params: { id: string } & ClientData) => {
    const client = getClient(UsersClient, params);
    await client.resetPassword(params.id);
  },
);

export const testUserAccess = createAsyncThunk(
  'users/testUserAccess',
  async (params: ClientData, { rejectWithValue }) => {
    const client = getClient(UsersClient, params);
    try {
      await client.testMyAccess();
    } catch (e: unknown) {
      if (e instanceof ApiException && e.status === 403) {
        // Only in the case of a "Forbidden" response, we reject
        // with this specific value to handle it in the reducer.
        // Note this value could be anything fixed, like
        // "USER_MUST_BE_LOGGED_OUT_NOW", but the status code
        // is descriptive of the situation, and we only deal
        // with this in a single case (testUserAccess.rejected).
        return rejectWithValue(403);
      }
    }
  },
);
