import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import { FC, useCallback, useLayoutEffect, useMemo, useState } from 'react';

import { useAddMultipleContext } from './useContext';

type TypeQuestionsProps = {
  onChange: (value: string) => void;
};

export const TypeQuestions: FC<TypeQuestionsProps> = ({ onChange }) => {
  const { lines, text } = useAddMultipleContext();

  const [isTyping, setIsTyping] = useState(false);

  const textarea = document.getElementById('questions-textarea');
  const lineNumbers = document.getElementById('line-numbers');

  useLayoutEffect(() => {
    if (isTyping) {
      document.querySelector('textarea')?.focus();
    }
  }, [isTyping]);

  useLayoutEffect(() => {
    if (textarea && lineNumbers) {
      // Sync the scroll position of the textarea with the line numbers
      textarea.addEventListener('scroll', () => {
        lineNumbers.scrollTop = textarea.scrollTop;
      });
    }
  }, [lineNumbers, textarea]);

  /**
   * This bit of magic allows us to calculate the number of lines a string will
   * take up in a textarea. It does this by creating a canvas element with the
   * same font family and size as the textarea, and then measuring the width of
   * each word in the string, calculating the total until it passes the width
   * of the textarea itself, at which point it adds a new line to the counter.
   */
  const numberOfLines = useCallback(
    (line: string) => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d') as CanvasRenderingContext2D;

      const textareaStyles = window.getComputedStyle(textarea as HTMLElement);
      context.font = `${textareaStyles.fontSize} ${textareaStyles.fontFamily}`;

      const paddingLeft = parseInt(textareaStyles.paddingLeft.slice(0, -2));
      const paddingRight = parseInt(textareaStyles.paddingRight.slice(0, -2));
      const textareaWidth =
        (textarea as HTMLElement).getBoundingClientRect().width -
        paddingLeft -
        paddingRight;

      const words = line.split(' ');
      let lineCount = 0;
      let currentLine = '';
      for (const word of words) {
        const wordWidth = context.measureText(word).width;
        const lineWidth = context.measureText(currentLine).width;

        if (lineWidth + wordWidth > textareaWidth) {
          lineCount++;
          currentLine = word + ' ';
        } else {
          currentLine += word + ' ';
        }
      }

      if (currentLine.trim() !== '') {
        lineCount++;
      }

      return lineCount;
    },
    [textarea],
  );

  const lineNumbersContent = useMemo(() => {
    let lineNumber = 1;
    return text.split('\n').map((q, i) => {
      if (q.trim() === '') {
        return <div key={`${q}-${i}`}>&nbsp;</div>;
      }
      return (
        <div key={`${q}-${i}`}>
          {lineNumber++}
          {Array.from({ length: numberOfLines(q) - 1 }).map((_, j) => (
            <div key={`${q}-${i}-${j}`}>&nbsp;</div>
          ))}
        </div>
      );
    });
  }, [numberOfLines, text]);

  return (
    <>
      <div
        className={clsx(
          'flex h-[calc(100%-4rem)] bg-gray-100 text-gray-500',
          'rounded-lg border-2 border-dashed border-gray-300',
          'has-[:hover]:border-gray-400',
        )}
      >
        {isTyping ? (
          <div className='flex justify-start w-full bg-white rounded-lg pr-2'>
            <div
              className={clsx(
                'flex-none w-8 text-right p-2 rounded-l-lg text-sm',
                'border-r border-gray-300 bg-gray-100 leading-8',
                'text-gray-400 overflow-y-scroll',
              )}
              id='line-numbers'
              style={{ scrollbarWidth: 'none' }}
            >
              {lineNumbersContent}
            </div>
            <textarea
              aria-label='Questions textarea'
              className={clsx(
                'w-full flex-1 resize-none whitespace-nowrap leading-8 p-2',
                'rounded-r-lg border-none focus:border-none focus:ring-0',
                'text-lg text-wrap',
              )}
              id='questions-textarea'
              onBlur={() => {
                if (text === '') {
                  setIsTyping(false);
                }
              }}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              required
              value={text}
            />
          </div>
        ) : (
          <button
            aria-label='Type questions'
            className={clsx(
              'flex justify-center items-center rounded-lg',
              'w-full h-full bg-white',
            )}
            onClick={() => {
              setIsTyping(true);
            }}
          >
            <div className='text-green-base flex flex-col gap-2'>
              <PencilSquareIcon className='h-12' /> Type questions here
              <div className='text-gray-400 text-xs'>
                One (1) question per line
              </div>
            </div>
          </button>
        )}
      </div>{' '}
      <div className='flex-none p-2 text-xs text-green-base'>
        {text !== '' &&
          `${lines.length} question${lines.length !== 1 ? 's' : ''} to add`}
      </div>
    </>
  );
};
