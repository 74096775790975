import { configSlice } from 'features/config/configSlice';
import {
  cancelInspection as cancelInspectionInstance,
  createInspection,
  updateInspectionInstance,
} from 'features/inspections/inspectionActions';
import { inspectionEditSlice } from 'features/inspections/inspectionEditSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

import { useInternationalization } from './useInternationalization';
import useToast from './useToast';

export const useInspectionEdit = () => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const { setErrorMessage, setSuccessMessage } = useToast();
  const t = useInternationalization('inspections.edit');

  const createInspectionRequest = useSelector(
    inspectionEditSlice.selectors.createInspectionRequest,
  );
  const editInspectionInstanceRequest = useSelector(
    inspectionEditSlice.selectors.editInspectionInstanceRequest,
  );

  const createNewInspection = async () =>
    await dispatch(
      createInspection({
        baseUrl,
        inspection: createInspectionRequest,
        token,
      }),
    ).unwrap();

  const saveInspectionInstance = async () => {
    return dispatch(
      updateInspectionInstance({
        baseUrl,
        ...editInspectionInstanceRequest,
        token,
      }),
    )
      .then(() => {
        setSuccessMessage(t('success'));
      })
      .catch(() => {
        setErrorMessage(t('failure'));
      });
  };
  const cancelInspection = async () => {
    return dispatch(
      cancelInspectionInstance({
        baseUrl,
        dueDate: editInspectionInstanceRequest.dueDate,
        seriesId: editInspectionInstanceRequest.seriesId,
        token,
      }),
    )
      .then(() => {
        setSuccessMessage(t('cancel.success'));
      })
      .catch(() => {
        setErrorMessage(t('cancel.failure'));
      });
  };
  return {
    cancelInspection,
    createNewInspection,
    saveInspectionInstance,
  };
};
