import { inspectionSeriesEditSlice } from 'features/inspections/inspectionSeriesEditSlice';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC } from 'react';
import { useSelector } from 'react-redux';

export const Facility: FC = () => {
  const t = useInternationalization('inspections.edit.series.fields');

  const facility = useSelector(inspectionSeriesEditSlice.selectors.facility);

  return (
    <>
      <span>{t('facility')}</span>
      <span className='place-self-end'>{facility?.name}</span>
    </>
  );
};
