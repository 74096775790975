import {
  InspectionInstance,
  Status,
  Summary,
  TaskInstance,
} from '@dakota/platform-client';
import { LocalDate } from '@js-joda/core';
import { DatePickerRange } from 'components/DatePicker';
import { configSlice } from 'features/config/configSlice';
import { listInspectionInstances } from 'features/inspections/inspectionActions';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { listTasks } from 'features/tasks/tasksActions';
import { tasksSlice } from 'features/tasks/tasksSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import Fuse from 'fuse.js';
import { getItemAssignmentStatus } from 'Pages/Assignments/helper';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { AssignmentType, SearchStatus } from 'types';
import { compareAssignments } from 'utils/compare';

import { useDateRange } from './useDateRange';

type UseFilteredAssignmentsParams = {
  assigneeId: string;
  /**
   * Initial date range for the date picker.
   * @default range from today to 7 days from now
   */
  initialDateRange?: DatePickerRange;
  /**
   * The initial selected type
   * @default All
   */
  initialSelectedType?: AssignmentType;
  /**
   * If present, filter by text search
   * @default `undefined`
   */
  searchQuery?: string;
  /**
   * If present, filter by facility
   * @default `undefined`
   */
  selectedFacilities?: Summary[];
  /**
   * If present, filter by type
   * @default `undefined`
   */
  selectedType?: AssignmentType;
  /**
   * Statuses to show in the table. This object *must* be memoized in the
   * caller.
   */
  statuses: SearchStatus[];
} & (
  | {
      /**
       * When we use an array for selected statuses, we always default to "all
       * statuses", so we don't need an initial value.
       */
      initialSelectedStatus?: never;
      /**
       * If present, filter by the statuses in the array. If not, use all statuses.
       * @default `undefined`
       */
      selectedStatus?: SearchStatus[];
    }
  | {
      initialSelectedStatus: SearchStatus;
      /**
       * If present, filter by status
       * @default `undefined`
       */
      selectedStatus?: SearchStatus;
    }
);

export const useFilteredAssignments = ({
  assigneeId,
  initialDateRange = {
    begin: LocalDate.now(),
    end: LocalDate.now().plusDays(7),
  },
  initialSelectedStatus,
  initialSelectedType,
  searchQuery = undefined,
  selectedFacilities = undefined,
  selectedStatus = undefined,
  selectedType = undefined,
  statuses,
}: UseFilteredAssignmentsParams) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const allInspections = useSelector(inspectionSlice.selectors.inspections);
  const allTasks = useSelector(tasksSlice.selectors.tasks);

  type Assignment = InspectionInstance | TaskInstance;

  const [filteredAssignments, setFilteredAssignments] = useState<Assignment[]>(
    [],
  );
  const [hookDataLoaded, setHookDataLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { dateRange, resetDateRange, setDateRange } =
    useDateRange(initialDateRange);

  const hasFilters =
    !!searchQuery ||
    !dateRange.begin.equals(initialDateRange.begin) ||
    !dateRange.end.equals(initialDateRange.end) ||
    !!selectedFacilities?.length ||
    (Array.isArray(selectedStatus) && !!selectedStatus.length) ||
    (!Array.isArray(selectedStatus) &&
      selectedStatus !== initialSelectedStatus) ||
    selectedType !== initialSelectedType;

  // Determines if the inspection's scheduled date falls within the selected
  // date range
  const isInDateRange = useCallback(
    (date: string) => {
      const parsedDate = LocalDate.parse(date);
      return (
        !parsedDate.isBefore(dateRange.begin) &&
        !parsedDate.isAfter(dateRange.end)
      );
    },
    [dateRange],
  );

  const isAtFacility = useCallback(
    (item: Assignment) =>
      !selectedFacilities?.length ||
      selectedFacilities.some((facility) => facility.id === item.facility.id),
    [selectedFacilities],
  );

  // Determines if the item's status matches the selected status
  const matchesStatus = useCallback(
    (item: Assignment) => {
      if (!selectedStatus) {
        return true;
      }
      const itemStatus = getItemAssignmentStatus(item);

      if (Array.isArray(selectedStatus)) {
        return !selectedStatus.length || selectedStatus.includes(itemStatus);
      }

      return selectedStatus === itemStatus;
    },
    [selectedStatus],
  );

  // Filters tasks based on current criteria
  const filterElements = useCallback(
    (tasks: Assignment[], skipDateCheck = false) =>
      tasks.filter((element) => {
        const isOverdue = element.overdue;
        const isScheduled = element.status === Status.Scheduled;
        const isInProgress = element.status === Status.InProgress;

        // If Overdue status is selected and this task is overdue, show it
        // regardless of the date range
        if (
          Array.isArray(selectedStatus) &&
          selectedStatus.includes('Overdue') &&
          isOverdue
        ) {
          return true;
        }

        // Include tasks that are scheduled or in progress, even if overdue
        if (
          Array.isArray(selectedStatus) &&
          ((selectedStatus.includes(Status.Scheduled) && isScheduled) ||
            (selectedStatus.includes(Status.InProgress) && isInProgress))
        ) {
          return true;
        }

        return (
          matchesStatus(element) &&
          (skipDateCheck || isInDateRange(element.timeline.dueDate)) &&
          isAtFacility(element)
        );
      }),
    [isAtFacility, isInDateRange, matchesStatus, selectedStatus],
  );

  useEffect(() => {
    const filteredStatuses = statuses.filter((s) => s !== 'Overdue');

    const fetchData = async () => {
      setIsLoading(true);
      try {
        await Promise.all([
          dispatch(
            listInspectionInstances({
              assigneeId,
              baseUrl,
              dueDate_begin: dateRange.begin.toString(),
              dueDate_end: dateRange.end.toString(),
              includeAllOverdue: true,
              statuses: filteredStatuses,
              token,
            }),
          ).unwrap(),
          dispatch(
            listTasks({
              assigneeId,
              baseUrl,
              dueDate_begin: dateRange.begin.toString(),
              dueDate_end: dateRange.end.toString(),
              includeAllOverdue: true,
              statuses: filteredStatuses,
              token,
            }),
          ).unwrap(),
        ]);
        setHookDataLoaded(true);
      } catch {
        setFilteredAssignments([]);
      } finally {
        setIsLoading(false);
      }
    };

    void fetchData();
  }, [dispatch, baseUrl, token, dateRange, assigneeId, statuses]);

  useEffect(() => {
    // Let's filter by type first
    let byType: Assignment[] = [];
    if (selectedType === AssignmentType.Inspection) {
      byType = allInspections;
    } else if (selectedType === AssignmentType.Task) {
      byType = allTasks;
    } else {
      byType = [...allInspections, ...allTasks];
    }

    // Get all overdue and in progress elements regardless of date range
    const overdueAndInProgress = filterElements(
      byType.filter((e) => e.overdue || e.status === Status.InProgress),
      true,
    );

    // Filter all elements with the complete criteria
    const filtered = filterElements(byType);

    // Combine overdue and in progress elements with filtered elements
    let combined = [
      ...overdueAndInProgress,
      ...filtered.filter((e) => !e.overdue && e.status !== Status.InProgress),
    ].toSorted(compareAssignments);

    if (searchQuery) {
      const fuse = new Fuse(combined, {
        findAllMatches: true,
        ignoreLocation: true,
        keys: [
          { name: 'title', weight: 1 }, // For Tasks
          { name: 'form.name', weight: 1 }, // For Inspections
          { name: 'facility.name', weight: 0.8 },
          { name: 'status', weight: 0.8 },
        ],
        shouldSort: true,
        threshold: 0.2,
        useExtendedSearch: true,
      });

      combined = fuse.search(searchQuery).map((result) => result.item);
    }

    setFilteredAssignments(combined);
  }, [allInspections, allTasks, filterElements, searchQuery, selectedType]);

  return {
    dateRange,
    filteredAssignments,
    hasFilters,
    hookDataLoaded,
    isLoading,
    resetDateRange,
    setDateRange,
  };
};
