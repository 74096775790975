import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { useInspectionLoad } from 'hooks/useInspectionLoad';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Dispatch } from './Dispatch';
import { InspectionLoadingAnimation } from './InspectionLoadingAnimation';

type Props = {
  /**
   * The source where the user is redirected after closing the dialog,
   * completing the inspection or canceling the inspection.
   *
   * If the value is `'email'` or `null`, the user is redirected to the
   * home page, which displays the dashboard.
   *
   * @default `null`
   */
  source?: null | string;
};

/**
 * This component just loads the inspection instance details object into the
 * state. That object is then passed to the dispatcher, which is the component
 * where the user permissions and the inspection status checks happen.
 *
 * TODO: Remove the old loader file once the implementation here is complete.
 */
export const Loader: FC<Props> = ({ source = null }) => {
  const { dueDate, seriesId } = useParams() as {
    dueDate: string;
    seriesId: string;
  };

  const t = useInternationalization('inspections.loader');
  const navigate = useNavigate();

  const inspection = useSelector(inspectionSlice.selectors.inspectionDetails);

  const { load } = useInspectionLoad();

  const navigateAway = useCallback(() => {
    navigate(source === null || source === 'email' ? '/' : source);
  }, [navigate, source]);

  useEffect(() => {
    if (!inspection) {
      // TODO: display error message if inspection fails to load
      load(seriesId, dueDate).catch(() => {
        navigate('/assignments');
      });
    }
  }, [dueDate, inspection, load, navigate, seriesId]);

  if (!inspection) {
    return <InspectionLoadingAnimation text={t('loading')} />;
  }

  return <Dispatch inspection={inspection} onClose={navigateAway} />;
};
