import { CheckIcon } from '@heroicons/react/16/solid';
import { clsx } from 'clsx';
import { FC } from 'react';

import { Step, WorkflowState } from './types';

type Props = {
  steps: Step[];
  undoIndex: (index: number) => void;
  workflowState: WorkflowState;
};

/**
 * This component just renders the step number and name.
 */
const Header: FC<Props> = ({ steps, undoIndex, workflowState }) => {
  const stepTextColor = (step: Step) => {
    switch (step.status) {
      case 'active':
        return 'text-green-base';
      case 'completed':
        return 'text-gray-800';
    }
  };

  const stepBorderColor = (step: Step) => {
    switch (step.status) {
      case 'active':
        return 'border-green-light';
      case 'completed':
        return 'border-green-base';
    }
  };

  const stepBgColor = (step: Step) => {
    return step.status === 'completed' ? 'bg-green-base' : '';
  };

  return (
    <div
      className={clsx(
        'flex justify-center items-center',
        'gap-12 h-16 text-sm',
      )}
      data-testid='list-of-steps'
    >
      {steps.map((step) => (
        <div
          className={clsx(
            'inline-flex items-center gap-2',
            stepTextColor(step),
          )}
          key={step.number}
        >
          <div
            className={clsx(
              'w-10 h-10 inline-flex justify-center items-center',
              `border-solid ${stepBorderColor(step)} ${stepBgColor(step)}`,
              'border-2 rounded-full font-bold',
            )}
          >
            {step.status === 'completed' ? (
              <button
                aria-label={step.number.toString()}
                className='text-white'
                id={`undo-step-${step.number}`}
                onClick={() => {
                  undoIndex(step.number - 1);
                }}
              >
                <CheckIcon className='w-5 h-5' />
              </button>
            ) : (
              <span className='text-green-base'>{step.number}</span>
            )}
          </div>
          <button
            className='max-w-48 hover:text-green-darker cursor-pointer'
            onClick={() => {
              undoIndex(step.number - 1);
            }}
          >
            {step.nameInStepper(workflowState, step.status)}
          </button>
        </div>
      ))}
    </div>
  );
};

export default Header;
