import { InspectionSection } from '@dakota/platform-client';
import { FC } from 'react';

import { Section } from './Section';

type SectionsProps = {
  dueDate: string;
  /**
   * The sections currently on display.
   * Corresponds to the sections of an unzoned inspection,
   * and the sections for the current zone in a zoned inspection.
   */
  sections: InspectionSection[];
  seriesId: string;
};

export const Sections: FC<SectionsProps> = ({
  dueDate,
  sections,
  seriesId,
}) => {
  return sections.map((section, sectionIndex) => (
    <Section
      dueDate={dueDate}
      key={section.sectionNumber}
      section={section}
      sectionIndex={sectionIndex}
      seriesId={seriesId}
    />
  ));
};
