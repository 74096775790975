import { clsx } from 'clsx';
import { Key } from 'react';
import { noOp } from 'utils/functional';

import Chip from '.';

type Props<T> = {
  /**
   * Classes to apply to the outer container.
   * @default ''
   */
  containerClassName?: string;
  /**
   * Elements to display using chips. When empty, nothing is rendered.
   */
  elements: readonly T[];
  /**
   * Key extractor for the elements, for traversing the collection.
   * @default `(e) => e as Key`
   */
  getKey: (element: T) => Key;
  /**
   * Extractor for the label to display for each element.
   */
  getLabel: (element: T) => string;
  /**
   * Function to call when the `X` button is clicked on an element's chip.
   * @default `noOp`
   */
  onRemove?: (element: T) => void;
  /**
   * Title to display before the chips.
   * If present, it will be followed by a colon. If not, it will be omitted.
   * @default ''
   */
  title?: string;
};

function Chips<T>({
  containerClassName = '',
  elements,
  getKey = (e) => e as Key,
  getLabel,
  onRemove = noOp,
  title = '',
}: Props<T>) {
  return elements.length > 0 ? (
    <div
      className={clsx(
        'flex flex-wrap items-start gap-2 pb-2',
        containerClassName,
      )}
      data-testid='chips-container'
    >
      {title && <span className='text-sm font-normal pr-1'>{title}:</span>}
      {elements.map((e) => (
        <Chip
          key={getKey(e)}
          onRemove={() => {
            onRemove(e);
          }}
          text={getLabel(e)}
        />
      ))}
    </div>
  ) : (
    <></>
  );
}

export default Chips;
