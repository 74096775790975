import Badge from '@mui/material/Badge';
import { clsx } from 'clsx';
import { ComponentProps, FC, ReactNode } from 'react';

type BadgeButtonProps = {
  icon: ReactNode;
  value?: number;
} & ComponentProps<'button'>;

/** Intentionally made to render nothing if value is zero. */
export const BadgeButton: FC<BadgeButtonProps> = ({
  className,
  icon,
  value = 0,
  ...props
}) => {
  if (value === 0) {
    return null;
  }

  return (
    <Badge
      badgeContent={value}
      slotProps={{ badge: { style: { zIndex: 0 } } }}
      sx={{
        '& .MuiBadge-standard': {
          backgroundColor: 'var(--color-green-base)',
          color: 'var(--color-white)',
        },
        mt: 1,
      }}
    >
      <button
        {...props}
        className={clsx(
          'size-10 border rounded-md border-gray-300 text-green-base',
          'hover:bg-gray-200 hover:text-green-dark',
          'inline-flex items-center justify-center [&>svg]:size-5',
          className,
        )}
      >
        {icon}
      </button>
    </Badge>
  );
};
