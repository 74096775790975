import { InspectionNotification, Status } from '@dakota/platform-client';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useDateFormat } from 'hooks/useDateFormat';
import { useInspectionLoad } from 'hooks/useInspectionLoad';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC, ReactNode } from 'react';

import { NotificationCard } from './NotificationCard';

type Props = {
  closePanel: () => void;
  notification: InspectionNotification;
};

export const InspectionNotificationDisplay: FC<Props> = ({
  closePanel,
  notification,
}) => {
  const t = useInternationalization('notifications.inspection');
  const { formatBackendDate } = useDateFormat();

  const { navigateTo } = useInspectionLoad();

  const alert = (text: ReactNode) => (
    <span className='text-red-base font-medium'>{text}</span>
  );

  const message = notification.isOverdue
    ? t.formatMessage('overdue', {
        alert,
        dueDate: formatBackendDate(notification.dueDate),
      })
    : t('dueToday');

  const ctaText =
    notification.status === Status.InProgress ? t('resume') : t('start');

  return (
    <NotificationCard
      ctaAction={() => {
        navigateTo(notification.seriesId, notification.dueDate);
        closePanel();
      }}
      ctaText={ctaText}
      icon={<MagnifyingGlassIcon />}
      message={message}
      ticketId={notification.ticketId}
      title={notification.name}
    />
  );
};
