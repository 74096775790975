import { LocalDate } from '@js-joda/core';
import { DatePicker } from 'components/DatePicker';
import { inspectionEditSlice } from 'features/inspections/inspectionEditSlice';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export const DueDate: FC = () => {
  const dispatch = useAppDispatch();

  const date = useSelector(inspectionEditSlice.selectors.date);

  const t = useInternationalization('inspections.schedule.label');

  return (
    <>
      <label
        className='required-field flex items-center'
        htmlFor='inspection-datepicker-input'
      >
        {t('dueDate')}
      </label>
      <div className='place-self-end'>
        <DatePicker
          asSingle
          id='inspection-datepicker'
          onChange={(newDate) =>
            dispatch(
              inspectionEditSlice.actions.setInspectionField({
                field: 'date',
                value: newDate.toString(),
              }),
            )
          }
          value={LocalDate.parse(date)}
        />
      </div>
    </>
  );
};
