import {
  Facility,
  Status,
  Summary,
  UserSummary,
} from '@dakota/platform-client';
import { PlusIcon } from '@heroicons/react/24/outline';
import Autocomplete from 'components/Autocomplete';
import Chips from 'components/Chip/Chips';
import { ClearAllButton } from 'components/ClearAll';
import { CreateItemButton } from 'components/CreateItemButton';
import { DatePicker } from 'components/DatePicker';
import InspectionsTable from 'components/Inspections/InspectionsTable';
import { PageHeader } from 'components/PageHeader';
import SearchInput from 'components/SearchInput';
import { facilitiesSlice } from 'features/facilities/facilitiesSlice';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { useEnumLabel } from 'hooks/useEnumLabel';
import { useFilteredInspections } from 'hooks/useFilteredInspections';
import { useInspectionSidePanelLoader } from 'hooks/useInspectionSidePanelLoader';
import { useInternationalization } from 'hooks/useInternationalization';
import { DataStatus, usePageLoadTracking } from 'hooks/usePageLoadTracking';
import { useUsers } from 'hooks/useUsers';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { SearchStatus } from 'types';
import { Permission } from 'utils/permissions';

const ScheduledInspections = () => {
  const t = useInternationalization();
  const isLoadingInspections = useSelector(
    inspectionSlice.selectors.isLoadingInspections,
  );
  const activeFacilities = useSelector(
    facilitiesSlice.selectors.activeFacilities,
  );

  const { activeUsersAndUnassigned } = useUsers();
  const { getStatusLabel, Label } = useEnumLabel();

  const [selectedStatus, setSelectedStatus] = useState<Status[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFacilities, setSelectedFacilities] = useState<Facility[]>([]);
  const [selectedAssignees, setSelectedAssignees] = useState<UserSummary[]>([]);

  const { stopTracking } = usePageLoadTracking();

  const statuses = useMemo(
    () => [Status.InProgress, 'Overdue', Status.Scheduled] as SearchStatus[],
    [],
  );

  const {
    dateRange,
    filteredInspections,
    hasFilters,
    hookDataLoaded,
    resetDateRange,
    setDateRange,
  } = useFilteredInspections({
    filterByEndDate: false,
    searchQuery,
    selectedAssignees,
    selectedFacilities,
    selectedStatus,
    statuses,
  });

  useEffect(() => {
    if (hookDataLoaded) {
      stopTracking(DataStatus.Fetched);
    }
  }, [hookDataLoaded, stopTracking]);

  const resetFilters = () => {
    setSelectedFacilities([]);
    setSelectedStatus([]);
    setSelectedAssignees([]);
    setSearchQuery('');
    resetDateRange();
  };

  const removeFacility = (facility: Summary) => {
    setSelectedFacilities(selectedFacilities.filter((f) => f !== facility));
  };

  const removeAssignee = (assignee: UserSummary) => {
    setSelectedAssignees(selectedAssignees.filter((a) => a !== assignee));
  };

  const { inspectionViewerComponent } = useInspectionSidePanelLoader();

  return (
    <div className='p-4 sm:p-8'>
      <PageHeader scaffold={['Scheduled', 'Inspections']} title='Scheduled'>
        <CreateItemButton
          buttonText={t('charts.schedule.inspection')}
          icon={<PlusIcon />}
          link='/manage/inspection-templates'
          permission={Permission.CreateInspection}
        />
      </PageHeader>
      <div className='sm:flex sm:justify-between sm:items-center sm:gap-2'>
        <div className='filters-container'>
          <SearchInput
            data-testid='search-input'
            id='scheduled-inspections-search-input'
            onSearch={setSearchQuery}
            value={searchQuery}
          />
          <DatePicker
            asSingle={false}
            id='scheduled-inspections-date-picker'
            onChange={setDateRange}
            scrollOnFocus={false}
            shortcutsType='next'
            showShortcuts
            value={dateRange}
          />
          <Autocomplete
            className='w-full sm:w-60'
            getOptionKey={(facility) => facility.id}
            getOptionLabel={(facility) => facility.name}
            id='scheduled-inspections-facility-selector'
            label={Label.Facilities}
            multiple
            onChange={setSelectedFacilities}
            options={activeFacilities}
            value={selectedFacilities}
          />
          <Autocomplete
            className='w-full sm:w-40'
            getOptionKey={(user) => user.id}
            getOptionLabel={(user) => user.name}
            id='scheduled-inspections-assignee-selector'
            label={Label.Assignee}
            multiple
            onChange={setSelectedAssignees}
            options={activeUsersAndUnassigned}
            value={selectedAssignees}
          />
          <Autocomplete
            className='w-full sm:w-32'
            getOptionLabel={getStatusLabel}
            id='scheduled-inspections-status-selector'
            label={Label.ProgressStatus}
            multiple
            onChange={setSelectedStatus}
            options={statuses}
            value={selectedStatus}
          />
          {hasFilters && <ClearAllButton onClick={resetFilters} />}
        </div>
      </div>
      {selectedFacilities.length > 0 && (
        <Chips
          elements={selectedFacilities}
          getKey={(f) => f.id}
          getLabel={(f) => f.name}
          onRemove={removeFacility}
          title={t('inspections.chips.label.facilities')}
        />
      )}
      {selectedAssignees.length > 0 && (
        <Chips
          elements={selectedAssignees}
          getKey={(a) => a.id}
          getLabel={(a) => a.name}
          onRemove={removeAssignee}
          title={t('inspections.chips.label.assignees')}
        />
      )}
      <InspectionsTable
        data={filteredInspections}
        loading={isLoadingInspections}
        searchQuery={searchQuery}
      />
      {inspectionViewerComponent}
    </div>
  );
};

export default ScheduledInspections;
