import { BellIcon } from '@heroicons/react/24/outline';
import { Badge, IconButton, Menu, styled } from '@mui/material';
import { useCacheBuster } from 'cacheBuster';
import { notificationsSlice } from 'features/notifications/notificationsSlice';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { NotificationsPanel } from '../Notifications';

export const AppNotifications: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const notifications = useSelector(notificationsSlice.selectors.notifications);

  const { initialized, versions } = useCacheBuster();
  const hasNewVersion = initialized && !!versions.current && !versions.isLatest;
  if (hasNewVersion) {
    // TODO: send toast notification
  }

  /**
   * Modified from an example in MUI documentation to have
   * the badge icon exactly on the border of the avatar circle.
   */
  const StyledBadge = styled(Badge)(() => ({
    '@keyframes ripple': {
      '0%': {
        border: '1px solid var(--color-red-base)',
        opacity: 1,
        transform: 'scale(.8)',
      },
      '100%': {
        border: '1px solid var(--color-red-light)',
        opacity: 0,
        transform: 'scale(2.4)',
      },
    },
    '& .MuiBadge-badge': {
      '&::after': {
        animation: 'ripple 1.2s 1 ease-in-out',
        borderRadius: '50%',
        content: '""',
        height: '100%',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
      },
      backgroundColor: 'var(--color-red-base)',
      color: 'var(--color-white)',
    },
  }));

  return (
    <>
      <IconButton
        aria-controls={anchorEl ? 'notifications-panel' : undefined}
        aria-expanded={anchorEl ? 'true' : undefined}
        aria-haspopup='true'
        aria-label='Notifications'
        id='notifications-button'
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <StyledBadge badgeContent={notifications.length}>
          <BellIcon className='w-7 h-7' />
        </StyledBadge>
      </IconButton>
      {anchorEl && (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          aria-labelledby='notifications-button'
          id='notifications-panel'
          onClose={() => {
            setAnchorEl(null);
          }}
          open
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          <NotificationsPanel
            notifications={notifications}
            onClose={() => {
              setAnchorEl(null);
            }}
          />
        </Menu>
      )}
    </>
  );
};
