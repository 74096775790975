import { XMarkIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import { FC } from 'react';

type Props = {
  onClose: () => void;
  title: string;
};

export const Header: FC<Props> = ({ onClose, title }) => {
  return (
    <div
      className={clsx(
        'flex-none flex justify-between items-center',
        'h-16 bg-green-base text-white',
      )}
    >
      <div
        className='pl-4 text-xl font-semibold truncate'
        id='edit-series-title'
      >
        {title}
      </div>
      <div className='pr-4'>
        <button
          aria-label='Close'
          className='flex items-center justify-center h-full'
          id='close-edit-series'
          onClick={onClose}
        >
          <XMarkIcon className='h-6 w-6' />
        </button>
      </div>
    </div>
  );
};
