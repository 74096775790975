import { Prompt as InspectionPrompt } from '@dakota/platform-client';
import { ChatBubbleLeftIcon, PaperClipIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import { BadgeButton } from 'components/BadgeButton';
import { Carousel, IMedia } from 'components/Carousel';
import { configSlice } from 'features/config/configSlice';
import { flagsSlice } from 'features/flags/flagsSlice';
import {
  deleteAttachment,
  updateAttachment,
} from 'features/inspections/inspectionActions';
import { tokenSlice } from 'features/token/tokenSlice';
import { userSlice } from 'features/user/userSlice';
import { useCheckPermission } from 'hooks/useCheckPermission';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { FeatureFlag } from 'utils/featureFlags';
import { Permission } from 'utils/permissions';

import { AddNote } from './AddNote';
import { Answers } from './Answers';
import { Attachments } from './Attachments';
import { AttachmentsSidePanel } from './AttachmentsSidePanel';
import { Notes } from './Notes';
import { PromptMenu } from './PromptMenu';

type PromptProps = {
  dueDate: string;
  prompt: InspectionPrompt;
  /**
   * The index of the prompt in the section.
   * This is strictly to facilitate the state update when a user
   * answers the question.
   */
  promptIndex: number;
  /**
   * The index of the section in the conduct inspection state.
   * This is strictly to facilitate the state update when a user
   * answers the question.
   */
  sectionIndex: number;
  seriesId: string;
};

export const Prompt: FC<PromptProps> = ({
  dueDate,
  prompt,
  promptIndex,
  sectionIndex,
  seriesId,
}) => {
  const t = useInternationalization('inspections.prompts');
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const currentUser = useSelector(userSlice.selectors.currentUser);

  const isFlagEnabled = useSelector(flagsSlice.selectors.isFlagEnabled);
  const useTaskLinking = isFlagEnabled(FeatureFlag.TaskLinking);

  const [isNotePanelOpen, setIsNotePanelOpen] = useState(false);
  const [isAttachmentPanelOpen, setIsAttachmentPanelOpen] = useState(false);

  const isAdmin = useCheckPermission(Permission.Admin);
  const isPeopleManager = useCheckPermission(Permission.ManageUsers);

  const canUpdate = useCallback(
    (id: string) => {
      const attachment = prompt.media.find((m) => m.id === id);

      return (
        isAdmin || isPeopleManager || currentUser.id === attachment?.userId
      );
    },
    [prompt.media, isAdmin, isPeopleManager, currentUser.id],
  );

  const [carouselFileIndex, setCarouselFileIndex] = useState(-1);

  /** Only show files that are not a security risk in the carousel. */
  const carouselFiles = prompt.media.filter(
    (media) => !media.securityRisk && !!media.filePath,
  ) as IMedia[];

  const onEditDescription = (attachmentId: string, description: string) =>
    dispatch(
      updateAttachment({
        attachmentId,
        attachmentIndex: carouselFiles.findIndex((f) => f.id === attachmentId),
        baseUrl,
        description,
        dueDate,
        promptId: prompt.id,
        promptIndex,
        sectionIndex,
        seriesId,
        token,
      }),
    ).unwrap();

  const onDeleteAttachment = async (attachmentId: string) =>
    dispatch(
      deleteAttachment({
        attachmentId,
        attachmentIndex: carouselFiles.findIndex((f) => f.id === attachmentId),
        baseUrl,
        dueDate,
        promptId: prompt.id,
        promptIndex,
        sectionIndex,
        seriesId,
        token,
      }),
    ).unwrap();

  return (
    <div className='flex flex-col p-6 border-t border-gray-300' key={prompt.id}>
      <div className='flex flex-col sm:flex-row items-start sm:items-center gap-4 relative'>
        <div className='flex-1'>
          <p className='font-semibold text-gray-800 text-sm leading-5 whitespace-pre-wrap'>
            {prompt.question.text}
          </p>
          <div className='text-sm text-gray-500'>
            {prompt.question.citation}
          </div>
        </div>
        <div
          className={clsx(
            'flex items-center gap-1',
            'max-sm:justify-between max-sm:w-full',
          )}
        >
          <Answers {...{ prompt, promptIndex, sectionIndex }} />
          <PromptMenu
            openAttachmentPanel={() => {
              setIsAttachmentPanelOpen(true);
            }}
            openNotePanel={() => {
              setIsNotePanelOpen(true);
            }}
          />
        </div>
      </div>
      {useTaskLinking ? (
        <div className='flex items-center gap-3'>
          <BadgeButton
            aria-label={t('label.openNotes')}
            icon={<ChatBubbleLeftIcon />}
            onClick={() => {
              setIsNotePanelOpen(true);
            }}
            value={prompt.comments.length}
          />
          <BadgeButton
            aria-label={t('label.openAttachments')}
            icon={<PaperClipIcon />}
            onClick={() => {
              setIsAttachmentPanelOpen(true);
            }}
            value={prompt.media.length}
          />
        </div>
      ) : (
        <>
          <Attachments prompt={prompt} showInCarousel={setCarouselFileIndex} />
          <Notes {...{ prompt, promptIndex, sectionIndex }} />
        </>
      )}
      {carouselFileIndex > -1 && carouselFiles.length > 0 && (
        <Carousel
          canDelete={canUpdate}
          canEditDescription={canUpdate}
          initialFileIndex={carouselFileIndex}
          media={carouselFiles}
          onClose={() => {
            setCarouselFileIndex(-1);
          }}
          onDelete={onDeleteAttachment}
          onEditDescription={onEditDescription}
        />
      )}
      {isNotePanelOpen && (
        <AddNote
          onClose={() => {
            setIsNotePanelOpen(false);
          }}
          prompt={prompt}
          promptId={prompt.id}
          promptIndex={promptIndex}
          question={prompt.question}
          sectionIndex={sectionIndex}
        />
      )}
      {isAttachmentPanelOpen && (
        <AttachmentsSidePanel
          onClose={() => {
            setIsAttachmentPanelOpen(false);
          }}
          prompt={prompt}
          promptIndex={promptIndex}
          sectionIndex={sectionIndex}
          showInCarousel={setCarouselFileIndex}
        />
      )}
    </div>
  );
};
