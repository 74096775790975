import {
  ArrowDownIcon,
  EllipsisVerticalIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Popover } from '@mui/material';
import { FC, PropsWithChildren, useState } from 'react';

type DropdownMenuProps = {
  buttonAriaLabel?: string;
  buttonTestId?: string;
  contentTestId?: string;
};

export const DropdownMenu: FC<PropsWithChildren<DropdownMenuProps>> = ({
  buttonAriaLabel,
  buttonTestId,
  children,
  contentTestId,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = (
    <div>
      <div className='max-sm:hidden'>
        <EllipsisVerticalIcon />
      </div>
      <div className='sm:hidden inline-flex items-center gap-1'>
        Actions
        <ArrowDownIcon className='w-4 h-4' />
      </div>
    </div>
  );

  const close = (
    <div>
      <div className='max-sm:hidden'>
        <XMarkIcon />
      </div>
      <div className='sm:hidden inline-flex items-center gap-1'>
        Close
        <XMarkIcon className='w-4 h-4' />
      </div>
    </div>
  );

  return (
    <button
      aria-label={buttonAriaLabel}
      className='sm:w-6 max-sm:min-w-6 h-6 text-green-base'
      data-testid={buttonTestId}
      onClick={(e) => {
        setAnchorEl(anchorEl ? null : e.currentTarget);
      }}
    >
      {anchorEl ? close : open}
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={() => {
          setAnchorEl(null);
        }}
        open={!!anchorEl}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <div data-testid={contentTestId}>{children}</div>
      </Popover>
    </button>
  );
};
