const borderColor = 'var(--color-gray-300)';
const textColor = 'var(--color-gray-700)';

export const autocompleteStyles = {
  '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    borderColor,
  },
  '& .Mui-disabled': {
    '.MuiOutlinedInput-notchedOutline': {
      borderColor,
    },
    backgroundColor: 'var(--color-gray-200)',
    cursor: 'not-allowed',
  },
  '& .Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'var(--color-blue-base)',
    },
    '& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline':
      {
        borderColor: 'var(--color-blue-base) !important',
      },
  },
  '& .Mui-required': {
    position: 'absolute',
  },
  '& .MuiAutocomplete-input': {
    '&::placeholder': {
      color: textColor,
    },
    color: textColor,
  },
  '& .MuiAutocomplete-inputFocused': {
    boxShadow: 'none',
    paddingLeft: '4px !important',
  },
  '& .MuiAutocomplete-inputRoot': {
    flexWrap: 'nowrap',
    fontFamily: 'var(--font-sans)',
    fontSize: '14px',
    lineHeight: '20px',
  },
  '& .MuiAutocomplete-listbox': {
    color: textColor,
  },
  '& .MuiFormLabel-root': {
    '&.Mui-focused': {
      color: 'var(--color-green-base)',
    },
    color: 'var(--color-gray-300)',
    fontFamily: 'var(--font-sans)',
    fontSize: 'var(--text-sm)',
    fontWeight: 'var(--font-weight-normal)',
    lineHeight: 'var(--leading-6)',
  },
  '& .MuiInputBase-root': {
    borderRadius: 'var(--radius-md)',
    height: 'var(--spacing-10)',
    paddingY: 'auto',
  },
  '& .MuiInputLabel-asterisk': {
    color: 'var(--color-red-base)',
  },
  '& .MuiInputLabel-shrink': {
    backgroundColor: 'var(--color-white)',
    color: 'var(--color-gray-400)',
    fontFamily: 'var(--font-sans)',
    fontSize: '16px',
    fontWeight: 'var(--font-weight-normal)',
    lineHeight: '16px',
    paddingRight: '4px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor,
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 'var(--radius-md)',
    fontWeight: 'var(--font-weight-normal)',
  },
  '& + .MuiAutocomplete-popper': {
    '.MuiAutocomplete-option': {
      backgroundColor: 'var(--color-white)',
    },
    '.MuiAutocomplete-option:hover': {
      backgroundColor: 'var(--color-green-base)',
    },
    backgroundColor: 'var(--color-white)',
    borderColor,
    borderRadius: 'var(--radius-md)',
    borderWidth: '1px',
    boxShadow: 'var(--shadow-md)',
    marginY: '4px !important',
  },
  '& + .MuiAutocomplete-popper .MuiAutocomplete-paper': {
    borderRadius: 'var(--radius-md)',
    boxShadow: 'none',
  },
};
