import { FC, useEffect } from 'react';

/**
 * We need to have this logic as a child of the `Dialog` component
 * from HeadlessUI, so that when the user presses Escape,
 * the event handler here catches it first and prevents propagation.
 */
const EscapeEventHandler: FC = () => {
  useEffect(() => {
    function handleEscape(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        // Prevent the listener from HeadlessUI's Dialog from closing it
        e.stopImmediatePropagation();
      }
    }

    window.addEventListener('keydown', handleEscape);

    return () => {
      window.removeEventListener('keydown', handleEscape);
    };
  }, []);

  return <></>;
};

export default EscapeEventHandler;
