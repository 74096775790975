import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import {
  ApplicationInsights,
  ICfgSyncConfig,
  IConfig,
  IConfiguration,
} from '@microsoft/applicationinsights-web';
import { AppInfo, TelemetryConfig } from 'features/config/types';

import { addUserTelemetry, createAddAppTelemetry } from './inititalizers';
import { UserTracker } from './userTracker';
import { Identity } from './util';

// Parameters must not contain spaces or ,;=|
// https://github.com/microsoft/ApplicationInsights-JS/blob/master/API-reference.md#setauthenticatedusercontext
const cleanName = (n?: string) => n?.replace(/[,;=|]|\s/g, '') ?? '';

const ensureProtocol = (url?: string) =>
  url && !/^https?:\/\//i.exec(url) ? `https://${url}` : url;

function getCorrelationSet(...values: (string | undefined)[]) {
  const result = new Set(
    values
      .map(ensureProtocol)
      .map(tryGetHost)
      .filter((c) => typeof c === 'string')
      .map((c) => decodeURI(c).toLowerCase()),
  );
  return result.size == 0 ? undefined : [...result];
}

function getStorageNameModifier(...values: string[]) {
  // finds the first value in the input that is not empty/null after trimming
  // leading/trailing whitespace and underscores a special-case value of 'none'
  // is recognized as a short-circuit since blank/null would move on to the next
  // item, we can specify options: { storageNameModifier:
  // 'none' } and force no-prefix (instead of falling back to env name)
  for (const val of values) {
    const temp = val.replace(/(^[_\s]{1,3})|([_\s]{1,3}$)/g, '').toLowerCase();
    if (temp) {
      return temp === 'none' ? undefined : temp;
    }
  }
  return undefined;
}

function tryGetHost(url?: string) {
  try {
    return url ? new URL(url).host : null;
  } catch {
    return null;
  }
}

// cannot partially initialize configuration AND a connection string
// must lazily instantiate the whole entire instance else it won't work
let appInsights: ApplicationInsights | undefined;
const reactPlugin = new ReactPlugin();

export function loadAppInsights(aiConfig: TelemetryConfig, appInfo: AppInfo) {
  if (appInsights) {
    return;
  }

  const connString = aiConfig.connectionString;
  const nameAdjust = getStorageNameModifier(appInfo.environment);
  const cookieSuffix = nameAdjust ? `_${nameAdjust}` : undefined;
  const endpointUrl = ensureProtocol(aiConfig.endpointUrl);

  // for explanation of all configuration settings, see:
  // https://github.com/microsoft/ApplicationInsights-JS/blob/master/README.md
  const config: IConfig & IConfiguration = {
    autoTrackPageVisitTime: true,
    connectionString: connString,
    cookieCfg: {
      domain: aiConfig.cookieDomain,
      enabled: !aiConfig.disableCookies,
    },
    correlationHeaderDomains: getCorrelationSet(
      aiConfig.endpointUrl,
      aiConfig.configUrl,
      ...(aiConfig.correlationHeaderDomains || []),
    ),
    correlationHeaderExcludedDomains: getCorrelationSet(
      '*.auth0.com',
      ...(aiConfig.correlationHeaderExcludes || []),
    ),
    disableInstrumentationKeyValidation: true,
    disableTelemetry: !aiConfig.enabled || !connString,
    // prefer beacon
    disableXhr: true,
    enableAjaxErrorStatusText: true,
    // we will track route changes ourselves
    enableAutoRouteTracking: false,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    endpointUrl: endpointUrl,
    excludeRequestFromAutoTrackingPatterns: [
      /config\.json$/,
      /\/users\/test-access$/,
      /guides\.app\.dakotasoft\.com/,
    ],
    extensionConfig: {
      AppInsightsCfgSyncPlugin: {
        cfgUrl: ensureProtocol(aiConfig.configUrl),
      } as ICfgSyncConfig,
    },
    extensions: [reactPlugin],
    // prefer beacon
    isBeaconApiDisabled: false,
    // unlimited for long lived page
    maxAjaxCallsPerView: -1,
    // cookies/session
    namePrefix: nameAdjust,
    sessionCookiePostfix: cookieSuffix,
    userCookiePostfix: cookieSuffix,
    // must be set if we are passing via connection string
    userOverrideEndpointUrl: endpointUrl,
  };

  // only done as a fallback (basically during tests)
  // to prevent from throwing/crashing. temporarily required
  // until the SDK removes this property completely
  if (!connString) {
    config.instrumentationKey = '';
  }

  appInsights = new ApplicationInsights({ config });
  appInsights.addTelemetryInitializer(createAddAppTelemetry(appInfo));
  appInsights.addTelemetryInitializer(addUserTelemetry);

  appInsights.loadAppInsights();

  // must be done AFTER initialization
  if (appInfo.version) {
    appInsights.context.application.ver = appInfo.version;
  }

  UserTracker.registerEvents({
    onClear: () => appInsights?.clearAuthenticatedUserContext(),
    onSet: (id: Identity) =>
      appInsights?.setAuthenticatedUserContext(
        cleanName(id.userName),
        cleanName(id.orgId),
        false /* store in cookie */,
      ),
  });
}

function isTelemetryEnabled() {
  return (
    appInsights &&
    appInsights.core.isInitialized?.() &&
    !appInsights.config.disableTelemetry
  );
}

export { reactPlugin as appInsights, isTelemetryEnabled };
