import Button from 'components/Button';
import { MultilineInput } from 'components/MultilineInput';
import { useAppConfiguration } from 'hooks/useAppConfiguration';
import { FC, useEffect, useState } from 'react';

type Props =
  | {
      /**
       * If onSave and onCancel are present, the component will show the save and cancel buttons
       */
      onCancel: () => void;
      onSave: (note: string) => Promise<unknown>;
      onTextChange?: never;
    }
  | {
      onCancel?: never;
      onSave?: never;
      /**
       * If onTextChange is present, the component will not show the save and cancel buttons
       */
      onTextChange: (note: string) => void;
    };

/**
 * Allows the creation of a new note. It is not tied to Inspections or Tasks,
 * so it can be used in both places. When the user clicks the save button, the
 * `onSave` function is called with the text of the note and the text in the
 * input is reset to empty. It is up to the parent component to keep showing
 * this one or to hide it after a successful save.
 */
export const NewNote: FC<Props> = ({ onCancel, onSave, onTextChange }) => {
  const [newNoteText, setNewNoteText] = useState('');
  const [error, setError] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const { getConfig } = useAppConfiguration();

  const save = () => {
    setIsSaving(true);
    if (onSave) {
      onSave(newNoteText)
        .then(() => {
          setNewNoteText('');
        })
        .catch(() => {
          setError('Failed to create note');
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  };

  useEffect(() => {
    if (!onSave) {
      onTextChange(newNoteText);
    }
  }, [newNoteText, onSave, onTextChange]);

  return (
    <div aria-label='Add note' className='flex flex-col gap-2'>
      <MultilineInput
        aria-label='New note'
        autoFocus
        error={error}
        id='new-note'
        loading={isSaving}
        maxLength={getConfig('NoteMaxLength')}
        onChange={setNewNoteText}
        placeholder='Enter note'
      />
      {onSave && (
        <div className='flex justify-end gap-1 items-center'>
          <Button
            aria-label='Cancel adding note'
            className='text-xs'
            onClick={onCancel}
            secondary
          >
            Cancel
          </Button>
          <Button
            aria-label='Save note'
            className='text-xs'
            disabled={newNoteText === ''}
            loading={isSaving}
            onClick={save}
          >
            Save
          </Button>
        </div>
      )}
    </div>
  );
};
