import {
  InspectionInstanceDetails,
  isZonedInspectionInstance,
  UnzonedInspectionDetails,
} from '@dakota/platform-client';
import { Dialog } from '@mui/material';
import Confirmation from 'components/SimpleConfirmation';
import { configSlice } from 'features/config/configSlice';
import {
  cancelInspection,
  saveResponses,
} from 'features/inspections/inspectionActions';
import {
  inspectionSlice,
  responsesInSections,
} from 'features/inspections/inspectionSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import useToast from 'hooks/useToast';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

import { ExpandCollapse } from './ExpandCollapse';
import { Footer } from './Footer';
import { Header } from './Header';
import { PrintButton } from './PrintButton';
import { Sections } from './Sections';
import { ZonesMenu } from './ZonesMenu';

type ConductInspectionProps = {
  inspectionDetails: InspectionInstanceDetails;
  onClose: () => void;
};

export const ConductInspection: FC<ConductInspectionProps> = ({
  inspectionDetails,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const token = useSelector(tokenSlice.selectors.token);
  const baseUrl = useSelector(configSlice.selectors.backend);

  const { setErrorMessage, setSuccessMessage } = useToast();

  const currentZoneIndex = useSelector(
    inspectionSlice.selectors.currentZoneIndex,
  );
  const isCancelConfirmationOpen = useSelector(
    inspectionSlice.selectors.isCancelConfirmationOpen,
  );
  const isCanceling = useSelector(
    inspectionSlice.selectors.isCancelingInspection,
  );

  const sections = useMemo(() => {
    if (isZonedInspectionInstance(inspectionDetails)) {
      return inspectionDetails.zones[currentZoneIndex].sections;
    }
    return (inspectionDetails as UnzonedInspectionDetails).sections;
  }, [currentZoneIndex, inspectionDetails]);

  const zonesMenu = isZonedInspectionInstance(inspectionDetails) ? (
    <ZonesMenu zones={inspectionDetails.zones} />
  ) : null;

  // A flattened array of the current responses to the inspection,
  // both saved and unsaved
  const responses = useMemo(() => {
    if (isZonedInspectionInstance(inspectionDetails)) {
      return responsesInSections(
        inspectionDetails.zones.flatMap((zone) => zone.sections),
      );
    }
    return responsesInSections(
      (inspectionDetails as UnzonedInspectionDetails).sections,
    );
  }, [inspectionDetails]);

  const saveCurrentResponses = async () => {
    dispatch(inspectionSlice.actions.setSavingResponses(true));
    await dispatch(
      saveResponses({
        baseUrl,
        dueDate: inspectionDetails.timeline.dueDate,
        responses,
        seriesId: inspectionDetails.seriesId,
        token: token,
      }),
    )
      .unwrap()
      .finally(() =>
        dispatch(inspectionSlice.actions.setSavingResponses(false)),
      );
  };

  const cancelCurrentInspection = () => {
    dispatch(inspectionSlice.actions.setIsCanceling(true));

    dispatch(
      cancelInspection({
        baseUrl,
        dueDate: inspectionDetails.timeline.dueDate,
        seriesId: inspectionDetails.seriesId,
        token,
      }),
    )
      .unwrap()
      .then(() => {
        setSuccessMessage('Inspection canceled');
      })
      .then(onClose)
      .catch(() => {
        setErrorMessage('Failed to cancel inspection');
      })
      .finally(() => {
        dispatch(inspectionSlice.actions.setIsCanceling(false));
        dispatch(inspectionSlice.actions.closeCancelConfirmation());
      });
  };

  return (
    <Dialog fullScreen open sx={{ zIndex: 10 }}>
      <div aria-label='Inspection' className='h-dvh flex flex-col'>
        <Header inspectionDetails={inspectionDetails} onClose={onClose} />
        <div className='flex-1 overflow-y-scroll flex flex-col px-4 sm:px-24 pb-4'>
          <div className='flex-none flex justify-between gap-3 text-sm py-3'>
            <PrintButton {...{ inspectionDetails, saveCurrentResponses }} />
            <div className='hidden sm:flex flex-1'>{zonesMenu}</div>
            <ExpandCollapse />
          </div>
          <div className='sm:hidden'>{zonesMenu}</div>
          <div className='flex-1'>
            <Sections
              dueDate={inspectionDetails.timeline.dueDate}
              sections={sections}
              seriesId={inspectionDetails.seriesId}
            />
          </div>
        </div>
        <Footer {...{ inspectionDetails, onClose, saveCurrentResponses }} />
      </div>
      {isCancelConfirmationOpen && (
        <Confirmation
          cancelText={'Return to Inspection'}
          confirmLabel='Confirm Cancel Inspection'
          confirmText={'Cancel Inspection'}
          loading={isCanceling}
          onCancel={() =>
            dispatch(inspectionSlice.actions.closeCancelConfirmation())
          }
          onConfirm={cancelCurrentInspection}
        >
          <div className='text-xl text-gray-700 mb-4'>Cancel Inspection?</div>
          Are you sure you want to cancel this inspection? All entries on this
          inspection will be removed.
        </Confirmation>
      )}
    </Dialog>
  );
};
