import { FormSummary, InspectionSeries } from '@dakota/platform-client';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import Button from 'components/Button';
import AcknowledgmentDialog from 'components/Dialog/AcknowledgmentDialog';
import { SidePanel_v2 as SidePanel } from 'components/SidePanel_v2';
import { SelectZones } from 'components/Zones/SelectZones';
import { inspectionEditSlice } from 'features/inspections/inspectionEditSlice';
import { useDateFormat } from 'hooks/useDateFormat';
import { useInspectionEdit } from 'hooks/useInspectionEdit';
import { useInternationalization } from 'hooks/useInternationalization';
import useToast from 'hooks/useToast';
import { FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { unassignedUser } from 'utils/user';

import { Assignee } from './fields/Assignee';
import { DueDate } from './fields/DueDate';
import { Facility } from './fields/Facility';
import { InspectionPriority } from './fields/Priority';
import { Recurrence } from './fields/Recurrence';

type ScheduleInspectionProps = {
  form: FormSummary;
};

export const ScheduleInspection: FC<ScheduleInspectionProps> = ({ form }) => {
  const dispatch = useAppDispatch();
  const t = useInternationalization('inspections.schedule');
  const { createNewInspection } = useInspectionEdit();
  const { setErrorMessage } = useToast();
  const { formatBackendDate } = useDateFormat();

  const [saving, setSaving] = useState(false);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [scheduledInspection, setScheduledInspection] = useState<
    InspectionSeries | undefined
  >();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const facility = useSelector(inspectionEditSlice.selectors.facility);
  const zones = useSelector(inspectionEditSlice.selectors.zones);
  const hasUnsavedChanges = useSelector(
    inspectionEditSlice.selectors.hasUnsavedChanges,
  );

  const onClose = () => {
    setIsPanelOpen(false);
    dispatch(inspectionEditSlice.actions.clear());
  };

  const checkChangesAndClose = () => {
    if (hasUnsavedChanges) {
      setShowConfirmation(true);
    } else {
      onClose();
    }
  };

  const onSchedule = () => {
    dispatch(
      inspectionEditSlice.actions.initializeInspection({
        assigneeId: unassignedUser.id,
        facility: form.facility,
        form,
      }),
    );
    setIsPanelOpen(true);
  };

  const createInspection = () => {
    setSaving(true);

    createNewInspection()
      .then((result) => {
        setSaving(false);
        onClose();
        setScheduledInspection(result);
      })
      .catch((e: unknown) => {
        if (e === 400) {
          setErrorMessage(t('action.save.error'));
        } else {
          setErrorMessage(t('action.save.failure'));
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const getAcknowledgmentMessage = useCallback(
    (inspection: InspectionSeries) =>
      t.formatMessage('success.message', {
        assigneeName: inspection.assigneeName,
        boldText: t.boldText,
        date: formatBackendDate(inspection.startDate),
        formName: inspection.form.name,
      }),
    [formatBackendDate, t],
  );

  return (
    <>
      {scheduledInspection && (
        <AcknowledgmentDialog
          acknowledgmentText={getAcknowledgmentMessage(scheduledInspection)}
          onAcknowledgment={() => {
            setScheduledInspection(undefined);
          }}
          onCloseDialog={() => {
            setScheduledInspection(undefined);
          }}
          title={t('success.title')}
        />
      )}
      <Button
        data-testid='scheduler-button'
        disabled={form.inactive}
        onClick={onSchedule}
        secondary
      >
        {t('button')}
      </Button>
      {isPanelOpen && (
        <SidePanel
          onClose={checkChangesAndClose}
          primary={{
            action: createInspection,
            disabled: !facility,
            loading: saving,
            text: saving ? t('button.saving') : t('button.save'),
          }}
          secondary={{
            action: checkChangesAndClose,
            text: t('button.close'),
          }}
          title={t('title')}
          titleIcon={<CalendarIcon />}
        >
          <div className='text-base font-normal text-gray-900'>{form.name}</div>
          <div
            className={clsx(
              'mt-6 text-sm font-medium gap-3',
              'grid grid-cols-[minmax(auto,40%)_calc(60%-0.5rem)]',
            )}
          >
            <Facility />
            <SelectZones facility={facility} zones={zones} />
            <Assignee />
            <InspectionPriority />
            <DueDate />
            <Recurrence />
          </div>
        </SidePanel>
      )}
      {showConfirmation && (
        <AcknowledgmentDialog
          acknowledgmentText={t('acknowledgement.message')}
          buttonText={t('acknowledgement.button')}
          cancelButtonText={t('acknowledgement.cancel')}
          confirmationButtonSide='right'
          onAcknowledgment={() => {
            setShowConfirmation(false);
          }}
          onCloseDialog={() => {
            setShowConfirmation(false);
            onClose();
          }}
          title={t('acknowledgement.title')}
        />
      )}
    </>
  );
};
