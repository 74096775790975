import { AnswerType, ItemType } from '@dakota/platform-client';
import { ExclamationCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import {
  AutocompleteRenderGroupParams,
  Checkbox,
  IconButton,
} from '@mui/material';
import { clsx } from 'clsx';
import Autocomplete from 'components/Autocomplete';
import Input from 'components/Input';
import { MultilineInput } from 'components/MultilineInput';
import Tooltip from 'components/Tooltip';
import { FC } from 'react';

import { useAddMultipleContext } from './useContext';

type CategorizeQuestionProps = {
  index: number;
};

export const CategorizeQuestion: FC<CategorizeQuestionProps> = ({ index }) => {
  const {
    getGroupName,
    questionExists,
    questionIsDuplicate,
    questions,
    questionSelected,
    selectableAnswerTypes,
    selectableItemTypes,
    setQuestionExists,
    setQuestionIsDuplicate,
    setQuestions,
    setQuestionSelected,
  } = useAddMultipleContext();
  const question = questions[index];
  const exists = questionExists[index];
  const duplicate = questionIsDuplicate[index];
  const selected = questionSelected[index];

  const toggleQuestionSelection = () => {
    setQuestionSelected((prev) =>
      prev.map((isSelected, i) => (i === index ? !isSelected : isSelected)),
    );
  };

  const updateQuestion = (text: string) => {
    setQuestions((prev) =>
      prev.map((prevQuestion, i) =>
        i === index ? { ...prevQuestion, text } : prevQuestion,
      ),
    );
  };

  const changeItemType = (itemType: ItemType) => {
    setQuestions((prev) =>
      prev.map((prevQuestion, i) =>
        i === index ? { ...prevQuestion, itemType } : prevQuestion,
      ),
    );
  };

  const changeAnswerType = (answerType: AnswerType) => {
    setQuestions((prev) =>
      prev.map((prevQuestion, i) =>
        i === index ? { ...prevQuestion, answerType } : prevQuestion,
      ),
    );
  };

  const changeCitation = (citation: string) => {
    setQuestions((prev) =>
      prev.map((prevQuestion, i) =>
        i === index
          ? { ...prevQuestion, citation: citation.trim() }
          : prevQuestion,
      ),
    );
  };

  const renderGroup = (params: AutocompleteRenderGroupParams) => (
    <div key={params.key}>
      <div className='p-2 italic text-sm font-semibold text-gray-700'>
        {params.group}
      </div>
      <div>{params.children}</div>
    </div>
  );

  const removeQuestion = () => {
    setQuestions((prev) => prev.filter((_, i) => i !== index));
    setQuestionSelected((prev) => prev.filter((_, i) => i !== index));
    setQuestionExists((prev) => prev.filter((_, i) => i !== index));
    setQuestionIsDuplicate((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <>
      <div
        className={clsx(
          'flex gap-2 justify-between items-center border rounded p-4',
          {
            'hover:bg-blue-lighter bg-blue-lightest border-blue-base':
              !(exists || duplicate) && selected,
          },
          {
            'hover:bg-gray-50 bg-white border-gray-300':
              !(exists || duplicate) && !selected,
          },
          {
            'hover:bg-red-lightest bg-white border-red-base':
              exists || duplicate,
          },
        )}
        key={question.text}
      >
        <div className='flex-none'>
          <Tooltip arrow title='Select this question'>
            <Checkbox
              checked={selected}
              inputProps={{ 'aria-label': 'Select question' }}
              onChange={toggleQuestionSelection}
            />
          </Tooltip>
        </div>
        <MultilineInput
          aria-label='Question text'
          className={clsx('flex-1', exists ? 'text-gray-400' : 'text-gray-700')}
          maxRows={4}
          minRows={1}
          onChange={updateQuestion}
          value={question.text}
        />
        <Autocomplete
          className='flex-none w-64'
          getOptionKey={(itemType) => itemType.id}
          getOptionLabel={(itemType) => itemType.name}
          groupBy={getGroupName}
          isOptionEqualToValue={(a, b) => a.id === b.id}
          onChange={changeItemType}
          options={selectableItemTypes}
          renderGroup={renderGroup}
          required
          testId='question-item-type'
          value={question.itemType}
        />
        <Autocomplete
          className='flex-none w-32'
          getOptionKey={(answerType) => answerType.id}
          getOptionLabel={(answerType) => answerType.name}
          isOptionEqualToValue={(a, b) => a.id === b.id}
          onChange={changeAnswerType}
          options={selectableAnswerTypes}
          required
          testId='question-response-type'
          value={question.answerType}
        />
        <Input
          aria-label='Citation'
          className='w-40'
          onChange={(e) => {
            changeCitation(e.target.value);
          }}
          placeholder='Citation (optional)'
          value={question.citation}
        />
        <div className='flex-none'>
          <Tooltip arrow title='Remove this question'>
            <IconButton
              aria-label='Remove question'
              onClick={(e) => {
                e.stopPropagation();
                removeQuestion();
              }}
            >
              <TrashIcon className='w-6 h-6 text-red-base' />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {exists && (
        <div
          className={clsx(
            'text-red-base italic font-semibold rounded text-xs',
            'pl-3 inline-flex gap-1 text-pretty',
          )}
        >
          <ExclamationCircleIcon className='w-4' />
          Item Type &ldquo;{question.itemType.name}&rdquo; already has a
          question with this exact text. Please change the Item Type or remove
          the question.
        </div>
      )}{' '}
      {duplicate && (
        <div
          className={clsx(
            'text-red-base italic font-semibold rounded text-xs',
            'pl-3 inline-flex gap-1 text-pretty',
          )}
        >
          <ExclamationCircleIcon className='w-4' />
          You are attempting to add a duplicate question to &ldquo;
          {question.itemType.name}&rdquo;. Please change the Item Type or remove
          the question.
        </div>
      )}
    </>
  );
};
