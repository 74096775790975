import { TaskInstanceDetails } from '@dakota/platform-client';
import { ArrowPathIcon, PrinterIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import { Spinner } from 'components/Spinner';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  editSeries: () => void;
  isLoadingSeries: boolean;
  task: TaskInstanceDetails;
};

const buttonClasses = clsx(
  'flex items-center gap-1 px-2 py-1 text-green-base text-sm',
  'border border-green-base rounded h-full',
  'hover:bg-green-base hover:text-white',
  'disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed',
  'disabled:border-gray-400',
);

export const Buttons: FC<Props> = ({ editSeries, isLoadingSeries, task }) => {
  const navigate = useNavigate();
  const t = useInternationalization('tasks.edit');

  return (
    <div className='flex gap-2 !justify-end'>
      {!task.seriesRecurrence.isCanceled && (
        <button
          className={buttonClasses}
          disabled={isLoadingSeries}
          onClick={editSeries}
        >
          {isLoadingSeries ? <Spinner /> : <ArrowPathIcon className='h-4' />}
          {task.seriesRecurrence.isSingleInstance
            ? t('button.convertToSeries')
            : t('button.editSeries')}
        </button>
      )}
      <button
        className={buttonClasses}
        onClick={() => {
          navigate('/print-task', { state: { task } });
        }}
      >
        <PrinterIcon className='h-4' /> {t('button.print')}
      </button>
    </div>
  );
};
