import { Priority as PriorityType } from '@dakota/platform-client';
import Autocomplete from 'components/Autocomplete';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { useEnumLabel } from 'hooks/useEnumLabel';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export const Priority: FC = () => {
  const dispatch = useAppDispatch();
  const { getPriorityLabel } = useEnumLabel();
  const t = useInternationalization('tasks.edit');

  const priority = useSelector(taskEditSlice.selectors.priority);

  return (
    <div className='flex'>
      <label className='flex-1' htmlFor='task-priority'>
        {t('label.priority')}
      </label>
      <Autocomplete
        className='w-64'
        getOptionLabel={getPriorityLabel}
        id='task-priority'
        onChange={(value: PriorityType) =>
          dispatch(
            taskEditSlice.actions.setTaskField({
              field: 'priority',
              value,
            }),
          )
        }
        options={[PriorityType.High, PriorityType.Medium, PriorityType.Low]}
        placeholder={t('placeholder.priority')}
        value={priority}
      />
    </div>
  );
};
