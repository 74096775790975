import { TaskInstanceDetails } from '@dakota/platform-client';
import {
  ClipboardIcon,
  ClockIcon,
  PrinterIcon,
} from '@heroicons/react/24/outline';
import { DateTimeFormatter, LocalDate } from '@js-joda/core';
import { Locale } from '@js-joda/locale_en-us';
import { clsx } from 'clsx';
import { toHumanReadable } from 'components/recurrence/humanReadable';
import { SidePanel_v2 as SidePanel } from 'components/SidePanel_v2';
import { useEnumLabel } from 'hooks/useEnumLabel';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Attachments } from './EditTask/fields/Attachments';
import { Notes } from './EditTask/fields/Notes';
import { Zone } from './EditTask/fields/Zone';

type ViewTaskProps = {
  onClose: () => void;
  task: TaskInstanceDetails;
};

/**
 * Note: copied from src/Pages/Tasks/EditTask/fields/Buttons.tsx.
 * Should be removed once we start using new button component.
 */
const buttonClasses = clsx(
  'flex items-center gap-1 px-2 py-1 text-green-base text-sm',
  'border border-green-base rounded h-full',
  'hover:bg-green-base hover:text-white',
  'disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed',
  'disabled:border-gray-400',
);

export const ViewTask: FC<ViewTaskProps> = ({ onClose, task }) => {
  const navigate = useNavigate();
  const t = useInternationalization('tasks.view');
  const { getPriorityLabel, getStatusLabel, Label } = useEnumLabel();

  return (
    <SidePanel
      onClose={onClose}
      title={t('panel.title')}
      titleIcon={<ClipboardIcon aria-label='Clipboard Icon' />}
    >
      <div
        aria-label='View Task Panel'
        className={clsx(
          'flex-1 flex flex-col gap-4 overflow-y-auto text-sm text-gray-700',
        )}
      >
        <div
          className={clsx(
            'flex flex-col gap-4',
            '*:flex *:items-center *:gap-4 *:min-h-9',
          )}
        >
          <div aria-label='Task title' className='justify-between text-lg'>
            {task.title}
            <button
              className={clsx(buttonClasses, '!h-9')}
              onClick={() => {
                navigate('/print-task', { state: { task } });
              }}
            >
              <PrinterIcon className='h-4' /> {t('button.print')}
            </button>
          </div>
          <div className='flex gap-1 items-center text-sm font-normal text-gray-700'>
            <ClockIcon className='w-6' />
            {toHumanReadable(task.seriesRecurrence.rule)}
          </div>
          <div
            aria-label='Task description'
            className={clsx(
              '!block text-sm font-normal text-balance whitespace-pre-line',
              'max-h-40 overflow-y-auto',
              task.description ? 'text-gray-700' : 'text-gray-400 italic',
            )}
          >
            {task.description ?? t('noDescription')}
          </div>
          <div>
            <div className='flex-1'>{t('label.status')}</div>
            <strong aria-label={Label.TaskStatus}>
              {getStatusLabel(task.status)}
            </strong>
          </div>
          <div>
            <div className='flex-1'>{t('label.facility')}</div>
            <strong aria-label='Task facility'>{task.facility.name}</strong>
          </div>
          <Zone />
          <div>
            <div className='flex-1'>{t('label.assignee')}</div>
            <strong
              aria-label='Task assignee'
              className={clsx({ 'italic text-gray-400': !task.assigneeId })}
            >
              {task.assigneeName ?? t('unassigned')}
            </strong>
          </div>
          <div>
            <div className='flex-1'>{t('label.priority')}</div>
            <strong aria-label='Task priority'>
              {getPriorityLabel(task.priority)}
            </strong>
          </div>
          <div>
            <div className='flex-1'>{t('label.dueDate')}</div>
            <strong aria-label='Task due date'>
              {LocalDate.parse(task.timeline.dueDate).format(
                DateTimeFormatter.ofPattern('MM/d/yyyy').withLocale(Locale.US),
              )}
            </strong>
          </div>
        </div>
        <div className='flex flex-col gap-4'>
          <Notes task={task} />
          <Attachments task={task} />
        </div>
        <div
          className={clsx(
            'flex flex-col gap-4',
            '*:flex *:justify-stretch *:items-center *:h-9',
          )}
        >
          <div className='text-base font-bold'>{t('label.source')}</div>
          <div>
            <div className='flex-1'>{t('label.reportedBy')}</div>
            <strong aria-label='Task reported by'>{task.reportedBy}</strong>
          </div>
        </div>
      </div>
    </SidePanel>
  );
};
