import { ReportRequest, Summary } from '@dakota/platform-client';
import { ArrowDownOnSquareIcon, CheckIcon } from '@heroicons/react/24/outline';
import { LocalDate } from '@js-joda/core';
import { Menu, Tooltip } from '@mui/material';
import { clsx } from 'clsx';
import Button from 'components/Button';
import { configSlice } from 'features/config/configSlice';
import {
  getInspectionDataExport,
  getTaskDataExport,
} from 'features/reports/reportsActions';
import { reportsSlice } from 'features/reports/reportsSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { useInternationalization } from 'hooks/useInternationalization';
import useToast from 'hooks/useToast';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { saveBlobToDisk } from 'utils/file';

type Props = {
  endDate: LocalDate;
  // The selected facilities are of type Summary in the parent,
  // but we only need the ids
  selectedFacilityIds: Summary['id'][];
  startDate: LocalDate;
};

const DownloadDashboardButton: FC<Props> = ({
  endDate,
  selectedFacilityIds,
  startDate,
}) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const t = useInternationalization('charts');

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isOptionsOpen = Boolean(anchorEl);

  const isLoadingResponseDataReport = useSelector(
    reportsSlice.selectors.isLoadingResponseDataReport,
  );

  const { setErrorMessage } = useToast();

  const downloadInspections = () => {
    void dispatch(
      getInspectionDataExport({
        baseUrl,
        body: ReportRequest.fromJS({
          dateRange: {
            endDate,
            startDate,
          },
          facilities: selectedFacilityIds,
        }),
        token,
      }),
    )
      .unwrap()
      .then((result) => {
        saveBlobToDisk(result.data, result.fileName);
      })
      .catch(() => {
        setErrorMessage(t('download.error.inspections'));
      })
      .finally(() => {
        setAnchorEl(null);
      });
  };

  const downloadTasks = () => {
    void dispatch(
      getTaskDataExport({
        baseUrl,
        body: ReportRequest.fromJS({
          dateRange: {
            endDate,
            startDate,
          },
          facilities: selectedFacilityIds,
        }),
        token,
      }),
    )
      .unwrap()
      .then((result) => {
        saveBlobToDisk(result.data, result.fileName);
      })
      .catch(() => {
        setErrorMessage(t('download.error.tasks'));
      })
      .finally(() => {
        setAnchorEl(null);
      });
  };

  return (
    <>
      <Tooltip
        arrow
        placement='top'
        PopperProps={{ style: { maxWidth: 220 } }}
        title={t('download.tooltip')}
      >
        <div>
          <Button
            aria-label='Download Dashboard'
            data-testid='download-dashboard-button'
            disabled={isLoadingResponseDataReport}
            icon={<ArrowDownOnSquareIcon />}
            iconPosition='right'
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              setAnchorEl(event.currentTarget);
            }}
            secondary
          />
        </div>
      </Tooltip>
      {isOptionsOpen && (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          id='download-dashboard-data'
          onClose={() => {
            setAnchorEl(null);
          }}
          open
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          <div
            className={clsx(
              'min-w-32 py-2 px-4 flex flex-col items-start gap-2',
              '*:gap-2 *:w-full *:flex *:justify-between *:items-center *:text-gray-700',
            )}
          >
            <button
              className='group'
              id='download-inspections-button'
              onClick={downloadInspections}
            >
              {t('download.inspections')}
              <CheckIcon className='h-5 opacity-0 group-hover:opacity-100 transition-opacity text-green-base' />
            </button>
            <button
              className='group'
              id='download-tasks-button'
              onClick={downloadTasks}
            >
              {t('download.tasks')}
              <CheckIcon className='h-5 opacity-0 group-hover:opacity-100 transition-opacity text-green-base' />
            </button>
          </div>
        </Menu>
      )}
    </>
  );
};

export default DownloadDashboardButton;
