import { TaskInstanceDetails } from '@dakota/platform-client';
import { ClipboardIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import AcknowledgmentDialog from 'components/Dialog/AcknowledgmentDialog';
import { SidePanel_v2 as SidePanel } from 'components/SidePanel_v2';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { useEditSeries } from 'hooks/useEditSeries';
import { useInternationalization } from 'hooks/useInternationalization';
import { useTaskEdit } from 'hooks/useTaskEdit';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

import { Assignee } from './fields/Assignee';
import { Attachments } from './fields/Attachments';
import { Buttons } from './fields/Buttons';
import { Description } from './fields/Description';
import { DueDate } from './fields/DueDate';
import { Facility } from './fields/Facility';
import { Notes } from './fields/Notes';
import { Priority } from './fields/Priority';
import { Recurrence } from './fields/Recurrence';
import { ReportedBy } from './fields/ReportedBy';
import { Status } from './fields/Status';
import { TicketId } from './fields/TicketId';
import { Title } from './fields/Title';
import { Zone } from './fields/Zone';

type EditTaskProps = {
  onClose: () => void;
  task: TaskInstanceDetails;
};

export const EditTask: FC<EditTaskProps> = ({ onClose, task }) => {
  const dispatch = useAppDispatch();
  const t = useInternationalization('tasks.edit');

  const hasChanges = useSelector(taskEditSlice.selectors.hasUnsavedChanges);
  const { saveTask } = useTaskEdit();

  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);

  const [isSavingTask, setIsSavingTask] = useState(false);

  const { editSeries, EditSeriesComponent, isLoadingSeries } = useEditSeries({
    isSavingTask,
    saveTask,
    task,
  });

  const clearAndClose = () => {
    dispatch(taskEditSlice.actions.clear());
    onClose();
  };

  const close = () => {
    if (hasChanges) {
      setShowCloseConfirmation(true);
    } else {
      clearAndClose();
    }
  };

  const confirmClose = () => {
    setShowCloseConfirmation(false);
    clearAndClose();
  };

  useEffect(() => {
    dispatch(taskEditSlice.actions.load(task));
    /**
     * We ONLY load the task into the state when the component mounts.
     * The task object will change every time we add or update a note or
     * attachment, which would reset all the rest of the state, including the
     * partial changes the user might have made, which we DON'T want to lose.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <SidePanel
      onClose={close}
      primary={{
        action: () => {
          void saveTask()
            .then(onClose)
            .finally(() => {
              setIsSavingTask(false);
            });
        },
        disabled: !hasChanges,
        loading: isSavingTask,
        text: t('panel.button.save'),
      }}
      secondary={{ action: close, text: t('panel.button.close') }}
      title={t('panel.title')}
      titleIcon={<ClipboardIcon aria-label='Clipboard Icon' />}
    >
      <div
        aria-label='Edit Task Panel'
        className={clsx(
          'flex flex-col gap-4 text-sm font-medium',
          '*:flex *:justify-stretch *:items-center *:min-h-9',
        )}
      >
        <Buttons
          editSeries={editSeries}
          isLoadingSeries={isLoadingSeries}
          task={task}
        />
        <div className='flex flex-col !items-start'>
          <Title />
          <TicketId />
        </div>
        <Recurrence />
        <Description />
        <Status />
        <Facility />
        <Zone />
        <Assignee />
        <Priority />
        <DueDate />
      </div>
      <div className='flex flex-col gap-4'>
        <Notes task={task} />
        <Attachments task={task} />
      </div>
      <div
        className={clsx(
          'flex flex-col gap-4',
          '*:flex *:justify-stretch *:items-center *:h-9',
        )}
      >
        <div className='text-base font-bold'>{t('label.source')}</div>
        <ReportedBy />
      </div>
      <EditSeriesComponent />
      {showCloseConfirmation && (
        <AcknowledgmentDialog
          acknowledgmentText={t('acknowledgement.message')}
          buttonText={t('acknowledgement.button')}
          cancelButtonText={t('acknowledgement.cancel')}
          confirmationButtonSide='right'
          onAcknowledgment={() => {
            setShowCloseConfirmation(false);
          }}
          onCloseDialog={confirmClose}
          title={t('acknowledgement.title')}
        />
      )}
    </SidePanel>
  );
};
