import { clsx } from 'clsx';
import { Spinner } from 'components/Spinner';
import {
  cloneElement,
  ComponentPropsWithoutRef,
  FC,
  PropsWithChildren,
  ReactElement,
} from 'react';

type ButtonProps = {
  /**
   * If `true`, the button has a red background and white text.
   * @default `false`
   */
  alert?: boolean;
  className?: string;
  /**
   * If `false`, the button doesn't have a shadow around it.
   * @default `true`
   */
  hasShadow?: boolean;
  /**
   * When `true`, the button is disabled and a loading spinner
   * is shown immediately before the text.
   * @default `false`
   */
  loading?: boolean;
  /**
   * If `true`, the button has a green outline and green text with white background
   * @default `false`
   */
  outlined?: boolean;
  /**
   * If `true`, the button has a light gray background and dark gray text.
   * @default `false`
   */
  secondary?: boolean;
} & ComponentPropsWithoutRef<'button'> &
  IconProps;

type IconProps =
  | {
      icon: ReactElement;
      /**
       * The position of the icon relative to the text.
       * @default `left`
       */
      iconPosition?: 'left' | 'right';
    }
  | {
      icon?: never;
      iconPosition?: never;
    };

const Button: FC<PropsWithChildren<ButtonProps>> = ({
  alert = false,
  children,
  className = '',
  disabled,
  hasShadow = true,
  icon = undefined,
  iconPosition = 'left',
  loading = false,
  outlined = false,
  secondary = false,
  ...restProps
}) => {
  const baseButtonStyles = clsx(
    'inline-flex items-center justify-center py-2 px-3 border rounded-md',
    'gap-1 transition-all ease-in-out disabled:border-gray-400',
    'disabled:opacity-50 disabled:cursor-not-allowed text-sm font-medium',
    { 'shadow-md hover:shadow-lg': hasShadow },
  );

  const primaryButtonStyles =
    !alert &&
    !secondary &&
    !outlined &&
    clsx(
      'bg-green-base border-green-base text-white',
      'hover:bg-green-dark focus:bg-green-dark',
    );

  const secondaryButtonStyles =
    secondary &&
    clsx(
      'bg-gray-50 border-gray-300 text-gray-700',
      'hover:bg-gray-100 focus:bg-gray-100',
    );

  const alertButtonStyles =
    alert &&
    clsx(
      outlined
        ? 'bg-red-lightest text-gray-700 focus:bg-red-lighter hover:bg-red-lighter'
        : 'bg-red-base text-white focus:bg-red-dark hover:bg-red-dark',
      'border-red-base hover:border-red-dark',
    );

  const outlinedButtonStyles =
    outlined &&
    !alert &&
    clsx(
      'bg-white border-green-base text-green-dark',
      'hover:bg-green-lightest focus:bg-green-lighter',
    );

  const buttonStyles = clsx(
    baseButtonStyles,
    primaryButtonStyles,
    secondaryButtonStyles,
    alertButtonStyles,
    outlinedButtonStyles,
    className,
  );

  const iconOnlyStyles = children ? 'w-4 h-4 sm:w-5 sm:h-5' : 'w-5 h-5';

  const IconElement = icon && (
    <span className='inline-block'>
      {cloneElement(icon, {
        className: iconOnlyStyles,
        'data-testid': 'button-icon',
      })}
    </span>
  );

  return (
    <button
      className={buttonStyles}
      {...restProps}
      disabled={disabled || loading}
    >
      {iconPosition === 'left' && IconElement}
      {loading && (
        <Spinner
          className={clsx(
            'mr-1.5',
            { 'text-gray-700': secondary },
            { 'text-white': !secondary && !outlined },
            { 'text-green-base': outlined },
          )}
        />
      )}
      {children}
      {iconPosition === 'right' && IconElement}
    </button>
  );
};

export default Button;
