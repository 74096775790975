import { createSelector, createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { FeatureFlag } from 'utils/featureFlags';

import { getFeature } from './flagsActions';

type Flag = (typeof FeatureFlag)[keyof typeof FeatureFlag];

type FlagsState = {
  flags: Record<Flag, boolean>;
};

const initialState: FlagsState = {
  // Initialize all flags to `false`.
  flags: Object.values(FeatureFlag).reduce(
    (acc, flag) => ({ ...acc, [flag]: false }),
    {} as Record<Flag, boolean>,
  ),
};

export const flagsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getFeature.fulfilled, (state, action) => {
      const { enabled, name } = action.payload;
      set(state.flags, name, enabled);
    });
  },
  initialState,
  name: 'flags',
  reducers: {},
  selectors: {
    isFlagEnabled: createSelector(
      (state: FlagsState) => state.flags,
      (flags) => (flag: Flag) => flags[flag],
    ),
  },
});
