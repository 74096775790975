import PermissionGuard from 'auth/PermissionGuard';
import TabNavigation from 'components/TabNavigation';
import { useInternationalization } from 'hooks/useInternationalization';
import { NavLink, Outlet } from 'react-router-dom';
import { Permission } from 'utils/permissions';
const Manage = () => {
  const t = useInternationalization('layout.navigation.tabs');

  return (
    <div className={'h-full'}>
      <TabNavigation>
        <PermissionGuard permissions={Permission.CreateItemMetadata}>
          <NavLink id='tabnav-item-groups' to='item-groups'>
            {t('item.groups')}
          </NavLink>
          <NavLink id='tabnav-item-types' to='item-types'>
            {t('item.types')}
          </NavLink>
        </PermissionGuard>
        <PermissionGuard permissions={Permission.CreateQuestion}>
          <NavLink id='tabnav-inspection-questions' to='inspection-questions'>
            {t('inspection.questions')}
          </NavLink>
        </PermissionGuard>
        <PermissionGuard
          permissions={[
            Permission.CreateInspection,
            Permission.CreateGlobalForm,
          ]}
        >
          <NavLink id='tabnav-inspection-templates' to='inspection-templates'>
            {t('inspection.templates')}
          </NavLink>
        </PermissionGuard>
      </TabNavigation>
      <Outlet />
    </div>
  );
};

export default Manage;
