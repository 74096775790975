import { TaskNotification } from '@dakota/platform-client';
import { ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';
import { useDateFormat } from 'hooks/useDateFormat';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC, ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { NotificationCard } from './NotificationCard';

type Props = {
  closePanel: () => void;
  notification: TaskNotification;
};

export const TaskNotificationDisplay: FC<Props> = ({
  closePanel,
  notification,
}) => {
  const t = useInternationalization('notifications.task');
  const { formatBackendDate } = useDateFormat();
  const navigate = useNavigate();

  const goToTask = useCallback(() => {
    navigate(
      `/tasks/${notification.seriesId}/${notification.dueDate}?source=${location.pathname}`,
    );
    closePanel();
  }, [closePanel, navigate, notification.dueDate, notification.seriesId]);

  const alert = (text: ReactNode) => (
    <span className='text-red-base font-medium'>{text}</span>
  );

  const message = notification.isOverdue
    ? t.formatMessage('overdue', {
        alert,
        dueDate: formatBackendDate(notification.dueDate),
      })
    : t('dueToday');

  return (
    <NotificationCard
      ctaAction={goToTask}
      ctaText={t('open')}
      icon={<ClipboardDocumentCheckIcon />}
      message={message}
      ticketId={notification.ticketId}
      title={notification.title}
    />
  );
};
