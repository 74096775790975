import { useAuth0 } from '@auth0/auth0-react';
import { RoleType, Summary, UserDetails } from '@dakota/platform-client';
import { PlusIcon } from '@heroicons/react/20/solid';
import { Dialog } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DakotaUser, IdentityClaims } from 'auth/DakotaUser';
import PermissionGuard from 'auth/PermissionGuard';
import { clsx } from 'clsx';
import Autocomplete from 'components/Autocomplete';
import Button from 'components/Button';
import Chips from 'components/Chip/Chips';
import { ClearAllButton } from 'components/ClearAll';
import { PageHeader } from 'components/PageHeader';
import SearchInput from 'components/SearchInput';
import { MuiGridWrapper } from 'components/Table/GridWrapper';
import { MobileTable } from 'components/Table/mobileTable';
import Tooltip from 'components/Tooltip';
import { configSlice } from 'features/config/configSlice';
import { getActiveOrgFacilities } from 'features/facilities/facilitiesActions';
import { facilitiesSlice } from 'features/facilities/facilitiesSlice';
import { rolesSlice } from 'features/roles/rolesSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { getAllUsers } from 'features/user/userActions';
import { userSlice } from 'features/user/userSlice';
import Fuse from 'fuse.js';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useEnumLabel } from 'hooks/useEnumLabel';
import { useInternationalization } from 'hooks/useInternationalization';
import { DataStatus, usePageLoadTracking } from 'hooks/usePageLoadTracking';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { ActiveStatus, activeStatuses } from 'types';
import { alphabeticalCompare } from 'utils/functional';
import { getHighlighter } from 'utils/highlighter';
import { Permission } from 'utils/permissions';

import AddEditUser from './addUser';
import UserMenu from './userMenu';

const Users: React.FC = () => {
  const t = useInternationalization();
  const t2 = useInternationalization('organization.table.users.header');
  const { getActiveStatusLabel, Label } = useEnumLabel();
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const allUsers = useSelector(userSlice.selectors.allUsers);
  const allRoles = useSelector(rolesSlice.selectors.allRolesSummaries);
  const isLoadingRoles = useSelector(rolesSlice.selectors.isLoadingRoles);
  const allAdministrativeFacilities = useSelector(
    facilitiesSlice.selectors.allActiveOrgFacilities,
  );
  const isLoadingActiveOrgFacilities = useSelector(
    facilitiesSlice.selectors.isLoadingActiveOrgFacilities,
  );
  const { isMobile } = useBreakpoints();
  const { user } = useAuth0<DakotaUser>();
  const { stopTracking } = usePageLoadTracking();

  const orgName = user?.[IdentityClaims.OrgInfo].name ?? '';

  const [selectedStatus, setSelectedStatus] = useState<ActiveStatus>('Active');
  const [selectedFacilities, setSelectedFacilities] = useState<Summary[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<Summary[]>([]);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredUsers, setFilteredUsers] = useState<UserDetails[]>([]);
  const [userForFacilitiesList, setUserForFacilitiesList] =
    useState<null | UserDetails>(null);

  useEffect(() => {
    void Promise.all([
      dispatch(getAllUsers({ baseUrl, token })),
      dispatch(getActiveOrgFacilities({ baseUrl, token })),
    ]).then(() => {
      stopTracking(DataStatus.Fetched);
    });
  }, [baseUrl, dispatch, stopTracking, token]);

  const isAnyFacilitySelected = useCallback(
    (facilities: Summary[]) =>
      facilities.some((f) => selectedFacilities.some((sf) => sf.id === f.id)),
    [selectedFacilities],
  );

  const isAnyRoleSelected = useCallback(
    (roles: Summary[]) => {
      return roles.some((role) =>
        selectedRoles.some((selectedRole) => selectedRole.id === role.id),
      );
    },
    [selectedRoles],
  );

  useEffect(() => {
    let newUsers = allUsers;

    if (selectedStatus === 'Active') {
      newUsers = newUsers.filter((u) => !u.inactive);
    } else if (selectedStatus === 'Inactive') {
      newUsers = newUsers.filter((u) => u.inactive);
    }

    if (selectedRoles.length > 0) {
      newUsers = newUsers.filter((u) => isAnyRoleSelected(u.roles));
    }

    if (selectedFacilities.length > 0) {
      newUsers = newUsers.filter(
        (u) =>
          isAnyFacilitySelected(u.facilities.direct) ||
          isAnyFacilitySelected(u.facilities.implicit),
      );
    }

    if (searchQuery) {
      const fuse = new Fuse(newUsers, {
        findAllMatches: true,
        ignoreLocation: true,
        keys: [
          { name: 'displayName', weight: 1 },
          { name: 'email', weight: 1 },
          { name: 'facilities.direct.name', weight: 0.8 },
          { name: 'roles.name', weight: 0.8 },
        ],
        shouldSort: true,
        threshold: 0.2,
        useExtendedSearch: true,
      });
      newUsers = fuse.search(searchQuery).map((r) => r.item);
    }

    setFilteredUsers(
      newUsers.toSorted(alphabeticalCompare((u) => u.displayName)),
    );
  }, [
    allUsers,
    isAnyFacilitySelected,
    isAnyRoleSelected,
    searchQuery,
    selectedFacilities,
    selectedRoles,
    selectedStatus,
  ]);

  const resetFilters = () => {
    setSelectedFacilities([]);
    setSelectedRoles([]);
    setSelectedStatus('Active');
    setSearchQuery('');
  };

  const removeFacility = (facility: Summary) => {
    setSelectedFacilities(selectedFacilities.filter((f) => f !== facility));
  };

  const removeRole = (role: Summary) => {
    setSelectedRoles(selectedRoles.filter((r) => r !== role));
  };

  const textGray400 = 'text-gray-400';
  const getClasses = (inactive: boolean) =>
    `flex flex-col space-y-1 ${inactive ? textGray400 : ''}`;

  const highlight = getHighlighter(searchQuery);

  const nameColumn: GridColDef<UserDetails> = {
    field: 'name',
    flex: 1,
    headerName: t2('name'),
    minWidth: 200,
    renderCell: (params) => (
      <div className={getClasses(params.row.inactive)}>
        {highlight(`${params.row.lastName}, ${params.row.firstName}`)}
      </div>
    ),
  };

  const emailColumn: GridColDef<UserDetails> = {
    field: 'email',
    headerName: t2('email'),
    minWidth: 300,
    renderCell: (params) => (
      <div className={getClasses(params.row.inactive)}>
        {highlight(params.row.email)}
      </div>
    ),
  };

  const phoneColumn: GridColDef<UserDetails> = {
    field: 'phone',
    headerName: t2('phoneNumber'),
    minWidth: 150,
    renderCell: (params) => (
      <div className={getClasses(params.row.inactive)}>{params.row.phone}</div>
    ),
  };

  const rolesColumn: GridColDef<UserDetails> = {
    field: 'roles',
    flex: 1,
    headerName: t2('role'),
    minWidth: 200,
    renderCell: (params) => (
      <div className={getClasses(params.row.inactive)}>
        {highlight(params.row.roles.map((role) => role.name).join(', '))}
      </div>
    ),
  };

  const facilitiesColumn: GridColDef<UserDetails> = {
    align: 'center',
    display: 'flex',
    field: 'accessLevel',
    headerAlign: 'center',
    headerName: t2('facilityAccess'),
    renderCell: (params) => {
      const { direct } = params.row.facilities;

      if (params.row.accessLevel == RoleType.Admin) {
        return (
          <Tooltip
            arrow
            enterTouchDelay={0}
            title={`This user has access to all facilities at ${orgName}`}
          >
            <div
              className={clsx(
                'inline-block text-xs rounded-md p-2',
                'bg-gray-100 text-gray-500 hover:cursor-help',
              )}
              data-testid='access-level'
            >
              All Facilities
            </div>
          </Tooltip>
        );
      }

      return (
        <button
          className={clsx(
            'text-green-dark bg-green-lighter rounded-md',
            'text-xs p-2 hover:bg-green-light',
          )}
          data-testid='access-level'
          onClick={() => {
            setUserForFacilitiesList(params.row);
          }}
        >
          {direct.length === 1 ? direct[0].name : `${direct.length} facilities`}
        </button>
      );
    },
  };

  const actionsColumn: GridColDef<UserDetails> = {
    display: 'flex',
    field: 'menu',
    headerName: '',
    renderCell: (params) => <UserMenu user={params.row} />,
  };

  const columns = [
    nameColumn,
    emailColumn,
    phoneColumn,
    rolesColumn,
    facilitiesColumn,
    actionsColumn,
  ];

  const facilitiesColumnMobile: GridColDef<UserDetails> = {
    field: 'facilities',
    headerName: t2('facilities'),
    renderCell: (params) => {
      if (params.row.accessLevel === RoleType.Admin) {
        return <>All Facilities</>;
      }
      const facilities = params.row.facilities.direct;
      return (
        <ul className='list-disc list-inside'>
          {facilities.map((facility) => (
            <li key={facility.id}>{facility.name}</li>
          ))}
        </ul>
      );
    },
  };

  const columnsMobile = [
    nameColumn,
    emailColumn,
    phoneColumn,
    rolesColumn,
    facilitiesColumnMobile,
    actionsColumn,
  ];

  return (
    <div className='p-4 sm:p-8'>
      <PageHeader
        scaffold={[t('scaffold.users'), t('scaffold.myOrganization')]}
        title={t('page.title.users')}
      >
        <PermissionGuard permissions={Permission.CreateUser}>
          <Button
            className='bg-green-base px-4 py-2'
            data-testid='add-user-button'
            icon={<PlusIcon />}
            id='add-user-button'
            onClick={() => {
              setIsAddUserModalOpen(true);
            }}
          >
            {t('organization.cta.addUser')}
          </Button>
        </PermissionGuard>
      </PageHeader>
      <div className='filters-container'>
        <SearchInput
          data-testid='users-search-input'
          id='users-search-input'
          onSearch={(query: string) => {
            setSearchQuery(query.toLowerCase());
          }}
          value={searchQuery}
        />
        <Autocomplete
          className='w-full sm:w-60'
          getOptionKey={(facility) => facility.id}
          getOptionLabel={(facility) => facility.name}
          id='users-facility-selector'
          label={Label.Facilities}
          loading={isLoadingActiveOrgFacilities}
          multiple
          onChange={setSelectedFacilities}
          options={allAdministrativeFacilities}
          value={selectedFacilities}
        />
        <div className='sm:flex max-sm:grid max-sm:grid-cols-2 gap-3'>
          <Autocomplete
            className='sm:w-60'
            getOptionKey={(role) => role.id}
            getOptionLabel={(role) => role.name}
            id='users-role-selector'
            label={Label.Roles}
            loading={isLoadingRoles}
            multiple
            onChange={setSelectedRoles}
            options={allRoles as Summary[]}
            value={selectedRoles}
          />
          <PermissionGuard permissions={Permission.DeactivateUser}>
            <Autocomplete
              className='sm:w-28'
              getOptionLabel={getActiveStatusLabel}
              id='users-status-selector'
              label={Label.ActiveStatus}
              onChange={setSelectedStatus}
              options={activeStatuses}
              value={selectedStatus}
            />
          </PermissionGuard>
        </div>
        {(selectedFacilities.length > 0 ||
          selectedRoles.length > 0 ||
          selectedStatus !== 'Active' ||
          searchQuery.length > 0) && <ClearAllButton onClick={resetFilters} />}
      </div>
      {selectedFacilities.length > 0 && (
        <Chips
          elements={selectedFacilities}
          getKey={(f) => f.id}
          getLabel={(f) => f.name}
          onRemove={removeFacility}
          title='Facilities'
        />
      )}
      {selectedRoles.length > 0 && (
        <Chips
          elements={selectedRoles}
          getKey={(r) => r.id}
          getLabel={(r) => r.name}
          onRemove={removeRole}
          title='Roles'
        />
      )}
      {isMobile ? (
        <MobileTable columns={columnsMobile} rows={filteredUsers} />
      ) : (
        <MuiGridWrapper columns={columns} rows={filteredUsers} />
      )}
      {isAddUserModalOpen && (
        <AddEditUser
          onClose={() => {
            setIsAddUserModalOpen(false);
          }}
        />
      )}
      {userForFacilitiesList && (
        <Dialog
          fullWidth
          maxWidth='xs'
          onClose={() => {
            setUserForFacilitiesList(null);
          }}
          open
        >
          <div className='flex flex-col gap-3 p-6 text-gray-500'>
            <div className='text-xl font-semibold text-gray-900'>
              Facilities for {userForFacilitiesList.displayName}
            </div>
            <ol className='list-decimal list-inside'>
              {userForFacilitiesList.facilities.direct.map((facility) => (
                <li key={facility.id}>{facility.name}</li>
              ))}
            </ol>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default Users;
