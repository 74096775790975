import { PromptMedia, TaskMedia } from '@dakota/platform-client';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import { FC, useEffect, useState } from 'react';
import { blobFromBase64 } from 'utils/file';

import { AttachmentIcon } from '../../components/Attachment/AttachmentIcon';

export type UploadState = 'error' | 'pending' | 'uploaded' | 'uploading';

type AttachmentProps = {
  attachment: PromptMedia | TaskMedia;
  showInCarousel?: () => void;
};

/** @deprecated */
const Attachment: FC<AttachmentProps> = ({ attachment, showInCarousel }) => {
  const [thumbnailUrl, setThumbnailUrl] = useState('');

  useEffect(() => {
    if (attachment.thumbnail?.data) {
      const objectUrl = URL.createObjectURL(
        blobFromBase64(attachment.thumbnail.data),
      );
      setThumbnailUrl(objectUrl);
      return () => {
        URL.revokeObjectURL(objectUrl);
      };
    }
  }, [attachment.thumbnail?.data]);

  return (
    <div
      aria-label='Attachment'
      className={clsx(
        'border border-gray-200 rounded-lg',
        'h-36 flex flex-col justify-between',
        'bg-center bg-no-repeat bg-cover',
      )}
      style={{
        backgroundImage: thumbnailUrl ? `url(${thumbnailUrl})` : '',
      }}
    >
      <button
        aria-label='View attachment'
        className={clsx(
          'flex-none flex justify-center items-center text-gray-300',
          'rounded-t-lg group h-28',
          {
            'hover:bg-green-lightest':
              !thumbnailUrl && !attachment.securityRisk,
            'hover:bg-red-lightest': attachment.securityRisk,
          },
        )}
        disabled={attachment.securityRisk}
        onClick={showInCarousel}
      >
        {!thumbnailUrl && !attachment.securityRisk && (
          <AttachmentIcon fileType={attachment.fileType} />
        )}
        {attachment.securityRisk && (
          <div className='flex flex-col items-center justify-center h-full'>
            <ExclamationTriangleIcon className='w-8 h-8 sm:w-6 sm:h-6 text-red-base' />
            <span className='text-sm sm:text-xs text-red-base'>
              File has been Quarantined
            </span>
          </div>
        )}
      </button>
      <div
        className={clsx(
          'rounded-b-lg text-xs p-1 flex-1',
          'text-left flex flex-col justify-between gap-1',
          thumbnailUrl ? 'bg-gray-100/75' : 'bg-gray-100',
        )}
      >
        <button
          className={clsx(
            'flex-none truncate text-start h-full',
            attachment.description ? 'font-bold' : 'font-medium',
          )}
          disabled={attachment.securityRisk}
          onClick={showInCarousel}
        >
          {attachment.description ?? attachment.originalName}
        </button>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-deprecated
export default Attachment;
