import { InspectionInstance, Status } from '@dakota/platform-client';
import { CalendarIcon, ClockIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import AcknowledgmentDialog from 'components/Dialog/AcknowledgmentDialog';
import { toHumanReadable } from 'components/recurrence/humanReadable';
import { SidePanel_v2 as SidePanel } from 'components/SidePanel_v2';
import { WarningMessage } from 'components/WarningMessage';
import { inspectionEditSlice } from 'features/inspections/inspectionEditSlice';
import { useCheckPermission } from 'hooks/useCheckPermission';
import { useDateFormat } from 'hooks/useDateFormat';
import { useEnumLabel } from 'hooks/useEnumLabel';
import { useInspectionEdit } from 'hooks/useInspectionEdit';
import { useInspectionSeriesLoader } from 'hooks/useInspectionSeriesLoader';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/store';
import { Permission } from 'utils/permissions';

import { Buttons } from '../EditInspection/fields/Buttons';

type Props = {
  inspection: { status: Omit<Status, 'Scheduled'> } & InspectionInstance;
  onClose: () => void;
};

export const ViewInspection: FC<Props> = ({ inspection, onClose }) => {
  const dispatch = useAppDispatch();
  const { formatBackendDate } = useDateFormat();
  const { getPriorityLabel, getStatusLabel } = useEnumLabel();
  const t = useInternationalization('inspections');
  const { cancelInspection } = useInspectionEdit();
  const navigate = useNavigate();
  const { EditSeriesComponent, fetchInspectionSeries, isLoadingSeries } =
    useInspectionSeriesLoader({
      inspection,
    });

  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [isCancelingInspection, setIsCancelingInspection] = useState(false);

  const canCancelInspection = useCheckPermission(Permission.CancelInspection);
  const isInspectionInProgress = inspection.status === Status.InProgress;

  const cancelMessage = t.formatMessage('edit.cancel.message', {
    boldText: t.boldText,
    dueDate: formatBackendDate(inspection.timeline.dueDate),
    formName: inspection.form.name,
  });

  useEffect(() => {
    dispatch(
      inspectionEditSlice.actions.load(inspection as InspectionInstance),
    );
  }, [dispatch, inspection]);

  const warningMessage = t('status.warning', {
    status: inspection.status,
  });

  const clearAndClose = useCallback(() => {
    dispatch(inspectionEditSlice.actions.clear());
    onClose();
  }, [dispatch, onClose]);

  const onCancelInspection = () => {
    setIsCancelingInspection(true);
    void cancelInspection()
      .then(() => {
        dispatch(inspectionEditSlice.actions.clear());
        navigate('/inspections');
      })
      .finally(() => {
        setIsCancelingInspection(false);
      });
  };

  return (
    <>
      <SidePanel
        destructive={
          canCancelInspection && isInspectionInProgress
            ? {
                action: () => {
                  setShowCancelConfirmation(true);
                },
                text: t('edit.button.cancel'),
              }
            : undefined
        }
        onClose={clearAndClose}
        title='Edit Inspection'
        titleIcon={<CalendarIcon />}
      >
        <Buttons
          fetchInspectionSeries={fetchInspectionSeries}
          inspection={inspection}
          isLoadingSeries={isLoadingSeries}
        />
        <div className='my-3'>
          <h3 className='text-base font-normal text-gray-900'>
            {inspection.form.name}
          </h3>
          {inspection.ticketId && (
            <h4 className='text-xs font-semibold text-gray-900 mt-1'>
              ID: {inspection.ticketId}
            </h4>
          )}
        </div>
        <div className='my-1'>
          <WarningMessage variant='light'>{warningMessage}</WarningMessage>
        </div>
        <div
          className={clsx(
            'mt-6 text-sm font-medium gap-3',
            'grid grid-cols-[minmax(auto,40%),calc(60%-0.5rem)]',
          )}
        >
          <div className='col-span-2 flex items-center gap-1.5'>
            <ClockIcon className='size-5' />
            {toHumanReadable(inspection.seriesRecurrence.rule)}
          </div>
          <span className='font-medium'>{t('view.label.status')}</span>
          <span className='place-self-end font-semibold'>
            {getStatusLabel(inspection.status)}
          </span>
          <span className='font-medium'>{t('view.label.facility')}</span>
          <span className='place-self-end font-semibold'>
            {inspection.facility.name}
          </span>
          {inspection.zones.length > 0 && (
            <div className='col-span-2 grid grid-cols-[minmax(auto,40%),calc(60%-0.5rem)] gap-3'>
              <span className='font-medium'>{t('view.label.zone')}</span>
              <span className='place-self-end font-semibold'>
                {inspection.zones.map((zone) => zone.name).join(', ')}
              </span>
            </div>
          )}
          <span className='font-medium'>{t('view.label.assignee')}</span>
          <span className='place-self-end font-semibold'>
            {inspection.assigneeName ?? 'Unassigned'}
          </span>
          <span className='font-medium'>{t('view.label.priority')}</span>
          <span className='place-self-end font-semibold'>
            {getPriorityLabel(inspection.priority)}
          </span>
          <span className='font-medium'>{t('view.label.dueDate')}</span>
          <span className='place-self-end font-semibold'>
            {formatBackendDate(inspection.timeline.dueDate)}
          </span>
        </div>
        {showCancelConfirmation && (
          <AcknowledgmentDialog
            acknowledgmentDisabled={isCancelingInspection}
            acknowledgmentText={cancelMessage}
            alertButton={true}
            buttonText={t('edit.cancel.confirm')}
            cancelButtonText={t('edit.cancel.return')}
            confirmationButtonSide='right'
            onAcknowledgment={onCancelInspection}
            onCloseDialog={() => {
              setShowCancelConfirmation(false);
            }}
            outlinedAcknowledgmentButton={true}
            title={t('edit.cancel.header')}
          />
        )}
      </SidePanel>
      <EditSeriesComponent onClose={clearAndClose} />
    </>
  );
};
