import { Summary } from '@dakota/platform-client';
import { XMarkIcon } from '@heroicons/react/24/solid';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { clsx } from 'clsx';
import { FC, useCallback } from 'react';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import { StrictModeDroppable } from 'utils/StrictModeDroppable';

type OrderZonesProps = {
  onDeleteZone: (zone: Summary) => void;
  onMoveZone: (fromIndex: number, toIndex: number) => void;
  zones: Summary[];
};

export const OrderZones: FC<OrderZonesProps> = ({
  onDeleteZone,
  onMoveZone,
  zones,
}) => {
  const moveZone = (onEnd: DropResult) => {
    const fromIndex = onEnd.source.index;
    const toIndex = onEnd.destination?.index;
    if (toIndex === undefined) {
      return;
    }

    onMoveZone(fromIndex, toIndex);
  };

  const renderDraggable = useCallback(
    (zone: Summary, index: number) => (
      <Draggable draggableId={zone.id} index={index} key={zone.id}>
        {(provided, snapshot) => (
          <div
            data-testid='draggable'
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={clsx(
              'py-2 px-3 border border-gray-300 rounded-md flex items-center justify-between h-9 text-sm',
              snapshot.isDragging ? 'bg-gray-100' : '',
              snapshot.dropAnimation ? 'bg-green-lightest' : '',
            )}
            ref={provided.innerRef}
          >
            <div
              className={clsx('flex items-center gap-2 font-normal')}
              key={zone.id}
            >
              <DragIndicatorIcon sx={{ color: 'var(--color-gray-400)' }} />
              {zone.name}
            </div>
            <button
              aria-label='Delete zone'
              className='self-center'
              data-testid='delete-icon'
              onClick={() => {
                onDeleteZone(zone);
              }}
            >
              <XMarkIcon className='w-5 h-5 text-red-darker' />
            </button>
          </div>
        )}
      </Draggable>
    ),
    [onDeleteZone],
  );

  if (zones.length < 2) {
    return null;
  }

  return (
    <DragDropContext onDragEnd={moveZone}>
      <StrictModeDroppable droppableId='zones'>
        {(droppableProvided) => (
          <div
            {...droppableProvided.droppableProps}
            className='flex flex-col gap-3 select-none'
            ref={droppableProvided.innerRef}
          >
            {zones.map(renderDraggable)}
            {droppableProvided.placeholder}
          </div>
        )}
      </StrictModeDroppable>
    </DragDropContext>
  );
};
