import { convert, LocalDate, nativeJs } from '@js-joda/core';
import { clsx } from 'clsx';
import { FC, useLayoutEffect, useState } from 'react';
import Datepicker, {
  Configs,
  DateValueType,
  PopoverDirectionType,
} from 'react-tailwindcss-datepicker';

import { shortcutsInTheFuture, shortcutsInThePast } from './shortcuts';

/**
 * Date range required and returned when using the range picker.
 */
export type DatePickerRange = {
  begin: LocalDate;
  end: LocalDate;
};

type DatePickerProps = {
  disabled?: boolean;
  /**
   * Used both for ID and data-testid on the container.
   * @default 'datepicker-container'
   */
  id?: string;
  minDate?: LocalDate;
  placeholder?: string;
  /**
   * @default 'down'
   */
  popoverDirection?: PopoverDirectionType;
  /**
   * @default `true`
   */
  scrollOnFocus?: boolean;
} & DateSelectionProps;

type DateSelectionProps =
  | {
      /**
       * Use for a date range. Two dates are required.
       */
      asSingle: false;
      onChange: (newValue: DatePickerRange) => void;
      /**
       * Used to determine the type of shortcuts to show (next or last with
       * today always being returned).
       * @default 'last'
       */
      shortcutsType?: 'last' | 'next';
      showShortcuts?: boolean;
      value: DatePickerRange;
    }
  | {
      /**
       * Use for a single date. Range is disabled.
       */
      asSingle: true;
      /**
       * Callback to update the selected date.
       * It uses a single `LocalDate` object.
       */
      onChange: (newValue: LocalDate) => void;
      shortcutsType?: never;
      showShortcuts?: never;
      value: LocalDate;
    };

export const DatePicker: FC<DatePickerProps> = ({
  asSingle,
  disabled,
  id = 'datepicker-container',
  minDate,
  onChange,
  placeholder = undefined,
  popoverDirection = 'down',
  scrollOnFocus = true,
  shortcutsType = 'last',
  showShortcuts = false,
  value,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const adaptedValue: DateValueType = asSingle
    ? { endDate: convert(value).toDate(), startDate: convert(value).toDate() }
    : {
        endDate: convert(value.end).toDate(),
        startDate: convert(value.begin).toDate(),
      };

  const adaptedOnChange = (date: DateValueType) => {
    if (asSingle && date?.startDate) {
      if (typeof date.startDate === 'string') {
        onChange(LocalDate.parse(date.startDate));
      } else {
        onChange(LocalDate.from(nativeJs(date.startDate)));
      }
    } else if (!asSingle && date?.startDate && date.endDate) {
      if (
        typeof date.startDate === 'string' &&
        typeof date.endDate === 'string'
      ) {
        onChange({
          begin: LocalDate.parse(date.startDate),
          end: LocalDate.parse(date.endDate),
        });
      } else {
        onChange({
          begin: LocalDate.from(nativeJs(date.startDate)),
          end: LocalDate.from(nativeJs(date.endDate)),
        });
      }
    }
  };

  const configs: Configs = showShortcuts
    ? {
        /**
         * Per requirement from product, our shortcuts are either:
         *   > Today
         *   > Last 7 days
         *   > Last 30 days
         *   > Last 60 days
         *   > Last 365 days
         * or:
         *   > Today
         *   > Next 7 days
         *   > Next 30 days
         *   > Next 60 days
         *   > Next 365 days
         */
        shortcuts:
          shortcutsType === 'last' ? shortcutsInThePast : shortcutsInTheFuture,
      }
    : {};

  let focusProps = {};
  if (scrollOnFocus) {
    focusProps = {
      onFocus: () =>
        document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' }),
    };
  }

  useLayoutEffect(() => {
    if (!id) {
      return;
    }

    /**
     * This query will return the actual element that contains
     * the floating calendar, which is opened when the user clicks
     * on the input or the toggle button of the DatePicker.
     *
     * This is the 3rd and last element inside the main container
     * provided by the DatePicker component, which has this structure:
     *
     *   <div id={id}>      <-- we create this one below
     *     <div>            <-- main div from DatePicker
     *       <input>        <-- input where the user types the date
     *       <button>       <-- calendar "toggle"
     *       <div>          <-- *** what we're capturing here ***
     *     </div>
     *   </div>
     */
    const calendar = document.evaluate(
      `//*[@id="${id}"]/div/div`,
      document,
      null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
    ).singleNodeValue as Node;

    /**
     * Callback to pass to a "mutation observer".
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
     */
    const onCalendarMutated: MutationCallback = (mutationList) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'attributes') {
          const classes = (mutation.target as HTMLDivElement).classList;
          // If we're here, it means a class was added or removed
          // from the calendar container. If the list of classes contains
          // 'opacity-0', it means the calendar was just closed,
          // so we set the 'open' state to false. If it contains
          // 'opacity-1', the calendar was just opened so we set the
          // state to true, which will enable the custom height as well.
          if (classes.contains('opacity-0')) {
            setIsOpen(false);
          } else if (classes.contains('opacity-1')) {
            setIsOpen(true);
          }
        }
      }
    };

    const observer = new MutationObserver(onCalendarMutated);

    /**
     * Start observing changes to the calendar's class attribute.
     * The callback will be executed when the class attribute changes.
     */
    observer.observe(calendar, { attributeFilter: ['class'] });

    return () => {
      observer.disconnect();
    };
  }, [id]);

  return (
    <div
      {...focusProps}
      className={clsx('transition-all', isOpen ? 'h-[400px]' : 'h-9')}
      data-testid={id}
      id={id}
    >
      <Datepicker
        asSingle={asSingle}
        configs={configs}
        containerClassName={clsx(
          'relative rounded-md h-9',
          asSingle ? 'w-36' : 'w-60 max-sm:w-full',
          '*:border-gray-300',
        )}
        disabled={disabled}
        displayFormat={'MM/DD/YYYY'}
        inputClassName={clsx(
          'w-[calc(100%-2.25rem)] h-full px-2 border rounded-l-md',
          'sm:text-center text-sm text-gray-700',
          'placeholder:text-sm placeholder:font-normal placeholder:text-gray-300',
          'focus:outline-0 focus:outline-none focus:border-none',
          'focus:ring-blue-base focus:ring-2 focus:ring-inset',
          'disabled:bg-gray-200 disabled:cursor-not-allowed disabled:text-gray-400',
        )}
        inputId={`${id}-input`}
        minDate={minDate ? convert(minDate).toDate() : null}
        onChange={adaptedOnChange}
        placeholder={placeholder}
        popoverDirection={popoverDirection}
        primaryColor='emerald' // See tailwind.config.ts for details
        readOnly={true}
        showShortcuts={showShortcuts}
        toggleClassName={clsx(
          'absolute bg-gray-50 rounded-r-md right-0 h-full px-2',
          'text-gray-700 border',
          'disabled:bg-gray-200 disabled:cursor-not-allowed disabled:text-gray-400',
        )}
        useRange={!asSingle}
        value={adaptedValue}
      />
    </div>
  );
};
