/**
 * Map of human-readable feature flags to their corresponding keys.
 *
 * As the flags are no longer used (once a feature is completely released or
 * discarded), they should be removed from this map, to make sure we're not
 * using them unintentionally.
 */
export const FeatureFlag = {
  Localization: 'Feature/Localization',
  TaskLinking: 'Products/TaskLinking',
} as const;
