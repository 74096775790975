import { configSlice } from 'features/config/configSlice';
import {
  getInspectionDetails,
  startInspection,
} from 'features/inspections/inspectionActions';
import { tokenSlice } from 'features/token/tokenSlice';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/store';

import { useInternationalization } from './useInternationalization';
import useToast from './useToast';

export const useInspectionLoad = () => {
  const t = useInternationalization('inspections.table.action');

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const { setErrorMessage } = useToast();

  const [loading, setLoading] = useState(false);

  const url = (seriesId: string, dueDate: string) =>
    `/inspections/${seriesId}/${dueDate}?source=${location.pathname}`;

  const navigateTo = useCallback(
    (seriesId: string, dueDate: string) => {
      navigate(url(seriesId, dueDate));
    },
    [navigate],
  );

  const start = useCallback(
    async (seriesId: string, dueDate: string) => {
      setLoading(true);

      return dispatch(startInspection({ baseUrl, dueDate, seriesId, token }))
        .unwrap()
        .catch(() => {
          setErrorMessage(t('start.failure'));
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [baseUrl, dispatch, token, t, setErrorMessage],
  );

  /** Load the inspection details into the state */
  const load = useCallback(
    async (seriesId: string, dueDate: string) => {
      setLoading(true);

      return dispatch(
        getInspectionDetails({ baseUrl, dueDate, seriesId, token }),
      ).unwrap();
    },
    [baseUrl, dispatch, token],
  );

  /** Load the inspection into the state and redirect to its URL. */
  const resume = useCallback(
    (seriesId: string, dueDate: string) => {
      void load(seriesId, dueDate).then(() => {
        navigateTo(seriesId, dueDate);
      });
    },
    [load, navigateTo],
  );

  return { load, loading, navigateTo, resume, start };
};
