import { InspectionInstance } from '@dakota/platform-client';
import { CalendarIcon, ClockIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import AcknowledgmentDialog from 'components/Dialog/AcknowledgmentDialog';
import { toHumanReadable } from 'components/recurrence/humanReadable';
import { SidePanel_v2 as SidePanel } from 'components/SidePanel_v2';
import { SelectZones } from 'components/Zones/SelectZones';
import { inspectionEditSlice } from 'features/inspections/inspectionEditSlice';
import { useCheckPermission } from 'hooks/useCheckPermission';
import { useDateFormat } from 'hooks/useDateFormat';
import { useEnumLabel } from 'hooks/useEnumLabel';
import { useInspectionEdit } from 'hooks/useInspectionEdit';
import { useInspectionSeriesLoader } from 'hooks/useInspectionSeriesLoader';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/store';
import { Permission } from 'utils/permissions';

import { Assignee } from '../Schedule/fields/Assignee';
import { DueDate } from '../Schedule/fields/DueDate';
import { InspectionPriority as Priority } from '../Schedule/fields/Priority';
import { Buttons } from './fields/Buttons';

type Props = {
  inspection: InspectionInstance;
  onClose: () => void;
};

export const EditInspection: FC<Props> = ({ inspection, onClose }) => {
  const dispatch = useAppDispatch();
  const t = useInternationalization('inspections.edit');

  const hasUnsavedChanges = useSelector(
    inspectionEditSlice.selectors.hasUnsavedChanges,
  );

  const { EditSeriesComponent, fetchInspectionSeries, isLoadingSeries } =
    useInspectionSeriesLoader({
      hasUnsavedChanges,
      inspection,
    });

  const { getStatusLabel } = useEnumLabel();
  const { formatBackendDate } = useDateFormat();
  const { cancelInspection, saveInspectionInstance } = useInspectionEdit();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(inspectionEditSlice.actions.load(inspection));
  }, [dispatch, inspection]);

  const zones = useSelector(inspectionEditSlice.selectors.zones);
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [isSavingInspection, setIsSavingInspection] = useState(false);
  const [isCancelingInspection, setIsCancelingInspection] = useState(false);

  const canCancelInspection = useCheckPermission(Permission.CancelInspection);

  const confirmClose = () => {
    setShowCloseConfirmation(false);
    onClose();
    dispatch(inspectionEditSlice.actions.clear());
  };

  const close = useCallback(() => {
    if (hasUnsavedChanges) {
      setShowCloseConfirmation(true);
    } else {
      onClose();
      dispatch(inspectionEditSlice.actions.clear());
    }
  }, [hasUnsavedChanges, onClose, dispatch]);

  const onSave = () => {
    setIsSavingInspection(true);
    void saveInspectionInstance()
      .then(() => {
        navigate('/inspections');
      })
      .finally(() => {
        setIsSavingInspection(false);
        dispatch(inspectionEditSlice.actions.clear());
      });
  };

  const cancelMessage = t.formatMessage('cancel.message', {
    boldText: t.boldText,
    dueDate: formatBackendDate(inspection.timeline.dueDate),
    formName: inspection.form.name,
  });

  const onCancelInspection = () => {
    setIsCancelingInspection(true);
    void cancelInspection()
      .then(() => {
        navigate('/inspections');
      })
      .finally(() => {
        setIsCancelingInspection(false);
      });
  };

  return (
    <>
      <SidePanel
        destructive={
          canCancelInspection
            ? {
                action: () => {
                  setShowCancelConfirmation(true);
                },
                text: t('button.cancel'),
              }
            : undefined
        }
        onClose={close}
        primary={{
          action: onSave,
          disabled: !hasUnsavedChanges || isSavingInspection,
          text: isSavingInspection ? t('button.saving') : t('button.save'),
        }}
        secondary={{ action: close, text: t('button.close') }}
        title='Edit Inspection'
        titleIcon={<CalendarIcon />}
      >
        <Buttons
          fetchInspectionSeries={fetchInspectionSeries}
          inspection={inspection}
          isLoadingSeries={isLoadingSeries}
        />
        <h3 className='text-base font-normal text-gray-900'>
          {inspection.form.name}
        </h3>
        {inspection.ticketId && (
          <h4 className='text-xs font-semibold text-gray-900 mt-1'>
            ID: {inspection.ticketId}
          </h4>
        )}
        <div
          className={clsx(
            'mt-6 text-sm font-medium gap-3',
            'grid grid-cols-[minmax(auto,40%)_calc(60%-0.5rem)]',
          )}
        >
          <div className='col-span-2 flex items-center gap-1'>
            <ClockIcon className='size-5' />
            {toHumanReadable(inspection.seriesRecurrence.rule)}
          </div>
          <span>Status</span>
          <span className='place-self-end'>
            {getStatusLabel(inspection.status)}
          </span>
          <span>Facility</span>
          <span className='place-self-end'>{inspection.facility.name}</span>
          <SelectZones facility={inspection.facility} zones={zones} />
          <Assignee />
          <Priority />
          <DueDate />
        </div>
        {showCloseConfirmation && (
          <AcknowledgmentDialog
            acknowledgmentText={t('discard.message')}
            buttonText={t('discard.confirm')}
            cancelButtonText={t('discard.return')}
            onAcknowledgment={confirmClose}
            onCloseDialog={() => {
              setShowCloseConfirmation(false);
            }}
            title={t('discard.header')}
          />
        )}
        {showCancelConfirmation && (
          <AcknowledgmentDialog
            acknowledgmentDisabled={isCancelingInspection}
            acknowledgmentText={cancelMessage}
            alertButton
            buttonText={t('cancel.confirm')}
            cancelButtonText={t('cancel.return')}
            confirmationButtonSide='right'
            onAcknowledgment={onCancelInspection}
            onCloseDialog={() => {
              setShowCancelConfirmation(false);
            }}
            outlinedAcknowledgmentButton
            title={t('cancel.header')}
          />
        )}
      </SidePanel>
      <EditSeriesComponent onClose={close} />
    </>
  );
};
