import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import Tooltip from 'components/Tooltip';
import { useInternationalization } from 'hooks/useInternationalization';
import { NavLink } from 'react-router-dom';

import logo from '../../assets/Dakota-Logo.png';
import Navigation from './Navigation';

type Props = {
  isOpen: boolean;
  toggleSidebar: () => void;
};

const SidebarDesktop = ({ isOpen, toggleSidebar }: Props) => {
  const t = useInternationalization('layout');

  return (
    <div className='flex flex-col h-full'>
      {isOpen ? (
        <NavLink to='/'>
          <img
            alt='Dakota Software'
            className='w-36 py-5 place-self-center mx-auto'
            src={logo}
          />
        </NavLink>
      ) : (
        <NavLink
          className={clsx(
            'flex justify-center items-center w-full bg-gray-400',
            'text-white text-5xl font-semibold aspect-square select-none',
          )}
          to='/'
        >
          D
        </NavLink>
      )}
      <div
        className={clsx(
          isOpen ? 'w-56' : 'w-20',
          'flex-1 flex flex-col justify-between',
          'transition-all shrink-0 px-2 py-4',
        )}
      >
        <Navigation isSidebarOpen={isOpen} />
        <button
          className={clsx(
            'flex items-center gap-3 p-3 text-gray-800 rounded-lg',
            'hover:bg-gray-200',
            isOpen ? 'place-self-end' : 'place-self-center mx-auto',
          )}
          id='toggle-navigation'
          onClick={toggleSidebar}
        >
          {isOpen ? (
            <>
              <ArrowLeftIcon width={24} />
              <p className='text-sm font-medium'>
                {t('navigation.menu.close')}
              </p>
            </>
          ) : (
            <Tooltip placement='right' title={t('navigation.menu.open')}>
              <ArrowRightIcon width={24} />
            </Tooltip>
          )}
        </button>
      </div>
    </div>
  );
};

export default SidebarDesktop;
