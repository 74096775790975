import { Group } from '@dakota/platform-client';
import { PencilIcon, PlusIcon } from '@heroicons/react/24/outline';
import Input from 'components/Input';
import { SidePanel_v2 as SidePanel } from 'components/SidePanel_v2';
import { configSlice } from 'features/config/configSlice';
import {
  addItemGroup,
  updateItemGroup,
} from 'features/items/itemGroupsActions';
import { itemGroupsSlice } from 'features/items/itemGroupsSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { useInternationalization } from 'hooks/useInternationalization';
import useToast from 'hooks/useToast';
import { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export type AddEditItemGroupProps = {
  /**
   * If present, the component will be in edit mode.
   * Otherwise it will be in add mode.
   */
  itemGroup?: Group;
  onClose: () => void;
};

const AddEditItemGroup: FC<AddEditItemGroupProps> = ({
  itemGroup,
  onClose,
}) => {
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const dispatch = useAppDispatch();

  const t = useInternationalization('manage');

  const itemGroups = useSelector(itemGroupsSlice.selectors.itemGroups);

  const { setErrorMessage, setSuccessMessage } = useToast();

  const [name, setName] = useState(itemGroup?.name ?? '');
  const [nameError, setNameError] = useState('');

  const [saving, setSaving] = useState(false);

  // To validate the form, we check that the name doesn't clash with
  // any existing item group
  let existingItemGroups = itemGroups;
  if (itemGroup) {
    existingItemGroups = existingItemGroups.filter(
      (f) => f.id !== itemGroup.id,
    );
  }

  useEffect(() => {
    if (
      existingItemGroups.find(
        (f) => f.name.toLowerCase() === name.trim().toLowerCase(),
      )
    ) {
      setNameError('There is already an Item Group with this name');
    } else {
      setNameError('');
    }
  }, [name, existingItemGroups]);

  const clearFormData = useCallback(() => {
    setName(itemGroup?.name ?? '');
  }, [itemGroup?.name]);

  // Form is valid if all required fields are present
  const isFormValid =
    !!name.trim() &&
    !existingItemGroups.find(
      (f) => f.name.toLowerCase() === name.trim().toLowerCase(),
    );

  const isEditValid = !itemGroup || name.trim() !== itemGroup.name;

  const submitHandler = () => {
    setSaving(true);

    if (itemGroup) {
      const updatedItemGroup = {
        ...itemGroup,
        name: name.trim(),
      } as Group;

      const payload = {
        baseUrl,
        itemGroup: updatedItemGroup,
        token,
      };

      dispatch(updateItemGroup(payload))
        .unwrap()
        .then(() => {
          clearAndClose();
          setSuccessMessage('Item group updated successfully');
        })
        .catch(() => {
          setErrorMessage('Failed to update item group');
        })
        .finally(() => {
          setSaving(false);
        });
    } else {
      const payload = {
        baseUrl,
        itemGroup: {
          name: name.trim(),
        } as Group,
        token,
      };

      dispatch(addItemGroup(payload))
        .unwrap()
        .then(() => {
          clearAndClose();
          setSuccessMessage('Item group added successfully');
        })
        .catch(() => {
          setErrorMessage('Failed to add item group');
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  const clearAndClose = () => {
    if (!itemGroup) {
      // Clear the form only if we are adding a new item type
      clearFormData();
    }
    onClose();
  };

  const isSaveDisabled = !isFormValid || !isEditValid || saving;

  return (
    <SidePanel
      onClose={clearAndClose}
      primary={{
        action: submitHandler,
        disabled: isSaveDisabled,
        loading: saving,
        text: t('cta.save'),
      }}
      secondary={{ action: clearAndClose, text: t('cta.close') }}
      title={itemGroup ? t('cta.editItemGroup') : t('cta.addItemGroup')}
      titleIcon={
        itemGroup ? (
          <PencilIcon className='w-6' />
        ) : (
          <PlusIcon className='w-6' />
        )
      }
    >
      <div className='h-full flex flex-col justify-between'>
        <div className='p-6 relative'>
          <Input
            className='w-full mt-2'
            errorExplanation={nameError}
            id='item-group-name'
            label={t('table.header.itemGroup.name')}
            onChange={(e) => {
              setName(e.target.value);
            }}
            required
            value={name}
          />
        </div>
      </div>
    </SidePanel>
  );
};

export default AddEditItemGroup;
