import { Prompt, Status } from '@dakota/platform-client';
import Button from 'components/Button';
import { answersSlice } from 'features/answers/answersSlice';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { userSlice } from 'features/user/userSlice';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

type AnswersProps = {
  prompt: Prompt;
  promptIndex: number;
  sectionIndex: number;
};

export const Answers: FC<AnswersProps> = ({
  prompt,
  promptIndex,
  sectionIndex,
}) => {
  const { question, response } = prompt;
  const dispatch = useAppDispatch();

  const inspection = useSelector(inspectionSlice.selectors.inspectionDetails);
  const currentUser = useSelector(userSlice.selectors.currentUser);
  const canAnswer =
    inspection?.status === Status.InProgress &&
    currentUser.id === inspection.assigneeId;

  const getAnswerType = useSelector(answersSlice.selectors.getAnswerType);

  const answerType = getAnswerType(question.answerType.id);

  const getColorForScore = (score?: number) => {
    if (score === 0) {
      return 'var(--color-red-dark)';
    }
    if (score === 100) {
      return 'var(--color-green-base)';
    }
    return 'var(--color-yellow-base)';
  };

  const selectAnswer = (choiceId: string) => {
    if (
      (choiceId === 'Skip' && response.notApplicable) ||
      response.choiceId === choiceId
    ) {
      // We have to unselect the current choice
      dispatch(
        inspectionSlice.actions.selectAnswer({
          choiceId: null,
          promptIndex,
          sectionIndex,
        }),
      );
    } else {
      dispatch(
        inspectionSlice.actions.selectAnswer({
          choiceId,
          promptIndex,
          sectionIndex,
        }),
      );
    }
  };

  return (
    <div className='flex flex-wrap gap-2'>
      {answerType.answers.map((answer) => {
        const bg = getColorForScore(answer.score);

        return (
          <Button
            disabled={!canAnswer}
            key={answer.id}
            onClick={() => {
              selectAnswer(answer.id);
            }}
            secondary
            style={{
              backgroundColor: response.choiceId === answer.id ? bg : undefined,
              color: response.choiceId === answer.id ? 'white' : undefined,
            }}
          >
            {answer.answer}
          </Button>
        );
      })}
      <Button
        disabled={!canAnswer}
        onClick={() => {
          selectAnswer('Skip');
        }}
        secondary
        style={{
          backgroundColor: response.notApplicable
            ? 'var(--color-gray-400)'
            : undefined,
          color: response.notApplicable ? 'var(--color-white)' : undefined,
        }}
      >
        Skip
      </Button>
    </div>
  );
};
