import { AddMediaParams } from '@dakota/platform-client';
import { UploadState } from 'components/Attachment';
import { set } from 'dot-prop-immutable';
import { configSlice } from 'features/config/configSlice';
import { addAttachment } from 'features/inspections/inspectionActions';
import { tokenSlice } from 'features/token/tokenSlice';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export type FileUpload = {
  description: string;
  error: string;
  file: File;
  state: UploadState;
};

type Props = {
  dueDate: string;
  promptId: string;
  promptIndex: number;
  sectionIndex: number;
  seriesId: string;
};

export default function useInspectionAttachmentUpload({
  dueDate,
  promptId,
  promptIndex,
  sectionIndex,
  seriesId,
}: Props) {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const [isSaving, setIsSaving] = useState(false);
  const [uploads, setUploads] = useState<FileUpload[]>([]);

  const addFiles = useCallback(
    (newFiles: File[]) => {
      const actualFilesToAdd = [] as FileUpload[];

      for (const file of newFiles) {
        if (!uploads.some((f) => f.file.name === file.name)) {
          actualFilesToAdd.push({
            description: '',
            error: '',
            file,
            state: 'pending',
          });
        }
      }

      setUploads((prev) => prev.concat(actualFilesToAdd));
    },
    [uploads],
  );

  const removeFile = useCallback(
    (name: string) => {
      const index = uploads.findIndex((upload) => upload.file.name === name);
      if (index !== -1) {
        setUploads((prev) => prev.toSpliced(index, 1));
      }
    },
    [uploads],
  );

  const setDescription = useCallback((index: number, description: string) => {
    setUploads((prev) => set(prev, `${index}.description`, description));
  }, []);

  const saveAttachments = useCallback(async () => {
    setIsSaving(true);

    // We keep a local copy of the upload state for the final success message,
    // and we also update the state in the component to keep track of the
    // count of files that have been uploaded successfully.
    const uploadState = [] as UploadState[];

    for (let i = 0; i < uploads.length; i++) {
      setUploads((prev) => set(prev, `${i}.state`, 'uploading'));

      const upload = uploads[i];
      const options = {
        description: uploads[i].description,
        mediaFile: {
          data: upload.file,
          fileName: upload.file.name,
        },
      } as AddMediaParams;

      await dispatch(
        addAttachment({
          baseUrl,
          dueDate,
          options,
          promptId,
          promptIndex,
          sectionIndex,
          seriesId,
          token,
        }),
      )
        .unwrap()
        .then(() => {
          uploadState[i] = 'uploaded';
          setUploads((prev) => set(prev, `${i}.state`, 'uploaded'));
        })
        .catch((e: unknown) => {
          uploadState[i] = 'error';
          setUploads((prev) => set(prev, `${i}.state`, 'error'));
          if (typeof e === 'string') {
            setUploads((prev) => set(prev, `${i}.error`, e));
          }
        })
        .finally(() => {
          setIsSaving(false);
        });
    }

    return { success: uploadState.every((state) => state === 'uploaded') };
  }, [
    baseUrl,
    dispatch,
    dueDate,
    promptId,
    promptIndex,
    sectionIndex,
    seriesId,
    token,
    uploads,
  ]);

  return {
    addFiles,
    isSaving,
    removeFile,
    saveAttachments,
    setDescription,
    uploads,
  };
}
