import { cva, VariantProps } from 'class-variance-authority';
import { clsx } from 'clsx';
import React, { FC } from 'react';

import { Spinner } from './Spinner';

const baseLayout =
  'inline-flex items-center justify-center gap-1 whitespace-nowrap';

const buttonVariants = cva(
  clsx(
    baseLayout,
    "[&_svg]:pointer-events-none [&_svg:not([class*='size-'])]:size-4 [&_svg]:shrink-0",
    'disabled:opacity-40 disabled:cursor-not-allowed disabled:pointer-events-none',
    'aria-disabled:opacity-20 aria-disabled:cursor-not-allowed aria-disabled:pointer-events-none',
    'transition-all ease-in-out',
  ),
  {
    compoundVariants: [
      {
        class:
          'bg-red-base hover:bg-red-dark focus:bg-red-dark pressed:bg-red-dark',
        intent: 'destructive',
        variant: 'filled',
      },
      {
        class:
          'bg-green-base hover:bg-green-dark focus:bg-green-dark pressed:bg-green-dark',
        intent: 'normal',
        variant: 'filled',
      },
      {
        class: clsx(
          'bg-white text-gray-700 border-gray-300 hover:bg-gray-100',
          'focus:bg-gray-200 focus:border-gray-400',
        ),
        intent: 'normal',
        variant: 'outlined',
      },
      {
        class: clsx(
          'bg-white text-red-dark border-red-dark/50 hover:bg-red-lightest',
          'focus:bg-red-lighter focus:border-red-dark',
        ),
        intent: 'destructive',
        variant: 'outlined',
      },
      {
        class:
          'bg-green-lighter text-green-darker focus:bg-green-light pressed:bg-green-light',
        intent: 'normal',
        variant: 'tonal',
      },
      {
        class:
          'bg-red-lightest text-red-dark focus:bg-red-lighter pressed:bg-red-lighter',
        intent: 'destructive',
        variant: 'tonal',
      },
      {
        class: clsx(
          'text-green-base hover:bg-green-lightest focus:bg-green-lighter',
          'disabled:text-gray-400 aria-disabled:text-gray-400',
        ),
        intent: 'normal',
        variant: 'text',
      },
      {
        class: 'text-red-dark hover:bg-red-lightest focus:bg-red-lighter',
        intent: 'destructive',
        variant: 'text',
      },
      {
        class: 'text-xs font-normal py-1 px-2 h-7 has-[>svg]:px-1.5',
        contentType: 'text',
        size: 'small',
      },
      {
        class: 'text-sm font-medium py-2 px-4 h-9 has-[>svg]:px-3',
        contentType: 'text',
        size: 'medium',
      },
      {
        class: 'text-md font-medium py-4 px-6 h-11 has-[>svg]:px-5',
        contentType: 'text',
        size: 'large',
      },
      {
        class: 'size-7',
        contentType: 'icon',
        size: 'small',
      },
      {
        class: 'size-9',
        contentType: 'icon',
        size: 'medium',
      },
      {
        class: 'size-11',
        contentType: 'icon',
        size: 'large',
      },
    ],
    defaultVariants: {
      contentType: 'text',
      intent: 'normal',
      size: 'medium',
      variant: 'filled',
    },
    variants: {
      contentType: {
        icon: 'rounded-full',
        text: 'rounded-md',
      },
      intent: {
        destructive: null,
        normal: null,
      },
      size: {
        large: null,
        medium: null,
        small: null,
      },
      variant: {
        filled: 'text-white hover:shadow-md',
        outlined: 'border border-solid',
        text: 'disabled:opacity-50 aria-disabled:opacity-50',
        tonal: 'hover:shadow-md',
      },
    },
  },
);

type ButtonProps = { loading?: boolean } & React.ComponentProps<'button'> &
  VariantProps<typeof buttonVariants>;

export const Button: FC<ButtonProps> = ({
  className,
  contentType,
  intent,
  loading,
  size,
  variant,
  ...props
}) => {
  return (
    <button
      {...props}
      className={clsx(
        buttonVariants({ className, contentType, intent, size, variant }),
        { relative: loading },
      )}
      disabled={props.disabled || loading}
    >
      <div className={clsx(baseLayout, { 'opacity-0': loading })}>
        {props.children}
      </div>
      <Spinner
        className={clsx('text-inherit absolute', { hidden: !loading })}
      />
    </button>
  );
};
