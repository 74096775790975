import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC } from 'react';

import ChartContainer from './ChartContainer';

export const InspectionsOnlyChart: FC = () => {
  const t = useInternationalization('charts');

  return (
    <ChartContainer
      content={
        <div className='h-full flex flex-col justify-center items-center'>
          <InformationCircleIcon className='w-24 h-24 text-green-base' />
          <div className='text-lg font-light text-gray-700 text-center'>
            {t('unavailable.inspections.only')}
          </div>
        </div>
      }
    />
  );
};
