import { useInternationalization } from 'hooks/useInternationalization';
import { AddAttachments } from 'Pages/Tasks/AddAttachments';
import { FC } from 'react';

export const Attachments: FC = () => {
  const t = useInternationalization('tasks.create.label');
  return (
    <>
      <div className='font-bold'>{t('attachments')}</div>
      <AddAttachments />
    </>
  );
};
