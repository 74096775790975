import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { IconButton } from '@mui/material';
import { clsx } from 'clsx';
import { MultilineInput } from 'components/MultilineInput';
import { Spinner } from 'components/Spinner';
import Tooltip from 'components/Tooltip';
import { useAppConfiguration } from 'hooks/useAppConfiguration';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC } from 'react';

import { AttachmentIcon } from './AttachmentIcon';

export type UploadState = 'error' | 'pending' | 'uploaded' | 'uploading';

type FilePreviewProps = {
  description: string;
  file: File;
  index: number;
  onRemove: () => void;
  setDescription: (description: string) => void;
  state: UploadState;
  /**
   * When true, header will be displayed in red and editable text will be
   * disabled
   */
  warning?: boolean;
};

export const FilePreview: FC<FilePreviewProps> = ({
  description,
  file,
  index,
  onRemove,
  setDescription,
  state,
  warning = false,
}) => {
  const t = useInternationalization('inspections.prompts.attachments.preview');
  const { getConfig } = useAppConfiguration();

  return (
    <div
      className={clsx(
        'border border-gray-300 rounded-lg divide-y divide-gray-300 *:px-2 group',
        { 'opacity-50': state === 'uploading' },
      )}
    >
      <div
        className={clsx(
          'flex items-center justify-between gap-2 text-sm p-4',
          'rounded-t-lg',
          warning || state === 'error'
            ? 'bg-red-light'
            : 'bg-gray-50 group-hover:bg-gray-200',
        )}
      >
        <div className='flex-1'>Attachment {index + 1}</div>
        {state === 'uploading' && <Spinner className='h-5' />}
        {state === 'uploaded' && (
          <CheckCircleIcon className='text-green-base h-5' />
        )}
        {state === 'error' && (
          <>
            <Tooltip title={t('remove')}>
              <IconButton aria-label={t('remove')} onClick={onRemove}>
                <TrashIcon className='h-5 text-red-base' />
              </IconButton>
            </Tooltip>
            <ExclamationCircleIcon
              aria-label={t('uploadError')}
              className='text-red-dark h-5'
            />
          </>
        )}
        {state === 'pending' && (
          <Tooltip title={t('remove')}>
            <IconButton aria-label={t('remove')} onClick={onRemove}>
              <TrashIcon className='h-5 text-red-base' />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div className='flex justify-between items-center py-2'>
        <div className='flex-1 text-xs text-gray-500 flex flex-col gap-1'>
          <div className='max-w-64 truncate'>
            {t.formatMessage('fileName', {
              fileName: file.name,
              monoText: t.monoText,
            })}
          </div>
          {t('fileSize', { bytes: file.size })}
        </div>
        <div className='flex-none'>
          <AttachmentIcon fileType={file.type} />
        </div>
      </div>
      <div className='p-2'>
        <MultilineInput
          aria-label={t('description.label')}
          maxLength={getConfig('AttachmentDescriptionMaxLength')}
          maxRows={6}
          minRows={1}
          onChange={setDescription}
          placeholder={t('description.placeholder')}
          style={{
            borderColor: 'var(--color-gray-200)',
            color: 'var(--color-gray-500)',
          }}
          value={description}
        />
      </div>
    </div>
  );
};
