import Input from 'components/Input';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { useAppConfiguration } from 'hooks/useAppConfiguration';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export const Title: FC = () => {
  const dispatch = useAppDispatch();
  const t = useInternationalization('tasks.create');

  const { getConfig } = useAppConfiguration();

  const title = useSelector(taskEditSlice.selectors.title);
  const hasErrorFiles = useSelector(taskEditSlice.selectors.hasErrorFiles);

  return (
    <>
      <label className='required-field' htmlFor='task-title-input'>
        {t('label.title')}
      </label>
      <div>
        <Input
          className='w-full'
          disabled={hasErrorFiles}
          id='task-title-input'
          maxLength={getConfig('TaskTitleMaxLength')}
          onChange={(e) =>
            dispatch(
              taskEditSlice.actions.setTaskField({
                field: 'title',
                value: e.target.value,
              }),
            )
          }
          placeholder={t('placeholder.title')}
          value={title}
        />
      </div>
    </>
  );
};
