import { TaskInstanceDetails } from '@dakota/platform-client';
import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import {
  addAttachmentToInstance,
  addTaskNote,
  deleteAttachment,
  deleteTaskNote,
  getTaskDetails,
  updateAttachment,
  updateNonRecurringTaskInstance,
  updateTaskInstance,
  updateTaskNote,
  updateTaskStatus,
} from './tasksActions';

const tasksDetailsAdapter = createEntityAdapter<TaskInstanceDetails>();

type TasksDetailsState = ReturnType<typeof tasksDetailsAdapter.getInitialState>;

const initialState: TasksDetailsState = tasksDetailsAdapter.getInitialState();

export const tasksDetailsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(updateTaskInstance.fulfilled, (state, action) => {
      tasksDetailsAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(getTaskDetails.fulfilled, (state, action) => {
      tasksDetailsAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(addAttachmentToInstance.fulfilled, (state, action) => {
      // If the media failed to upload, there's nothing else to do here
      if (!action.payload) {
        return;
      }

      // Let's find the task instance that this media belongs to
      const { dueDate, seriesId } = action.meta.arg;
      const task = Object.values(state.entities).find(
        (t) => t.seriesId === seriesId && t.timeline.dueDate === dueDate,
      );

      // By construction we have this value, but we have to rule out corner case
      if (!task) {
        return;
      }

      // Finally, we can update the task instance with the new media and count
      tasksDetailsAdapter.updateOne(state, {
        changes: {
          attachments: {
            ...task.attachments,
            media: task.attachments.media + 1,
          },
          media: [...task.media, action.payload],
        },
        id: task.id,
      });
    });
    builder.addCase(updateAttachment.fulfilled, (state, action) => {
      const { attachmentId, taskId } = action.meta.arg;
      tasksDetailsAdapter.updateOne(state, {
        changes: {
          media: state.entities[taskId].media.map((media) =>
            media.id === attachmentId ? action.payload : media,
          ),
        },
        id: taskId,
      });
    });
    builder.addCase(deleteAttachment.fulfilled, (state, action) => {
      const { attachmentId, taskId } = action.meta.arg;
      tasksDetailsAdapter.updateOne(state, {
        changes: {
          attachments: {
            ...state.entities[taskId].attachments,
            media: state.entities[taskId].attachments.media - 1,
          },
          media: state.entities[taskId].media.filter(
            (media) => media.id !== attachmentId,
          ),
        },
        id: taskId,
      });
    });
    builder.addCase(addTaskNote.fulfilled, (state, action) => {
      const { taskId } = action.meta.arg;
      tasksDetailsAdapter.updateOne(state, {
        changes: {
          attachments: {
            ...state.entities[taskId].attachments,
            comments: state.entities[taskId].attachments.comments + 1,
          },
          comments: [...state.entities[taskId].comments, action.payload],
        },
        id: taskId,
      });
    });
    builder.addCase(deleteTaskNote.fulfilled, (state, action) => {
      const { id: noteId, taskId } = action.meta.arg;
      tasksDetailsAdapter.updateOne(state, {
        changes: {
          attachments: {
            ...state.entities[taskId].attachments,
            comments: state.entities[taskId].attachments.comments - 1,
          },
          comments: state.entities[taskId].comments.filter(
            (comment) => comment.id !== noteId,
          ),
        },
        id: taskId,
      });
    });
    builder.addCase(updateTaskNote.fulfilled, (state, action) => {
      const { id: noteId, taskId } = action.meta.arg;
      tasksDetailsAdapter.updateOne(state, {
        changes: {
          comments: state.entities[taskId].comments.map((comment) =>
            comment.id === noteId ? action.payload : comment,
          ),
        },
        id: taskId,
      });
    });
    builder.addCase(
      updateNonRecurringTaskInstance.fulfilled,
      (state, action) => {
        tasksDetailsAdapter.updateOne(state, {
          changes: action.payload,
          id: action.payload.id,
        });
      },
    );
    builder.addCase(updateTaskStatus.fulfilled, (state, action) => {
      const updatedTask = action.payload;

      tasksDetailsAdapter.updateOne(state, {
        changes: updatedTask,
        id: updatedTask.id,
      });
    });
  },
  initialState,
  name: 'tasksDetails',
  reducers: {},
  selectors: {
    getTaskInstanceDetails: createSelector(
      (state: TasksDetailsState) => state.entities,
      (entities) => (seriesId?: string, date?: string) =>
        Object.values(entities).find(
          (t) => t.seriesId === seriesId && t.timeline.dueDate === date,
        ),
    ),
  },
});
