import { Menu, MenuItem } from '@mui/material';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC } from 'react';
import { EditSeriesChoice } from 'types';

type ChoiceMenuProps = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onSelect: (choice: EditSeriesChoice) => void;
};

const ChoiceMenu: FC<ChoiceMenuProps> = ({ anchorEl, onClose, onSelect }) => {
  const t = useInternationalization('inspections.edit.choice');

  const selectOption = (choice: EditSeriesChoice) => {
    onSelect(choice);
    onClose();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      onClose={onClose}
      open={Boolean(anchorEl)}
      slotProps={{ paper: { sx: { mt: 1 } } }}
    >
      <MenuItem
        onClick={() => {
          selectOption(EditSeriesChoice.CurrentAndFollowing);
        }}
      >
        {t('thisAndFollowing')}
      </MenuItem>

      <MenuItem
        onClick={() => {
          selectOption(EditSeriesChoice.All);
        }}
      >
        {t('allEvents')}
      </MenuItem>
    </Menu>
  );
};

export default ChoiceMenu;
