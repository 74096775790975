import { Priority } from '@dakota/platform-client';
import Autocomplete from 'components/Autocomplete';
import { inspectionEditSlice } from 'features/inspections/inspectionEditSlice';
import { useEnumLabel } from 'hooks/useEnumLabel';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export const InspectionPriority: FC = () => {
  const dispatch = useAppDispatch();
  const { getPriorityLabel } = useEnumLabel();

  const priority = useSelector(inspectionEditSlice.selectors.priority);

  const t = useInternationalization('inspections.schedule.label');

  return (
    <>
      <label
        className='flex items-center'
        htmlFor='inspection-priority-selector'
      >
        {t('priority')}
      </label>
      <Autocomplete
        fullWidth
        getOptionLabel={getPriorityLabel}
        id='inspection-priority-selector'
        onChange={(value: Priority) =>
          dispatch(
            inspectionEditSlice.actions.setInspectionField({
              field: 'priority',
              value,
            }),
          )
        }
        options={[Priority.Low, Priority.Medium, Priority.High]}
        value={priority}
      />
    </>
  );
};
