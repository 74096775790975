import { Status as TaskStatus } from '@dakota/platform-client';
import Autocomplete from 'components/Autocomplete';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { useEnumLabel } from 'hooks/useEnumLabel';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export const Status: FC = () => {
  const dispatch = useAppDispatch();
  const { getStatusLabel, Label } = useEnumLabel();
  const t = useInternationalization('tasks.edit');

  const status = useSelector(taskEditSlice.selectors.status);

  const options = useMemo(
    () => [
      TaskStatus.Scheduled,
      TaskStatus.InProgress,
      TaskStatus.Completed,
      TaskStatus.Canceled,
    ],
    [],
  );

  return (
    <div className='flex items-center'>
      <label className='flex-1' htmlFor='task-status'>
        {t('label.status')}
      </label>
      <Autocomplete
        className='w-64'
        getOptionLabel={getStatusLabel}
        id='task-status'
        onChange={(value: TaskStatus) =>
          dispatch(
            taskEditSlice.actions.setTaskField({
              field: 'status',
              value,
            }),
          )
        }
        options={options}
        placeholder={Label.ProgressStatus}
        value={status}
      />
    </div>
  );
};
