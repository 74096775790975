import { useMediaQuery } from 'usehooks-ts';

export const useBreakpoints = () => {
  /**
   * From Tailwind, --breakpoint-sm: 640px. We need to hardcode the value here
   * as media queries can't take CSS variables.
   */
  const isMobile = useMediaQuery('(max-width: 640px)');

  return { isDesktop: !isMobile, isMobile };
};
