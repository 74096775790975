import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import { FC } from 'react';

type Props = {
  text: string;
};

export const InspectionLoadingAnimation: FC<Props> = ({ text }) => (
  <div
    className={clsx(
      'w-full h-full flex flex-col gap-2 justify-center items-center',
      'text-lg text-gray-500 animate-pulse',
    )}
  >
    <ClipboardDocumentIcon className='w-24 h-24' />
    {text}
  </div>
);
