import { ChatBubbleLeftIcon, PaperClipIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import { IconBadge } from 'components/IconBadge';
import Tooltip from 'components/Tooltip';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC } from 'react';

type AttachmentCounterProps = {
  comments: number;
  media: number;
  onClick?: () => void;
};

export const AttachmentCounter: FC<AttachmentCounterProps> = ({
  comments,
  media,
  onClick,
}) => {
  const t = useInternationalization('tasks.table');

  return (
    <button
      aria-label='Total attachments and notes'
      className={clsx('flex-none text-gray-300')}
      disabled={!onClick}
      onClick={onClick}
    >
      <div
        className={clsx(
          'sm:hidden flex flex-col gap-2',
          'text-gray-700 *:flex *:gap-2 *:items-center',
        )}
      >
        <div>
          <PaperClipIcon className='w-5 h-5' />
          {t('attachment.count', { attachments: media })}
        </div>
        <div>
          <ChatBubbleLeftIcon className='w-5 h-5' />
          {t('note.count', { notes: comments })}
        </div>
      </div>
      <div className='max-sm:hidden sm:space-x-2 flex flex-nowrap'>
        <Tooltip title={t('attachment.count', { attachments: media })}>
          <IconBadge badgeContent={media}>
            <PaperClipIcon className='w-5 h-5' />
          </IconBadge>
        </Tooltip>
        <div
          className={clsx('sm:hidden max-sm:col-span-5', {
            'text-gray-500': media > 0,
          })}
        >
          {t('attachment.count', { attachments: media })}
        </div>
        <Tooltip title={t('note.count', { notes: comments })}>
          <IconBadge badgeContent={comments}>
            <ChatBubbleLeftIcon
              className={clsx('w-5 h-5', {
                'text-gray-500': comments > 0,
              })}
            />
          </IconBadge>
        </Tooltip>
        <div
          className={clsx('sm:hidden max-sm:col-span-5', {
            'text-gray-500': comments > 0,
          })}
        >
          {t('note.count', { notes: comments })}
        </div>
      </div>
    </button>
  );
};
