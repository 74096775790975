import {
  TaskComment,
  TaskInstanceDetails,
  TaskMedia,
} from '@dakota/platform-client';
import { ClockIcon, PrinterIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import Button from 'components/Button';
import { renderImageDetails } from 'components/Print/ImageDetails';
import { toHumanReadable } from 'components/recurrence/humanReadable';
import Toggle from 'components/Toggle';
import { useDateFormat } from 'hooks/useDateFormat';
import { useEnumLabel } from 'hooks/useEnumLabel';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatBackendDateTime } from 'utils/date';

import logo from '../../../../src/assets/DakotaLogo_wht.png';

const PrintTask = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { formatBackendDate } = useDateFormat();
  const { getPriorityLabel, getStatusLabel } = useEnumLabel();

  const { task } = location.state as {
    task: TaskInstanceDetails;
  };

  const hasThumbnails = task.media.some((m) => m.thumbnail);
  const [shouldRenderThumbnails, setShouldRenderThumbnails] = useState(false);
  const recurrence = toHumanReadable(task.seriesRecurrence.rule);

  const taskNotes = (notes: TaskComment[]) =>
    notes.map((note) => (
      <div aria-label={`Task Note ${note.id}`} className='pt-2' key={note.id}>
        <p className='text-sm pl-2'>{note.text}</p>
        <p className='text-xs pl-2'>
          - created on: {formatBackendDateTime(note.commented)} by{' '}
          {note.userName}
        </p>
      </div>
    ));

  const renderAttachmentDetails = (
    promptMedia: TaskMedia[],
    excludeThumbnails: boolean,
  ) =>
    promptMedia
      .filter((media) => !excludeThumbnails || !media.thumbnail)
      .map((media) => (
        <div className='' key={media.id}>
          <p className='text-sm truncate'>{media.originalName}</p>
          <p className='text-xs italic text-gray-500'>
            {media.description ?? 'No description'}
          </p>
        </div>
      ));

  return (
    <div className='m-9 print:m-0'>
      <div
        aria-label='Print Task Message'
        className={clsx(
          'print:hidden bg-blue-lightest rounded-lg flex flex-col',
          'items-center border border-blue-base p-6 mb-4 text-sm',
          'text-gray-900 gap-4 w-full sm:w-2/5 mx-auto',
        )}
      >
        <div className='text-center text-pretty space-y-2'>
          <p className='text-base font-medium'>
            This is a preview of the printed version of your Task.
          </p>
          <p>
            On your print dialog you can adjust the scale to make sure the
            content fits on the page. This message will not be printed.
          </p>
        </div>
        {hasThumbnails && (
          <Toggle
            id='display-thumbnails'
            isOn={shouldRenderThumbnails}
            label='Include Image Thumbnails'
            onToggle={() => {
              setShouldRenderThumbnails((prev) => !prev);
            }}
          />
        )}
        <Button
          icon={<PrinterIcon />}
          onClick={() => {
            window.print();
          }}
        >
          Print Task
        </Button>
        <Button
          className='text-xs'
          onClick={() => {
            navigate(-1);
          }}
          secondary
        >
          Return
        </Button>
      </div>
      <div aria-label='Dakota Logo' className='bg-gray-600 p-2'>
        <img alt='dakota-logo' className='w-32' src={logo} />
      </div>
      <div className='py-2'>
        <div className='flex flex-col gap-2'>
          <div
            aria-label='Task Title'
            className='p-2 text-xl font-bold bg-gray-300'
          >
            {task.title}
          </div>
          <div>
            {' '}
            <div className='flex gap-1 items-center text-sm font-normal text-gray-700'>
              <ClockIcon className='w-6' />
              {recurrence}
            </div>
          </div>
          <div className='text-md'>
            {task.description ?? (
              <div className='italic text-gray-500'> No description </div>
            )}
          </div>
          <div className='flex flex-col text-sm gap-2'>
            <div className='p-2 bg-gray-300'>
              <span className='text-lg font-bold'>Task Details:</span>
            </div>
            <div>
              <span className='font-bold pr-1'>Facility:</span>
              <span>{task.facility.name}</span>
            </div>
            <div>
              <span className='font-bold pr-1'>Zone:</span>
              <span>
                {task.zone?.name ?? (
                  <span className='italic text-gray-500'> No zone </span>
                )}
              </span>
            </div>
            <div>
              <span className='font-bold pr-1'>Due Date:</span>
              <span>{formatBackendDate(task.timeline.dueDate)}</span>
            </div>
            <div>
              <span className='font-bold pr-1'>Assignee:</span>
              <span>{task.assigneeName ?? 'Unassigned'}</span>
            </div>
            <div>
              <span className='font-bold pr-1'>Priority:</span>
              <span>{getPriorityLabel(task.priority)}</span>
            </div>
            <div>
              <span className='font-bold pr-1'>Status:</span>
              <span>{getStatusLabel(task.status)}</span>
            </div>
          </div>
          <div aria-label='Task Source' className='flex flex-col text-sm'>
            <div className='p-2 bg-gray-300'>
              <span className='text-lg font-bold'>Source:</span>
            </div>
            <div>
              <span className='font-bold pr-1'>Reported by:</span>
              <span>{task.reportedBy}</span>
            </div>
          </div>
          {task.comments.length > 0 && (
            <div className='flex flex-col text-sm'>
              <div className='p-2 bg-gray-300'>
                <span className='text-lg font-bold'>Notes:</span>
              </div>
              {taskNotes(task.comments)}
            </div>
          )}
          {task.media.length > 0 && (
            <div className='flex flex-col text-sm'>
              <div className='p-2 bg-gray-300'>
                <span className='text-lg font-bold'>Attachments:</span>
              </div>

              {shouldRenderThumbnails ? (
                <>
                  <div className='grid grid-cols-3 gap-2 pt-2'>
                    {renderImageDetails(task.media, shouldRenderThumbnails)}
                  </div>
                  <div className='flex flex-col gap-2'>
                    {renderAttachmentDetails(task.media, true)}
                  </div>
                </>
              ) : (
                <div className='flex flex-col gap-2 pt-2'>
                  {renderAttachmentDetails(task.media, false)}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrintTask;
