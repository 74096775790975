import { PromptMedia, TaskMedia } from '@dakota/platform-client';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clsx } from 'clsx';
import { useInternationalization } from 'hooks/useInternationalization';
import { useLocalePreference } from 'hooks/useLocalePreference';
import { FC, useEffect, useState } from 'react';
import ReactTimeAgo from 'react-time-ago';
import { blobFromBase64 } from 'utils/file';

import { AttachmentIcon } from './AttachmentIcon';

export type UploadState = 'error' | 'pending' | 'uploaded' | 'uploading';

type AttachmentProps = {
  attachment: PromptMedia | TaskMedia;
  showInCarousel?: () => void;
};

const AttachmentV2: FC<AttachmentProps> = ({ attachment, showInCarousel }) => {
  const t = useInternationalization('inspections.prompts.attachments.card');
  const { userLocale } = useLocalePreference();

  const [thumbnailUrl, setThumbnailUrl] = useState('');

  useEffect(() => {
    if (attachment.thumbnail?.data) {
      const objectUrl = URL.createObjectURL(
        blobFromBase64(attachment.thumbnail.data),
      );
      setThumbnailUrl(objectUrl);
      return () => {
        URL.revokeObjectURL(objectUrl);
      };
    }
  }, [attachment.thumbnail?.data]);

  return (
    <button
      aria-label={t('label')}
      className={clsx(
        'group rounded-lg flex flex-col bg-center bg-no-repeat bg-cover text-xs/4 font-medium',
        'max-sm:outline sm:hover:outline outline-gray-300',
        {
          'bg-green-lightest outline-green-base/25':
            !thumbnailUrl && !attachment.securityRisk,
          'bg-red-lightest outline-red-base/25': attachment.securityRisk,
        },
      )}
      disabled={attachment.securityRisk}
      onClick={showInCarousel}
      style={{
        backgroundImage: thumbnailUrl ? `url(${thumbnailUrl})` : '',
      }}
    >
      <div
        aria-label={t('view')}
        className='w-full h-28 flex justify-center items-center text-gray-300'
      >
        {attachment.securityRisk && (
          <div className='flex flex-col items-center justify-center h-full text-red-base'>
            <FontAwesomeIcon icon={faTriangleExclamation} size='xl' />
            {t('quarantined')}
          </div>
        )}
        {!thumbnailUrl && !attachment.securityRisk && (
          <AttachmentIcon fileType={attachment.fileType} />
        )}
      </div>
      <div
        className={clsx(
          'w-full h-16 px-2 py-1.5 bg-white/90 text-gray-900 grid gap-1',
          'text-start *:truncate rounded-b-md',
          { 'bg-white/90': thumbnailUrl },
        )}
      >
        <span>{attachment.description ?? attachment.originalName}</span>
        <span>{attachment.userName}</span>
        <span className='font-normal'>
          <ReactTimeAgo
            date={Date.parse(attachment.uploaded)}
            locale={userLocale}
          />
        </span>
      </div>
    </button>
  );
};

export default AttachmentV2;
