import { InspectionInstance, TaskInstance } from '@dakota/platform-client';
import { SearchStatus } from 'types';

/**
 * Determines the assignment status for an InspectionInstance or TaskInstance
 * since we have two different status types.
 *
 * The logic assumes that in the assignments page, we only deal with
 * three statuses: In Progress, Overdue, and Scheduled.
 *
 * 1. First, the function checks if the item is Overdue. If so,
 *    it returns AssignmentStatus.Overdue.
 * 2. Next, it checks if the item is In Progress. If so,
 *    it returns AssignmentStatus.InProgress.
 * 3. Finally, if neither of the above conditions are met, we know
 *    the item must be Scheduled, because no other statuses are
 *    fetched on the assignments page.
 *
 * @param item - The item to evaluate (InspectionInstance or TaskInstance).
 * @returns The AssignmentStatus value.
 */
export const getItemAssignmentStatus = (
  item: InspectionInstance | TaskInstance,
): SearchStatus => {
  return item.overdue ? 'Overdue' : item.status;
};
