import { Summary } from '@dakota/platform-client';
import { UserIcon } from '@heroicons/react/24/solid';
import { Tooltip } from '@mui/material';
import { clsx } from 'clsx';
import { FC } from 'react';

import { getRgbColorById } from './color';
import { getInitials } from './initials';

type AssigneeAvatarProps = {
  /**
   * The user to display the avatar for. If not provided, the avatar will
   * display as unassigned.
   */
  user?: Summary;
};

export const AssigneeAvatar: FC<AssigneeAvatarProps> = ({ user }) => (
  <Tooltip arrow placement='bottom' title={user?.name ?? 'Unassigned'}>
    <span
      className={clsx(
        'inline-flex h-8 w-8 items-center justify-center',
        'rounded-full select-none',
        { 'bg-gray-400': !user?.id },
      )}
      data-testid='assignee-avatar'
      style={{
        backgroundColor: user?.id ? getRgbColorById(user.id) : undefined,
      }}
    >
      {user?.id ? (
        <span className='text-sm font-medium leading-none text-white'>
          {getInitials(user.name)}
        </span>
      ) : (
        <UserIcon aria-label='Unassigned' className='h-5 w-5 text-white' />
      )}
    </span>
  </Tooltip>
);
