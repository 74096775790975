import { Summary } from '@dakota/platform-client';
import Autocomplete from 'components/Autocomplete';
import { facilitiesSlice } from 'features/facilities/facilitiesSlice';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { unassignedUser } from 'utils/user';

export const Facility: FC = () => {
  const dispatch = useAppDispatch();
  const t = useInternationalization('tasks.create.label');

  const activeFacilities = useSelector(
    facilitiesSlice.selectors.activeFacilities,
  ).map((f) => Summary.fromJS(f));
  const hasErrorFiles = useSelector(taskEditSlice.selectors.hasErrorFiles);
  const facility = useSelector(taskEditSlice.selectors.facility);

  return (
    <>
      <label className='required-field' htmlFor='task-facility-selector'>
        {t('facility')}
      </label>
      <Autocomplete
        disabled={hasErrorFiles}
        fullWidth
        getOptionKey={(f) => f.id}
        getOptionLabel={(f) => f.name}
        id='task-facility-selector'
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(value) => {
          dispatch(
            taskEditSlice.actions.setTaskField({
              field: 'assigneeId',
              value: unassignedUser.id,
            }),
          );
          dispatch(
            taskEditSlice.actions.setTaskField({
              field: 'zone',
              value: undefined,
            }),
          );
          dispatch(
            taskEditSlice.actions.setTaskField({
              field: 'facility',
              value,
            }),
          );
        }}
        options={activeFacilities}
        value={facility ?? null}
      />
    </>
  );
};
