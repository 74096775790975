import { ArrowDownOnSquareIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import Button from 'components/Button';
import { Spinner } from 'components/Spinner';
import { filesSlice } from 'features/files/filesSlice';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebounceCallback } from 'usehooks-ts';

import { IMedia } from '.';
import { ImageWithoutThumbnail } from './ImageWithoutThumbnail';
import { ImageWithThumbnail } from './ImageWithThumbnail';

type Props = {
  file: IMedia;
  isDownloading: boolean;
  isNavigationVisible: boolean;
  onDownload: () => void;
  toggleNavigation: () => void;
};

export const Preview: FC<Props> = ({
  file,
  isDownloading,
  isNavigationVisible,
  onDownload,
  toggleNavigation,
}) => {
  const isImage = file.fileType === 'image';

  const [hasError, setHasError] = useState(false);

  const getFileById = useSelector(filesSlice.selectors.getFileById);
  // No need to fetch as this is being fetched in the parent component
  const cachedFile = getFileById(file.id);

  const toggleAfterDelay = useDebounceCallback(toggleNavigation, 2000);

  useEffect(() => {
    if (isNavigationVisible) {
      toggleAfterDelay();
    }

    return () => {
      toggleAfterDelay.cancel();
    };
    // We *ONLY* want this automatic toggle when a new file is selected,
    // and *ONLY* if the navigation is already visible.
  }, [file, isNavigationVisible, toggleAfterDelay]);

  if (!cachedFile) {
    return (
      <div className='max-sm:absolute-center'>
        <Spinner />
      </div>
    );
  }

  if (isImage && file.thumbnail && !hasError) {
    return (
      <ImageWithThumbnail
        description={file.description}
        filename={file.originalName}
        fullSizeUrl={cachedFile.fileUrl}
        onError={() => {
          setHasError(true);
        }}
        thumbnail={file.thumbnail}
        toggleNavigation={toggleNavigation}
      />
    );
  }

  if (isImage && !file.thumbnail && !hasError) {
    return (
      <ImageWithoutThumbnail
        description={file.description}
        filename={file.originalName}
        fullSizeUrl={cachedFile.fileUrl}
        onError={() => {
          setHasError(true);
        }}
        toggleNavigation={toggleNavigation}
      />
    );
  }

  return (
    <div
      className={clsx(
        'inline-flex flex-col gap-3 items-center',
        'max-sm:w-full max-sm:absolute-center',
      )}
    >
      <div
        className={clsx(
          'text-lg font-medium text-center',
          'max-sm:text-balance max-sm:max-w-64',
        )}
      >
        This file cannot be previewed. Please download to view.
      </div>
      <Button
        className='flex gap-1'
        loading={isDownloading}
        onClick={onDownload}
      >
        <ArrowDownOnSquareIcon className='w-6' />
        Download
      </Button>
    </div>
  );
};
