import { TaskSeries } from '@dakota/platform-client';
import { Dialog } from '@mui/material';
import Button from 'components/Button';
import { PageHeader } from 'components/PageHeader';
import Confirmation from 'components/SimpleConfirmation';
import { useTaskSeriesEdit } from 'hooks/useTaskSeriesEdit';
import { FC, useState } from 'react';
import { EditSeriesChoice } from 'types';

import { Header } from './layout/header';
import { SeriesDetails } from './layout/seriesDetails';

type EditSeriesProps = {
  /**
   * The option to edit all events or this event and following events.
   */
  editOption: EditSeriesChoice;
  /**
   * The scheduled date of the task instance.
   */
  instanceScheduledDate: string;
  /**
   * Callback to close the dialog.
   */
  onClose: () => void;
  /**
   * The task series to edit.
   */
  task: TaskSeries;
};

export const EditSeries: FC<EditSeriesProps> = ({
  editOption,
  instanceScheduledDate,
  onClose,
  task,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { hasChanges, isSaving, splitSeries, updateSeries } = useTaskSeriesEdit(
    task,
    instanceScheduledDate,
  );

  const attemptClose = () => {
    if (hasChanges) {
      setShowConfirmation(true);
    } else {
      onClose();
    }
  };

  const confirmClose = () => {
    setShowConfirmation(false);
    onClose();
  };

  const saveChanges = () => {
    if (editOption === EditSeriesChoice.All) {
      updateSeries();
    } else {
      splitSeries();
    }
  };

  return (
    <Dialog
      data-testid='edit-series'
      disableEscapeKeyDown
      fullWidth
      onClose={attemptClose}
      open
    >
      <PageHeader scaffold={['Edit Series', 'Tasks']} />
      <Header onClose={attemptClose} title='Edit Task Series' />
      <div className='flex-1 overflow-y-auto'>
        <SeriesDetails editOption={editOption} task={task} />
      </div>
      <div className='flex items-center h-14 border-t p-4 gap-2 text-sm bg-gray-100'>
        <Button
          disabled={!hasChanges}
          id='save-edit-series'
          loading={isSaving}
          onClick={saveChanges}
        >
          {isSaving ? 'Saving...' : 'Save'}
        </Button>
        <Button id='cancel-edit-series' onClick={attemptClose} secondary>
          Cancel
        </Button>
      </div>
      {showConfirmation && (
        <Confirmation
          cancelText='Return to form'
          confirmText='Discard'
          onCancel={() => {
            setShowConfirmation(false);
          }}
          onConfirm={confirmClose}
          title='Discard Changes?'
        >
          You have unsaved changes. Are you sure you want to discard them?
        </Confirmation>
      )}
    </Dialog>
  );
};
