import { Prompt } from '@dakota/platform-client';
import { faFileArrowUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Attachment from 'components/Attachment/AttachmentV2';
import { FilePreview } from 'components/Attachment/FilePreview';
import { Button } from 'components/Button_v2';
import { WarningMessage } from 'components/WarningMessage';
import { useAppConfiguration } from 'hooks/useAppConfiguration';
import { FileUpload } from 'hooks/useInspectionAttachmentUpload';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC, useRef } from 'react';

type AddAttachmentsProps = {
  addFiles: (files: File[]) => void;
  isSaving: boolean;
  prompt: Prompt;
  removeFile: (name: string) => void;
  setDescription: (index: number, description: string) => void;
  showInCarousel: (index: number) => void;
  uploads: FileUpload[];
};

export const AddAttachments: FC<AddAttachmentsProps> = ({
  addFiles,
  isSaving,
  prompt,
  removeFile,
  setDescription,
  showInCarousel,
  uploads,
}) => {
  const t = useInternationalization('inspections.prompts.attachments');
  const { getConfig } = useAppConfiguration();

  const currentCount = prompt.media.length;

  const fileInputRef = useRef<HTMLInputElement>(null);

  const limit = getConfig('MaximumAttachmentsPerQuestion');

  const notUploadedCount = uploads.filter(
    (upload) => upload.state === 'pending' || upload.state === 'uploading',
  ).length;

  return (
    <div className='flex-1 overflow-y-auto'>
      <div aria-label={t('label')} className='p-3 flex flex-col gap-3'>
        {currentCount + notUploadedCount > limit && (
          <WarningMessage>
            {t('warning.moreThanMaxAttachments', { limit })}
          </WarningMessage>
        )}
        {currentCount == limit && (
          <WarningMessage variant='light'>
            {t('warning.alreadyAtMaxAttachments', { limit })}
          </WarningMessage>
        )}
        {currentCount < limit && currentCount + notUploadedCount == limit && (
          <WarningMessage variant='light'>
            {t('warning.approachedMaxAttachments', { limit })}
          </WarningMessage>
        )}
        {uploads.map(({ error, file }, index) => {
          if (error) {
            return (
              <WarningMessage key={`${file.name} + ${index}`}>
                {t.formatMessage('warning.unableToUpload', {
                  boldText: t.boldText,
                  error,
                  fileName: file.name,
                })}
              </WarningMessage>
            );
          } else if (file.size > getConfig('AttachmentMaxFileSize')) {
            return (
              <WarningMessage key={`${file.name} + ${index}`}>
                {t.formatMessage('warning.fileSizeTooLarge', {
                  boldText: t.boldText,
                  fileName: file.name,
                  maxBytes: getConfig('AttachmentMaxFileSize'),
                })}
              </WarningMessage>
            );
          }
        })}
        <div>
          <input
            aria-label={t('fileInput.label')}
            className='hidden'
            multiple
            onChange={(e) => {
              if (e.target.files) {
                addFiles(Array.from(e.target.files));
              }
            }}
            ref={fileInputRef}
            type='file'
          />
          <Button
            disabled={isSaving || currentCount + uploads.length >= limit}
            onClick={() => fileInputRef.current?.click()}
            variant='outlined'
          >
            <FontAwesomeIcon icon={faFileArrowUp} />
            {t('fileInput.button')}
          </Button>
        </div>
        <div className='flex flex-col gap-3'>
          {uploads.map((upload, index) => (
            <FilePreview
              description={upload.description}
              file={upload.file}
              index={currentCount + index}
              key={upload.file.name}
              onRemove={() => {
                removeFile(upload.file.name);
              }}
              setDescription={(description) => {
                setDescription(index, description);
              }}
              state={upload.state}
              warning={upload.file.size > getConfig('AttachmentMaxFileSize')}
            />
          ))}
        </div>
        <div className='grid sm:grid-cols-2 gap-3'>
          {prompt.media.map((attachment, attachmentIndex) => (
            <Attachment
              attachment={attachment}
              key={attachment.id}
              showInCarousel={() => {
                showInCarousel(attachmentIndex);
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
