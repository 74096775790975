import { InspectionInstanceDetails, Status } from '@dakota/platform-client';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Fade } from '@mui/material';
import { clsx } from 'clsx';
import { Button } from 'components/Button_v2';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { useDateFormat } from 'hooks/useDateFormat';
import { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import logo from '../../assets/DakotaLogo_wht.png';
import { getStatusText } from './format';

type HeaderProps = {
  inspectionDetails: InspectionInstanceDetails;
  onClose: () => void;
};

export const Header: FC<HeaderProps> = ({ inspectionDetails, onClose }) => {
  const [detailsOpen, setDetailsOpen] = useState(false);
  const { formatBackendDate } = useDateFormat();

  const totalAnsweredQuestions = useSelector(
    inspectionSlice.selectors.totalAnsweredQuestions,
  );
  const totalQuestions = useSelector(inspectionSlice.selectors.totalQuestions);

  const metadata = useMemo(
    () => (
      <>
        <span>Facility</span>
        <span>{inspectionDetails.facility.name}</span>
        <span>Due Date</span>
        <span>
          {formatBackendDate(inspectionDetails.timeline.dueDate)}
          {inspectionDetails.overdue && <span> (Overdue)</span>}
        </span>
        <span>Inspector</span>
        <span className={clsx({ italic: !inspectionDetails.assigneeId })}>
          {inspectionDetails.assigneeId === undefined
            ? 'Unassigned'
            : inspectionDetails.assigneeName}
        </span>
        <span>Status</span>
        <span>{getStatusText(inspectionDetails.status)}</span>
        {inspectionDetails.status === Status.Completed && (
          <>
            <span>Completed on</span>
            <span>
              {formatBackendDate(inspectionDetails.timeline.endDate as string)}
            </span>
          </>
        )}{' '}
      </>
    ),
    [
      formatBackendDate,
      inspectionDetails.assigneeId,
      inspectionDetails.assigneeName,
      inspectionDetails.facility.name,
      inspectionDetails.overdue,
      inspectionDetails.status,
      inspectionDetails.timeline.dueDate,
      inspectionDetails.timeline.endDate,
    ],
  );

  return (
    <div
      className={clsx(
        'flex-none p-2 sm:px-8 bg-green-base text-white',
        'flex flex-col justify-center',
      )}
    >
      <div className='flex-none h-20 *:flex flex justify-between items-center gap-4'>
        <img
          alt='Dakota Logo'
          className='flex-none w-28 max-sm:w-16'
          src={logo}
        />
        <div className='flex-1 flex-col items-start overflow-hidden'>
          {inspectionDetails.ticketId && (
            <div className='text-xs/5 font-medium'>
              ID: {inspectionDetails.ticketId}
            </div>
          )}
          <div className='w-full text-base/5 sm:text-xl/5 text-pretty font-semibold'>
            {inspectionDetails.form.name}
          </div>
          <div
            className={clsx(
              'text-xs/5 font-normal hidden sm:flex gap-1',
              '*:even:me-4 *:even:font-light *:odd:after:content-[":"]',
            )}
          >
            {metadata}
          </div>
        </div>
        <div className='flex-none flex-row items-center'>
          {totalQuestions > 0 && (
            <div className='flex-none flex flex-col items-end'>
              <div className='text-base sm:text-xl flex justify-end gap-2'>
                {totalAnsweredQuestions}/{totalQuestions}
                <button
                  aria-label='Show inspection details'
                  className='block sm:hidden transition-all'
                  onClick={() => {
                    setDetailsOpen((pre) => !pre);
                  }}
                >
                  {detailsOpen ? (
                    <ChevronUpIcon className='w-5 h-5' />
                  ) : (
                    <ChevronDownIcon className='w-5 h-5' />
                  )}
                </button>
              </div>
              <div className='text-xs font-normal hidden sm:block'>
                Answered Questions
              </div>
            </div>
          )}
          <div className='pl-4 flex-none hidden sm:flex justify-center'>
            <button aria-label='Close' onClick={onClose}>
              <XMarkIcon className='w-8 h-8' />
            </button>
          </div>
        </div>
      </div>
      <Fade className='sm:hidden' in={detailsOpen} unmountOnExit>
        <div aria-label='Inspection details' className='mt-4'>
          <div
            className={clsx(
              'grid grid-cols-4 gap-x-2 gap-y-0.5 text-xs/5 font-normal',
              '*:even:col-span-3 *:even:font-light mb-4',
            )}
          >
            {metadata}
            {inspectionDetails.status === Status.InProgress && (
              <>
                <span>Questions</span>
                <span>
                  {totalAnsweredQuestions} answered,{' '}
                  {totalQuestions - totalAnsweredQuestions} pending
                </span>
              </>
            )}
          </div>
          <Button onClick={onClose} size='small' variant='outlined'>
            Close inspection
          </Button>
        </div>
      </Fade>
    </div>
  );
};
