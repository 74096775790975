import { useAuth0 } from '@auth0/auth0-react';
import {
  AtSymbolIcon,
  LanguageIcon,
  MapPinIcon,
  PhoneIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import { Skeleton } from '@mui/material';
import { DakotaUser, IdentityClaims } from 'auth/DakotaUser';
import { clsx } from 'clsx';
import LogoutButton from 'components/AuthButton/LogoutButton';
import EditableText from 'components/EditableText';
import Tooltip from 'components/Tooltip';
import { configSlice } from 'features/config/configSlice';
import { flagsSlice } from 'features/flags/flagsSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { updatePhoneNumber } from 'features/user/userActions';
import { userSlice } from 'features/user/userSlice';
import { useLocalePreference } from 'hooks/useLocalePreference';
import useToast from 'hooks/useToast';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { FeatureFlag } from 'utils/featureFlags';

const UserMenu: FC = () => {
  const { user } = useAuth0<DakotaUser>();

  const { ChangeLanguageDropdown } = useLocalePreference();

  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const currentUser = useSelector(userSlice.selectors.currentUser);
  const updating = useSelector(userSlice.selectors.isUpdatingPhoneNumber);

  const isFlagEnabled = useSelector(flagsSlice.selectors.isFlagEnabled);
  const isLocalizationEnabled = isFlagEnabled(FeatureFlag.Localization);

  const { setSuccessMessage } = useToast();

  const [phoneNumberError, setPhoneNumberError] = useState('');

  if (!user) {
    return (
      <div className='w-96 min-h-64 px-4 py-2'>
        <Skeleton height={32} sx={{ mb: 1 }} variant='rounded' />
        <Skeleton height={90} sx={{ mb: 4 }} variant='rounded' />
        <Skeleton height={32} sx={{ mb: 1 }} variant='rounded' />
        <Skeleton height={24} sx={{ mb: 4 }} variant='rounded' />
        <Skeleton height={32} sx={{ mb: 1 }} variant='rounded' />
        <Skeleton height={24} sx={{ mb: 4 }} variant='rounded' />
        <LogoutButton showIcon />
      </div>
    );
  }

  const orgId = user[IdentityClaims.OrgInfo].id;
  const orgName = user[IdentityClaims.OrgInfo].name;

  const userRoles = currentUser.roles.map((role) => role.name).join(', ');

  const copyOrgId = () => {
    void navigator.clipboard.writeText(orgId);
    setSuccessMessage('Organization ID copied to clipboard');
  };

  const updateMyPhoneNumber = (newPhoneNumber: string) => {
    dispatch(updatePhoneNumber({ baseUrl, phone: newPhoneNumber, token }))
      .unwrap()
      .then(() => {
        setSuccessMessage('Phone number updated successfully');
      })
      .catch(() => {
        setPhoneNumberError('Failed to update phone number. Please try again.');
      });
  };

  return (
    <div
      className={clsx(
        'max-sm:w-80 sm:w-96 min-h-64 px-4 py-2',
        'flex flex-col gap-4 text-gray-700 text-xs',
      )}
      data-testid='user-menu'
      id='user-menu'
    >
      <div className='w-full border-b border-gray-200 text-xl'>
        {currentUser.firstName} {currentUser.lastName}
      </div>
      <div className='flex items-center gap-1'>
        <AtSymbolIcon className='w-4 h-4' />
        {currentUser.email}
      </div>
      <div className='flex items-center gap-1'>
        <PhoneIcon className='w-4 h-4' />
        <EditableText
          allowEmpty
          containerClasses='w-full'
          disabled={updating}
          errorMessage={phoneNumberError}
          id='user-phone-number'
          initialEditableValue={currentUser.phone ?? ''}
          onEdit={updateMyPhoneNumber}
          saveOnEnter
          showIcon={false}
          text={
            updating
              ? 'Updating...'
              : currentUser.phone ?? 'No phone number provided'
          }
          textClasses='!p-0 !min-h-0 text-gray-700'
        />
      </div>
      {user[IdentityClaims.Country] && (
        <div className='flex items-center gap-1'>
          <MapPinIcon className='w-4 h-4' />
          {user[IdentityClaims.Country]}
        </div>
      )}
      <div className='flex gap-1'>
        <UserGroupIcon className='h-4' /> {userRoles}
      </div>
      {isLocalizationEnabled && (
        <div className='flex items-center gap-1'>
          <LanguageIcon className='h-4' />
          <ChangeLanguageDropdown />
        </div>
      )}
      <div className='w-full border-b border-gray-200 text-lg mt-4'>
        {orgName}
      </div>
      <div className='flex items-baseline'>
        Org ID:
        <Tooltip title='Click to copy Org ID'>
          <button
            className={clsx(
              'ml-1 font-mono bg-gray-100 p-1 rounded',
              'hover:text-gray-900 hover:underline',
            )}
            id='org-id-button'
            onClick={copyOrgId}
          >
            {orgId}
          </button>
        </Tooltip>
      </div>
      <div className='mt-4'>
        <LogoutButton showIcon />
      </div>
    </div>
  );
};

export default UserMenu;
