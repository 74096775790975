import { LocalDate } from '@js-joda/core';
import { Recurrence as InspectionRecurrence } from 'components/recurrence';
import { inspectionSeriesEditSlice } from 'features/inspections/inspectionSeriesEditSlice';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export const Recurrence: FC = () => {
  const dispatch = useAppDispatch();

  const date = useSelector(inspectionSeriesEditSlice.selectors.startDate);
  const recurrenceRule = useSelector(
    inspectionSeriesEditSlice.selectors.recurrenceRule,
  );

  const initialDate = useMemo(() => LocalDate.parse(date), [date]);

  return (
    <>
      <label htmlFor='recurrence-option-selector'>Recurrence</label>
      <div>
        <InspectionRecurrence
          className='w-full'
          initialDate={initialDate}
          initialRRule={recurrenceRule}
          onChange={(rule) =>
            dispatch(
              inspectionSeriesEditSlice.actions.setInspectionField({
                field: 'recurrenceRule',
                value: rule,
              }),
            )
          }
        />
      </div>
    </>
  );
};
