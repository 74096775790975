import {
  AnswerChoice,
  AnswerType,
  InspectionInstanceDetails,
  InspectionSection,
  isUnzonedInspectionInstance,
  isZonedInspectionInstance,
  Prompt,
  PromptMedia,
} from '@dakota/platform-client';
import { PrinterIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import Button from 'components/Button';
import { renderImageDetails } from 'components/Print/ImageDetails';
import Toggle from 'components/Toggle';
import { useDateFormat } from 'hooks/useDateFormat';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatBackendDateTime } from 'utils/date';

import logo from '../../../src/assets/DakotaLogo_wht.png';
import { getStatusText } from './utils';

const PrintInspection = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { formatBackendDate } = useDateFormat();

  const { answerTypes, inspection } = location.state as {
    answerTypes: AnswerType[];
    inspection: InspectionInstanceDetails;
  };
  const [shouldRenderThumbnails, setShouldRenderThumbnails] = useState(false);

  const inspectionStatus = getStatusText(inspection.status);

  const promptsHaveThumbnail = (prompts: Prompt[]) =>
    prompts.some((prompt) => prompt.media.some((m) => m.thumbnail));

  const sectionsHaveThumbnail = (sections: InspectionSection[]) =>
    sections.some((section) => promptsHaveThumbnail(section.prompts));

  const hasThumbnails =
    (isZonedInspectionInstance(inspection) &&
      sectionsHaveThumbnail(
        inspection.zones.flatMap((zone) => zone.sections),
      )) ||
    (isUnzonedInspectionInstance(inspection) &&
      sectionsHaveThumbnail(inspection.sections));

  const totalQuestionsInSections = (sections: InspectionSection[]) =>
    sections.reduce((acc, section) => acc + section.prompts.length, 0);

  let totalQuestions = 0;
  if (isZonedInspectionInstance(inspection)) {
    totalQuestions = totalQuestionsInSections(
      inspection.zones.flatMap((zone) => zone.sections),
    );
  } else if (isUnzonedInspectionInstance(inspection)) {
    totalQuestions = totalQuestionsInSections(inspection.sections);
  }

  const promptComments = (prompt: Prompt) =>
    prompt.comments
      .filter((c) => c.promptId === prompt.id)
      .map((comment) => (
        <div key={comment.id}>
          <p className='text-sm pl-2'>{comment.text}</p>
          <p className='text-xs pl-2'>
            - created on: {formatBackendDateTime(comment.commented)} by{' '}
            {comment.userName}
          </p>
        </div>
      ));

  const renderAttachmentDetails = (
    promptMedia: PromptMedia[],
    excludeThumbnails: boolean,
  ) =>
    promptMedia
      .filter((media) => !excludeThumbnails || !media.thumbnail)
      .map((media) => (
        <div className='' key={media.id}>
          <p className='text-sm truncate'>{media.originalName}</p>
          <p className='text-xs italic text-gray-500'>
            {media.description ?? 'No description'}
          </p>
        </div>
      ));

  const sectionHeader = (section: InspectionSection) => (
    <p
      className='text-black font-bold p-2 text-lg bg-gray-300'
      data-testid={`section-${section.sectionNumber}-header`}
    >
      {section.header}
    </p>
  );

  const selectedAnswer = (prompt: Prompt) =>
    prompt.response.notApplicable ? 'Skip' : prompt.response.choiceId ?? null;

  const displayChoices = useMemo(
    () => (answerTypeId: string) =>
      answerTypes.find((a) => a.id === answerTypeId)?.answers ?? [],
    [answerTypes],
  );

  const selectedChoiceClasses = 'border rounded-full border-black px-1';

  const promptChoices = (
    choices: AnswerChoice[],
    promptAnswer: null | string,
  ) =>
    choices.map((answer) => (
      <div
        className={promptAnswer === answer.id ? selectedChoiceClasses : ''}
        data-testid={`answerType-choice-${answer.answer}`}
        key={answer.id}
      >
        {answer.answer}
      </div>
    ));

  const skipChoice = (promptAnswer: null | string) => (
    <div
      className={promptAnswer === 'Skip' ? selectedChoiceClasses : ''}
      data-testid='answerType-choice-Skip'
    >
      Skip
    </div>
  );

  const renderSection = (section: InspectionSection) => (
    <div
      data-testid={`section-${section.sectionNumber}`}
      key={section.sectionNumber}
    >
      {sectionHeader(section)}
      {section.prompts.map(renderPrompt)}
    </div>
  );

  const renderPrompt = (prompt: Prompt) => {
    const promptAnswer = selectedAnswer(prompt);
    const choices = displayChoices(prompt.question.answerType.id);

    return (
      <div
        className={clsx(
          'flex flex-col justify-between gap-2 p-2 fit-content',
          'odd:bg-white even:bg-gray-100',
        )}
        data-testid={`prompt-${prompt.id}`}
        key={prompt.id}
      >
        <div className='flex flex-row w-full'>
          <div className='flex-1'>
            <p
              className='font-semibold text-gray-800 text-md leading-6'
              data-testid={`question-text-${prompt.id}`}
            >
              {prompt.question.text}
            </p>
            <div
              className='text-sm text-gray-500'
              data-testid={`citation-text-${prompt.id}`}
            >
              {prompt.question.citation}
            </div>
          </div>
          <div
            className='flex gap-2 font-semibold text-sm h-fit'
            data-testid={`answerTypes-${prompt.question.answerType.name}`}
          >
            {promptChoices(choices, promptAnswer)}
            {skipChoice(promptAnswer)}
          </div>
        </div>
        {prompt.media.length > 0 && (
          <>
            <p className='font-semibold'>Attachments:</p>
            {shouldRenderThumbnails ? (
              <>
                <div className='grid grid-cols-3 gap-2'>
                  {renderImageDetails(prompt.media, shouldRenderThumbnails)}
                </div>
                <div className='flex flex-col gap-2'>
                  {renderAttachmentDetails(prompt.media, true)}
                </div>
              </>
            ) : (
              <div className='flex flex-col gap-2'>
                {renderAttachmentDetails(prompt.media, false)}
              </div>
            )}
          </>
        )}
        {prompt.comments.length > 0 && (
          <>
            <p className='font-semibold'>Notes:</p> {promptComments(prompt)}
          </>
        )}
      </div>
    );
  };

  return (
    <div className='m-9 print:m-0'>
      <div
        className={clsx(
          'print:hidden bg-blue-lightest rounded-lg flex flex-col',
          'items-center border border-blue-base p-6 mb-4 text-sm',
          'text-gray-900 gap-4 w-full sm:w-2/5 mx-auto',
        )}
        data-testid='print-inspection-message'
      >
        <div className='text-center text-pretty space-y-2'>
          <p className='text-base font-medium'>
            This is a preview of the printed version of your Inspection.
          </p>
          <p>
            On your print dialog you can adjust the scale to make sure the
            content fits on the page. This message will not be printed.
          </p>
        </div>
        {hasThumbnails && (
          <Toggle
            id='display-thumbnails'
            isOn={shouldRenderThumbnails}
            label='Include Image Thumbnails'
            onToggle={() => {
              setShouldRenderThumbnails((prev) => !prev);
            }}
          />
        )}
        <Button
          data-testid='print-inspection-button'
          icon={<PrinterIcon />}
          onClick={() => {
            window.print();
          }}
        >
          Print Inspection
        </Button>
        <Button
          className='text-xs'
          data-testid='inspection-back-button'
          onClick={() => {
            navigate(-1);
          }}
          secondary
        >
          Return
        </Button>
      </div>
      <div className='bg-gray-600 p-2' data-testid='logo'>
        <img alt='dakota-logo' className='w-32' src={logo} />
      </div>
      <div className='p-2 my-2' data-testid='print-header'>
        <div>
          {inspection.ticketId && (
            <div className='text-xs font-semibold'>
              ID: {inspection.ticketId}
            </div>
          )}
          <div
            className='text-xl font-bold'
            data-testid='print-header-form-name'
          >
            {inspection.form.name}
          </div>
          <div
            className='text-sm space-x-1'
            data-testid='print-header-form-details'
          >
            <span className='font-bold'>Facility name:</span>
            <span>{inspection.facility.name}</span>
            <span className='font-bold'>Due Date:</span>
            <span>{formatBackendDate(inspection.timeline.dueDate)}</span>
            <span className='font-bold'>Inspector:</span>
            <span>{inspection.assigneeName}</span>
            <span className='font-bold'>Status:</span>
            <span>{inspectionStatus}</span>
            <span className='font-bold'># of Questions:</span>
            <span>{totalQuestions}</span>
          </div>
        </div>
      </div>
      {isZonedInspectionInstance(inspection) &&
        inspection.zones.map((zone) => (
          <div data-testid={`inspection-zone-${zone.order}`} key={zone.id}>
            <div
              className='text-black font-bold p-2 text-xl bg-gray-400'
              data-testid={`inspection-zone-${zone.order}-header`}
            >
              Zone: {zone.name}
            </div>
            {zone.sections.map(renderSection)}
          </div>
        ))}
      {isUnzonedInspectionInstance(inspection) &&
        inspection.sections.map(renderSection)}
    </div>
  );
};

export default PrintInspection;
