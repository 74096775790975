import { InspectionSection } from '@dakota/platform-client';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

import { Prompt } from './Prompt';

type SectionProps = {
  dueDate: string;
  /**
   * The section to display in this accordion.
   */
  section: InspectionSection;
  /**
   * The index of the section in the conduct inspection state.
   */
  sectionIndex: number;
  seriesId: string;
};

export const Section: FC<SectionProps> = ({
  dueDate,
  section,
  sectionIndex,
  seriesId,
}) => {
  const dispatch = useAppDispatch();

  const isSectionExpanded = useSelector(
    inspectionSlice.selectors.isSectionExpanded,
  );

  const toggleSection = () => {
    dispatch(inspectionSlice.actions.toggleSectionExpanded(sectionIndex));
  };

  return (
    <Accordion
      aria-label={`Section ${sectionIndex}`}
      disableGutters
      expanded={isSectionExpanded(sectionIndex)}
      key={section.sectionNumber}
      onChange={toggleSection}
      sx={{
        '&:before': { display: 'none' },
        '&:first-of-type': { borderRadius: '8px' },
        '&:last-of-type': { borderRadius: '8px' },
        bgcolor: 'var(--color-white)',
        borderRadius: '8px',
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)',
        marginBottom: 2,
        zIndex: 1,
      }}
    >
      <AccordionSummary
        aria-controls={`panel${sectionIndex}a-content`}
        expandIcon={<ExpandMoreIcon />}
        id={`panel${sectionIndex}a-header`}
        sx={{
          backgroundColor: 'var(--color-gray-50)',
          borderRadius: '8px',
          padding: '15px',
          position: 'sticky',
          top: '0',
          zIndex: 1,
        }}
      >
        <p className='text-gray-700 font-bold text-md leading-5'>
          {section.header}
        </p>
      </AccordionSummary>
      <AccordionDetails sx={{ flexDirection: 'column', padding: 0 }}>
        {section.prompts.map((prompt, promptIndex) => (
          <Prompt
            key={prompt.id}
            {...{ dueDate, prompt, promptIndex, sectionIndex, seriesId }}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
