import {
  InspectionInstance,
  InspectionInstanceDetails,
  InspectionSection,
  isUnzonedInspectionInstance,
  isZonedInspectionInstance,
  PromptComment,
  PromptMedia,
  PromptResponse,
  PromptResponseDetails,
  UnzonedInspectionDetails,
  ZonedInspectionDetails,
} from '@dakota/platform-client';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import dotProp from 'dot-prop-immutable';
import { WritableDraft } from 'immer';
import { compareByDateTime } from 'utils/date';

import {
  addAttachment,
  addNote,
  cancelInspection,
  completeInspection,
  deleteAttachment,
  deleteNote,
  getInspectionDetails,
  listInspectionInstances,
  saveResponses,
  startInspection,
  updateAttachment,
  updateNote,
} from './inspectionActions';

export type ResponseType = {
  answerId: string;
  promptId: string;
};

/**
 * Status necessary to conduct an inspection, from the inspection itself
 * to the responses, comments and media that the user enters.
 */
type ConductInspectionState = {
  /**
   * The index of the current zone being inspected.
   * For unzoned inspections, this is always 0.
   */
  currentZoneIndex: number;
  /**
   * Indicates if there are unsaved changes in the inspection.
   */
  hasUnsavedChanges: boolean;
  /**
   * The details object for the inspection, either with sections,
   * or zones with sections. Uninitialized at first until we load it
   * from the backend.
   */
  inspection?: InspectionInstanceDetails;
  /**
   * The list of inspections as returned by the `listInspectionInstances` API.
   * Not guaranteed to be fresh to contain any particular inspection,
   * as it depends on the last time the API was called.
   */
  inspections: InspectionInstance[];
  /**
   * A map of facility IDs to the inspections associated to that facility.
   */
  inspectionsPerFacility: Map<string, InspectionInstance[]>;
  /**
   * Indicates if the confirmation dialog to cancel the inspection is open.
   */
  isCancelConfirmationOpen: boolean;
  /**
   * True only while the inspection is being canceled.
   */
  isCancelingInspection: boolean;
  /**
   * True while the inspection is being completed in the backend.
   */
  isCompletingInspection: boolean;
  /**
   * True when fetching inspections from the `listInspections` API.
   */
  isLoadingInspections: boolean;
  /**
   * True while the responses are being saved.
   */
  isSavingResponses: boolean;
  /**
   * For each section, indicates if the accordion is expanded.
   */
  sectionAccordions: boolean[];
};

const initialState: ConductInspectionState = {
  currentZoneIndex: 0,
  hasUnsavedChanges: false,
  inspection: undefined,
  inspections: [],
  inspectionsPerFacility: new Map(),
  isCancelConfirmationOpen: false,
  isCancelingInspection: false,
  isCompletingInspection: false,
  isLoadingInspections: false,
  isSavingResponses: false,
  sectionAccordions: [],
};

const loadInspectionInstanceDetails = (
  state: WritableDraft<ConductInspectionState>,
  action: PayloadAction<InspectionInstanceDetails>,
) => {
  const inspection = action.payload;
  // Obscene complication, but it helps to have the notes and attachments
  // sorted from the moment we load the inspection details
  if (isZonedInspectionInstance(inspection)) {
    state.inspection = {
      ...inspection,
      zones: inspection.zones.map((zone) => ({
        ...zone,
        sections: sortNotesAndAttachments(zone.sections),
      })),
    } as ZonedInspectionDetails;
  } else if (isUnzonedInspectionInstance(inspection)) {
    state.inspection = {
      ...inspection,
      sections: sortNotesAndAttachments(inspection.sections),
    } as UnzonedInspectionDetails;
  }
  /**
   * There are no unsaved changes when we first load an inspection.
   */
  state.hasUnsavedChanges = false;
  /**
   * Fill the section accordion with the corresponding section count,
   * all set to `expanded` as default.
   */
  state.sectionAccordions = Array<boolean>(
    countTotalSections(action.payload),
  ).fill(true);
};

const updateInspectionsPerFacility = (
  state: WritableDraft<ConductInspectionState>,
  inspections: InspectionInstance[],
) => {
  inspections.forEach((inspection) => {
    const facilityId = inspection.facility.id;
    const inspectionId = inspection.id;
    const currentInspections = state.inspectionsPerFacility.get(facilityId);
    if (!currentInspections?.some((i) => i.id === inspectionId)) {
      state.inspectionsPerFacility.set(
        facilityId,
        (currentInspections ?? []).concat(inspection),
      );
    }
  });
};

export const inspectionSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(
      getInspectionDetails.fulfilled,
      loadInspectionInstanceDetails,
    );
    builder.addCase(startInspection.fulfilled, (state, action) => {
      state.inspection = action.payload;
      state.hasUnsavedChanges = false;
      state.sectionAccordions = Array<boolean>(
        countTotalSections(action.payload),
      ).fill(true);
    });
    builder.addCase(cancelInspection.fulfilled, (state, action) => {
      const { dueDate, seriesId } = action.meta.arg;
      if (
        state.inspection?.seriesId === seriesId &&
        state.inspection.timeline.dueDate === dueDate
      ) {
        inspectionSlice.caseReducers.clearInspection(state);
      }
      state.inspections = state.inspections.filter(
        (i) => i.seriesId !== seriesId || i.timeline.dueDate !== dueDate,
      );
      state.inspectionsPerFacility.forEach((inspections, facilityId) => {
        state.inspectionsPerFacility.set(
          facilityId,
          inspections.filter(
            (i) => i.seriesId !== seriesId && i.timeline.dueDate !== dueDate,
          ),
        );
      });
    });
    builder.addCase(saveResponses.pending, (state) => {
      state.isSavingResponses = true;
    });
    builder.addCase(saveResponses.fulfilled, (state) => {
      state.hasUnsavedChanges = false;
      state.isSavingResponses = false;
    });
    builder.addCase(saveResponses.rejected, (state) => {
      state.isSavingResponses = false;
    });
    builder.addCase(completeInspection.fulfilled, (state, action) => {
      state.isCompletingInspection = false;
      state.inspection = action.payload;
    });
    builder.addCase(completeInspection.pending, (state) => {
      state.isCompletingInspection = true;
    });
    builder.addCase(completeInspection.rejected, (state) => {
      state.isCompletingInspection = false;
    });
    builder.addCase(addNote.fulfilled, (state, action) => {
      const { promptIndex, sectionIndex } = action.meta.arg;

      if (isUnzonedInspectionInstance(state.inspection)) {
        state.inspection = dotProp.set(
          state.inspection,
          `sections.${sectionIndex}.prompts.${promptIndex}.comments`,
          (comments: PromptComment[]) => [...comments, action.payload],
        );
      } else if (isZonedInspectionInstance(state.inspection)) {
        state.inspection = dotProp.set(
          state.inspection,
          `zones.${state.currentZoneIndex}.sections.${sectionIndex}.prompts.${promptIndex}.comments`,
          (comments: PromptComment[]) => [...comments, action.payload],
        );
      }
    });
    builder.addCase(updateNote.fulfilled, (state, action) => {
      const { noteIndex, promptIndex, sectionIndex } = action.meta.arg;

      if (isUnzonedInspectionInstance(state.inspection)) {
        state.inspection = dotProp.set(
          state.inspection,
          `sections.${sectionIndex}.prompts.${promptIndex}.comments.${noteIndex}.text`,
          action.payload.text,
        );
      } else if (isZonedInspectionInstance(state.inspection)) {
        state.inspection = dotProp.set(
          state.inspection,
          `zones.${state.currentZoneIndex}.sections.${sectionIndex}.prompts.${promptIndex}.comments.${noteIndex}.text`,
          action.payload.text,
        );
      }
    });
    builder.addCase(deleteNote.fulfilled, (state, action) => {
      const { noteIndex, promptIndex, sectionIndex } = action.meta.arg;

      // Note: dot-prop delete returns a `Partial` inspection object,
      // so we can't assign it directly to the state, which is why
      // we need to cast it to the correct type.
      if (isUnzonedInspectionInstance(state.inspection)) {
        state.inspection = dotProp.delete<UnzonedInspectionDetails>(
          state.inspection,
          `sections.${sectionIndex}.prompts.${promptIndex}.comments.${noteIndex}`,
        ) as UnzonedInspectionDetails;
      } else if (isZonedInspectionInstance(state.inspection)) {
        state.inspection = dotProp.delete<ZonedInspectionDetails>(
          state.inspection,
          `zones.${state.currentZoneIndex}.sections.${sectionIndex}.prompts.${promptIndex}.comments.${noteIndex}`,
        ) as ZonedInspectionDetails;
      }
    });
    builder.addCase(addAttachment.fulfilled, (state, action) => {
      const { promptIndex, sectionIndex } = action.meta.arg;

      if (isUnzonedInspectionInstance(state.inspection)) {
        state.inspection = dotProp.set(
          state.inspection,
          `sections.${sectionIndex}.prompts.${promptIndex}.media`,
          (attachments: PromptMedia[]) => [...attachments, action.payload],
        );
      } else if (isZonedInspectionInstance(state.inspection)) {
        state.inspection = dotProp.set(
          state.inspection,
          `zones.${state.currentZoneIndex}.sections.${sectionIndex}.prompts.${promptIndex}.media`,
          (attachments: PromptMedia[]) => [...attachments, action.payload],
        );
      }
    });
    builder.addCase(updateAttachment.fulfilled, (state, action) => {
      const { attachmentIndex, promptIndex, sectionIndex } = action.meta.arg;

      if (isUnzonedInspectionInstance(state.inspection)) {
        state.inspection = dotProp.set(
          state.inspection,
          `sections.${sectionIndex}.prompts.${promptIndex}.media.${attachmentIndex}.description`,
          action.payload.description,
        );
      } else if (isZonedInspectionInstance(state.inspection)) {
        state.inspection = dotProp.set(
          state.inspection,
          `zones.${state.currentZoneIndex}.sections.${sectionIndex}.prompts.${promptIndex}.media.${attachmentIndex}.description`,
          action.payload.description,
        );
      }
    });
    builder.addCase(deleteAttachment.fulfilled, (state, action) => {
      const { attachmentIndex, promptIndex, sectionIndex } = action.meta.arg;

      if (isUnzonedInspectionInstance(state.inspection)) {
        state.inspection = dotProp.delete<UnzonedInspectionDetails>(
          state.inspection,
          `sections.${sectionIndex}.prompts.${promptIndex}.media.${attachmentIndex}`,
        ) as UnzonedInspectionDetails;
      } else if (isZonedInspectionInstance(state.inspection)) {
        state.inspection = dotProp.delete<ZonedInspectionDetails>(
          state.inspection,
          `zones.${state.currentZoneIndex}.sections.${sectionIndex}.prompts.${promptIndex}.media.${attachmentIndex}`,
        ) as ZonedInspectionDetails;
      }
    });
    builder.addCase(listInspectionInstances.pending, (state) => {
      state.isLoadingInspections = true;
    });

    builder.addCase(listInspectionInstances.fulfilled, (state, action) => {
      state.isLoadingInspections = false;
      state.inspections = action.payload;
      updateInspectionsPerFacility(state, action.payload);
    });
    builder.addCase(listInspectionInstances.rejected, (state) => {
      state.isLoadingInspections = false;
    });
  },
  initialState,
  name: 'conductInspection',
  reducers: {
    clearInspection: (state) => {
      state.inspection = undefined;
      state.currentZoneIndex = 0;
      state.isCancelConfirmationOpen = false;
      state.hasUnsavedChanges = false;
      state.sectionAccordions = [];
    },
    closeCancelConfirmation: (state) => {
      state.isCancelConfirmationOpen = false;
    },
    /**
     * Collapses all the sections.
     */
    collapseAll: (state) => {
      state.sectionAccordions.fill(false);
    },
    /**
     * Expands all the sections.
     */
    expandAll: (state) => {
      state.sectionAccordions.fill(true);
    },
    goToNextZone: (state) => {
      state.currentZoneIndex++;
      inspectionSlice.caseReducers.expandAll(state);
    },
    goToPreviousZone: (state) => {
      state.currentZoneIndex--;
      inspectionSlice.caseReducers.expandAll(state);
    },
    goToZone: (state, action: PayloadAction<string>) => {
      const zoneId = action.payload;
      const index = (
        state.inspection as ZonedInspectionDetails
      ).zones.findIndex((zone) => zone.id === zoneId);
      if (index !== -1) {
        state.currentZoneIndex = index;
      }
    },
    openCancelConfirmationDialog: (state) => {
      state.isCancelConfirmationOpen = true;
    },
    selectAnswer: (
      state,
      action: PayloadAction<{
        choiceId: null | string;
        promptIndex: number;
        sectionIndex: number;
      }>,
    ) => {
      const { choiceId, promptIndex, sectionIndex } = action.payload;
      if (isUnzonedInspectionInstance(state.inspection)) {
        state.inspection = dotProp.set(
          state.inspection,
          `sections.${sectionIndex}.prompts.${promptIndex}.response`,
          (response: PromptResponseDetails) => ({
            ...response,
            choiceId:
              choiceId == null || choiceId === 'Skip' ? undefined : choiceId,
            notApplicable: choiceId == 'Skip',
          }),
        );
      } else if (isZonedInspectionInstance(state.inspection)) {
        state.inspection = dotProp.set(
          state.inspection,
          `zones.${state.currentZoneIndex}.sections.${sectionIndex}.prompts.${promptIndex}.response`,
          (response: PromptResponseDetails) => ({
            ...response,
            choiceId:
              choiceId == null || choiceId === 'Skip' ? undefined : choiceId,
            notApplicable: choiceId == 'Skip',
          }),
        );
      }
      state.hasUnsavedChanges = true;
    },
    setIsCanceling(state, action: PayloadAction<boolean>) {
      state.isCancelingInspection = action.payload;
    },
    setIsCompleting(state, action: PayloadAction<boolean>) {
      state.isCompletingInspection = action.payload;
    },
    setSavingResponses(state, action: PayloadAction<boolean>) {
      state.isSavingResponses = action.payload;
    },
    toggleSectionExpanded: (state, action: PayloadAction<number>) => {
      const index = action.payload;
      state.sectionAccordions[index] = !state.sectionAccordions[index];
    },
  },
  selectors: {
    areAllSectionsCollapsed: (state: ConductInspectionState) =>
      state.sectionAccordions.every((isExpanded) => !isExpanded),
    areAllSectionsExpanded: (state: ConductInspectionState) =>
      state.sectionAccordions.every(Boolean),
    currentZoneIndex: (state: ConductInspectionState) => state.currentZoneIndex,
    hasUnsavedChanges: (state: ConductInspectionState) =>
      state.hasUnsavedChanges,
    inspectionDetails: (state: ConductInspectionState) => state.inspection,
    inspections: (state: ConductInspectionState) => state.inspections,
    inspectionsPerFacility: (state: ConductInspectionState) =>
      state.inspectionsPerFacility,
    isCancelConfirmationOpen: (state: ConductInspectionState) =>
      state.isCancelConfirmationOpen,
    isCancelingInspection: (state: ConductInspectionState) =>
      state.isCancelingInspection,
    isCompletingInspection: (state: ConductInspectionState) =>
      state.isCompletingInspection,
    isLoadingInspections: (state: ConductInspectionState) =>
      state.isLoadingInspections,
    isSavingResponses: (state: ConductInspectionState) =>
      state.isSavingResponses,
    isSectionExpanded: (state: ConductInspectionState) =>
      isSectionExpanded(state),
    totalAnsweredQuestions: (state: ConductInspectionState) =>
      countTotalAnsweredQuestions(state),
    totalNotes: (state: ConductInspectionState) => countTotalNotes(state),
    totalQuestions: (state: ConductInspectionState) =>
      countTotalQuestions(state),
  },
});

export const countAnsweredQuestionsInSections = (
  sections: InspectionSection[],
) =>
  sections
    .flatMap((section) => section.prompts)
    .map((prompt) => prompt.response)
    .reduce(
      (acc, response) =>
        acc + (response.choiceId ?? response.notApplicable ? 1 : 0),
      0,
    );

export const countTotalQuestionsInSections = (sections: InspectionSection[]) =>
  sections.flatMap((section) => section.prompts).length;

const countTotalAnsweredQuestions = createSelector(
  (state: ConductInspectionState) => state.inspection,
  (inspection) => {
    if (isUnzonedInspectionInstance(inspection)) {
      return countAnsweredQuestionsInSections(inspection.sections);
    } else if (isZonedInspectionInstance(inspection)) {
      return countAnsweredQuestionsInSections(
        inspection.zones.flatMap((zone) => zone.sections),
      );
    }
    return 0;
  },
);

const countTotalQuestions = createSelector(
  (state: ConductInspectionState) => state.inspection,
  (inspection) => {
    if (isUnzonedInspectionInstance(inspection)) {
      return countTotalQuestionsInSections(inspection.sections);
    } else if (isZonedInspectionInstance(inspection)) {
      return countTotalQuestionsInSections(
        inspection.zones.flatMap((zone) => zone.sections),
      );
    }
    return 0;
  },
);

const isSectionExpanded = createSelector(
  (state: ConductInspectionState) => state.sectionAccordions,
  (sectionAccordions) => (index: number) => sectionAccordions[index],
);

const countTotalSections = (inspection: InspectionInstanceDetails) => {
  if (isUnzonedInspectionInstance(inspection)) {
    return inspection.sections.length;
  }
  const zonedInspection = inspection as ZonedInspectionDetails;
  // A zoned inspection will have an empty zones array
  // when it's scheduled or overdue.
  if (zonedInspection.zones.length === 0) {
    return 0;
  }
  return zonedInspection.zones[0].sections.length;
};

const countNotesInSections = (sections: InspectionSection[]) =>
  sections
    .flatMap((section) => section.prompts)
    .flatMap((prompt) => prompt.comments).length;

const countTotalNotes = createSelector(
  (state: ConductInspectionState) => state.inspection,
  (inspection) => {
    if (isUnzonedInspectionInstance(inspection)) {
      return countNotesInSections(inspection.sections);
    } else if (isZonedInspectionInstance(inspection)) {
      return countNotesInSections(
        inspection.zones.flatMap((zone) => zone.sections),
      );
    }
    return 0;
  },
);

const sortNotesAndAttachments = (sections: InspectionSection[]) => {
  return sections.map((section) => ({
    ...section,
    prompts: section.prompts.map((prompt) => ({
      ...prompt,
      comments: prompt.comments.toSorted((lhs, rhs) =>
        compareByDateTime(lhs.commented, rhs.commented),
      ),
      media: prompt.media.toSorted((lhs, rhs) =>
        compareByDateTime(lhs.uploaded, rhs.uploaded),
      ),
    })),
  }));
};

export const responsesInSections = (sections: InspectionSection[]) =>
  sections.flatMap((section) =>
    section.prompts.map(
      (prompt) =>
        ({
          promptId: prompt.id,
          response: prompt.response,
        }) as PromptResponse,
    ),
  );
