import { Group, ItemType } from '@dakota/platform-client';
import { PencilIcon, PlusIcon } from '@heroicons/react/24/outline';
import Autocomplete from 'components/Autocomplete';
import { getRendererWithLibraryLogo } from 'components/Autocomplete/renderFunctions';
import Input from 'components/Input';
import { SidePanel_v2 as SidePanel } from 'components/SidePanel_v2';
import { configSlice } from 'features/config/configSlice';
import { itemGroupsSlice } from 'features/items/itemGroupsSlice';
import { addItemType, updateItemType } from 'features/items/itemTypesActions';
import { itemTypesSlice } from 'features/items/itemTypesSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { useInternationalization } from 'hooks/useInternationalization';
import useToast from 'hooks/useToast';
import { FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export type AddEditItemTypeProps = {
  /**
   * If present, the component will be in edit mode.
   * Otherwise it will be in add mode.
   */
  itemType?: ItemType;
  onClose: () => void;
};

const AddEditItemType: FC<AddEditItemTypeProps> = ({ itemType, onClose }) => {
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const dispatch = useAppDispatch();

  const t = useInternationalization('manage');

  const itemTypes = useSelector(itemTypesSlice.selectors.itemTypes);
  const itemGroups = useSelector(itemGroupsSlice.selectors.itemGroups);

  const { setErrorMessage, setSuccessMessage } = useToast();

  const [name, setName] = useState(itemType?.name ?? '');
  const [itemGroup, setItemGroup] = useState<Group>(
    itemGroups.find((g) => g.id === itemType?.groupId) ?? itemGroups[0],
  );
  const [saving, setSaving] = useState(false);

  const isEdit = !!itemType;

  // To validate the form, we check that the name doesn't clash with
  // any existing item type
  let existingItemTypes = itemTypes;
  if (itemType) {
    existingItemTypes = existingItemTypes.filter((f) => f.id !== itemType.id);
  }

  const trimmedName = name.trim();
  const nameExists = existingItemTypes.find(
    (f) => f.name.toLowerCase() === trimmedName.toLowerCase(),
  );

  const nameError = nameExists
    ? 'There is already an Item Type with this name'
    : '';

  const clearFormData = useCallback(() => {
    setName(itemType?.name ?? '');
    setItemGroup(itemGroups[0]);
  }, [itemGroups, itemType?.name]);

  // Form is valid if all required fields are present and name is not already
  // taken
  const isFormValid = !!trimmedName && !nameExists;

  // Edit is valid if any field is different to its original
  const isEditValid =
    trimmedName !== itemType?.name || itemGroup.id !== itemType.groupId;

  const submitHandler = () => {
    setSaving(true);

    if (itemType) {
      const updatedItemType = {
        groupId: itemGroup.id,
        id: itemType.id,
        name: trimmedName,
      } as ItemType;

      const payload = {
        baseUrl,
        itemType: updatedItemType,
        token,
      };

      dispatch(updateItemType(payload))
        .unwrap()
        .then(() => {
          clearFormData();
          setSuccessMessage('Item type updated successfully');
        })
        .catch(() => {
          setErrorMessage('Failed to update item type');
        })
        .finally(() => {
          setSaving(false);
          onClose();
        });
    } else {
      const payload = {
        baseUrl,
        itemType: {
          groupId: itemGroup.id,
          name: trimmedName,
        } as ItemType,
        token,
      };

      dispatch(addItemType(payload))
        .unwrap()
        .then(() => {
          clearFormData();
          setSuccessMessage('Item type added successfully');
        })
        .catch(() => {
          setErrorMessage('Failed to add item type');
        })
        .finally(() => {
          setSaving(false);
          onClose();
        });
    }
  };

  const clearAndClose = () => {
    if (!itemType) {
      // Clear the form only if we are adding a new item type
      clearFormData();
    }
    onClose();
  };

  return (
    <SidePanel
      onClose={clearAndClose}
      primary={{
        action: submitHandler,
        disabled: !isFormValid || (isEdit && !isEditValid),
        loading: saving,
        text: t('cta.save'),
      }}
      secondary={{ action: clearAndClose, text: t('cta.close') }}
      title={itemType ? t('cta.editItemType') : t('cta.addItemType')}
      titleIcon={itemType ? <PencilIcon /> : <PlusIcon />}
    >
      <Input
        className='w-full mb-6'
        errorExplanation={nameError}
        id='item-type-name'
        label='Item Type Name'
        onChange={(e) => {
          setName(e.target.value);
        }}
        required
        value={name}
      />
      <Autocomplete
        getOptionKey={(option) => option.id}
        getOptionLabel={(option) => option.name}
        id='item-type-group'
        onChange={setItemGroup}
        options={itemGroups}
        outsideLabel='Select Item Group'
        renderOption={getRendererWithLibraryLogo((option) => option.name)}
        required
        value={itemGroup}
      />
    </SidePanel>
  );
};

export default AddEditItemType;
