import { FileType } from '@dakota/platform-client';
import {
  faFile,
  faFileAudio,
  faFileGif,
  faFileImage,
  faFilePdf,
  faFileSpreadsheet,
  faFileVideo,
  faPaperclip,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'components/Tooltip';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC, useMemo } from 'react';

type AttachmentIconProps = {
  fileType: string | typeof FileType;
};

export const AttachmentIcon: FC<AttachmentIconProps> = ({ fileType }) => {
  const t = useInternationalization(
    'inspections.prompts.attachments.card.icon',
  );
  const { isMobile } = useBreakpoints();

  const { icon, tooltip } = useMemo(() => {
    switch (fileType) {
      case 'application/pdf':
      case FileType.PDF:
        return { icon: faFilePdf, tooltip: t('pdf') };
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case FileType.Spreadsheet:
      case 'text/csv':
        return { icon: faFileSpreadsheet, tooltip: t('spreadsheet') };
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case FileType.Document:
        return { icon: faFile, tooltip: t('document') };
      case FileType.Audio:
        return { icon: faFileAudio, tooltip: t('audio') };
      case FileType.Image:
      case 'image/jpeg':
      case 'image/png':
        return { icon: faFileImage, tooltip: t('image') };
      case FileType.Video:
      case 'video/mp4':
        return { icon: faFileVideo, tooltip: t('video') };
      case 'image/gif':
        return { icon: faFileGif, tooltip: t('gif') };
      case FileType.Other:
      default:
        return { icon: faPaperclip, tooltip: t('other') };
    }
  }, [fileType, t]);

  const faIcon = useMemo(
    () => (
      <FontAwesomeIcon aria-label={tooltip} className='text-4xl' icon={icon} />
    ),
    [icon, tooltip],
  );

  return (
    <div className='text-gray-400'>
      {isMobile ? (
        <div className='flex flex-col gap-1 font-normal'>
          {faIcon}
          {tooltip}
        </div>
      ) : (
        <Tooltip arrow title={tooltip}>
          {faIcon}
        </Tooltip>
      )}
    </div>
  );
};
