import { Group, ItemType } from '@dakota/platform-client';
import { PlusIcon } from '@heroicons/react/20/solid';
import { GridColDef } from '@mui/x-data-grid';
import PermissionGuard from 'auth/PermissionGuard';
import Autocomplete from 'components/Autocomplete';
import { getRendererWithLibraryLogo } from 'components/Autocomplete/renderFunctions';
import Button from 'components/Button';
import Chip from 'components/Chip';
import { ClearAllButton } from 'components/ClearAll';
import DakotaLibraryLogo from 'components/DakotaLibraryLogo';
import { PageHeader } from 'components/PageHeader';
import SearchInput from 'components/SearchInput';
import { MuiGridWrapper } from 'components/Table/GridWrapper';
import { itemGroupsSlice } from 'features/items/itemGroupsSlice';
import { itemTypesSlice } from 'features/items/itemTypesSlice';
import Fuse from 'fuse.js';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useCheckPermission } from 'hooks/useCheckPermission';
import { useEnumLabel } from 'hooks/useEnumLabel';
import { useInternationalization } from 'hooks/useInternationalization';
import { DataStatus, usePageLoadTracking } from 'hooks/usePageLoadTracking';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ActiveStatus, activeStatuses } from 'types';
import { alphabeticalCompare } from 'utils/functional';
import { getHighlighter } from 'utils/highlighter';
import { Permission } from 'utils/permissions';

import AddEditItemType from './addItemType';
import ItemTypeMenu from './itemTypeMenu';

const ItemTypes: React.FC = () => {
  const { isMobile } = useBreakpoints();
  const t = useInternationalization('manage.table.header');
  const t2 = useInternationalization();
  const { getActiveStatusLabel, Label } = useEnumLabel();

  const { stopTracking } = usePageLoadTracking();

  const itemGroups = useSelector(itemGroupsSlice.selectors.itemGroups);

  const itemTypes = useSelector(itemTypesSlice.selectors.itemTypes);

  useEffect(() => {
    stopTracking(DataStatus.PreviouslyLoaded);
  }, [stopTracking]);

  const canSeeContextualMenu = useCheckPermission([
    Permission.UpdateItemMetadata,
    Permission.DeactivateItemMetadata,
  ]);

  const [searchQuery, setSearchQuery] = useState('');
  const [selectableItemGroups, setSelectableItemGroups] = useState<Group[]>([]);
  const [selectedItemGroups, setSelectedItemGroups] = useState<Group[]>([]);
  const [isAddItemTypePanelOpen, setIsAddItemTypePanelOpen] = useState(false);

  // We *only* display the filtered item types
  const [filteredItemTypes, setFilteredItemTypes] = useState<ItemType[]>([]);

  const [selectedStatus, setSelectedStatus] = useState<ActiveStatus>('Active');

  useEffect(() => {
    setSelectableItemGroups(
      itemGroups
        .filter((ig) => !ig.inactive)
        .toSorted(alphabeticalCompare((e) => e.name)),
    );
  }, [itemGroups]);

  useEffect(() => {
    // Add item group name to facilitate search
    let newItemTypes = itemTypes.map((itemType) => ({
      ...itemType,
      itemGroupName: itemGroups.find((g) => g.id === itemType.groupId)?.name,
    }));

    if (selectedStatus === 'Active') {
      newItemTypes = newItemTypes.filter((q) => !q.inactive);
    } else if (selectedStatus === 'Inactive') {
      newItemTypes = newItemTypes.filter((q) => q.inactive);
    }

    if (selectedItemGroups.length > 0) {
      newItemTypes = newItemTypes.filter((itemType) =>
        selectedItemGroups.find((group) => group.id === itemType.groupId),
      );
    }

    if (searchQuery) {
      const fuse = new Fuse(newItemTypes, {
        findAllMatches: true,
        ignoreLocation: true,
        keys: ['name', 'itemGroupName'],
        shouldSort: true,
        threshold: 0.2,
        useExtendedSearch: true,
      });
      newItemTypes = fuse.search(searchQuery).map((e) => e.item);
    }

    setFilteredItemTypes(
      // convert back to ItemType without group name for display
      newItemTypes
        .toSorted(alphabeticalCompare((i) => i.name))
        .map((i) => ItemType.fromJS(i)),
    );
  }, [itemGroups, itemTypes, searchQuery, selectedItemGroups, selectedStatus]);

  const removeItemGroup = (group: Group) => {
    setSelectedItemGroups((prev) => prev.filter((g) => g.id !== group.id));
  };

  const resetFilters = () => {
    setSearchQuery('');
    setSelectedItemGroups([]);
    setSelectedStatus('Active');
  };

  const getClasses = (inactive: boolean) => (inactive ? 'text-gray-400' : '');

  const highlight = getHighlighter(searchQuery);

  const columns: GridColDef<ItemType>[] = [
    {
      field: 'item-type',
      flex: 1,
      headerName: t('itemType.name'),
      renderCell: (params) => {
        return (
          <p
            aria-label='Item Type name'
            className={getClasses(params.row.inactive)}
          >
            {highlight(params.row.name)}
            {params.row.isPlatformDefined && <DakotaLibraryLogo />}
          </p>
        );
      },
    },
    {
      field: 'item-group',
      flex: 1,
      headerName: t('itemGroup.name'),
      renderCell: (params) => {
        const group = itemGroups.find((g) => g.id === params.row.groupId);
        return (
          <p className={getClasses(params.row.inactive)}>
            {highlight(group?.name)}
            {group?.isPlatformDefined && <DakotaLibraryLogo />}
          </p>
        );
      },
    },
  ];

  if (!isMobile) {
    columns.push({
      display: 'flex',
      field: 'menu',
      headerName: '',
      renderCell: (params) => {
        const itemType = params.row;
        const group = itemGroups.find(
          (g) => g.id === itemType.groupId,
        ) as Group;
        return canSeeContextualMenu &&
          !itemType.isPlatformDefined &&
          !itemType.isDefault ? (
          <ItemTypeMenu itemGroup={group} itemType={itemType} />
        ) : (
          <></>
        );
      },
      resizable: false,
      width: 50,
    });
  }

  return (
    <div className='p-4 sm:p-8'>
      <PageHeader
        scaffold={[t2('scaffold.itemTypes'), t2('scaffold.manageContent')]}
        title={t2('page.title.itemTypes')}
      >
        <PermissionGuard permissions={Permission.CreateItemMetadata}>
          <Button
            className='bg-green-base px-4 py-2'
            data-testid='add-item-type-button'
            icon={<PlusIcon />}
            onClick={() => {
              setIsAddItemTypePanelOpen(true);
            }}
          >
            {t2('manage.cta.addItemType')}
          </Button>
        </PermissionGuard>
      </PageHeader>
      <div className='filters-container'>
        <SearchInput
          data-testid='search-input'
          id='item-types-search-input'
          onSearch={(e) => {
            setSearchQuery(e);
          }}
          value={searchQuery}
        />
        <Autocomplete
          className='max-sm:w-full min-w-36 sm:max-w-64 sm:w-48'
          getOptionKey={(option) => option.id}
          getOptionLabel={(option) => option.name}
          id='item-types-group-selector'
          label={Label.ItemGroup}
          multiple
          onChange={setSelectedItemGroups}
          options={selectableItemGroups}
          renderOption={getRendererWithLibraryLogo((option) => option.name)}
          value={selectedItemGroups}
        />
        <PermissionGuard permissions={Permission.Admin}>
          <Autocomplete
            className='w-full sm:w-28'
            getOptionLabel={getActiveStatusLabel}
            id='item-types-status-selector'
            label={Label.ActiveStatus}
            onChange={setSelectedStatus}
            options={activeStatuses}
            value={selectedStatus}
          />
        </PermissionGuard>
        {(selectedItemGroups.length > 0 ||
          selectedStatus !== 'Active' ||
          !!searchQuery) && <ClearAllButton onClick={resetFilters} />}
      </div>
      {selectedItemGroups.length > 0 && (
        <div className='flex items-center gap-2 pb-2'>
          <span className='text-sm font-normal pr-1'>Groups:</span>
          {selectedItemGroups.map((group) => (
            <Chip
              key={group.id}
              onRemove={() => {
                removeItemGroup(group);
              }}
              testId={`chip-${group.id}`}
              text={group.name}
            />
          ))}
        </div>
      )}
      <MuiGridWrapper
        columns={columns}
        density={isMobile ? 'compact' : 'comfortable'}
        rows={filteredItemTypes}
      />
      {isAddItemTypePanelOpen && (
        <AddEditItemType
          onClose={() => {
            setIsAddItemTypePanelOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default ItemTypes;
