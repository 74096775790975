import PermissionGuard from 'auth/PermissionGuard';
import TabNavigation from 'components/TabNavigation';
import { useInternationalization } from 'hooks/useInternationalization';
import { NavLink, Outlet } from 'react-router-dom';
import { Permission } from 'utils/permissions';

const Tasks = () => {
  const t = useInternationalization('layout.navigation.tabs');

  return (
    <div className='h-full bg-white' data-testid='tasks'>
      <TabNavigation>
        <PermissionGuard permissions={Permission.ReadTasks}>
          <NavLink id='tabnav-scheduled-tasks' to='scheduled'>
            {t('scheduled.tasks')}
          </NavLink>
          <NavLink id='tabnav-completed-tasks' to='completed'>
            {t('completed.tasks')}
          </NavLink>
        </PermissionGuard>
      </TabNavigation>
      <Outlet />
    </div>
  );
};
export default Tasks;
