import { TaskInstanceDetails } from '@dakota/platform-client';
import { Dialog } from '@mui/material';
import { useCheckPermission } from 'hooks/useCheckPermission';
import { FC } from 'react';
import { Permission } from 'utils/permissions';

import { EditTask } from './EditTask';
import { TaskLoadingAnimation } from './TaskLoadingAnimation';
import { ViewTask } from './ViewTask';

type TaskDetailsProps = {
  onClose: () => void;
  task?: TaskInstanceDetails;
};

export const TaskDetails: FC<TaskDetailsProps> = ({ onClose, task }) => {
  const hasAdminPermission = useCheckPermission(Permission.Admin);
  const hasEditTaskPermission = useCheckPermission(Permission.UpdateTask);
  /**
   * Who can edit a task:
   * - Admins
   * - Users with "edit task" permission
   */
  const canEditTask = hasAdminPermission || hasEditTaskPermission;

  return (
    <>
      {task && canEditTask && <EditTask onClose={onClose} task={task} />}
      {task && !canEditTask && <ViewTask onClose={onClose} task={task} />}
      {!task && (
        <Dialog open>
          <div className='p-12 bg-white'>
            <TaskLoadingAnimation />
          </div>
        </Dialog>
      )}
    </>
  );
};
